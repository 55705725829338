import { useMutation, useQuery } from '@tanstack/react-query';
import { PagingResponse } from '../types';
import {
  PaginateOptions,
  PaginateSearchOptions,
} from '../types/pagination.types';
import { KvsResponse, KvsDataResponse } from '../types/kvs.types';
import { ApiServiceErr, axiosApi, MutOptions, DeleteBody } from './apiService';

export interface KeyValue {
  key: string;
  value: string;
}

export interface UpdateKeyValueBody extends KeyValue {
  _id: string;
}

export interface AddNewKeyValuesBody {
  kvsGroup: string;
  kvsData: KeyValue[];
}
export interface KvsGroup {
  kvsGroup: string;
}

export interface CreateKvsBody
  extends Pick<KvsResponse, 'name' | 'description'> {
  data: KeyValue[];
}

export interface KeyIdValue {
  kvsId: string;
  value: string;
}

export interface UpdateKvsBody {
  _id: string;
  name?: string;
  description?: string;
  data?: KeyIdValue[];
}

export interface ActiveGroup {
  id: '';
  name: '';
}

export const useCreateKvs = (opt?: MutOptions<KvsResponse>) =>
  useMutation<KvsResponse, ApiServiceErr, CreateKvsBody>(async (data) => {
    const response = await axiosApi.post('/kvs-groups', data);
    return response.data;
  }, opt);

export const useGetPaginateKvs = ({
  limit,
  page,
  sortby,
  order,
  search,
}: PaginateOptions & PaginateSearchOptions) =>
  useQuery<PagingResponse<KvsResponse>, ApiServiceErr>(
    ['/kvs-groups?', limit, page, sortby, order, search],
    async () => {
      const response = await axiosApi.get('/kvs-groups', {
        params: { limit, page, sortby, order, search },
      });
      return response.data;
    },
  );

export const useGetPaginateKvsData = ({
  limit,
  page,
  kvsGroup,
  sortby,
  order,
  search,
}: PaginateOptions & PaginateSearchOptions & KvsGroup) =>
  useQuery<PagingResponse<KvsDataResponse>, ApiServiceErr>(
    ['/kvs-data', limit, page, kvsGroup, sortby, order, search],
    async () => {
      const response = await axiosApi.get('/kvs-data', {
        params: { limit, page, kvsGroup, sortby, order, search },
      });
      return response.data;
    },
  );

export const useUpdateKvs = (opt?: MutOptions<KvsResponse>) =>
  useMutation<KvsResponse, ApiServiceErr, UpdateKvsBody>(async (data) => {
    let updateData = {};
    if (data.data) {
      updateData = {
        data: data.data,
      };
    } else {
      updateData = {
        name: data.name,
        description: data.description,
      };
    }
    const response = await axiosApi.put(`/kvs-groups/${data._id}`, updateData);
    return response.data;
  }, opt);

export const useGetKvsGroup = ({
  limit,
  page,
  sortby,
  order,
  search,
  kvsGroupId,
}: PaginateOptions & PaginateSearchOptions & { kvsGroupId: string }) =>
  useQuery<PagingResponse<KvsDataResponse> & KvsResponse, ApiServiceErr>(
    ['/kvs-groups', kvsGroupId, limit, page, sortby, order, search],
    async () => {
      if (!kvsGroupId) return;
      const response = await axiosApi.get(`/kvs-groups/${kvsGroupId}`, {
        params: { limit, page, sortby, order, search },
      });
      return response.data;
    },
  );

export const useUpdateKeyValue = (opt?: MutOptions<KvsResponse>) =>
  useMutation<KvsResponse, ApiServiceErr, UpdateKeyValueBody>(async (data) => {
    const response = await axiosApi.put(`/kvs-data/${data._id}`, {
      key: data.key,
      value: data.value,
    });
    return response.data;
  }, opt);

export const useAddKeyValues = (opt?: MutOptions<KvsResponse>) =>
  useMutation<KvsResponse, ApiServiceErr, AddNewKeyValuesBody>(async (data) => {
    const response = await axiosApi.post('/kvs-data', data);
    return response.data;
  }, opt);

export const useDeleteKvsGroup = (opt?: MutOptions<KvsResponse>) =>
  useMutation<KvsResponse, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/kvs-groups/${data.id}`);
    return response.data;
  }, opt);

export const useDeleteKvsKeyValue = (opt?: MutOptions<KvsDataResponse>) =>
  useMutation<KvsDataResponse, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/kvs-data/${data.id}`);
    return response.data;
  }, opt);

export const useGetKeyValueListOfaGroup = ({
  kvsGroupId,
}: {
  kvsGroupId?: string;
}) =>
  useQuery<PagingResponse<KvsDataResponse> & KvsResponse, ApiServiceErr>(
    ['/kvs-groups', kvsGroupId],
    async () => {
      if (!kvsGroupId) return;
      const response = await axiosApi.get(`/kvs-groups/${kvsGroupId}`, {
        params: { all: true },
      });
      return response.data;
    },
  );

import React, { FC, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import Stack from '@mui/material/Stack';

import { useAdminResetPassword } from '../../../api-http/superAdmin';
import { AlertBar } from '../../../components/AlertBar';
import { authMsg, notifyMsg } from '../../../configs';
import { Nullable } from '../../../types';
import { BaseUserWithOrg } from '../../../types/user.types';
import { useSnackbar } from 'notistack';
import { ERROR, SUCCESS } from '../../../util/constant';
import { getApiError } from '../../../api-http/apiService';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface ResetPasswordFormProps {
  onFinished: () => void;
  userData?: Nullable<BaseUserWithOrg>;
}

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .max(255)
    .required(authMsg.NEW_PASSWORD_REQUIRED)
    .min(6, authMsg.PASSWORD_6CHAR_REQUIRED)
    .matches(/^[^\s]+$/, authMsg.PASSWORD_SPACES)
    .test(
      'passwordRequirements',
      authMsg.PASSWORD_COMBINATION_REQUIRED,
      (value: any) =>
        [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every((pattern) =>
          pattern.test(value),
        ),
    ),

  confirmNewPassword: yup
    .string()
    .max(255)
    .required(authMsg.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('newPassword')], authMsg.PASSWORD_UNMATCHED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  onFinished,
  userData,
}) => {
  const { isLoading, error, isSuccess, mutate, isError } =
    useAdminResetPassword();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useEffect(() => {
    if (isSuccess) {
      onFinished();
      enqueueSnackbar(notifyMsg.PASSWORD_CHANGED_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      const errorMsg = getApiError(error);
      enqueueSnackbar(errorMsg, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError]);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate({
        newPassword: values?.newPassword,
        id: userData?._id || '',
      });
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-newpassword">
            New Password
          </InputLabel>

          <OutlinedInput
            fullWidth
            placeholder="New Password"
            id={'newPassword'}
            name="newPassword"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="New Password"
            style={{ marginBottom: '10px' }}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
          />
          <FormHelperText error id="accountId-error">
            {formik.errors.newPassword}
          </FormHelperText>
        </FormControl>
        <Stack spacing={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-confirmpassword">
              Confirm Password
            </InputLabel>

            <OutlinedInput
              fullWidth
              placeholder="Confirm Password"
              id={'confirmNewPassword'}
              name="confirmNewPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              value={formik.values.confirmNewPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
              error={
                formik.touched.confirmNewPassword &&
                Boolean(formik.errors.confirmNewPassword)
              }
            />
            <FormHelperText error id="accountId-error">
              {formik.errors.confirmNewPassword}
            </FormHelperText>
          </FormControl>

          <Button
            color={'primary'}
            disabled={isLoading}
            variant={'contained'}
            fullWidth
            type={'submit'}
          >
            Update
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

import { useGetApks } from '../../api-http/apk';
import { PageTitle } from '../../components/PageTitle';
import { APK_UPLOAD_ACCESS_USER } from '../../configs';
import { useAuth } from '../../context/auth-context';
import { metaTitle } from '../../util/tabTitle';
import ApkTable from './ApkTable';
import ApkUploadForm from './ApkUploadForm';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React, { FC, useState } from 'react';
import { Download } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const HomePage: FC = () => {
  metaTitle('Home');

  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const { refetch, error, data: apksData, isLoading } = useGetApks();

  const [expanded, setExpanded] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const handleAccordionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    const downloadLink = apksData?.data[0]?.key;

    const anchor = document.createElement('a');
    anchor.href = downloadLink;
    anchor.download = apksData?.data[0]?.name;
    anchor.click();
  };

  const handleExpandIconClick = () => {
    setExpanded((prevExpanded) => !prevExpanded);
    if (accordionExpanded) {
      setTimeout(() => setAccordionExpanded(!accordionExpanded), 100);
    } else {
      setAccordionExpanded(!accordionExpanded);
    }
  };

  return (
    <div>
      <PageTitle>Home Page</PageTitle>

      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Beta Release
          </Typography>

          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Codename: {currentUser?.organization?.pinCode}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => navigate('/slides')}>
            get started
          </Button>
        </CardActions>
      </Card>

      {currentUser?.email === APK_UPLOAD_ACCESS_USER && (
        <ApkUploadForm onFinished={refetch} />
      )}

      <Accordion style={{ marginTop: 20 }} expanded={expanded}>
        <AccordionSummary
          expandIcon={
            <Button
              style={{ display: 'flex', alignItems: 'center' }}
              disableRipple
            >
              {!accordionExpanded && <h3>Older versions</h3>}
              <ExpandMore />
            </Button>
          }
          onClick={handleExpandIconClick}
        >
          <h3 onClick={handleAccordionClick}>
            Latest APK version: {apksData?.data[0]?.name} <Download size={16} />
          </h3>
        </AccordionSummary>
        <AccordionDetails>
          <ApkTable data={apksData} error={error} isLoading={isLoading} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default HomePage;

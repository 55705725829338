import { FC } from 'react';
import Span from './components/Span';
import circle from '../../assets/circle.png';
import rectangle from '../../assets/rectangle.png';
import styled from 'styled-components';
import { fabric } from 'fabric';
import { useFabricEditor } from '../../lib/FabricJs';
import { getRanCoord } from '../../utils/getRanCoord';
import { ShapeType } from '../../types';

const shapesList = [
  { title: 'Rectangle', icon: rectangle, type: ShapeType.Rectangle },
  { title: 'Circle', icon: circle, type: ShapeType.Circle },
];

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100px;
  width: 100px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgb(0, 0, 0, 20%);
  }
`;

const Image = styled.img`
  width: 100%;
  max-height: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
`;

const ShapeWidget: FC = () => {
  const { canvas } = useFabricEditor();

  const onAddShape = (type: string) => {
    if (!canvas) return;

    if (type === ShapeType.Circle) {
      const object = new fabric.Circle({
        ...getRanCoord(canvas),
        type,
        radius: 100,
        fill: 'black',
      });
      canvas?.add(object);
    } else if (type === ShapeType.Rectangle) {
      const object = new fabric.Rect({
        ...getRanCoord(canvas),
        width: 100,
        height: 100,
        angle: 0,
        type,
        fill: 'black',
      });
      canvas?.add(object);
    } else {
      // eslint-disable-next-line no-alert
      alert('Not Implemented Yet');
    }
  };

  return (
    <>
      <Span>Lines & Shapes</Span>
      <Container>
        {shapesList.map(({ title, icon, type }, index) => {
          return (
            <ImageContainer
              key={index}
              role="banner"
              aria-label={title}
              onClick={() => onAddShape(type)}
            >
              <Image src={icon} alt={title} />
            </ImageContainer>
          );
        })}
      </Container>
    </>
  );
};

export default ShapeWidget;

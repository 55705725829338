import { Grid, Typography, capitalize } from '@mui/material';
import { ActivityMainWrapper, ActivityWrapper } from '../styles';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import { FC, useEffect } from 'react';
import { useGetDeviceLogs } from '../../../api-http/devices';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import { Loader } from 'react-feather';
import { displayDistance } from '../../../util/dateTime';
import { useSnack } from '../../../util/useSnack';
import { SocketData } from '../../../types';
import useSocket from '../../../util/useSocket';
import { SOCKET_EVENTS } from '../../../util/constant';

export interface RecentActivityProps {
  id?: string;
  deviceId?: string;
}

const RecentActivity: FC<RecentActivityProps> = ({ id, deviceId }) => {
  const { data: logs, isLoading, refetch, error } = useGetDeviceLogs(id);

  const { showErrSnack } = useSnack();
  const { socket } = useSocket();

  if (error) {
    showErrSnack(error.msg);
  }

  useEffect(() => {
    const updateDeviceListener = (socketData: SocketData) => {
      if (!deviceId) {
        return;
      }
      const { data: deviceIdList } = socketData;

      if (deviceIdList.includes(deviceId)) {
        refetch();
      }
    };

    if (socket) {
      socket.on(SOCKET_EVENTS.updateDevice, updateDeviceListener);
    }

    return () => {
      if (socket) {
        socket.off(SOCKET_EVENTS.updateDevice, updateDeviceListener);
      }
    };
  }, [socket, deviceId]);

  return (
    <ActivityMainWrapper>
      <Grid container>
        <Grid item xs={10}>
          <Typography fontWeight={'bold'} variant="h6" gutterBottom>
            Recent Activity
          </Typography>
        </Grid>
        {/* <Grid item xs={2}>
          <Button variant="text">View All</Button>
        </Grid> */}
        <Grid xs={12}>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {
              // eslint-disable-next-line no-nested-ternary
              !isLoading ? (
                logs && logs.length > 0 ? (
                  logs.map((log, index) => {
                    return (
                      <TimelineItem
                        style={{ marginTop: '-10px' }}
                        key={log.Date.toString()}
                      >
                        <TimelineSeparator>
                          <TimelineDot variant="outlined" color="primary" />
                          {index != logs.length - 1 && (
                            <TimelineConnector
                              sx={{ height: '10px', marginTop: '-8px' }}
                            />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography fontWeight={'medium'} variant="body2">
                            {capitalize(displayDistance(log.Date.toString()))}
                          </Typography>
                          <Typography variant="caption" color={'gray'}>
                            {log.action}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })
                ) : (
                  <ActivityWrapper>
                    <Typography
                      marginLeft={'-15px'}
                      color={'gray'}
                      marginBottom={'10px'}
                      fontSize={15}
                    >
                      This device has no recent activity
                    </Typography>
                    <RemoveFromQueueIcon
                      style={{ color: 'gray', fontSize: '45px' }}
                    />
                  </ActivityWrapper>
                )
              ) : (
                <ActivityWrapper>
                  <Loader />
                </ActivityWrapper>
              )
            }
          </Timeline>
        </Grid>
      </Grid>
    </ActivityMainWrapper>
  );
};

export default RecentActivity;

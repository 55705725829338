import { useFabricEditor } from '../../lib/FabricJs';
import { MenuItem, Popover } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import { useHandleHistory } from '../../lib/FabricJs/hooks/useHandleHistory';

const Button = styled.button`
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const Position = () => {
  const { canvas } = useFabricEditor();
  const { handleHistory } = useHandleHistory();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const arialDescribedId = open ? 'simple-popover' : undefined;
  const activeObjects = canvas?.getActiveObject();
  if (!canvas || !activeObjects) {
    return null;
  }

  return (
    <>
      <Button
        aria-describedby={arialDescribedId}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Position
      </Button>
      <Popover
        id={arialDescribedId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            canvas?.bringToFront(activeObjects);
            handleClose();
            handleHistory();
          }}
        >
          Move Front
        </MenuItem>
        <MenuItem
          onClick={() => {
            canvas?.bringForward(activeObjects);
            handleClose();
            handleHistory();
          }}
        >
          Move Forward
        </MenuItem>
        <MenuItem
          onClick={() => {
            canvas?.sendToBack(activeObjects);
            handleClose();
            handleHistory();
          }}
        >
          Move Back
        </MenuItem>
        <MenuItem
          onClick={() => {
            canvas?.sendBackwards(activeObjects);
            handleClose();
            handleHistory();
          }}
        >
          Move Backward
        </MenuItem>
      </Popover>
    </>
  );
};

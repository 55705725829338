import React, { FC, useRef, MouseEvent } from 'react';
import styled from 'styled-components';
import { v4 as uid } from 'uuid';

import { Typography } from '@mui/material';
import { InputType } from '../../types';
import { useEditorData } from '../../EditorProvider';
import { addElement } from '../../utils/surveyDataUtils';

const TAB_PANEL_WIDTH = 350;
const TAB_PANEL_MIN_WIDTH = 250;

interface TabProps {
  tabs: Array<{
    title: string;
    icon?: JSX.Element;
    children?: JSX.Element;
    type: string;
  }>;
  id: string;
}

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const TabList = styled.ul`
  display: flex;
  list-style-type: none;
  flex-direction: column;
  padding: 20px 0px;
  margin: 0;
  background-color: #18191b;
  min-width: ${TAB_PANEL_MIN_WIDTH}px;
  max-width: ${TAB_PANEL_WIDTH}px;
`;

const TabItem = styled.li`
  margin: 0;
`;

const IconWrapper = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;
  opacity: 0.8;
  width: 100%;
  transition: 0.3s;
  cursor: pointer;
  &: hover {
    opacity: 1;
    background-color: #252627;
  }
`;

const Tab = styled.a`
  display: flex;
  flex-grow: 1;
  text-decoration: none;
`;

const Tabs: FC<TabProps> = ({ tabs = [] }) => {
  const { history, setHistory, selectedItem, setSelectedItem } =
    useEditorData();

  const { present: surveyData } = history;
  const tabRefs = useRef<any>({});

  const handleClick = (
    tab: string,
    type: string,
    event?: MouseEvent<HTMLAnchorElement>,
  ) => {
    event?.preventDefault();

    const elementId = uid();
    const pageId = selectedItem?.pageId || uid();

    setSelectedItem({ pageId, elementId, type: type as InputType });

    const params = {
      surveyData,
      pageId,
      elementId,
      setHistory,
      type: type as InputType,
    };
    addElement(params);
  };

  return (
    <Wrap>
      <TabList role="tablist" aria-label="Side Panel">
        {tabs.map(({ title, icon, type }, index) => {
          return (
            <TabItem key={title}>
              <Tab
                key={index}
                onClick={(e) => handleClick(title, type, e)}
                ref={(ref) => (tabRefs.current[title] = ref)}
                role="tab"
              >
                {icon && (
                  <IconWrapper>
                    <>
                      {icon}
                      <Typography
                        variant="h6"
                        ml={1}
                        color="white"
                        fontSize={16}
                      >
                        {title}
                      </Typography>
                    </>
                  </IconWrapper>
                )}
              </Tab>
            </TabItem>
          );
        })}
      </TabList>
    </Wrap>
  );
};

export default Tabs;

import React, { ChangeEvent, ElementRef, FC, useRef, useState } from 'react';

import { Button, Stack, debounce } from '@mui/material';

import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import KvsKeyValuesTable from './KvsKeyValuesTable';
import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import { StyleTextfield } from '../../components/TextField';
import { DialogModal } from '../../components';
import { AddKeyValuesForm } from './AddKeyValuesForm';
import { DEBOUNCE_TIME } from '../../configs';
import Breadcrumbs from '../../components/Breadcrumb';
import { useLocation, useParams } from 'react-router-dom';

const KvsGroupPage: FC = () => {
  metaTitle('KVS Group');
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  type KvsKeyValueHandle = ElementRef<typeof KvsKeyValuesTable>;
  const kvsKeyValueTableRef = useRef<KvsKeyValueHandle>(null);

  const disableAddNew = useIsUserRole([
    UserRole.user,
    UserRole.layoutEditor,
    UserRole.dataEditor,
  ]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const paramId = useParams();
  const { id: kvsGroupId } = paramId;

  const location = useLocation();
  const { state, pathname } = location;
  const kvsGroupName = state?.groupName;

  const onRefetchData = () => {
    kvsKeyValueTableRef.current?.onRefetch();
  };

  const searchParams = state?.search;
  const kvsPageUrl = searchParams ? `/kvs${searchParams}` : '/kvs';

  return (
    <>
      <>
        <Breadcrumbs
          items={[
            {
              label: 'KVS',
              to: kvsPageUrl,
            },
            { label: `${kvsGroupName}`, to: pathname },
          ]}
        />
        <Stack>
          <FilterSelectWrapper>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              disabled={disableAddNew}
            >
              Add new key values
            </Button>
            <StyleTextfield
              id="keyValueSearch"
              label="Search Key Values"
              variant="outlined"
              onChange={debounce((event) => onSearch(event), DEBOUNCE_TIME)}
            />
          </FilterSelectWrapper>
        </Stack>
        <KvsKeyValuesTable
          searchText={searchText}
          kvsGroupId={kvsGroupId as string}
          ref={kvsKeyValueTableRef}
        />
        <DialogModal
          title="Add New Key Values"
          onClose={() => setOpen(false)}
          open={open}
          style={{ width: '40%' }}
        >
          <AddKeyValuesForm
            onFinished={() => setOpen(false)}
            kvsGroup={kvsGroupId as string}
            onRefetchData={onRefetchData}
          />
        </DialogModal>
      </>
    </>
  );
};

export default KvsGroupPage;

import React, { FC, useState } from 'react';

import { useFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert as MuiAlert,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { spacing } from '@mui/system';

import { authMsg, formMsg } from '../../../../configs';
import { UserRole } from '../../../../types/user.types';
import { useUserTypes } from '../../../../util/useUserTypes';
import { BaseOrganization } from '../../../../types/organization.types';
import { Nullable } from '../../../../types';
import { ApiServiceErr } from '../../../../api-http/apiService';
import { RegisterBody } from '../../../../api-http/users';

interface UserFormProps {
  onSubmit: (data: RegisterBody) => void;
  isLoading: boolean;
  error?: Nullable<ApiServiceErr>;
  currentUserRole?: UserRole;
  organizations?: BaseOrganization[];
  selectedOrgId?: string;
  showOrganizationSelector?: boolean;
}

const validationSchema = yup.object({
  name: yup.string().required(formMsg.NAME_REQUIRED),
  email: yup
    .string()
    .email(authMsg.EMAIL_INVALID)
    .required(authMsg.EMAIL_REQUIRED),
  password: yup
    .string()
    .required(authMsg.PASSWORD_REQUIRED)
    .min(8, authMsg.PASSWORD_6CHAR_REQUIRED)
    .max(16, authMsg.PASSWORD_MAXIMUM_16CHARACTERS)
    .matches(/^[^\s]+$/, authMsg.PASSWORD_SPACES)
    .test(
      'passwordRequirements',
      authMsg.PASSWORD_COMBINATION_REQUIRED,
      (value: any) =>
        [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every((pattern) =>
          pattern.test(value),
        ),
    ),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

const Alert = styled(MuiAlert)(spacing);

export const UserForm: FC<UserFormProps> = ({
  isLoading,
  onSubmit,
  error,
  currentUserRole = UserRole.user,
  selectedOrgId,
}) => {
  const userTypes = useUserTypes(currentUserRole);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      organization: selectedOrgId,
      name: '',
      email: '',
      role: userTypes[0].id,
      password: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        {error && (
          <Alert mt={2} mb={3} severity="warning">
            {error.msg}
          </Alert>
        )}

        <TextField
          fullWidth
          id="name"
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          style={{ marginBottom: 10 }}
        />

        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          style={{ marginBottom: 10 }}
        />
        <FormControl sx={{ width: '100%', minWidth: 200 }}>
          <InputLabel id="role">Role</InputLabel>
          <Select
            fullWidth
            id="role"
            name="role"
            value={formik.values.role}
            label="User Role"
            title="User Role"
            placeholder="User Role"
            onChange={formik.handleChange}
            style={{ marginBottom: 10 }}
          >
            {userTypes.map((userType) => (
              <MenuItem key={userType.id} value={userType.id}>
                {userType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            style={{ marginBottom: 10 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {Boolean(formik.errors.password) && formik.touched.password ? (
          <FormHelperText error id="password-error">
            {formMsg.PASSWORD_REQUIREMENTS_FAILED}
          </FormHelperText>
        ) : null}
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={isLoading}
        >
          Create
        </Button>
      </form>
    </Container>
  );
};

import { DialogModal } from '../../components';
import Breadcrumbs from '../../components/Breadcrumb';
import { StyleTextfield } from '../../components/TextField';
import devices from '../../components/canvas-editor-v2/assets/devices.json';
import { FilterSelect } from '../../components/canvas-editor-v2/components/common/FilterSelect';
import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import { DEBOUNCE_TIME } from '../../configs';
import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { SlideForm } from './SlideForm';
import SlideTable from './SlidesTable';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  debounce,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { FC, useState } from 'react';
import RefreshButton from '../../components/RefreshButton';
import { useHandleSearch } from '../../util/useHandleSearch';
import { useLocationSearch } from '../../util/useLocationSearch';

const SlidesPage: FC = () => {
  metaTitle('Slides');

  const searchVal = useLocationSearch();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(searchVal || '');
  const [searchedSlide, setSearchedSlide] = useState(searchVal || '');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [refresh, setRefresh] = useState(false);

  useHandleSearch(search);

  const isViewOnly = useIsUserRole([UserRole.user, UserRole.dataEditor]);

  const searchSlides = (searchTerm: string) => {
    setSearchedSlide(searchTerm);
  };

  const handleDeviceChange = (
    event: SelectChangeEvent<typeof selectedDevice>,
  ) => {
    setSelectedDevice(event.target.value);
  };

  return (
    <div>
      <Breadcrumbs
        items={[
          {
            label: 'Slides',
            to: '/slides',
            description:
              'This page houses all created slides and enables users to create and customize slides for presentations. Users can choose from various components such as images, videos, text, shapes, KVS values, and widgets. They can create and edit slides multiple times within a presentation.Slides can be filtered by screen type and searched by name. Available actions include renaming slides, cloning (creating copies), and removing slides.',
          },
        ]}
      />

      <Stack>
        <FilterSelectWrapper>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              disabled={isViewOnly}
              sx={{ marginRight: '15px' }}
            >
              Add new
            </Button>

            <RefreshButton onClick={() => setRefresh(!refresh)} />
          </div>
          <div>
            <FilterSelect>
              <FormControl
                sx={{
                  '& > :not(style)': {
                    width: '30ch',
                    height: '50px',
                    paddingLeft: '10px',
                    fontSize: '14px',
                  },
                }}
              >
                <InputLabel id="device">Screen Size</InputLabel>
                <Select
                  value={selectedDevice}
                  id="device"
                  name="device"
                  label="Filter By Device"
                  title="device"
                  onChange={handleDeviceChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {devices?.map((device) => (
                    <MenuItem key={device.id} value={device.id}>
                      {device.name} - ({device.type})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FilterSelect>
            <StyleTextfield
              label="Search Slides"
              variant="outlined"
              onChange={(event) => {
                setSearch(event.target.value);
                debounce(
                  () => searchSlides(event.target.value),
                  DEBOUNCE_TIME,
                )();
              }}
              value={search}
            />
          </div>
        </FilterSelectWrapper>
      </Stack>

      <SlideTable
        searchedSlide={searchedSlide}
        selectedDevice={selectedDevice}
        onRefresh={refresh}
      />

      <DialogModal title="New Slide" onClose={() => setOpen(false)} open={open}>
        <SlideForm onFinished={() => setOpen(false)} />
      </DialogModal>
    </div>
  );
};

export default SlidesPage;

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useHandleSearch = (search: string) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    if (search) {
      searchParams.set('search', search);
    } else {
      searchParams.delete('search');
    }
    const newUrl = `?${searchParams.toString()}`;
    navigate(newUrl);
  }, [search]);

  return {};
};

import { getUrl } from '../util/selectRoute';

export const ADVERTEX_API_URL =
  process.env.REACT_APP_API || 'https://api-v3.stec.tv/api';
export const DISPLAY_HOST_NAME = 'stec.tv';
export const STATIC_BASE_URI = 'https://stec.fra1.digitaloceanspaces.com';
export const WS_URL = getUrl('WS_URL');

export const isDevelopment = process.env.NODE_ENV === 'development';
export const DEBOUNCE_TIME = 1000;
export const APP_ROUTE = getUrl('APP_ROUTE');

export const BBC_NEWS_URL = 'https://rss.app/feeds/v1.1/JZMODiNmWEzKCTv1.json';
export const CNN_NEWS_URL = 'https://rss.app/feeds/v1.1/O1CXUFaT7ZBhzUgn.json';

export const APK_UPLOAD_ACCESS_USER = 'apkadmin@stec.tv';

import { VerifyPinBody } from '../../../api-http/devices';
import { DialogModal } from '../../../components';
import { RegisterForm } from './RegisterForm';
import { VerifyPinCode } from './VerifyPinCode';
import { FC, useState } from 'react';

interface AddUserModalProps {
  onFinished: () => void;
  onClose: () => void;
  open: boolean;
}

export const AddDeviceModal: FC<AddUserModalProps> = ({
  onFinished,
  open,
  onClose,
  ...props
}) => {
  const [step, setStep] = useState(0);
  const [deviceData, setDeviceData] = useState<VerifyPinBody>();

  const closeModal = () => {
    onClose();
    onFinished();
    setStep(0);
  };

  return (
    <DialogModal title={'Add Device'} onClose={closeModal} open={open}>
      {step === 0 && (
        <VerifyPinCode
          {...props}
          onSuccess={(data: VerifyPinBody) => {
            setDeviceData(data);
            setStep(1);
          }}
          isLoading={false}
          error={null}
        />
      )}
      {step === 1 && (
        <RegisterForm
          {...props}
          onSuccess={() => {
            closeModal();
          }}
          isLoading={false}
          error={null}
          deviceData={deviceData}
        />
      )}
    </DialogModal>
  );
};

import { PagingResponse } from '../types';
import { ApiServiceErr, axiosApi, MutOptions } from './apiService';
import { useMutation, useQuery } from '@tanstack/react-query';

export interface ApkResponse {
  _id: string;
  name: string;
  version: string;
  key: string;
}

export const useUploadApk = (opt?: MutOptions<any>) =>
  useMutation<any, ApiServiceErr, any>(async (data) => {
    const response = await axiosApi.post('/apk/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }, opt);

export const useGetApks = () =>
  useQuery<PagingResponse<ApkResponse>, ApiServiceErr>(
    ['/apk/all?limit=1&page=1'],
    async () => {
      const response = await axiosApi.get('/apk/all');
      return response.data;
    },
  );

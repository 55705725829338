import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { Download, CornerUpLeft, CornerUpRight } from 'react-feather';
import styled from 'styled-components';
import { isDevelopment } from '../../../../configs';
import { useFabricEditor } from '../../lib/FabricJs';
import { useEditorData } from '../../lib/FabricJs';
import { getControlButtonText } from '../../utils/utils';
import { SvgButton } from '../../../common/ToolBar';

const FooterBarContainer = styled.footer`
  background: white;
  display: flex;
  height: 50px;
  border-top: 1px solid lightgray;
  padding: 10px;
`;

const exportJSonToFile = (json: unknown) => {
  const link = document.createElement('a');
  const file = new Blob([JSON.stringify(json)], { type: 'text/plain' });
  link.href = URL.createObjectURL(file);
  link.download = 'json-file.json';
  link.click();
  URL.revokeObjectURL(link.href);
};

const FooterBar: FC = () => {
  const { canvas } = useFabricEditor();
  const { undo, redo, canUndo, canRedo } = useEditorData();
  const onExportToJson = () => {
    const json = canvas?.toJSON(['data']); // TODO: reuse this
    if (json) {
      exportJSonToFile(json);
    }
  };
  const controlButtonText = getControlButtonText();
  return (
    <FooterBarContainer>
      {isDevelopment && (
        <Tooltip title="Download is available only for the development">
          <SvgButton onClick={onExportToJson}>
            <Download />
          </SvgButton>
        </Tooltip>
      )}

      {canUndo ? (
        <Tooltip title={`Undo (${controlButtonText} + z)`}>
          <SvgButton onClick={undo}>
            <CornerUpLeft />
          </SvgButton>
        </Tooltip>
      ) : (
        <SvgButton disabled>
          <CornerUpLeft />
        </SvgButton>
      )}

      {canRedo ? (
        <Tooltip title={`Undo (${controlButtonText} + shift + z)`}>
          <SvgButton onClick={redo}>
            <CornerUpRight />
          </SvgButton>
        </Tooltip>
      ) : (
        <SvgButton disabled>
          <CornerUpRight />
        </SvgButton>
      )}
    </FooterBarContainer>
  );
};

export default FooterBar;

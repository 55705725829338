import {
  Circle,
  Minus,
  Smile,
  ChevronDown,
  ChevronsDown,
  ToggleLeft,
  AlignJustify,
} from 'react-feather';
import styled from 'styled-components';
import Tabs from './Tabs/Tabs';
import { InputType } from '../types';

interface StyleProps {
  color?: string;
}

const SidePanelContainer = styled.div<StyleProps>`
  background: ${({ color }) => color};
  display: flex;
  border-right: 1px solid lightgray;
`;

const {
  RADIO_BUTTON,
  RATING_SCALE,
  DROP_DOWN,
  MULTI_DROP_DOWN,
  TOGGLE,
  SINGLE_LINE,
  MULTI_LINE,
} = InputType;

const tabs = [
  {
    title: 'Radio Button Group',
    icon: <Circle color="white" size={16} />,
    type: RADIO_BUTTON,
  },
  {
    title: 'Rating Scale',
    icon: <Smile color="white" size={16} />,
    type: RATING_SCALE,
  },
  {
    title: 'Drop Down',
    icon: <ChevronDown color="white" size={16} />,
    type: DROP_DOWN,
  },
  {
    title: 'Multi Select Drop Down',
    icon: <ChevronsDown color="white" size={16} />,
    type: MULTI_DROP_DOWN,
  },
  {
    title: 'Yes/No (Boolean)',
    icon: <ToggleLeft color="white" size={16} />,
    type: TOGGLE,
  },
  {
    title: 'Single Line Input',
    icon: <Minus color="white" size={16} />,
    type: SINGLE_LINE,
  },
  {
    title: 'Multi Line Input',
    icon: <AlignJustify color="white" size={16} />,
    type: MULTI_LINE,
  },
];

const SidePanel = () => {
  return (
    <SidePanelContainer>
      <Tabs tabs={tabs} id="side-panel" />
    </SidePanelContainer>
  );
};

export default SidePanel;

import {
  useCreateSlide,
  useDeleteSlide,
  useGetPaginateSlide,
} from '../../api-http/slides-v2';
import { SlideResponse } from '../../types/slide.types';
import { DialogModal } from '../../components';
import { AlertBar } from '../../components/AlertBar';
import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../components/TableTheme';
import { CanvasPreviewModalV2 } from '../../components/canvas-preview-v2/CanvasPreviewModalV2';
import { notifyMsg } from '../../configs';
import { useAuth } from '../../context/auth-context';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import { displayDistance } from '../../util/dateTime';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useSnack } from '../../util/useSnack';
import { useTable } from '../../util/useTable';
import { RenameSlideForm } from './RenameSlideForm';
import { SlideActionMenu } from './SlideActionsMenu';
import {
  Brush as BrushIcon,
  LiveTvRounded as LiveTvRoundedIcon,
} from '@mui/icons-material';
import {
  Button,
  LinearProgress,
  TablePagination,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useConfirm } from 'material-ui-confirm';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ChevronDown } from 'react-feather';
import { SOCKET_EVENTS } from '../../util/constant';
import { BouncingLoader } from '../../components/canvas-editor-v2/components/common/BouncingLoader';
import { styled } from '@mui/system';
import { green } from '@mui/material/colors';
import useSocket from '../../util/useSocket';

const iconStyle = {
  fontSize: '18px',
};

const Dot = styled('span')({
  display: 'inline-block',
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: green[500],
  marginRight: '5px',
});
interface SlideTableProp {
  searchedSlide?: string;
  selectedDevice?: string;
  onRefresh: boolean;
}

const SlideTable: React.FC<SlideTableProp> = ({
  searchedSlide,
  selectedDevice,
  onRefresh,
}) => {
  const { socket } = useSocket();
  const isViewOnly = useIsUserRole([UserRole.user, UserRole.dataEditor]);
  const location = useLocation();

  const confirm = useConfirm();
  const { switchedOrgId, currentUser } = useAuth();
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { sortOnClick, pagingParams, tableProps } = useTable(true);
  const { sortby, order } = pagingParams;

  const {
    data: list,
    isLoading: getAllSlidesIsLoading,
    error,
    refetch,
    isRefetching,
  } = useGetPaginateSlide({
    ...pagingParams,
    device: selectedDevice,
    search: searchedSlide,
  });

  const { error: deleteError, mutate: deleteSlide } = useDeleteSlide({
    onSuccess: () => {
      refetch();
      showSuccessSnack(notifyMsg.SLIDE_DELETE_SUCCESS);
    },
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  const { mutate: createSlide, error: createError } = useCreateSlide({
    onSuccess: () => {
      refetch();
      showSuccessSnack(notifyMsg.SLIDE_CLONE_SUCCESS);
    },
    onError: (err) => showErrSnack(err),
  });

  const [renameSlideModal, setRenameSlideModal] = useState(false);
  const [activeSlide, setActiveSlide] = useState<SlideResponse | null>(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    refetch();
  }, [switchedOrgId, onRefresh]);

  const delayedEffect = () => {
    refetch();
  };

  useEffect(() => {
    const timeout = setTimeout(delayedEffect, 1000);
    return () => clearTimeout(timeout);
  }, [socket]);

  useEffect(() => {
    refetch();
  }, [selectedDevice]);

  useEffect(() => {
    refetch();
  }, [searchedSlide]);

  const handleMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    slide: SlideResponse,
  ) => {
    setActiveSlide(slide);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const remove = async () => {
    try {
      if (activeSlide?._id) {
        await confirm({
          description: notifyMsg.DELETE_CONFIRMATION,
          confirmationText: 'Yes',
        });
        deleteSlide({ id: activeSlide?._id });
      }
    } catch (e) {}
  };

  const clone = () => {
    createSlide({
      dynamicData: activeSlide?.dynamicData || [],
      projectData: activeSlide?.projectData || {},
      status: activeSlide?.status || true,
      title: `${activeSlide?.title}_clone`,
    });
    handleMenuClose();
  };

  useEffect(() => {
    const { dataConnection, editSlide } = SOCKET_EVENTS;
    if (socket) {
      socket.on(dataConnection, (socketData) => {
        const { event } = socketData;

        if (event === editSlide) {
          refetch();
        }
      });
    }
    return () => {
      if (socket) {
        socket.off(dataConnection);
      }
      refetch();
    };
  }, [socket]);

  const isLinerLoading = getAllSlidesIsLoading || isRefetching;

  return (
    <>
      {isLinerLoading && <LinearProgress />}
      <AlertBar
        severity="error"
        msg={error?.msg || deleteError?.msg || createError?.msg}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <StyledTableSortLabel
                  active={sortby === SortEnum.title || true}
                  direction={sortby === SortEnum.title ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.title)}
                >
                  Slide Title
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell>Device</StyledTableCell>
              <StyledTableCell>Updated By</StyledTableCell>
              <StyledTableCell>
                <StyledTableSortLabel
                  active={sortby === SortEnum.updatedAt || true}
                  direction={
                    sortby === SortEnum.updatedAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.updatedAt)}
                >
                  Updated
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>
                <StyledTableSortLabel
                  active={sortby === SortEnum.createdAt || true}
                  direction={
                    sortby === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Created
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center">Edit Status</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!list?.data?.length && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {isLinerLoading ? <CircularProgress /> : 'No Records Found '}
                </TableCell>
              </TableRow>
            )}
            {list?.data.map((slide) => {
              const {
                isEditing,
                editingBy,
                _id,
                title,
                updatedBy,
                updatedAt,
                createdAt,
                createdBy,
              } = slide;
              return (
                <TableRow
                  key={_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {isViewOnly || isEditing ? (
                      title
                    ) : (
                      <NavLink
                        style={{
                          textDecoration: 'underline',
                          textUnderlineOffset: '5px',
                        }}
                        to={`/editor-v2/${_id}`}
                        state={{
                          search: location?.search,
                        }}
                      >
                        <BrushIcon
                          sx={{
                            fontSize: '13px',
                            marginRight: '5px',
                            marginBottom: '-2px',
                          }}
                        />
                        {title}
                      </NavLink>
                    )}
                  </TableCell>
                  <TableCell>
                    {slide.projectData?.deviceInfo?.name}(
                    {slide.projectData?.deviceInfo?.type})
                  </TableCell>
                  <TableCell>{updatedBy}</TableCell>
                  <TableCell>{displayDistance(updatedAt)}</TableCell>
                  <TableCell>{createdBy}</TableCell>
                  <TableCell>{displayDistance(createdAt)}</TableCell>

                  <TableCell align="center">
                    {isEditing ? (
                      <Tooltip
                        title={
                          editingBy?._id == currentUser?._id
                            ? `You are currently editing this slide`
                            : `Editing By ${editingBy?.name}`
                        }
                      >
                        <span>
                          <BouncingLoader size={8} color="Black" />
                        </span>
                      </Tooltip>
                    ) : (
                      <Typography>
                        {' '}
                        <Dot /> Available
                      </Typography>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Tooltip title="Preview slide">
                      <Button
                        onClick={() => {
                          setActiveSlide(slide);
                          setOpenPreview(true);
                        }}
                      >
                        <LiveTvRoundedIcon sx={iconStyle} />
                      </Button>
                    </Tooltip>
                    <Button
                      onClick={(event) => handleMenu(event, slide)}
                      disabled={isViewOnly}
                    >
                      <ChevronDown size={16} />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>

      <SlideActionMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClickRename={() => {
          setRenameSlideModal(true);
          handleMenuClose();
        }}
        onClickClone={clone}
        onClickRemove={() => {
          remove();
          handleMenuClose();
        }}
      />

      <DialogModal
        title={'Rename Slide'}
        onClose={() => setRenameSlideModal(false)}
        open={renameSlideModal}
      >
        <RenameSlideForm
          onFinished={() => {
            setRenameSlideModal(false);
            setActiveSlide(null);
            refetch();
          }}
          slide={activeSlide}
        />
      </DialogModal>
      <CanvasPreviewModalV2
        title={activeSlide?.title}
        onClose={() => {
          setOpenPreview(false);
          setActiveSlide(null);
        }}
        open={openPreview}
        json={activeSlide?.projectData}
        slideId={activeSlide?._id}
      />
    </>
  );
};

export default SlideTable;

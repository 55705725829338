import * as React from 'react';
import { FC } from 'react';

import { Menu, MenuItem } from '@mui/material';
import { Trash2, Edit3, PenTool } from 'react-feather';

interface FormActionMenuProps {
  onClickEdit: () => void;
  onClickDelete: () => void;
  onClickRename: () => void;
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
}

export const FormActionMenu: FC<FormActionMenuProps> = ({
  onClickEdit,
  onClickDelete,
  onClickRename,
  onClose,
  open,
  anchorEl,
}) => {
  return (
    <Menu
      id="form-action-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <MenuItem onClick={onClickEdit}>
        <PenTool size={16} style={{ marginRight: 10 }} />
        Edit Form
      </MenuItem>
      <MenuItem onClick={onClickRename}>
        <Edit3 size={16} style={{ marginRight: 10 }} />
        Rename Form
      </MenuItem>
      <MenuItem onClick={onClickDelete}>
        <Trash2 size={16} style={{ marginRight: 10 }} />
        Delete Form
      </MenuItem>
    </Menu>
  );
};

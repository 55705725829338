import React, { FC, FormEvent, useState } from 'react';
import { InputType, SurveyElement } from '../../types';
import { useEditorData } from '../../EditorProvider';
import { changeElementData } from '../../utils/surveyDataUtils';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { RemoveButton } from './RemoveButton';
import getAlignment from '../../utils/getAlignment';

interface MultiSelectInput {
  id: number;
  value: string;
}
interface MultiSelectDropDownProps {
  index: number;
  elementId: string;
  pageId: string;
  elementData: SurveyElement;
}

const MultiSelectDropDown: FC<MultiSelectDropDownProps> = ({
  index,
  elementId,
  pageId,
  elementData,
}) => {
  const [itemSelectAllAdded, setItemSelectAllAdded] = useState(false);
  const [itemNoneAdded, setItemNoneAdded] = useState(false);
  const [itemOtherAdded, setItemOtherAdded] = useState(false);

  const [multiSelectInputs, setMultiSelectInputs] = useState<
    MultiSelectInput[]
  >([
    { id: 1, value: 'Item 1' },
    { id: 2, value: 'Item 2' },
    { id: 3, value: 'Item 3' },
  ]);

  const {
    history,
    setHistory,
    setSelectedItem,
    selectedItem: focus,
    setIsSettingsOpen,
  } = useEditorData();

  const { present: surveyData } = history;

  const onChange = (e: FormEvent<HTMLSpanElement>) => {
    if (!elementData) return;

    const { id, textContent } = e.currentTarget;

    const params = {
      surveyData,
      data: {
        [id]: textContent as string,
        showOtherItem: itemOtherAdded as boolean,
        showNoneItem: itemNoneAdded as boolean,
        showSelectAllItem: itemSelectAllAdded,
      },
      focus,
      setHistory,
    };
    changeElementData(params);
    setIsSettingsOpen(true);
  };

  const addMultiSelectInput = () => {
    const newInputId = multiSelectInputs.length + 1;
    const newMultiSelectInput: MultiSelectInput = {
      id: newInputId,
      value: `Item ${newInputId}`,
    };
    setMultiSelectInputs([...multiSelectInputs, newMultiSelectInput]);
  };

  const removeMultiSelectInput = (id: number) => {
    const updatedInputs = multiSelectInputs.filter((input) => input.id !== id);
    setMultiSelectInputs(updatedInputs);
  };

  return (
    <div
      className="svc-row"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedItem({ pageId, elementId, type: InputType.MULTI_DROP_DOWN });
      }}
    >
      <div className="sd-row sd-clearfix sd-page__row">
        <div
          data-key="question10"
          style={{ flex: '1 1 100%', minWidth: '300px', maxWidth: '100%' }}
        >
          <div
            data-sv-drop-target-survey-element="question1"
            className="svc-question__adorner"
          >
            <div
              className="svc-question__content svc-question__content--tagbox"
              tabIndex={0}
              style={{ paddingBottom: 0 }}
            >
              <div className="svc-question__drag-area">
                <svg
                  className="sv-svg-icon svc-question__drag-element"
                  role="img"
                  style={{ width: '24px', height: '24px' }}
                >
                  <use xlinkHref="#icon-drag-area-indicator_24x16" />
                </svg>
              </div>
              <div
                id="sq_4437"
                className="sd-element sd-question sd-row__question sd-row__question--small"
                role="combobox"
                aria-required="false"
                aria-invalid="false"
                aria-labelledby="sq_4437_ariaTitle"
                data-rendered="r"
                data-name="question1"
              >
                <div
                  className={`sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top ${getAlignment(
                    surveyData,
                  )}`}
                >
                  <h5
                    className="sd-title sd-element__title sd-question__title sd-element__title--collapsed"
                    id="sq_4437_ariaTitle"
                    aria-label="question1"
                  >
                    <span
                      data-key="q_num"
                      className="sd-element__num"
                      aria-hidden="true"
                      style={{ position: 'static' }}
                    >
                      {index}.
                    </span>
                    <span data-key="num-sp">&nbsp;</span>
                    <span className="svc-string-editor">
                      <span className="svc-string-editor__content">
                        <div className="svc-string-editor__border">
                          <svg
                            className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                            role="img"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-edit" />
                          </svg>
                        </div>
                        <span className="svc-string-editor__input">
                          <span
                            id="title"
                            role="textbox"
                            className="sv-string-editor"
                            contentEditable="true"
                            spellCheck="false"
                            aria-label="content editable"
                            data-gramm="false"
                            aria-placeholder={`Question ${index}`}
                            onBlur={onChange}
                            suppressContentEditableWarning
                          >
                            {elementData?.title}
                          </span>
                          <div className="svc-string-editor__controls" />
                        </span>
                      </span>
                    </span>
                  </h5>
                  <div className="sd-description sd-question__description">
                    <span
                      className={`svc-string-editor ${
                        !elementData?.description && 'svc-string-editor--hidden'
                      }`}
                    >
                      <span className="svc-string-editor__content">
                        <div className="svc-string-editor__border">
                          <svg
                            className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                            role="img"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-edit" />
                          </svg>
                        </div>
                        <span className="svc-string-editor__input">
                          <span
                            id="description"
                            role="textbox"
                            className="sv-string-editor"
                            spellCheck="false"
                            aria-label="content editable"
                            data-gramm="false"
                            onInput={onChange}
                          />
                          <div className="svc-string-editor__controls" />
                          {elementData?.description}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div className="sd-question__content" role="presentation">
                  <div className="sd-selectbase">
                    <div>
                      <div
                        id="sq_4437i"
                        className="sd-input sd-tagbox sd-dropdown sd-dropdown--empty sd-tagbox--empty"
                        role="combobox"
                        aria-required="false"
                        aria-label="question1"
                        aria-invalid="false"
                        aria-expanded="false"
                        aria-controls="sq_4437i_list"
                      >
                        <div className="sd-tagbox__value sd-dropdown__value">
                          <input
                            type="text"
                            autoComplete="off"
                            id="sq_4437i_0"
                            inputMode="text"
                            className="sd-tagbox__filter-string-input sd-dropdown__filter-string-input"
                            disabled
                            size={1}
                            aria-label="Select..."
                            aria-expanded="false"
                            aria-controls="sq_4437i_list"
                            placeholder="Select..."
                          />
                        </div>
                        <div
                          className="sd-tagbox_clean-button sd-dropdown_clean-button"
                          style={{ display: 'none' }}
                        >
                          <svg
                            className="sv-svg-icon sd-tagbox_clean-button-svg sd-dropdown_clean-button-svg"
                            role="img"
                            aria-label="Clear"
                          >
                            <use xlinkHref="#icon-clear" />
                            <title>Clear</title>
                          </svg>
                        </div>
                      </div>
                      <div />
                    </div>
                  </div>
                </div>
              </div>
              <div className="svc-question__dropdown-choices--wrapper">
                <div className="svc-question__dropdown-choices">
                  <div className="svc-question__dropdown-choice">
                    <div
                      className={
                        itemSelectAllAdded === false
                          ? 'svc-item-value-wrapper svc-item-value--new'
                          : 'svc-item-value-wrapper'
                      }
                    >
                      <div className="svc-item-value__ghost" />
                      <div className="svc-item-value-controls">
                        <span
                          className={
                            itemSelectAllAdded === false
                              ? 'svc-item-value-controls__button svc-item-value-controls__add'
                              : 'svc-item-value-controls__button svc-item-value-controls__remove'
                          }
                          aria-label="Click to add an item..."
                          tabIndex={0}
                          onClick={() =>
                            setItemSelectAllAdded(!itemSelectAllAdded)
                          }
                        >
                          <svg
                            className="sv-svg-icon"
                            role="img"
                            aria-label="Click to add an item..."
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-add_16x16" />
                            {itemSelectAllAdded === false ? (
                              <AddCircleOutlineIcon color="success" />
                            ) : (
                              <RemoveCircleOutlineIcon color="error" />
                            )}
                            <title>Click to add an item...</title>
                          </svg>
                        </span>
                      </div>
                      <div className="svc-item-value__item">
                        <div
                          className="sd-item sd-radio sd-selectbase__item sv-q-col-1"
                          role="presentation"
                        >
                          <label
                            className="sd-selectbase__label"
                            aria-label="Select All"
                          >
                            <input
                              className="sd-visuallyhidden sd-item__control sd-radio__control"
                              role="option"
                              type="checkbox"
                              name="question1"
                              id="sq_4437i_0"
                              disabled
                            />
                            <span className="sd-item__decorator sd-checkbox__decorator" />
                            <span className="sd-item__control-label">
                              <span className="svc-string-editor svc-string-editor--readonly">
                                <span className="svc-string-editor__content">
                                  <div className="svc-string-editor__border">
                                    <svg
                                      className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                                      role="img"
                                      style={{ width: '16px', height: '16px' }}
                                    >
                                      <use xlinkHref="#icon-edit" />
                                    </svg>
                                  </div>
                                  <span className="svc-string-editor__input">
                                    <span
                                      id="selectAllText"
                                      role="textbox"
                                      className="sv-string-editor"
                                      contentEditable={itemSelectAllAdded}
                                      spellCheck="false"
                                      aria-label="content editable"
                                      onInput={onChange}
                                      suppressContentEditableWarning
                                    >
                                      {elementData.selectAllText ||
                                        'Select All'}
                                    </span>
                                    <div className="svc-string-editor__controls" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {multiSelectInputs.map((input) => (
                      <div
                        className="svc-question__dropdown-choice"
                        key={input.id}
                      >
                        <div
                          className="svc-item-value-wrapper"
                          data-sv-drop-target-item-value="Item 1"
                        >
                          <div className="svc-item-value__ghost" />
                          <div className="svc-item-value-controls">
                            <span
                              className="svc-item-value-controls__button svc-item-value-controls__remove"
                              aria-label="Click to remove the item..."
                              tabIndex={0}
                              onClick={() => removeMultiSelectInput(input.id)}
                            >
                              <svg
                                className="sv-svg-icon"
                                role="img"
                                aria-label="Click to remove the item..."
                                style={{ width: '16px', height: '16px' }}
                              >
                                <use xlinkHref="#icon-remove_16x16" />
                                <RemoveCircleOutlineIcon color="error" />
                                <title>Click to remove the item...</title>
                              </svg>
                            </span>
                          </div>
                          <div className="svc-item-value__item">
                            <div
                              className="sd-item sd-radio sd-selectbase__item sv-q-col-1"
                              role="presentation"
                            >
                              <label
                                className="sd-selectbase__label"
                                aria-label={input.value}
                              >
                                <input
                                  className="sd-visuallyhidden sd-item__control sd-radio__control"
                                  role="option"
                                  type="checkbox"
                                  name={`question1_sq_${input.id}`}
                                  id={`sq_${input.id}`}
                                  disabled
                                  defaultValue={input.value}
                                />
                                <span className="sd-item__decorator sd-checkbox__decorator" />
                                <span className="sd-item__control-label">
                                  <span className="svc-string-editor">
                                    <span className="svc-string-editor__content">
                                      <div className="svc-string-editor__border">
                                        <svg
                                          className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                                          role="img"
                                          style={{
                                            width: '16px',
                                            height: '16px',
                                          }}
                                        >
                                          <use xlinkHref="#icon-edit" />
                                        </svg>
                                      </div>
                                      <span className="svc-string-editor__input">
                                        <span
                                          id={`item${input.id}`}
                                          role="textbox"
                                          className="sv-string-editor"
                                          contentEditable={true}
                                          spellCheck={false}
                                          aria-placeholder=""
                                          aria-label="content editable"
                                          data-gramm="false"
                                          onBlur={onChange}
                                          suppressContentEditableWarning
                                        >
                                          {input.value}
                                        </span>
                                        <div className="svc-string-editor__controls" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="svc-question__dropdown-choice">
                    <div className="svc-item-value-wrapper svc-item-value--new">
                      <div className="svc-item-value__ghost" />
                      <div className="svc-item-value-controls">
                        <span
                          className="svc-item-value-controls__button svc-item-value-controls__add"
                          aria-label="Click to add an item..."
                          tabIndex={0}
                          onClick={addMultiSelectInput}
                        >
                          <svg
                            className="sv-svg-icon"
                            role="img"
                            aria-label="Click to add an item..."
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-add_16x16" />
                            <AddCircleOutlineIcon color="success" />
                            <title>Click to add an item...</title>
                          </svg>
                        </span>
                      </div>
                      <div className="svc-item-value__item">
                        <div
                          className="sd-item sd-radio sd-selectbase__item sv-q-col-1"
                          role="presentation"
                        >
                          <label
                            className="sd-selectbase__label"
                            aria-label="Item 4"
                          >
                            <input
                              className="sd-visuallyhidden sd-item__control sd-radio__control"
                              role="option"
                              type="checkbox"
                              name="question1"
                              id="sq_4437i_4"
                              disabled
                              defaultValue="Item 4"
                            />
                            <span className="sd-item__decorator sd-checkbox__decorator" />
                            <span className="sd-item__control-label">
                              <span className="sv-string-viewer">{`Item ${
                                multiSelectInputs.length + 1
                              }`}</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="svc-question__dropdown-choice">
                    <div
                      className={
                        itemNoneAdded === false
                          ? 'svc-item-value-wrapper svc-item-value--new'
                          : 'svc-item-value-wrapper'
                      }
                    >
                      <div className="svc-item-value__ghost" />
                      <div className="svc-item-value-controls">
                        <span
                          className={
                            itemNoneAdded === false
                              ? 'svc-item-value-controls__button svc-item-value-controls__add'
                              : 'svc-item-value-controls__button svc-item-value-controls__remove'
                          }
                          aria-label="Click to add an item..."
                          tabIndex={0}
                          onClick={() => setItemNoneAdded(!itemNoneAdded)}
                        >
                          <svg
                            className="sv-svg-icon"
                            role="img"
                            aria-label="Click to add an item..."
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-add_16x16" />
                            {itemNoneAdded === false ? (
                              <AddCircleOutlineIcon color="success" />
                            ) : (
                              <RemoveCircleOutlineIcon color="error" />
                            )}
                            <title>Click to add an item...</title>
                          </svg>
                        </span>
                      </div>
                      <div className="svc-item-value__item">
                        <div
                          className="sd-item sd-radio sd-selectbase__item sv-q-col-1"
                          role="presentation"
                        >
                          <label
                            className="sd-selectbase__label"
                            aria-label="None"
                          >
                            <input
                              className="sd-visuallyhidden sd-item__control sd-radio__control"
                              role="option"
                              type="checkbox"
                              name="question1"
                              id="sq_4437i_5"
                              disabled
                              defaultValue="none"
                            />
                            <span className="sd-item__decorator sd-checkbox__decorator" />
                            <span className="sd-item__control-label">
                              <span className="svc-string-editor svc-string-editor--readonly">
                                <span className="svc-string-editor__content">
                                  <div className="svc-string-editor__border">
                                    <svg
                                      className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                                      role="img"
                                      style={{ width: '16px', height: '16px' }}
                                    >
                                      <use xlinkHref="#icon-edit" />
                                    </svg>
                                  </div>
                                  <span className="svc-string-editor__input">
                                    <span
                                      id="noneText"
                                      role="textbox"
                                      className="sv-string-editor"
                                      contentEditable={itemNoneAdded}
                                      spellCheck="false"
                                      aria-label="content editable"
                                      onBlur={onChange}
                                      suppressContentEditableWarning
                                    >
                                      {elementData.noneText || 'None'}
                                    </span>
                                    <div className="svc-string-editor__controls" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="svc-question__dropdown-choice">
                    <div
                      className={
                        itemOtherAdded === false
                          ? 'svc-item-value-wrapper svc-item-value--new'
                          : 'svc-item-value-wrapper'
                      }
                    >
                      <div className="svc-item-value__ghost" />
                      <div className="svc-item-value-controls">
                        <span
                          className={
                            itemOtherAdded === false
                              ? 'svc-item-value-controls__button svc-item-value-controls__add'
                              : 'svc-item-value-controls__button svc-item-value-controls__remove'
                          }
                          aria-label="Click to add an item..."
                          tabIndex={0}
                          onClick={() => setItemOtherAdded(!itemOtherAdded)}
                        >
                          <svg
                            className="sv-svg-icon"
                            role="img"
                            aria-label="Click to add an item..."
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-add_16x16" />
                            {itemOtherAdded === false ? (
                              <AddCircleOutlineIcon color="success" />
                            ) : (
                              <RemoveCircleOutlineIcon color="error" />
                            )}
                            <title>Click to add an item...</title>
                          </svg>
                        </span>
                      </div>
                      <div className="svc-item-value__item">
                        <div
                          className="sd-item sd-radio sd-selectbase__item sv-q-col-1"
                          role="presentation"
                        >
                          <label
                            className="sd-selectbase__label"
                            aria-label="Other (describe)"
                          >
                            <input
                              className="sd-visuallyhidden sd-item__control sd-radio__control"
                              role="option"
                              type="checkbox"
                              name="question1"
                              id="sq_4437i_6"
                              disabled
                              defaultValue="other"
                            />
                            <span className="sd-item__decorator sd-checkbox__decorator" />
                            <span className="sd-item__control-label">
                              <span className="svc-string-editor svc-string-editor--readonly">
                                <span className="svc-string-editor__content">
                                  <div className="svc-string-editor__border">
                                    <svg
                                      className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                                      role="img"
                                      style={{ width: '16px', height: '16px' }}
                                    >
                                      <use xlinkHref="#icon-edit" />
                                    </svg>
                                  </div>
                                  <span className="svc-string-editor__input">
                                    <span
                                      id="otherText"
                                      role="textbox"
                                      className="sv-string-editor"
                                      contentEditable={itemOtherAdded}
                                      spellCheck="false"
                                      aria-label="content editable"
                                      onBlur={onChange}
                                      suppressContentEditableWarning
                                    >
                                      {elementData.otherText ||
                                        'Other (describe)'}
                                    </span>
                                    <div className="svc-string-editor__controls" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <RemoveButton
                surveyData={surveyData}
                elementId={elementId}
                pageId={pageId}
                setHistory={setHistory}
                setSelectedItem={setSelectedItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiSelectDropDown;

import { useDeleteAssets, useGetPaginateAssets } from '../../api-http/assets';
import { DialogModal } from '../../components';
import { AlertBar } from '../../components/AlertBar';
import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../components/TableTheme';
import { notifyMsg } from '../../configs';
import { useAuth } from '../../context/auth-context';
import { AssetFileType, AssetsResponse } from '../../types/assets.types';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import { displayDistance } from '../../util/dateTime';
import { getStaticFileUrl } from '../../util/getStaticFileUrl';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useSnack } from '../../util/useSnack';
import { useTable } from '../../util/useTable';
import { ActionMenu } from './ActionMenu';
import { AssetWithPlaceholder } from './AssetWithPlaceholder';
import { RenameAssetForm } from './RenameAssetForm';
import { Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import { useConfirm } from 'material-ui-confirm';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ChevronDown, Copy, Download } from 'react-feather';

const UsageContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NotUsedDot = styled('div')`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: gray;
  margin-right: 5px;
`;

const UsedDot = styled('div')`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: limegreen;
  margin-right: 5px;
`;

const StyledLink = styled('a')`
  text-decoration: none;
  word-break: break-word;

  &:hover {
    text-decoration: underline;
  }
`;

const AssetsPreview = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`;

const StyledImage = styled('img')`
  max-width: 100%;
  width: 800px;
  height: auto;
`;

const StyledVideo = styled('video')`
  max-width: 100%;
  width: 800px;
  height: auto;
`;

interface AssetsTableProp {
  refresh: boolean;
  assetSearchTxt: string;
  showImage: boolean;
  showVideo: boolean;
}

export const AssetsTable: FC<AssetsTableProp> = ({
  refresh,
  assetSearchTxt,
  showImage,
  showVideo,
}) => {
  const confirm = useConfirm();
  const disableDelete = useIsUserRole([UserRole.user, UserRole.dataEditor]);
  let fileType: AssetFileType;

  if (showImage && showVideo) {
    fileType = AssetFileType.All;
  } else if (showImage) {
    fileType = AssetFileType.Image;
  } else if (showVideo) {
    fileType = AssetFileType.Video;
  } else {
    fileType = AssetFileType.None;
  }

  const { switchedOrgId } = useAuth();
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { sortOnClick, pagingParams, tableProps } = useTable();
  const { sortby, order } = pagingParams;

  const {
    data: list,
    error: listError,
    isLoading: listIsLoading,
    refetch: listRefetch,
  } = useGetPaginateAssets({
    ...pagingParams,
    fileType,
    search: assetSearchTxt,
  });

  const {
    error,
    mutate: deleteAsset,
    data: deletedAsset,
    isLoading,
  } = useDeleteAssets({
    onSuccess: () => showSuccessSnack(notifyMsg.ASSET_DELETE_SUCCESS),
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeAsset, setActiveAsset] = useState<AssetsResponse | undefined>();
  const [renameAssetModal, setRenameAssetModal] = useState(false);
  const [assetPreviewModal, setAssetPreviewModal] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const file = (imageUrl: string | undefined) => {
    if (imageUrl) {
      const fileType = imageUrl.split('.').pop()?.toLowerCase();
      if (
        (fileType && fileType.endsWith('jpeg')) ||
        fileType?.endsWith('jpg') ||
        fileType?.endsWith('png') ||
        fileType?.endsWith('gif')
      ) {
        return <StyledImage src={imageUrl} alt="Image Preview" />;
      } else if (fileType && fileType.endsWith('mp4')) {
        return <StyledVideo src={imageUrl} autoPlay controls />;
      }
    }
    return <div>Invalid media URL</div>;
  };

  useEffect(() => {
    listRefetch();
  }, [deletedAsset, refresh, tableProps, switchedOrgId]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    asset: AssetsResponse,
  ) => {
    setActiveAsset(asset);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (id: string) => {
    try {
      await confirm({
        description: notifyMsg.DELETE_CONFIRMATION,
        confirmationText: 'Yes',
      });
      deleteAsset({ id });
    } catch (e) {}
  };

  return (
    <>
      <AlertBar severity="error" msg={error?.msg || listError?.msg} />
      {(isLoading || listIsLoading) && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell width={200}>
                <StyledTableSortLabel
                  active={sortby === SortEnum.fileName || true}
                  direction={
                    sortby === SortEnum.fileName ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.fileName)}
                >
                  Asset Name
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={'center'}>Preview</StyledTableCell>
              <StyledTableCell align={'center'} width={150}>
                <StyledTableSortLabel
                  active={sortby === SortEnum.createdAt || true}
                  direction={
                    sortby === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Created At
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={'center'} width={110}>
                Created By
              </StyledTableCell>
              <StyledTableCell align={'center'} width={110}>
                Updated By
              </StyledTableCell>
              <StyledTableCell align={'center'} width={110}>
                Usage
              </StyledTableCell>
              <StyledTableCell align={'center'} width={90}>
                Copy URL
              </StyledTableCell>
              <StyledTableCell align={'center'} width={90}>
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.data?.map((asset) => {
              return (
                <TableRow
                  key={asset?._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component={'th'} scope={'row'}>
                    <StyledLink
                      download
                      onClick={() => {
                        setImageUrl(asset?.url);
                        setAssetPreviewModal(true);
                      }}
                    >
                      {asset?.fileName} ({asset?.fileType.split('/')[0]}){' '}
                      <Download size={14} />
                    </StyledLink>
                  </TableCell>
                  <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                    {asset?.fileType.includes('image') && (
                      <AssetWithPlaceholder
                        src={getStaticFileUrl(asset?.thumbnailUrl)}
                        fileName={asset.fileName}
                        width="140px"
                        type="image"
                      />
                    )}
                    {asset?.fileType.includes('video') && (
                      <AssetWithPlaceholder
                        src={getStaticFileUrl(asset?.path)}
                        fileName={asset.fileName}
                        width="140px"
                        type="video"
                      />
                    )}
                  </TableCell>
                  <TableCell align={'center'}>
                    {displayDistance(asset?.createdAt)}
                  </TableCell>
                  <TableCell align={'center'}>
                    {asset?.createdBy?.name}
                  </TableCell>
                  <TableCell align={'center'}>
                    {asset?.updatedBy?.name}
                  </TableCell>
                  <TableCell align={'center'}>
                    {asset?.slides?.length === 0 ? (
                      <UsageContainer>
                        <NotUsedDot />
                        <span>Not Used</span>
                      </UsageContainer>
                    ) : (
                      <UsageContainer>
                        <UsedDot />
                        <span>Used</span>
                      </UsageContainer>
                    )}
                  </TableCell>
                  <TableCell align={'center'}>
                    <CopyToClipboard
                      text={asset?.url}
                      onCopy={() =>
                        showSuccessSnack(notifyMsg.CLIP_BOARD_COPIED_SUCCESS)
                      }
                    >
                      <Button>
                        <Copy size={16} />
                      </Button>
                    </CopyToClipboard>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Button
                      onClick={(event) => handleClick(event, asset)}
                      disabled={disableDelete}
                    >
                      <ChevronDown size={16} />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>

      <ActionMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClickDelete={() => {
          handleDelete(activeAsset?._id || '');
          handleClose();
        }}
        onClickRename={() => {
          setRenameAssetModal(true);
          handleClose();
        }}
        disableDelete={activeAsset?.slides?.length !== 0}
      />

      {activeAsset && (
        <DialogModal
          title={'Rename Asset'}
          onClose={() => setRenameAssetModal(false)}
          open={renameAssetModal}
        >
          <RenameAssetForm
            onFinished={() => {
              listRefetch();
              setRenameAssetModal(false);
            }}
            asset={activeAsset}
          />
        </DialogModal>
      )}
      <DialogModal
        onClose={() => setAssetPreviewModal(false)}
        open={assetPreviewModal}
        width={'800px'}
      >
        <AssetsPreview>{file(imageUrl)}</AssetsPreview>
      </DialogModal>
    </>
  );
};

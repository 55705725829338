import React, { FC, FormEvent } from 'react';
import { InputType, SurveyElement } from '../../types';
import { useEditorData } from '../../EditorProvider';
import { changeElementData } from '../../utils/surveyDataUtils';
import { RemoveButton } from './RemoveButton';
import getAlignment from '../../utils/getAlignment';

interface MultiLineInputProps {
  index: number;
  elementId: string;
  pageId: string;
  elementData: SurveyElement;
}

const MultiLineInput: FC<MultiLineInputProps> = ({
  index,
  elementId,
  pageId,
  elementData,
}) => {
  const {
    history,
    setHistory,
    setSelectedItem,
    selectedItem: focus,
    setIsSettingsOpen,
  } = useEditorData();

  const { present: surveyData } = history;

  const onChange = (e: FormEvent<HTMLSpanElement>) => {
    if (!elementData) return;

    const { id, textContent } = e.currentTarget;

    const params = {
      surveyData,
      data: { [id]: textContent as string },
      focus,
      setHistory,
    };
    changeElementData(params);
    setIsSettingsOpen(true);
  };

  return (
    <div
      className="svc-row"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedItem({ pageId, elementId, type: InputType.MULTI_LINE });
      }}
    >
      <div className="sd-row sd-clearfix sd-page__row">
        <div
          data-key="question10"
          style={{ flex: '1 1 100%', minWidth: '300px', maxWidth: '100%' }}
        >
          <div
            data-sv-drop-target-survey-element="question1"
            className="svc-question__adorner"
          >
            <div
              className="svc-question__content svc-question__content--comment"
              tabIndex={0}
              style={{ paddingBottom: 0 }}
            >
              <div className="svc-question__drag-area">
                <svg
                  className="sv-svg-icon svc-question__drag-element"
                  role="img"
                  style={{ width: '24px', height: '24px' }}
                >
                  <use xlinkHref="#icon-drag-area-indicator_24x16" />
                </svg>
              </div>
              <div
                id="sq_5317"
                className="sd-element sd-question sd-row__question sd-row__question--small"
                role="textbox"
                aria-required="false"
                aria-invalid="false"
                aria-labelledby="sq_5317_ariaTitle"
                data-rendered="r"
                data-name="question1"
              >
                <div
                  className={`sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top ${getAlignment(
                    surveyData,
                  )}`}
                >
                  <h5
                    className="sd-title sd-element__title sd-question__title sd-element__title--collapsed"
                    id="sq_5317_ariaTitle"
                    aria-label="question1"
                  >
                    <span
                      data-key="q_num"
                      className="sd-element__num"
                      aria-hidden="true"
                      style={{ position: 'static' }}
                    >
                      1.
                    </span>
                    <span data-key="num-sp">&nbsp;</span>
                    <span className="svc-string-editor">
                      <span className="svc-string-editor__content">
                        <div className="svc-string-editor__border">
                          <svg
                            className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                            role="img"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-edit" />
                          </svg>
                        </div>
                        <span className="svc-string-editor__input">
                          <span
                            id="title"
                            role="textbox"
                            className="sv-string-editor"
                            contentEditable="true"
                            spellCheck="false"
                            aria-label="content editable"
                            data-gramm="false"
                            aria-placeholder={`Question ${index}`}
                            onBlur={onChange}
                            suppressContentEditableWarning
                          >
                            {elementData?.title}
                          </span>
                          <div className="svc-string-editor__controls" />
                        </span>
                      </span>
                    </span>
                  </h5>
                  <div className="sd-description sd-question__description">
                    <span
                      className={`svc-string-editor ${
                        !elementData?.description && 'svc-string-editor--hidden'
                      }`}
                    >
                      <span className="svc-string-editor__content">
                        <div className="svc-string-editor__border">
                          <svg
                            className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                            role="img"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-edit" />
                          </svg>
                        </div>
                        <span className="svc-string-editor__input">
                          <span
                            id="description"
                            role="textbox"
                            className="sv-string-editor"
                            spellCheck="false"
                            aria-label="content editable"
                            data-gramm="false"
                            onInput={onChange}
                          />
                          <div className="svc-string-editor__controls" />
                          {elementData?.description}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  className="sd-comment__content sd-question__content"
                  role="presentation"
                >
                  <textarea
                    id="sq_5317i"
                    className="sd-input sd-comment"
                    disabled
                    readOnly
                    cols={50}
                    rows={4}
                    aria-required="false"
                    aria-label="question1"
                    aria-invalid="false"
                    data-rendered="r"
                    style={{ resize: 'both' }}
                    defaultValue={''}
                  />
                </div>
              </div>
              <RemoveButton
                surveyData={surveyData}
                elementId={elementId}
                pageId={pageId}
                setHistory={setHistory}
                setSelectedItem={setSelectedItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiLineInput;

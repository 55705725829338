import React, { FC } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const BoxContainer = muiStyled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  marginBottom: 15,
  marginTop: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

interface GuideTextProps {
  isPublishedMode: boolean;
  hasSlides: boolean;
}

const GuideText: FC<GuideTextProps> = ({ isPublishedMode, hasSlides }) => {
  return (
    <>
      {isPublishedMode && !hasSlides && (
        <BoxContainer elevation={1}>
          It is empty. Click on Show draft version button to mange presentation.
        </BoxContainer>
      )}
      {!isPublishedMode && !hasSlides && (
        <BoxContainer elevation={1}>
          There is no slides. Lets add some slides
        </BoxContainer>
      )}
    </>
  );
};

export default GuideText;

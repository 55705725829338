import { useMutation, useQuery } from '@tanstack/react-query';
import { PagingResponse } from '../types';
import { EventResponse } from '../types/event.types';
import { PaginateOptions } from '../types/pagination.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';
import {
  SchedulerEventResponseBody,
  UpdateEventBody,
} from '../types/scheduler.types';

export type GetEventResponse = EventResponse;
export interface EventBody {
  name: string;
}

export const useGetEvents = () =>
  useQuery<PagingResponse<EventResponse>, ApiServiceErr>(
    [`/event/all`],
    async () => {
      const response = await axiosApi.get(`/event/all`);
      return response.data;
    },
  );

export const useDeleteEvents = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/event/delete/${data.id}`);
    return response.data;
  }, opt);

export const useGetPaginateEvents = ({
  limit,
  page,
  sortby,
  order,
  search = '',
}: PaginateOptions) =>
  useQuery<PagingResponse<GetEventResponse>, ApiServiceErr>(
    [
      `/event/all?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}`,
      limit,
      page,
      sortby,
      order,
      search,
    ],
    async () => {
      const response = await axiosApi.get(
        `/event/all?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}`,
      );
      return response.data;
    },
  );

export const useGetSchedulersEvents = ({
  scheduler,
  limit,
  page,
  sortby,
  order,
  search = '',
}: PaginateOptions) =>
  useQuery<PagingResponse<SchedulerEventResponseBody>, ApiServiceErr>(
    [
      `/event/all?scheduler=${scheduler}&limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}`,
    ],
    async () => {
      const response = await axiosApi.get(
        `/event/all?scheduler=${scheduler}&limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}`,
      );
      return response.data;
    },
  );

export const useUpdateEvent = (opt?: MutOptions<SchedulerEventResponseBody>) =>
  useMutation<SchedulerEventResponseBody, ApiServiceErr, UpdateEventBody>(
    async (data) => {
      const response = await axiosApi.put(`/event/update`, {
        id: data.id,
        presentation: data.presentation,
        startTime: data.startTime,
        endTime: data.endTime,
        presentationSchedule: data.presentationSchedule,
      });
      return response.data;
    },
    opt,
  );

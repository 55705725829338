import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import { metaTitle } from '../../util/tabTitle';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumb';
import styled from 'styled-components';
import { logTypes } from '../../util/logTypes';
import { useGetOrganizationUsers } from '../../api-http/users';
import { useAuth } from '../../context/auth-context';
import { BaseUser } from '../../types/user.types';
import { useSnack } from '../../util/useSnack';
import ActivityLogsTable from './ActivityLogsTable';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

const ContainerHeader = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const FilterSelect = styled.div`
  display: flex;
  gap: 10px;
  width: 500px;
`;

const today = new Date().toISOString().slice(0, 10);

const ActivityLogsPage: FC = () => {
  metaTitle('ActivityLogs');

  const [logType, setLogType] = useState<string>('');
  const [authoredBy, setAuthoredBy] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>(today);
  const { switchedOrgId } = useAuth();

  const {
    data: usersList,
    error: usersErrs,
    refetch: refetchUsers,
  } = useGetOrganizationUsers();

  const { showErrSnack } = useSnack();

  useEffect(() => {
    if (usersErrs) {
      showErrSnack(usersErrs.msg);
    }
  }, [switchedOrgId, usersErrs]);

  useEffect(() => {
    refetchUsers();
  }, [switchedOrgId]);

  return (
    <>
      <Stack>
        <FilterSelectWrapper>
          <Breadcrumbs
            items={[{ label: 'Activity Log', to: '/admin/activity-log' }]}
          />
        </FilterSelectWrapper>
      </Stack>
      <Stack>
        <ContainerHeader>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextField
              type="date"
              sx={{ width: '15%', backgroundColor: 'white' }}
              id="startDate"
              name="startDate"
              title="startDate"
              label="From"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              type="date"
              sx={{ width: '15%', backgroundColor: 'white' }}
              id="endDate"
              name="endDate"
              title="endDate"
              label="To"
              value={endDate}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </LocalizationProvider>

          <FilterSelect>
            <FormControl
              sx={{
                '& > :not(style)': {
                  width: '30ch',
                  height: '55px',
                  paddingLeft: '10px',
                  fontSize: '14px',
                },
              }}
            >
              <InputLabel style={{ marginTop: '2px' }} id="device">
                Log Type
              </InputLabel>
              <Select
                style={{ width: '250px', backgroundColor: 'white' }}
                id="logType"
                name="Log Type"
                title="Log Type"
                label="sortByLog"
                onChange={(e) => setLogType(e.target.value)}
                value={logType}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {logTypes?.map((logType, index) => (
                  <MenuItem key={index} value={logType}>
                    {logType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                '& > :not(style)': {
                  width: '30ch',
                  height: '55px',
                  paddingLeft: '10px',
                  fontSize: '14px',
                },
              }}
            >
              <InputLabel style={{ marginTop: '2px' }} id="device">
                Authored User
              </InputLabel>

              <Select
                style={{ width: '250px', backgroundColor: 'white' }}
                id="authoredBy"
                name="authoredBy"
                title="authoredBy"
                label="authoredBy"
                onChange={(e) => setAuthoredBy(e.target.value)}
                value={authoredBy}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {usersList?.map(
                  (user: Pick<BaseUser, '_id' | 'email' | 'name'>) => (
                    <MenuItem key={user._id} value={user._id}>
                      <div>
                        <p style={{ fontWeight: 'bold', fontSize: '12px' }}>
                          {user.name}
                        </p>
                        <p
                          style={{
                            fontWeight: 'light',
                            fontSize: '12px',
                            marginTop: '-14px',
                            color: 'GrayText',
                          }}
                        >
                          {user.email}
                        </p>
                      </div>
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </FilterSelect>
        </ContainerHeader>
        <ActivityLogsTable
          filterProps={{
            startDate: startDate,
            endDate: endDate,
            authoredUser: authoredBy,
            logType,
          }}
        />
      </Stack>
    </>
  );
};

export default ActivityLogsPage;

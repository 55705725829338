import { FC, useEffect } from 'react';
import { FabricJSCanvas, useFabricEditor } from '../lib/FabricJs';
import { RealSize } from '../types';

type CanvasBodyProps = RealSize;

const CanvasBody: FC<CanvasBodyProps> = ({ realHeight, realWidth }) => {
  const { editor, canvas, onReady } = useFabricEditor();
  useEffect(() => {
    if (canvas && !canvas?.backgroundColor) {
      canvas.setBackgroundColor('white', () => {});
      canvas.renderAll();
    }
  }, [editor]);

  return (
    <FabricJSCanvas
      onReady={onReady}
      realHeight={realHeight}
      realWidth={realWidth}
    />
  );
};

export default CanvasBody;

import { FC } from 'react';
import Button from '../components/Button';

import { useFabricEditor } from '../../../lib/FabricJs';
import handleWeatherWidget from '../../../lib/FabricJs/utils/handleWeatherWidget';

const WeatherWidget: FC = () => {
  const { canvas } = useFabricEditor();

  return (
    <Button onClick={() => canvas && handleWeatherWidget(canvas)}>
      Add Weather
    </Button>
  );
};

export default WeatherWidget;

import { Card } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(Card)`
  padding: 2rem;
  margin-bottom: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const DeviceInfoWrapper = styled.div`
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #233044;
  color: white;
  border-radius: 3px;
`;

export const PresentationActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const ScreenshotBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  align-items: center;

  img {
    width: 150px;
    cursor: pointer;
  }
`;

export const ActivityWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  align-items: center;
  flex-direction: column;
`;

export const ScreenshotsWrapper = styled.div`
  overflow-y: auto;
  max-height: 500px;
`;

export const ProfOfPlayWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const ScreenshotsMainWrapper = styled(Wrapper)`
  flex-grow: 2;
`;

export const ActivityMainWrapper = styled(Wrapper)`
  flex-grow: 1;
`;

import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  debounce,
} from '@mui/material';
import { AlertBar } from '../../../components/AlertBar';
import styled from 'styled-components';
import * as yup from 'yup';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import { Nullable } from '../../../types';
import { ApiServiceErr } from '../../../api-http/apiService';
import { useGetPresentationsWithSearch } from '../../../api-http/presentations';
import { SchedulerResponse } from '../../../types/scheduler.types';
import { StyleAutocomplete } from '../../../components/AutoComplete';
import { DEBOUNCE_TIME } from '../../../configs';
import { SchedulerBody } from '../../../api-http/scheduler';
import { formMsg } from '../../../configs';

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

interface SchedulerFormProps {
  onSubmit: (data: SchedulerBody) => void;
  isLoading: boolean;
  schedulerData?: Nullable<SchedulerResponse>;
  error?: Nullable<ApiServiceErr>;
}

const validationSchema = yup.object({
  name: yup.string().required(formMsg.SCHEDULER_NAME_REQUIRED),
  description: yup.string().required(formMsg.DESCRIPTION_REQUIRED),
  defaultPresentation: yup
    .string()
    .required(formMsg.DEFAULT_PRESENTATION_REQUIRED),
  timeZone: yup.string().required(formMsg.TIMEZONE_REQUIRED),
});

const labelStyle = 'original';
const timezones = {
  ...allTimezones,
  'America/Lima': 'Pittsburgh',
  'Europe/Berlin': 'Frankfurt',
};

export const SchedulerForm: FC<SchedulerFormProps> = ({
  schedulerData,
  isLoading,
  error,
  onSubmit,
}) => {
  const [searchText, setSearchText] = useState('');

  const { data: presentationData, refetch } =
    useGetPresentationsWithSearch(searchText);

  useEffect(() => {
    refetch();
  }, [searchText]);

  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  const [offSet, setOffSet] = useState(0);

  const formik = useFormik({
    initialValues: {
      ...(schedulerData ? { id: schedulerData._id || '' } : {}),
      name: '',
      description: '',
      defaultPresentation: '',
      timeZone: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit({ ...values, offSet });
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            label="Scheduler Name"
            id="name"
            title="Scheduler Name"
            placeholder="Scheduler Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <TextField
            label="Note"
            id="description"
            title="Note"
            placeholder="Note"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />

          <FormControl sx={{ width: '100%', minWidth: 200 }}>
            <StyleAutocomplete
              options={presentationData || []}
              getOptionLabel={(option) => `${option.name}`}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Default Presentation"
                  onChange={debounce(
                    (event) => setSearchText(event.target.value),
                    DEBOUNCE_TIME,
                  )}
                  value={searchText}
                  error={
                    formik.touched.defaultPresentation &&
                    Boolean(formik.errors.defaultPresentation)
                  }
                />
              )}
              onChange={(_event, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue('defaultPresentation', newValue._id);
                } else {
                  formik.setFieldValue('defaultPresentation', null);
                  setSearchText('');
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option._id === value?._id
              } // Customize the equality test
            />
          </FormControl>

          {Boolean(formik.errors.defaultPresentation) && (
            <FormHelperText style={{ color: 'red', margin: '0' }}>
              {formik.touched.defaultPresentation &&
                formik.errors.defaultPresentation}
            </FormHelperText>
          )}

          <FormControl sx={{ width: '100%', minWidth: 200 }}>
            <StyleAutocomplete
              options={options || []}
              getOptionLabel={(option) => `${option.label}`}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Schedule Timezone"
                  onChange={debounce(
                    (event) => setSearchText(event.target.value),
                    DEBOUNCE_TIME,
                  )}
                  value={searchText}
                  error={
                    formik.touched.timeZone && Boolean(formik.errors.timeZone)
                  }
                />
              )}
              onChange={(_event, newValue: any) => {
                if (newValue) {
                  setOffSet(newValue?.offset || 0);
                  formik.setFieldValue('timeZone', newValue?.label);
                } else {
                  formik.setFieldValue('timeZone', null);
                  setSearchText('');
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.offset === value?.offset
              }
            />
          </FormControl>

          {Boolean(formik.errors.timeZone) && (
            <FormHelperText style={{ color: 'red', margin: '0' }}>
              {formik.touched.timeZone && formik.errors.timeZone}
            </FormHelperText>
          )}

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
          >
            Submit
            {schedulerData ? 'Create' : ''}
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

import { DraftWithPresentationResponse } from '../../../types/drafts.types';
import { useEffect, useState } from 'react';
import {
  useDiscardDraft,
  useGetDraft,
  usePublishDraft,
  useUpdateDraft,
} from '../../../api-http/drafts';
import { notifyMsg } from '../../../configs';
import { useSnack } from '../../../util/useSnack';

const usePresentationApi = (draftId: string) => {
  const [cachedDraft, setCachedDraft] = useState<
    DraftWithPresentationResponse | undefined
  >(undefined);
  const {
    data: draftData,
    isLoading: isLoadingDraft,
    mutate: refetchDraftMutate,
    error: draftError,
  } = useGetDraft();
  const { showSuccessSnack } = useSnack();

  const {
    isLoading: isLoadingUpdating,
    error: updatePresentationError,
    mutate: updatePresentation,
  } = useUpdateDraft({
    onSuccess: () => {
      refetchDraft();
    },
  });

  const {
    isLoading: isLoadingPublishing,
    error: publishPresentationError,
    mutate: publishPresentation,
  } = usePublishDraft({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.PRESENTATION_PUBLISHED_SUCCESS);
      refetchDraft();
    },
  });

  const {
    isLoading: isLoadingDiscarding,
    mutate: discardDraft,
    error: discardDraftError,
  } = useDiscardDraft({
    onSuccess: () => refetchDraft(),
  });

  const refetchDraft = () => refetchDraftMutate({ id: draftId });

  useEffect(() => refetchDraft(), [draftId]);

  useEffect(() => {
    if (draftData) {
      setCachedDraft(draftData);
    }
  }, [draftData]);

  const isLoading =
    isLoadingDiscarding ||
    isLoadingPublishing ||
    isLoadingUpdating ||
    isLoadingDraft;

  const errMessage =
    updatePresentationError?.msg ||
    publishPresentationError?.msg ||
    discardDraftError?.msg ||
    draftError?.msg;

  return {
    discardDraft,
    updatePresentation,
    publishPresentation,
    refetchDraft,
    draftData: cachedDraft, // To avoid the no data issue while loading, we use cached Draft
    isLoading,
    errMessage,
    setCachedDraft: (d: DraftWithPresentationResponse) => setCachedDraft(d),
  };
};

export default usePresentationApi;

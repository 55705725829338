import {
  DraftWithPresentationResponse,
  SlideWithOptions,
} from '../../../types/drafts.types';

/**
 * convert .detailedPresentation data into slide data
 * using slideClones & slides
 **/
export const tranformDetailToSlide = (
  detailedPresentation?: Pick<
    DraftWithPresentationResponse['detailedPresentation'],
    'slideClones' | 'slides'
  >,
): SlideWithOptions[] => {
  if (!detailedPresentation || !detailedPresentation.slides) {
    return [];
  }
  return detailedPresentation?.slides?.map((slide, index) => {
    const slideData = detailedPresentation?.slideClones[index];
    return {
      ...slide,
      slideData,
    };
  });
};

import React, { FC, useEffect } from 'react';

import { useFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import { Box, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useUpdateSlide } from '../../api-http/slides-v2';
import { SlideResponse } from '../../types/slide.types';
import { AlertBar } from '../../components/AlertBar';
import { formMsg, notifyMsg } from '../../configs';
import { useSnack } from '../../util/useSnack';

interface RenameSlideFormProps {
  onFinished: () => void;
  slide: SlideResponse | null;
}

const validationSchema = yup.object({
  title: yup.string().required(formMsg.TITLE_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const RenameSlideForm: FC<RenameSlideFormProps> = ({
  onFinished,
  slide,
}) => {
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { SLIDE_NAME_UPDATE_SUCCESS, SLIDE_NAME_UPDATE_FAILED } = notifyMsg;
  const { isLoading, error, data, mutate, isSuccess } = useUpdateSlide({
    onSuccess: () => showSuccessSnack(SLIDE_NAME_UPDATE_SUCCESS),
    onError: () => showErrSnack(SLIDE_NAME_UPDATE_FAILED),
  });

  useEffect(() => {
    if (data?._id || isSuccess) {
      onFinished();
    }
  }, [data, isSuccess]);

  const formik = useFormik({
    initialValues: {
      title: slide?.title || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (slide?._id) {
        mutate({
          id: slide._id,
          title: values.title,
        });
      }
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="title"
          name="title"
          label="Slide Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <Box mt={'10px'} />
        <LoadingButton
          color="primary"
          disabled={!formik.dirty || isLoading}
          variant="contained"
          fullWidth
          type="submit"
          loading={isLoading}
        >
          Update
        </LoadingButton>
      </form>
    </Container>
  );
};

import { Frequency, RRule } from 'rrule';
import { datetime, Weekday } from 'rrule/dist/esm';

interface UseRRuleProps {
  frequency: string;
  rRuleFrequency: Frequency;
  startDate?: string;
  endDate?: string;
  selectedDays?: any[];
  weekDays?: Weekday[];
  dayFrequency?: string;
  fullDay?: Weekday;
  radioSelect?: string;
  selectMonth?: string;
  selectDate?: string;
}

export const rRuleGenerator = async ({
  frequency,
  rRuleFrequency,
  startDate,
  endDate,
  weekDays,
  radioSelect,
  dayFrequency,
  fullDay,
  selectMonth,
  selectDate,
}: UseRRuleProps) => {
  const startYear = Number(startDate?.toString().slice(0, 4)) || 0;
  const startMonth = Number(startDate?.toString().slice(5, 7)) || 0;
  const startDay = Number(startDate?.toString().slice(8, 10)) || 0;
  const endYear = Number(endDate?.toString().slice(0, 4)) || 0;
  const endMonth = Number(endDate?.toString().slice(5, 7)) || 0;
  const endDay = Number(endDate?.toString().slice(8, 10)) || 0;

  return new RRule({
    freq: rRuleFrequency,
    ...((frequency === 'Custom' || 'Weekly') && { byweekday: weekDays }),
    dtstart: datetime(startYear, startMonth, startDay, 0, 0),
    until: datetime(endYear, endMonth, endDay, 0, 0),
    ...(frequency === 'Monthly' &&
      radioSelect === 'On the' && {
        bysetpos: Number(dayFrequency),
        byweekday: fullDay,
      }),
    ...((frequency === 'Monthly' || radioSelect !== 'On the') && {
      bymonthday: Number(radioSelect?.slice(7, 10)),
    }),
    ...(frequency === 'Yearly' && {
      bymonth: Number(selectMonth),
      bymonthday: Number(selectDate),
    }),
  });
};

import { useMutation, useQuery } from '@tanstack/react-query';
import { PagingResponse } from '../types';
import { PaginateOptions } from '../types/pagination.types';
import { BaseUser, BaseUserWithOrg } from '../types/user.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';

export interface RegisterBody
  extends Pick<BaseUser, 'name' | 'email' | 'role'> {
  password: string;
}
interface RegisterResponse extends Pick<BaseUser, 'name' | 'email' | 'role'> {
  organization: string;
}

interface UserDeleteResponse {
  acknowledged: boolean;
  deletedCount: number;
}

interface UserUpdateBody {
  id: string;
  name: string;
}

interface UserUpdateResponse extends BaseUser {
  organization: string;
}

interface ChangePasswordBody {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const useUserRegister = (opt?: MutOptions<RegisterResponse>) =>
  useMutation<RegisterResponse, ApiServiceErr, RegisterBody>(async (data) => {
    const response = await axiosApi.post('/users/register', data);
    return response.data;
  }, opt);

export const useUserDelete = (opt?: MutOptions<UserDeleteResponse>) =>
  useMutation<UserDeleteResponse, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/users/remove/${data.id}`);
    return response.data;
  }, opt);

export const useUserUpdate = (opt?: MutOptions<UserUpdateResponse>) =>
  useMutation<UserUpdateResponse, ApiServiceErr, UserUpdateBody>(
    async (data) => {
      const response = await axiosApi.put(`/users/update/${data.id}`, {
        name: data.name,
      });
      return response.data;
    },
    opt,
  );

export const useUserChangePassword = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, ChangePasswordBody>(async (data) => {
    const response = await axiosApi.post('/users/changepassword', data);
    return response.data;
  }, opt);

export const useGetPaginateUsers = ({
  limit,
  page,
  sortby,
  order,
  search,
  organization,
}: PaginateOptions & { organization?: string }) =>
  useQuery<PagingResponse<BaseUserWithOrg[]>, ApiServiceErr>(
    [
      '/users/paginate/users?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}&organization=${organization}',
      limit,
      page,
      sortby,
      order,
      search,
    ],
    async () => {
      const response = await axiosApi.get(
        `/users/paginate/users?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}&organization=${organization}`,
      );
      return response.data;
    },
  );

export const useGetOrganizationUsers = () =>
  useQuery<Pick<BaseUser, '_id' | 'name' | 'email'>[], ApiServiceErr>(
    ['/users/organization/all'],
    async () => {
      const response = await axiosApi.get(`/users/organization/all`);
      return response.data;
    },
  );

import { fabric } from 'fabric';

import { IFRAME_WIDGETS } from '../../config';
import { useTooling } from '../../lib/FabricJs';
import handleAddWebSite from '../../lib/FabricJs/utils/handleWebSite';
import { useHandleHistory } from '../../lib/FabricJs/hooks/useHandleHistory';
import handleWorldClockAndDate from '../../lib/FabricJs/utils/handleWorldClockAndDate';
import {
  handleVideoFromFabricObject,
  VideoModeEnum,
} from '../../lib/FabricJs/utils/handleVideo';

const { WORLD_CLOCK, DATE, IFRAME } = IFRAME_WIDGETS;

export const useLoadJSON = () => {
  const { handleHistory } = useHandleHistory();
  const {
    onEdited,
    slidesProps: { updatedKvs },
  } = useTooling();

  const loadJSON = (canvas: fabric.Canvas, projectData: common.ProjectData) => {
    for (const object of projectData?.objects) {
      if (object?.data?.type === 'video') {
        object.src = '';
      } else if (object?.data?.type === 'kvs' && updatedKvs) {
        const kvsId = object?.data?.kvsId;
        if (updatedKvs[kvsId]) {
          object.text = updatedKvs[kvsId];
        }
      }
    }
    onEdited(projectData);
    canvas.loadFromJSON(projectData, async () => {
      for (const obj of canvas.getObjects()) {
        const params = { canvas: canvas, obj, isTime: true };
        switch (obj?.data?.type) {
          case 'video':
            await handleVideoFromFabricObject(
              canvas,
              obj,
              VideoModeEnum.Thumbnail,
            );
            // @ts-ignore;
            obj.isDelete = true; //Identify duplicate objects.
            break;
          case IFRAME:
            handleAddWebSite(canvas, obj?.data.src, handleHistory, obj);
            break;
          case WORLD_CLOCK:
            handleWorldClockAndDate({ ...params });
            break;
          case DATE:
            params.isTime = false;
            handleWorldClockAndDate({ ...params });
            break;
          default:
            canvas?.bringToFront(obj);
        }
      }
      canvas.hoverCursor = 'default';
    });
    canvas.discardActiveObject();
  };
  return { loadJSON };
};

import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { Box, Button, Grid } from '@mui/material';

import { SocketData } from '../../types';
import { UserRole } from '../../types/user.types';

import useSocket from '../../util/useSocket';
import { ProfOfPlayWrapper } from './styles';
import DeviceInfo from './components/DeviceInfo';
import ProofOfPlay from './components/ProofOfPlay';
import AssignedForm from './components/AssignedForm';
import CombinedForm from './components/CombinedForm';
import { useGetDevice } from '../../api-http/devices';
import RecentActivity from './components/RecentActivity';
import AdditionalInfo from './components/AdditionalInfo';
import { useIsUserRole } from '../../util/useIsUserRole';
import { EVENTS, SOCKET_EVENTS } from '../../util/constant';
import DeveloperOptions from './components/DeveloperOptions';
import AssignedScheduler from './components/AssignedScheduler';

const ViewDevice: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { socket } = useSocket();
  const params = useParams<{ id: string }>();

  const developerSectionAccess = useIsUserRole([
    UserRole.superAdmin,
    UserRole.orgAdmin,
  ]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalForms, setOpenModalForms] = useState(false);

  const { data, refetch } = useGetDevice(params?.id as string);

  useEffect(() => {
    refetch();
  }, [openModal]);

  useEffect(() => {
    const updateDeviceListener = (socketData: SocketData) => {
      if (!data) {
        return;
      }
      const { event, data: deviceIdList } = socketData;

      if (
        event === EVENTS.removeDevice &&
        deviceIdList.includes(data?.deviceId)
      ) {
        navigate && navigate('/devices');
      } else if (deviceIdList.includes(data?.deviceId)) {
        refetch();
      }
    };

    if (socket && data) {
      socket.on(SOCKET_EVENTS.dataConnection, updateDeviceListener);
    }

    return () => {
      if (socket) {
        socket.off(SOCKET_EVENTS.dataConnection, updateDeviceListener);
      }
    };
  }, [socket, data]);

  const searchParams = state?.search;
  const devicesPageUrl = searchParams ? `/devices${searchParams}` : '/devices';

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Button
        startIcon={<ArrowLeft />}
        style={{ marginBottom: 10 }}
        onClick={() => navigate(devicesPageUrl)}
      >
        Back
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DeviceInfo data={data} />
        </Grid>
        <Grid item xs={12}>
          {data?.form && (
            <AssignedForm
              deviceData={data}
              handleModalStatus={setOpenModalForms}
              modalStatus={openModalForms}
              refetch={refetch}
            />
          )}
          {data?.scheduler && (
            <AssignedScheduler
              {...data}
              id={data?._id}
              info={data?.deviceInfo.touchEnable}
              handleModalStatus={setOpenModal}
              modalStatus={openModal}
              refetch={refetch}
            />
          )}
          {!data?.form && !data?.scheduler && (
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <CombinedForm
                    {...data}
                    id={data?._id}
                    info={data?.deviceInfo.touchEnable}
                    handleModalStatus={setOpenModal || setOpenModalForms}
                    modalStatus={openModal}
                    refetch={refetch}
                  />
                </div>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <ProfOfPlayWrapper>
            <ProofOfPlay deviceId={data?.deviceId || ''} />
            <RecentActivity id={data?._id} deviceId={data?.deviceId} />
          </ProfOfPlayWrapper>
        </Grid>
        <Grid item xs={12}>
          <AdditionalInfo deviceInfo={data?.deviceInfo} />
        </Grid>
        {developerSectionAccess && (
          <Grid item xs={12}>
            <DeveloperOptions
              debugMode={data?.debugMode}
              debugURL={data?.debugURL}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ViewDevice;

import { FC, useEffect, useState } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useFabricEditor } from '../../lib/FabricJs';
import { useEditorData } from '../../lib/FabricJs';
import { ELEMENTS, IFRAME_WIDGETS } from '../../config';

const { WORLD_CLOCK, DATE } = IFRAME_WIDGETS;

const ElementPicker: FC = () => {
  const types = [WORLD_CLOCK, DATE];
  const { canvas } = useFabricEditor();
  const { element, setElement } = useEditorData();
  const [isClockSelected, setIsClockSelected] = useState(false);
  const [objectType, setObjectType] = useState(types[0]);

  const activeObjects = canvas?.getActiveObjects();

  useEffect(() => {
    let object = null;
    activeObjects?.forEach((obj) => {
      if (types.includes(obj?.data?.type)) {
        setObjectType(obj?.data?.type);
        object = obj;
      }
    });

    if (!ELEMENTS[objectType].includes(element)) {
      setElement('background');
    }

    if (object) {
      return setIsClockSelected(true);
    }

    setIsClockSelected(false);
  }, [activeObjects]);

  if (!isClockSelected) return null;

  return (
    <FormControl fullWidth style={{ marginTop: '10px' }}>
      <InputLabel id="demo-simple-select-label">Element</InputLabel>
      <Select
        id="demo-simple-select"
        value={element}
        label="Element"
        size="small"
        onChange={(e) => setElement(e.target.value)}
      >
        {ELEMENTS[objectType].map((elem) => (
          <MenuItem key={elem} value={elem}>
            {`${elem[0].toUpperCase()}${elem.slice(1)}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ElementPicker;

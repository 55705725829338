import { useEffect, useState } from 'react';
import { useFabricEditor } from './useFabricEditor';
import { useHandleHistory } from './useHandleHistory';
import { fabric } from 'fabric';

export const useHandleObjectLock = () => {
  const { canvas } = useFabricEditor();
  const { handleHistory } = useHandleHistory();

  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    const activeObjects = canvas?.getActiveObjects();
    setIsLocked(false);
    for (const activeObject of activeObjects || []) {
      if (activeObject.lockMovementX) setIsLocked(true);
    }
  }, [canvas?.getActiveObjects()]);

  const handleObjectLock = (transform?: fabric.Transform) => {
    const target = transform?.target;
    let isLockObject = isLocked;
    if (target) isLockObject = Boolean(target?.lockMovementX);
    const fabricCanvas = target?.canvas || canvas;
    if (!fabricCanvas) return null;
    const activeObjects = fabricCanvas?.getActiveObjects();
    for (const activeObject of activeObjects || []) {
      if (activeObject.type === 'i-text') {
        // @ts-ignore;
        activeObject.set({ editable: isLockObject });
      }
      activeObject.set({
        selectable: true,
        lockMovementX: !isLockObject,
        lockMovementY: !isLockObject,
        lockRotation: !isLockObject,
        lockScalingX: !isLockObject,
        lockScalingY: !isLockObject,
      });
      setIsLocked(!isLockObject);
    }
    fabricCanvas?.renderAll();
    handleHistory(fabricCanvas);
  };

  return { handleObjectLock, isLocked };
};

type GetHeightFromRatio = {
  ratioWidth: number;
  ratioHeight: number;
  width: number;
};

type GetMaxSize = {
  canvasWidth: number;
  canvasHeight: number;
  width: number;
  height: number;
};

export const getHeightFromRatio = ({
  ratioWidth,
  ratioHeight,
  width,
}: GetHeightFromRatio) => (width * ratioHeight) / ratioWidth;

export const getMaxSize = ({
  canvasWidth,
  canvasHeight,
  width,
  height,
}: GetMaxSize) => {
  const ratio = width / height;
  const canvasRatio = canvasWidth / canvasHeight;

  if (ratio > canvasRatio) {
    return {
      width: canvasWidth,
      height: getHeightFromRatio({
        ratioWidth: width,
        ratioHeight: height,
        width: canvasWidth,
      }),
    };
  }

  return {
    width: canvasHeight * ratio,
    height: canvasHeight,
  };
};

import { FC } from 'react';
import { Paper, Typography } from '@mui/material';
import styled from 'styled-components/macro';

import SignInComponent from './SignInForm';
import { metaTitle } from '../../../util/tabTitle';
import Logo from '../../../components/Logo';

const MainWrapper = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7ebf0;
`;

const Wrapper = styled(Paper)`
  padding: 28px;
  max-width: 400px;
  margin: 12px;
`;

const DisplaySignInPage: FC = () => {
  metaTitle('Sign In');
  return (
    <MainWrapper>
      <Wrapper>
        <Logo />

        <Typography component="h2" variant="body1" align="center" gutterBottom>
          Sign in to your organization to continue
        </Typography>
        <br />

        <SignInComponent />
      </Wrapper>
    </MainWrapper>
  );
};

export default DisplaySignInPage;

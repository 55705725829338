import { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { useKeyboardEvents } from './hooks/useKeyboardEvents';

import { useEditorData } from './providers/EditorProvider';
import { useTooling } from './providers/EditorToolingProvider';
import { useHandleResize } from './hooks/useHandleReize';
import { RealSize } from '../../types';
import MenuContext from '../../components/common/MenuContext';
import { useInitControls } from '../../core/hooks/useInitControls';
import { useEventControls } from '../../core/hooks/useEventControls';
import { useLoadJSON } from '../../core/hooks/useLoadJSON';
import { useUpdateCanvas } from '../../core/hooks/useUpdateCanvas';

export interface Props extends RealSize {
  onReady?: (canvas: fabric.Canvas) => void;
}

const style = {
  background: '#ebecf0',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};

export const FabricJSCanvas = ({ onReady, realWidth, realHeight }: Props) => {
  const canvasEl = useRef(null);
  const canvasElParent = useRef<HTMLDivElement>(null);
  const [points, setPoints] = useState<common.PointsProps | null>(null);
  const {
    canvas: currentCanvas,
    history,
    initLoad,
    setInitLoad,
  } = useEditorData();
  const { initControls } = useInitControls();
  const { eventControls } = useEventControls();
  const { loadJSON } = useLoadJSON();
  const { updateCanvas } = useUpdateCanvas();

  const {
    slidesProps: { projectData },
    openPreview,
  } = useTooling();

  useHandleResize(canvasElParent, { realWidth, realHeight }, openPreview);
  useKeyboardEvents();

  useEffect(() => {
    if (projectData?.objects && currentCanvas && !initLoad) {
      setInitLoad(true);
      loadJSON(currentCanvas, projectData);
    }
  }, [projectData, currentCanvas]);

  useEffect(() => {
    const { present } = history;
    if (currentCanvas && initLoad) {
      updateCanvas(currentCanvas, present);
    }
  }, [history.present]);

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasEl.current, {
      fireRightClick: true,
    });
    initControls();
    eventControls(canvas, setPoints);
    if (onReady) {
      onReady(canvas);
    }
    return () => {
      canvas.off();
      canvas.dispose();
    };
  }, []);

  return (
    <div
      id="canvas-editor-container"
      onContextMenu={(e: any) => {
        e.preventDefault();
        if (e?.target?.localName === 'div') {
          setPoints(null);
        } else {
          setPoints({
            x: e.pageX,
            y: e.pageY,
          });
        }
      }}
      onMouseDown={(e: any) => {
        if (
          e?.target?.localName === 'div' &&
          currentCanvas &&
          e?.target?.id !== 'overlay'
        ) {
          currentCanvas.discardActiveObject();
          setPoints(null);
        }
      }}
      ref={canvasElParent}
      style={style}
    >
      <canvas ref={canvasEl} />
      {points && (
        <MenuContext
          points={points}
          handleClose={() => {
            setPoints(null);
          }}
        />
      )}
    </div>
  );
};

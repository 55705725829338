import { PaginateOptions } from './pagination.types';

export interface UserNameAsset {
  name: string;
}

export interface AssetsResponse {
  url: string;
  path: string;
  _id: string;
  fileName: string;
  ext: string;
  fileType: string;
  slides: string[];
  originalName: string;
  thumbnailUrl?: string;
  organizationId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: UserNameAsset;
  updatedBy: UserNameAsset;
}

export interface AssetsRenameBody {
  id: string;
  name: string;
}

export enum AssetFileType {
  All = 'all',
  Image = 'image',
  Video = 'video',
  None = 'none',
}

export interface GetPaginateAssetsOptions extends PaginateOptions {
  fileType?: AssetFileType;
  search?: string;
}

import { FC, useState } from 'react';
import Span from './components/Span';
import styled from 'styled-components';
import { useEditorData, useFabricEditor } from '../../lib/FabricJs';
import { SketchPicker } from 'react-color';
import Popover from '@mui/material/Popover';
import { Plus } from 'react-feather';
import {
  CacheEnum,
  setLastUsedValue,
} from '../../lib/FabricJs/utils/sessionCache';
import { colors, defaultColorSet } from '../../configs';
import { handleWorldClockColorChange } from './InfoWidgets/WorldClockWidget';
import ElementPicker from '../Toolbar/ElementPicker';
import { handleDateColorChange } from './InfoWidgets/DateWidget';
import { IFRAME_WIDGETS } from '../../config';
import { SelectMode } from '../../types/types';

const { WORLD_CLOCK, DATE } = IFRAME_WIDGETS;

interface StyleProps {
  color: string;
}

const ColorContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`;

const ColorBox = styled.button<StyleProps>`
  height: 40px;
  width: 40px;
  margin-bottom: 15px;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: ${({ color }) => color};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColorPicker: FC = () => {
  const { activeColor, setActiveColor, setHistory, history, element } =
    useEditorData();
  const { canvas } = useFabricEditor();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColor = (color: string) => {
    const activeObjects = canvas?.getActiveObjects();
    setActiveColor(color);
    setLastUsedValue(CacheEnum.Color, activeObjects);
    if (activeObjects?.length) {
      activeObjects.map((object) => {
        if (object.type === SelectMode.GROUP) {
          // @ts-ignore
          object.getObjects().forEach((item) => {
            item.set({ fill: color });
          });
        }
        switch (object?.data?.type) {
          case WORLD_CLOCK:
            if (element === 'digits') {
              handleWorldClockColorChange(object, color, canvas);
              object.set('data', { ...object.data, color: color });
              return;
            }
            break;
          case DATE:
            if (element === 'date') {
              handleDateColorChange(object, color, canvas);
              object.set('data', { ...object.data, color: color });
              return;
            }
            break;
        }

        object.set({ fill: color });
      });
      const newPresent = canvas?._objects.map((object: fabric.Object) => {
        return {
          object,
          data: object.toJSON(['data']),
        };
      });
      setHistory(newPresent);
    } else {
      canvas?.setBackgroundColor(color, () => {});
      if (
        // @ts-ignore;
        !history.past.find((object) => object?.backgroundColor === colors.white)
      ) {
        setHistory({ backgroundColor: colors.white });
      }
      setHistory({ backgroundColor: color });
    }
    canvas?.renderAll();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ElementPicker />
      <Span color="#0d1216b3">Color Picker</Span>
      <FlexRow>
        <ColorBox
          style={{
            borderImage:
              'linear-gradient(#ff3131, #5e17eb, #00bf63, #0097b2,#ff5757, #ff914d) 5',
            borderWidth: '3px',
            borderStyle: 'solid',
          }}
          color="white"
          aria-describedby={id}
          onClick={handleClick}
        >
          <Plus />
        </ColorBox>
        <ColorBox style={{ border: '1px solid gray' }} color={activeColor} />
      </FlexRow>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SketchPicker
          color={activeColor}
          onChange={(data) => {
            handleColor(data?.hex);
          }}
          disableAlpha
        />
      </Popover>
      <Span color="#0d1216b3">Default Colors</Span>
      <ColorContainer>
        {defaultColorSet.map((color, key) => (
          <ColorBox
            style={{ border: '1px solid gray' }}
            key={key}
            color={color}
            onClick={() => handleColor(color)}
          />
        ))}
      </ColorContainer>
    </>
  );
};

export default ColorPicker;

import React from 'react';
import ButtonComponent from './common/ButtonComponent';
import { Tooltip } from '@mui/material';
import { useEditorData, useFabricEditor } from '../lib/FabricJs';
import { v4 as uuidv4 } from 'uuid';
import { SelectMode } from '../types/types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 12px;
  height: 40px;
  padding: 0 10px;
  background: #f6f7f9;
  border: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

const Group = () => {
  const { canvas } = useFabricEditor();
  const { selectMode } = useEditorData();

  if (selectMode === SelectMode.ONE) return null;

  const group = () => {
    if (!canvas) return null;
    const activeObj = canvas.getActiveObject() as fabric.ActiveSelection;
    if (!activeObj) return;
    const activeGroup = activeObj.toGroup();
    const objectsInGroup = activeGroup.getObjects();
    activeGroup.clone((newGroup: fabric.Group) => {
      // @ts-ignore
      newGroup.set('id', uuidv4());
      canvas.remove(activeGroup);
      objectsInGroup.forEach((object) => {
        canvas.remove(object);
      });
      canvas.add(newGroup);
      canvas.setActiveObject(newGroup);
    });
  };

  const unGroup = () => {
    if (!canvas) return null;
    const activeObject = canvas.getActiveObject() as fabric.Group;
    if (!activeObject) return;
    activeObject.toActiveSelection();
    activeObject.getObjects().forEach((item: fabric.Object) => {
      // @ts-ignore
      item.set('id', uuidv4());
    });
  };

  return (
    <Wrapper>
      {selectMode !== SelectMode.GROUP ? (
        <Tooltip title="Group selected object.">
          <ButtonComponent onClick={group}>Group</ButtonComponent>
        </Tooltip>
      ) : (
        <Tooltip title="Un-Group selected object.">
          <ButtonComponent onClick={unGroup}>Ungroup</ButtonComponent>
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default Group;

import { useTooling } from '../lib/FabricJs';
import { BouncingLoader } from './common/BouncingLoader';
import { CloudDone } from '@mui/icons-material';
import { FC } from 'react';
import { ChevronLeft, Monitor } from 'react-feather';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useUpdateSlide } from '../../../api-http/slides-v2';
import { UpdateSlideTitle } from '../../../types/slide.types';
import { debounce } from '@mui/material';
import { DEBOUNCE_TIME } from '../../../configs';
import { Socket } from 'socket.io-client';
import { updateSlideStatus } from '../../../util/useLockSlide';

const HeaderContainer = styled.header`
  background: rgb(115, 0, 230);
  display: flex;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const HeaderRight = styled(HeaderLeft)``;

const VerticalLine = styled.div`
  background: rgba(255, 255, 255, 0.4);
  height: 20px;
  width: 2px;
  margin: 0 10px;
`;

const CloudDoneIcon = styled(CloudDone)`
  margin: 0 10px;
  color: white;
`;

const HomeBack = styled(Link)`
  color: #fff;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  display: flex;
`;

const EditTitleForm = styled.form`
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  max-width: 300px;
  padding: 10px 20px 10px 10px;
  display: flex;
`;

const TitleInput = styled.input`
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  background: transparent;
  border: none;
  text-align: right;
  width: 100%;
  border-radius: 4px;
  padding: 0 4px;
  &:hover {
    outline: 1px solid white;
  }
`;

const PreviewButton = styled.button`
  background: white;
  margin: 0 10px;
  padding: 8px;
  opacity: 0.9;
  display: flex;
  align-items: center;
`;

const PreviewText = styled.span`
  font-size: 12px;
  margin: 0 4px;
`;

interface HeaderProps {
  onClickPreview: () => void;
  updateTitle: UpdateSlideTitle | undefined;
  socket: Socket;
}

const Header: FC<HeaderProps> = ({ onClickPreview, updateTitle, socket }) => {
  const {
    isLoadingWidget,
    slidesProps: { paramId },
  } = useTooling();
  const [searchParams] = useSearchParams();

  const { mutate } = useUpdateSlide();
  const { state } = useLocation();

  const slidesUrl = state?.search ? `/slides${state?.search}` : '/slides';
  return (
    <HeaderContainer>
      <HeaderLeft>
        <HomeBack
          to={searchParams.get('goBackUrl') ?? slidesUrl}
          aria-label="aria-label"
          onClick={() => updateSlideStatus(socket, paramId || '')}
        >
          <ChevronLeft color="white" size={28} /> Slides
        </HomeBack>
        <VerticalLine />
        {isLoadingWidget ? (
          <BouncingLoader size={8} color="white" />
        ) : (
          <CloudDoneIcon />
        )}
      </HeaderLeft>
      <HeaderRight>
        <EditTitleForm aria-label="Design title">
          <TitleInput
            defaultValue={updateTitle?.title || ''}
            type="text"
            placeholder="Design Title"
            aria-label="Design title"
            onChange={debounce((e) => {
              mutate({
                id: updateTitle?._id || '',
                title: e.target.value,
              });
            }, DEBOUNCE_TIME)}
          />
        </EditTitleForm>
        <PreviewButton aria-label="Preview Design" onClick={onClickPreview}>
          <Monitor size={18} />
          <PreviewText>Preview</PreviewText>
        </PreviewButton>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;

import LogoImg from '../assets/vendor/signage-logo.png';
import styled from 'styled-components';

const Image = styled.img`
  margin-right: ${(props) => props.theme.spacing(2)};
  width: 80%;
  margin: 0 auto;
  display: block;
`;

const Logo = () => {
  return <Image alt="SignageTec Logo" src={LogoImg} />;
};

export default Logo;

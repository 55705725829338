import React, { ChangeEvent, FC, useEffect } from 'react';

import { useFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { Button, Stack, TextField } from '@mui/material';
import { formMsg, notifyMsg } from '../../configs';
import { useUpdateKeyValue } from '../../api-http/kvs';
import { AlertBar } from '../../components/AlertBar';
import { useSnack } from '../../util/useSnack';
import { KvsDataResponse } from '../../types/kvs.types';
import { formatInputKvsKey } from '../../util/validations';

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

interface KvsFormProps {
  onFinished: () => void;
  activeKeyValue: KvsDataResponse | undefined;
  refetch: () => void;
}

const validationSchema = yup.object({
  key: yup
    .string()
    .required(formMsg.KEY_REQUIRED)
    .matches(/^[0-9A-Z_]+$/, formMsg.INVALID_KEY),
  value: yup.string().required(formMsg.VALUE_REQUIRED),
});

export const EditKeyValueForm: FC<KvsFormProps> = ({
  onFinished,
  activeKeyValue,
  refetch,
}) => {
  const { showErrSnack, showSuccessSnack } = useSnack();

  const { isLoading, data, mutate, error } = useUpdateKeyValue({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.KVS_KEY_VALUE_UPDATE_SUCCESS);
      refetch();
    },
    onError: (error) => {
      showErrSnack(error || notifyMsg.KVS_KEY_VALUE_UPDATE_FAILED);
    },
  });

  const formik = useFormik({
    initialValues: {
      key: activeKeyValue?.key || '',
      value: activeKeyValue?.value || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate({
        _id: activeKeyValue?._id || '',
        key: values.key,
        value: values.value,
      });
    },
  });

  useEffect(() => {
    if (data) {
      onFinished();
    }
  }, [data]);

  return (
    <Container>
      <AlertBar
        style={{ marginBottom: '10px' }}
        severity="error"
        msg={error?.msg}
      />
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              id="key"
              name="key"
              label="Key"
              value={formik.values.key}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                formatInputKvsKey(e, 'key', formik)
              }
              size="small"
              error={formik.touched.key && Boolean(formik.errors.key)}
              helperText={formik.touched.key && formik.errors.key}
            />
            <TextField
              fullWidth
              id="value"
              name="value"
              value={formik.values.value}
              onChange={formik.handleChange}
              label="Value"
              size="small"
              error={formik.touched.value && Boolean(formik.errors.value)}
              helperText={formik.touched.value && formik.errors.value}
            />
          </Stack>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            Save
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

import React, { FC } from 'react';

import { CornerUpLeft, CornerUpRight, Sliders } from 'react-feather';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { useEditorData } from '../../EditorProvider';

const ToolBarContainer = styled.div`
  background: white;
  display: flex;
  justify-content: flex-end;
  height: 50px;
  border-top: 1px solid lightgray;
  padding: 10px;
`;

const VerticalLine = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 2px;
  margin: 0 10px;
`;

const HorizontalSlider = styled(Sliders)`
  align-self: center;
  transform: rotate(90deg);
`;

const ToolBar: FC = () => {
  const { isSettingsOpen, setIsSettingsOpen, undo, redo, canUndo, canRedo } =
    useEditorData();
  return (
    <ToolBarContainer>
      <IconButton disabled={!canUndo} onClick={undo}>
        <CornerUpLeft />
      </IconButton>
      <IconButton disabled={!canRedo} onClick={redo}>
        <CornerUpRight />
      </IconButton>
      {!isSettingsOpen && (
        <>
          <VerticalLine />
          <IconButton onClick={() => setIsSettingsOpen(true)}>
            <HorizontalSlider />
          </IconButton>
        </>
      )}
    </ToolBarContainer>
  );
};

export default ToolBar;

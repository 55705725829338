import { ChangeEvent } from 'react';

export const isEmpty = (str: string) => !str.trim().length;

export const formatInput = (
  e: ChangeEvent<HTMLInputElement>,
  keyId: string,
  formik: any,
) => {
  const value = e.target.value.trim();
  formik.setFieldValue(keyId, value.toLowerCase());
};

export const formatInputKvsKey = (
  e: ChangeEvent<HTMLInputElement>,
  keyId: string,
  formik: any,
) => {
  const value = e.target.value.trim();
  formik.setFieldValue(keyId, value.toUpperCase());
};

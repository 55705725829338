export const DEFAULT_TIME_OUT = 3 * 1000; // 3 seconds
export const DEFAULT_TRANS_TIME = 3 * 1000; // 3 seconds
export enum AnimationEnum {
  none = 'None',
  fade = 'Fade',
  slide = 'Slide',
  zoom = 'Zoom',
}

export const TRANSITIONS = [
  AnimationEnum.none,
  AnimationEnum.fade,
  AnimationEnum.slide,
  AnimationEnum.zoom,
];

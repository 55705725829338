import { FC } from 'react';
import Span from '../components/Span';
import WorldClockWidget from './WorldClockWidget';
import DateWidget from './DateWidget';
import WebSiteWidget from '../WebSiteWidget';
import WeatherWidget from './WeatherWidget';
import NewsWidget from './NewsWidget';
import { NEWS_TYPES } from '../../../config';

const ShapeWidget: FC = () => {
  return (
    <>
      <Span>WebSite</Span>
      <WebSiteWidget />
      <Span>World Clock</Span>
      <WorldClockWidget />
      <Span>Date</Span>
      <DateWidget />
      <Span>Weather</Span>
      <WeatherWidget />
      <Span>News</Span>
      <NewsWidget newsType={NEWS_TYPES.BBC} />
      <NewsWidget newsType={NEWS_TYPES.CNN} />
    </>
  );
};

export default ShapeWidget;

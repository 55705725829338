import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';

export enum VideoModeEnum {
  Editor = 'editor',
  Thumbnail = 'thumbnail',
  Preview = 'preview',
}

interface HandleVideoOptions {
  url: string;
  canvas: fabric.Canvas;
  mode: VideoModeEnum;
  fileType: string;
  left?: number;
  top?: number;
  object?: fabric.Object;
  assetId: string;
  thumbnailUrl?: string;
}

const DEFAULT_THUMBNAIL_PAUSE_TIME = 300;

function getVideoDimensionsOf(url: string) {
  return new Promise((resolve) => {
    const video = document.createElement('video');

    video.addEventListener(
      'loadedmetadata',
      function () {
        const height = this.videoHeight;
        const width = this.videoWidth;

        resolve({ height, width, video });
        // remove event listener
        const newElement = video.cloneNode(true);
        video.parentNode?.replaceChild(newElement, video);
      },
      false,
    );

    video.src = url;
  });
}

export const handleVideo = async ({
  url,
  mode,
  canvas,
  fileType,
  left = 0,
  top = 0,
  object,
  assetId,
  thumbnailUrl,
}: HandleVideoOptions) => {
  const { width, height, video }: any = await getVideoDimensionsOf(url);
  function getVideoElement() {
    video.id = uuidv4();
    video.width = width;
    video.height = height;
    video.muted = true;
    video.poster = thumbnailUrl || '';
    video.controls = true;
    const source = document.createElement('source');
    source.src = url;
    source.type = fileType;
    video.appendChild(source);
    return video;
  }

  const videoE = getVideoElement();
  const fabVideo = new fabric.Image(videoE, {
    width,
    height,
    left,
    top,
    objectCaching: false,
  });
  fabVideo.set('data', {
    assetId,
    type: 'video',
    url,
    fileType,
    thumbnailUrl,
    deleteVideo: () => {
      // TODO: is this the best way to handle this?
      (fabVideo.getElement() as any).pause();
      fabVideo.getElement().remove();
    },
  });
  if (object) {
    const {
      scaleX,
      scaleY,
      angle,
      selectable,
      // @ts-ignore;
      id,
      // @ts-ignore;
      auto,
      lockMovementX,
      lockMovementY,
      lockRotation,
      lockScalingX,
      lockScalingY,
    } = object;
    fabVideo.set({
      // @ts-ignore;
      id,
      auto,
      scaleX,
      scaleY,
      angle,
      selectable,
      lockMovementX,
      lockMovementY,
      lockRotation,
      lockScalingX,
      lockScalingY,
      thumbnailUrl,
    });
  }
  canvas.add(fabVideo);
  switch (mode) {
    case VideoModeEnum.Editor:
      (fabVideo.getElement() as any).play();
      break;
    case VideoModeEnum.Thumbnail:
      // TODO: will this can be a memory leak?
      (fabVideo.getElement() as any).play();
      setTimeout(() => {
        (fabVideo.getElement() as any).pause();
      }, DEFAULT_THUMBNAIL_PAUSE_TIME);
      break;
    default:
      break;
  }
};

export const handleVideoFromFabricObject = async (
  canvas: fabric.Canvas,
  obj: fabric.Object,
  mode: VideoModeEnum,
) => {
  if (obj.data?.type === 'video' && obj.width && obj.height) {
    await handleVideo({
      canvas,
      url: obj.data.url,
      fileType: obj.data.fileType,
      mode: mode,
      left: obj.left,
      top: obj.top,
      object: obj,
      assetId: obj?.data?.assetId,
      thumbnailUrl: obj?.data?.thumbnailUrl,
    });
  }
};

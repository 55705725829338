import * as React from 'react';
import Popover from '@mui/material/Popover';
import { Grid, IconButton } from '@mui/material';

import styled from 'styled-components';
import { DraftResponse } from '../../../types/drafts.types';
import { displayDateTime } from '../../../util/dateTime';
import { InfoOutlined } from '@mui/icons-material';
const Title = styled('p')`
  font-weight: bold;
  font-size: 15px;
  margin: 0 0 3px 0;
`;

const SubTitle = styled('p')`
  font-size: 14px;
  margin: 0 0 5px 0;
`;

export interface MetaDataPopoverProps {
  data: Pick<
    DraftResponse,
    'category' | '_id' | 'createdAt' | 'updatedAt' | 'updatedBy' | 'createdBy'
  >;
}
const MetaDataPopover: React.FC<MetaDataPopoverProps> = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <InfoOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container p={3}>
          <Grid item xs={12}>
            <Title>Created</Title>
            <SubTitle>
              {data?.createdBy ?? '-'} (
              {displayDateTime(data?.createdAt ?? '-')})
            </SubTitle>
          </Grid>
          <Grid item xs={12}>
            <Title>Updated</Title>
            <SubTitle>
              {data?.updatedBy ?? '-'} {displayDateTime(data?.updatedAt ?? '-')}
            </SubTitle>
          </Grid>
          <Grid item xs={12}>
            <Title>Category</Title>
            <SubTitle>{data?.category?.name ?? '-'}</SubTitle>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default MetaDataPopover;

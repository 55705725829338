import { ApiServiceErr } from '../../../api-http/apiService';
import { useRegisterDevice, VerifyPinBody } from '../../../api-http/devices';
import { formMsg, notifyMsg } from '../../../configs';
import { Nullable } from '../../../types';
import { useSnack } from '../../../util/useSnack';
import { Alert as MuiAlert, Button, TextField } from '@mui/material';
import { spacing } from '@mui/system';
import { useFormik } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

interface RegisterFormProps {
  onSuccess: () => void;
  isLoading: boolean;
  error?: Nullable<ApiServiceErr>;
  deviceData?: VerifyPinBody;
}

interface FormValues {
  name: string;
}

const validationSchema = yup.object({
  name: yup.string().required(formMsg.DEVICE_NAME_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

const Alert = styled(MuiAlert)(spacing);

export const RegisterForm: FC<RegisterFormProps> = ({
  isLoading,
  onSuccess,
  error,
  deviceData,
}) => {
  const { showErrSnack, showSuccessSnack } = useSnack();

  const { mutate } = useRegisterDevice(deviceData, {
    onSuccess: () => {
      showSuccessSnack(notifyMsg.DEVICE_REGISTERED);
      onSuccess();
    },
    onError: (err) => {
      showErrSnack(err);
    },
  });

  const handleSubmit = (values: FormValues) => {
    mutate({
      name: values.name,
    });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        {error && (
          <Alert mt={2} mb={3} severity="warning">
            {error.msg}
          </Alert>
        )}

        <TextField
          fullWidth
          id="name"
          name="name"
          label="Device Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          style={{ marginBottom: 10 }}
        />
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={isLoading}
        >
          Connect
        </Button>
      </form>
    </Container>
  );
};

import { DialogModal } from '../../components';
import Breadcrumbs from '../../components/Breadcrumb';
import RefreshButton from '../../components/RefreshButton';
import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import { metaTitle } from '../../util/tabTitle';
import EventTable from './EventTable';
import { FullCalender } from './FullCalender';
import { PresentationSchedularForm } from './PresentationSchedularForm';
import { Button, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

const TopContainer = styled('div')`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const EventPage: FC = () => {
  const params = useParams<{ id: string }>();
  metaTitle('Event');
  const [openSchedule, setOpenSchedule] = useState(false);
  const [schedulerId, setSchedulerId] = useState('');
  const [refreshTable, setRefreshTable] = useState(false);
  const [toggle, setToggle] = useState(false);

  const location = useLocation();
  const { state } = location;
  const timeZone = state.timezone;

  const searchParams = state?.search;
  const schedulerPageUrl = searchParams
    ? `/scheduler${searchParams}`
    : '/scheduler';

  return (
    <>
      <Stack>
        <Breadcrumbs
          items={[
            {
              label: 'Schedulers',
              to: schedulerPageUrl,
            },
            {
              label: 'Events',
              to: location?.pathname,
              description:
                'This page showcases the collection of events organized within a specific schedule, highlighting their details and providing functionality for event creation, editing, and deletion. Additionally, the events can be viewed on a calendar interface. It is possible to have multiple events scheduled simultaneously. In such cases, the newly created event will replace any previously scheduled event. The event table presents a comprehensive overview of all events, including their start and end dates, times, and frequency.',
            },
          ]}
        />
        <FilterSelectWrapper>
          <TopContainer>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: 0 }}
                onClick={() => {
                  setOpenSchedule(true);
                  setSchedulerId(params.id || '');
                }}
                sx={{ marginRight: '15px' }}
              >
                Add New
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginBottom: 0 }}
                onClick={() => setToggle(!toggle)}
              >
                {toggle ? 'View on List' : 'View on Calendar'}
              </Button>

              <RefreshButton onClick={() => setRefreshTable(!refreshTable)} />
            </div>
          </TopContainer>
          <label style={{ fontSize: 18 }}>Time Zone : {timeZone}</label>
        </FilterSelectWrapper>
      </Stack>
      {toggle ? (
        <FullCalender refresh={refreshTable} />
      ) : (
        <EventTable refresh={refreshTable} />
      )}
      <DialogModal
        title="Create a new event"
        onClose={() => setOpenSchedule(false)}
        open={openSchedule}
        style={{ width: '30%' }}
      >
        <PresentationSchedularForm
          onFinished={() => setOpenSchedule(false)}
          schedulerId={schedulerId || ''}
        />
      </DialogModal>
    </>
  );
};

export default EventPage;

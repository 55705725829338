import { Button, debounce } from '@mui/material';
import Breadcrumbs from '../../components/Breadcrumb';
import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { DevicesTable } from './DevicesTable';
import { AddDeviceModal } from './components/AddDeviceModal';
import { FC, useState } from 'react';
import { StyleTextfield } from '../../components/TextField';
import { DEBOUNCE_TIME } from '../../configs';
import RefreshButton from '../../components/RefreshButton';
import { useHandleSearch } from '../../util/useHandleSearch';
import { useLocationSearch } from '../../util/useLocationSearch';

const DevicesPage: FC = () => {
  metaTitle('Devices');

  const searchVal = useLocationSearch();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [refreshUserTable, setRefreshUserTable] = useState(false);
  const [search, setSearch] = useState(searchVal || '');
  const [searchText, setSearchText] = useState(searchVal || '');
  useHandleSearch(searchText);

  const disableAddUser = useIsUserRole([
    UserRole.user,
    UserRole.dataEditor,
    UserRole.layoutEditor,
  ]);

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: 'Devices',
            to: '/devices',
            description:
              'This page displays all registered devices (screens) within the system. Users can add a device by entering the code shown on the device into the designated space. To receive the code, the device must have the app installed. The device list shows the status of each device, indicating whether it is online or offline. The actions available include viewing device details, removing a device, and obtaining screenshots as proof of play. In the device view, users can access basic device information, the currently playing presentation, remove the device from the list, and set a scheduler for the device.',
          },
        ]}
      />
      <FilterSelectWrapper>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenUserModal(true)}
            disabled={disableAddUser}
            style={{ marginBottom: 0 }}
          >
            Add Device
          </Button>

          <RefreshButton
            onClick={() => setRefreshUserTable(!refreshUserTable)}
          />
        </div>
        <div>
          <StyleTextfield
            label={'Search By Name'}
            variant={'outlined'}
            onChange={(event) => {
              setSearchText(event.target.value);
              debounce(() => setSearch(event.target.value), DEBOUNCE_TIME)();
            }}
            value={searchText}
          />
        </div>
      </FilterSelectWrapper>

      <DevicesTable refresh={refreshUserTable} search={search} />

      <AddDeviceModal
        onClose={() => setOpenUserModal(false)}
        open={openUserModal}
        onFinished={() => {
          setOpenUserModal(false);
          setRefreshUserTable(!refreshUserTable);
        }}
      />
    </>
  );
};

export default DevicesPage;

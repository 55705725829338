import { getApiError } from '../../api-http/apiService';
import { useUploadAsset } from '../../api-http/assets';
import { AlertBar } from '../../components/AlertBar';
import { notifyMsg } from '../../configs';
import { useSnack } from '../../util/useSnack';
import { CloudUpload, DeleteOutline } from '@mui/icons-material';
import {
  Button,
  LinearProgress,
  Typography,
  Box,
  Paper,
  IconButton,
  Grid,
} from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

export enum AssetAccept {
  Videos = 'video/*',
  Images = 'image/*',
}

interface AddAssetFormProps {
  onFinished: () => void;
  accept?: AssetAccept;
}

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

const TopContentContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: center;
`;

const StyledDropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  &:hover {
    border-color: #2196f3;
    background-color: #f0f8ff;
    color: #2196f3;
  }
`;

const StyledImage = styled.img`
  max-width: 150px;
  max-height: 150px;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-top: 28px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const AddAssetForm: FC<AddAssetFormProps> = ({ onFinished }) => {
  const { showSuccessSnack, showErrSnack } = useSnack();

  const { mutate, isLoading, error, data, isSuccess, isError } =
    useUploadAsset();

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (data) {
      onFinished();
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      showSuccessSnack(notifyMsg.ASSET_ADDED_SUCCESS);
    }
    if (isError) {
      const errorMsg = getApiError(error);
      showErrSnack(errorMsg);
    }
  }, [isSuccess, isError]);

  const uploadFile = () => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    mutate(formData);
  };

  const MAX_FILE_SIZE = 100000000; // 100MB in bytes

  const MAX_FILE_COUNT = 5;

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (files.length + acceptedFiles.length > MAX_FILE_COUNT) {
        showErrSnack(notifyMsg.ASSETS_MAX_FILE_COUNT);
        return;
      }

      acceptedFiles.forEach((file) => {
        if (
          !file.type.startsWith('image/') &&
          !file.type.startsWith('video/')
        ) {
          showErrSnack(notifyMsg.ASSETS_IMAGE_VIDEO_ALLOWED);
          return;
        }
        if (file.size > MAX_FILE_SIZE) {
          showErrSnack(notifyMsg.ASSETS_MAX_FILE_SIZE);
          return;
        }

        setFiles((prevFiles) => [...prevFiles, file]);
      });
    },
    [files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  const handleDelete = (index: number) => {
    setFiles((prevFiles: File[]) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />
      {isLoading && <LinearProgress />}
      <TopContentContainer>
        <CloudUpload sx={{ fontSize: 50, color: '#4A4458' }} />
        <Typography fontSize={20} fontWeight={600}>
          Choose files
        </Typography>
        <Typography fontSize={14} fontWeight={400} color={'#5C5C5C'}>
          Files must be in png, jpg, gif and mp4 format
        </Typography>
        <Typography fontSize={12} fontWeight={300} color={'#5C5C5C'}>
          Maximum file size is 100MB
        </Typography>
      </TopContentContainer>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Paper elevation={1}>
          <StyledDropzoneContainer {...getRootProps({ isDragActive })}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography fontSize={14} fontWeight={400} color={'#969696'}>
                Drop files here
              </Typography>
            ) : (
              <Typography fontSize={14} fontWeight={400} color={'#969696'}>
                Drag and drop files here, or click to select files
              </Typography>
            )}
          </StyledDropzoneContainer>
        </Paper>
      </Box>

      {files.length > 0 && (
        <Grid
          container
          spacing={2}
          marginTop={'16px'}
          height={'300px'}
          style={{ overflow: 'auto' }}
        >
          {files?.map((file: File, i: number) => (
            <Grid item xs={12} sm={12} md={12} key={i}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                border={'1px solid'}
                borderRadius={'5px'}
                borderColor={'#A49696'}
                padding={'10px'}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                >
                  {file.type.startsWith('image/') ? (
                    <StyledImage
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                    />
                  ) : (
                    <video width={'160'} height={'120'} controls>
                      <source
                        src={URL.createObjectURL(file)}
                        type={file.type}
                      />
                    </video>
                  )}
                  <Typography
                    fontSize={14}
                    fontWeight={400}
                    paddingLeft={'10px'}
                  >
                    {file.name}
                  </Typography>
                </Box>
                <Box marginTop={'8px'}>
                  <IconButton
                    color={'error'}
                    aria-label={'delete'}
                    onClick={() => handleDelete(i)}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      <ButtonContainer>
        <Button
          color="primary"
          variant="outlined"
          type="submit"
          disabled={isLoading}
          onClick={() => onFinished()}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={isLoading || files?.length === 0}
          onClick={uploadFile}
        >
          Upload
        </Button>
      </ButtonContainer>
    </Container>
  );
};

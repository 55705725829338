import { Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { Trash2, Eye } from 'react-feather';

interface DeviceActionMenuProps {
  onClickDelete: () => void;
  onClickView: () => void;
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
}

export const DeviceActionMenu: FC<DeviceActionMenuProps> = ({
  onClickDelete,
  onClickView,
  onClose,
  open,
  anchorEl,
}) => {
  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      <MenuItem onClick={onClickDelete}>
        <Trash2 size={16} style={{ marginRight: 10 }} />
        Remove
      </MenuItem>
      <MenuItem onClick={onClickView}>
        <Eye size={16} style={{ marginRight: 10 }} />
        View
      </MenuItem>
    </Menu>
  );
};

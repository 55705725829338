import { TextSnippet } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { FC, useState } from 'react';
import AddSlideModal from './AddSlideModal';
import EditKvsValuesModal from './EditKvsValuesModal';
import styled from 'styled-components';
import { useDraftEdit } from '../hooks/useDraftEdit';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface ActionButtonSectionProps
  extends Pick<ReturnType<typeof useDraftEdit>, 'handleAddSlide'> {
  isPublishedMode: boolean;
  publishedId: string;
  onRefetch: () => void;
}

const ActionButtonSection: FC<ActionButtonSectionProps> = ({
  isPublishedMode,
  publishedId,
  onRefetch,
  handleAddSlide,
}) => {
  const [openSlide, setSlideOpen] = useState(false);
  const [openKvsEdit, setOpenKvsEdit] = useState(false);

  return (
    <>
      <ButtonContainer>
        {!isPublishedMode ? (
          <Button variant="contained" onClick={() => setSlideOpen(true)}>
            Add New Slide
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => setOpenKvsEdit(true)}
            startIcon={<TextSnippet />}
          >
            Dynamic Data
          </Button>
        )}
      </ButtonContainer>

      <AddSlideModal
        open={openSlide}
        setOpen={setSlideOpen}
        onSlideAdd={(slide) => {
          handleAddSlide(slide);
          setSlideOpen(false);
        }}
      />

      <EditKvsValuesModal
        id={publishedId || ''}
        open={openKvsEdit}
        onClose={() => setOpenKvsEdit(false)}
        refetch={onRefetch}
      />
    </>
  );
};

export default ActionButtonSection;

import React from 'react';
import SettingsWrapper from './common/SettingsWrapper';
import centerX from '../assets/editor/centerX.svg';
import center from '../assets/editor/center.svg';
import centerY from '../assets/editor/centerY.svg';
import ButtonComponent from './common/ButtonComponent';
import { useFabricEditor } from '../lib/FabricJs';
import { useHandleHistory } from '../lib/FabricJs/hooks/useHandleHistory';
import { Tooltip } from '@mui/material';

const CenterAlign = () => {
  const { canvas } = useFabricEditor();
  const { handleHistory } = useHandleHistory();

  const alignCenter = () => {
    const activeObject = canvas?.getActiveObject();
    if (canvas && activeObject) {
      const ratio = canvas.getZoom() || 1;
      canvas._centerObject(
        activeObject,
        new fabric.Point(
          canvas.getCenter().left / ratio,
          canvas.getCenter().top / ratio,
        ),
      );
      handleHistory();
    }
  };

  const alignCenterX = () => {
    const activeObject = canvas?.getActiveObject();
    if (canvas && activeObject) {
      const ratio = canvas.getZoom() || 1;
      canvas._centerObject(
        activeObject,
        new fabric.Point(
          canvas.getCenter().left / ratio,
          activeObject.getCenterPoint().y,
        ),
      );
      handleHistory();
    }
  };

  const alignCenterY = () => {
    const activeObject = canvas?.getActiveObject();
    if (canvas && activeObject) {
      const ratio = canvas.getZoom() || 1;
      canvas._centerObject(
        activeObject,
        new fabric.Point(
          activeObject.getCenterPoint().x,
          canvas.getCenter().top / ratio,
        ),
      );
      handleHistory();
    }
  };

  return (
    <SettingsWrapper>
      <Tooltip title="centerX">
        <ButtonComponent onClick={alignCenterX}>
          <img src={centerX} />
        </ButtonComponent>
      </Tooltip>
      <Tooltip title="center">
        <ButtonComponent onClick={alignCenter}>
          <img src={center} />
        </ButtonComponent>
      </Tooltip>
      <Tooltip title="centerY">
        <ButtonComponent onClick={alignCenterY}>
          <img src={centerY} />
        </ButtonComponent>
      </Tooltip>
    </SettingsWrapper>
  );
};

export default CenterAlign;

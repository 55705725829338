import React, { FC } from 'react';
import { FormHelperText } from '@mui/material';

interface FormErrorTextProps {
  formikTouched?: boolean;
  formikErrors?: string;
}

const FormErrorText: FC<FormErrorTextProps> = ({
  formikTouched,
  formikErrors,
}) => (
  <FormHelperText style={{ color: '#CD2829', paddingLeft: 12, marginTop: 2 }}>
    {formikTouched && formikErrors}
  </FormHelperText>
);

export default FormErrorText;

const setWrapperElementStyles = (
  wrapperDiv: HTMLDivElement,
  object: any,
  displayRatio: number,
  deviceBrand?: string,
) => {
  wrapperDiv.style.position = 'absolute';
  wrapperDiv.style.display = 'block';
  wrapperDiv.style.left = `${object.left * displayRatio}px`;
  wrapperDiv.style.top = `${object.top * displayRatio}px`;
  wrapperDiv.style.width = `${object.width * object.scaleX * displayRatio}px`;
  wrapperDiv.style.height = `${object.height * object.scaleY * displayRatio}px`;
  wrapperDiv.style.transformOrigin = `${object.originX} ${object.originY}`;
  if (deviceBrand !== 'Amazon') {
    wrapperDiv.style.transform = `rotate(${object.angle}deg)`;
  }
  wrapperDiv.style.zIndex = '0';
  wrapperDiv.style.backgroundColor = '#ffffff';
};

const getWrapperElement = (
  object: any,
  displayRatio: number,
  assetId: string,
  deviceBrand?: string,
) => {
  const elementId = `wrapper-div_${assetId}`;
  const existingWrapperDiv = document.getElementById(
    elementId,
  ) as HTMLDivElement;
  if (existingWrapperDiv) {
    setWrapperElementStyles(
      existingWrapperDiv,
      object,
      displayRatio,
      deviceBrand,
    );
    return existingWrapperDiv;
  } else {
    const wrapperDiv = document.createElement('div') as HTMLDivElement;
    setWrapperElementStyles(wrapperDiv, object, displayRatio, deviceBrand);
    wrapperDiv.setAttribute('id', elementId);
    return wrapperDiv;
  }
};

const getVideoPlayingTxtElement = (displayRatio: number) => {
  const elementId = 'video-playing-p';
  const existingVideoPlaying = document.getElementById(
    elementId,
  ) as HTMLParagraphElement;
  if (existingVideoPlaying) {
    existingVideoPlaying.style.fontSize = `${displayRatio * 3}em`;
    return existingVideoPlaying;
  } else {
    const videoPlaying = document.createElement('p') as HTMLParagraphElement;
    videoPlaying.setAttribute('id', elementId);
    videoPlaying.innerHTML = 'Video is playing...';
    videoPlaying.style.fontSize = `${displayRatio * 3}em`;
    videoPlaying.style.paddingBottom = '30px';
    videoPlaying.style.fontWeight = 'bold';
    videoPlaying.style.textAlign = 'center';
    return videoPlaying;
  }
};

const getVideoScreenshotRestrictedTxtElement = (displayRatio: number) => {
  const elementId = 'video-screenshot-restriction-p';
  const existingVideoRestriction = document.getElementById(
    elementId,
  ) as HTMLParagraphElement;
  if (existingVideoRestriction) {
    existingVideoRestriction.style.fontSize = `${displayRatio * 3}em`;
    return existingVideoRestriction;
  } else {
    const videoRestriction = document.createElement(
      'p',
    ) as HTMLParagraphElement;
    videoRestriction.setAttribute('id', elementId);
    videoRestriction.innerHTML =
      'With the device privacy restrictions, we cannot take screenshots of videos.';
    videoRestriction.style.fontSize = `${displayRatio * 3}em`;
    videoRestriction.style.paddingBottom = '30px';
    videoRestriction.style.fontWeight = 'bold';
    videoRestriction.style.textAlign = 'center';
    return videoRestriction;
  }
};

const getCurrentPlayingVideoDetailsTxtElement = (
  id: string,
  displayRatio: number,
) => {
  const existingVideoCurrentDetails = document.getElementById(
    id,
  ) as HTMLParagraphElement;
  if (existingVideoCurrentDetails) {
    existingVideoCurrentDetails.style.fontSize = `${displayRatio * 3}em`;
    return existingVideoCurrentDetails;
  } else {
    const videoCurrentDetails = document.createElement(
      'p',
    ) as HTMLParagraphElement;
    videoCurrentDetails.setAttribute('id', id);
    videoCurrentDetails.style.fontSize = `${displayRatio * 3}em`;
    videoCurrentDetails.style.overflow = 'hidden';
    videoCurrentDetails.style.textAlign = 'center';
    videoCurrentDetails.style.marginBottom = `${displayRatio * 80}px`;
    return videoCurrentDetails;
  }
};

const getCurrentPlayingVideoDetailsHeaderTxtElement = (
  id: string,
  displayRatio: number,
) => {
  const existingVideoCurrentDetails = document.getElementById(
    id,
  ) as HTMLParagraphElement;
  if (existingVideoCurrentDetails) {
    existingVideoCurrentDetails.style.fontSize = `${displayRatio * 2}em`;
    return existingVideoCurrentDetails;
  } else {
    const videoCurrentDetails = document.createElement(
      'p',
    ) as HTMLParagraphElement;
    videoCurrentDetails.setAttribute('id', id);
    videoCurrentDetails.style.fontSize = `${displayRatio * 2}em`;
    videoCurrentDetails.style.overflow = 'hidden';
    videoCurrentDetails.style.textAlign = 'center';
    return videoCurrentDetails;
  }
};

export {
  getWrapperElement,
  getVideoPlayingTxtElement,
  getVideoScreenshotRestrictedTxtElement,
  getCurrentPlayingVideoDetailsTxtElement,
  getCurrentPlayingVideoDetailsHeaderTxtElement,
};

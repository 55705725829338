type ActiveObject = any; // Note: this is a FabricJs type

export enum CacheEnum {
  Color = 'color',
}

export const setSessionCache = (key: CacheEnum, value: string) =>
  sessionStorage.setItem(key, value);

export const getSessionCache = (key: CacheEnum) => sessionStorage.getItem(key);

export const setLastUsedValue = (
  value: CacheEnum,
  activeObjects: ActiveObject[] = [],
) => {
  for (const current of activeObjects) {
    if (value === CacheEnum.Color) {
      const lastUsedColor = current['fill'];
      if (lastUsedColor) {
        setSessionCache(value, lastUsedColor);
      }
    }
  }
};

import { FC, useCallback, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ChevronLeft, Monitor } from 'react-feather';
import styled from 'styled-components';
import { useEditorData } from '../EditorProvider';
import { BouncingLoader } from '../../canvas-editor-v2/components/common/BouncingLoader';
import { debounce } from '@mui/material';
import { useUpdateForms } from '../../../api-http/forms';
import { DEBOUNCE_TIME } from '../../../configs';
import { CloudDone } from '@mui/icons-material';

const HeaderContainer = styled.header`
  background: rgb(115, 0, 230);
  display: flex;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const HeaderRight = styled(HeaderLeft)``;

const VerticalLine = styled.div`
  background: rgba(255, 255, 255, 0.4);
  height: 20px;
  width: 2px;
  margin: 0 10px;
`;

const HomeBack = styled(Link)`
  color: #fff;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  display: flex;
`;

const PreviewButton = styled.button`
  background: white;
  margin: 0 10px;
  padding: 8px;
  opacity: 0.9;
  display: flex;
  align-items: center;
`;

const PreviewText = styled.span`
  font-size: 12px;
  margin: 0 4px;
`;

const CloudDoneIcon = styled(CloudDone)`
  margin: 0 10px;
  color: white;
`;

interface HeaderProps {
  onClickPreview?: () => void;
}

const Header: FC<HeaderProps> = () => {
  const { id } = useParams();
  const didMount = useRef(false);
  const { setShowPreview } = useEditorData();
  const { history } = useEditorData();
  const { present } = history;

  const { isLoading, mutate } = useUpdateForms({
    onSuccess: () => {},
    onError: () => {},
  });

  const updateApiDebounce = useCallback(
    debounce((data) => mutate(data), DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (id && didMount.current) {
      updateApiDebounce({ _id: id, formBody: JSON.stringify(present) });
    } else {
      didMount.current = true;
    }
  }, [present]);

  return (
    <HeaderContainer>
      <HeaderLeft>
        <HomeBack to="/forms" aria-label="editor-back-button">
          <ChevronLeft color="white" size={28} /> Forms
        </HomeBack>
        <VerticalLine />
        {isLoading ? (
          <BouncingLoader size={8} color="white" />
        ) : (
          <CloudDoneIcon />
        )}
      </HeaderLeft>
      <HeaderRight>
        <PreviewButton
          aria-label="Preview Design"
          onClick={() => setShowPreview(true)}
        >
          <Monitor size={18} />
          <PreviewText>Preview</PreviewText>
        </PreviewButton>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;

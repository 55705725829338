import { FC } from 'react';
import Button from '../components/Button';

import { useFabricEditor } from '../../../lib/FabricJs';
import handleWorldClockAndDate, {
  getDimensions,
  getStyleTag,
} from '../../../lib/FabricJs/utils/handleWorldClockAndDate';
import { useHandleHistory } from '../../../lib/FabricJs/hooks/useHandleHistory';

export const handleWorldClockColorChange = (
  object: fabric.Object,
  color: string,
  canvas?: fabric.Canvas | null,
) => {
  const [clock] = document.getElementsByClassName(
    object.data.className,
  ) as HTMLCollectionOf<HTMLIFrameElement>;
  const doc = clock.contentDocument || clock.contentWindow?.document;
  const styleTag = doc?.querySelector('style');

  if (styleTag) {
    const { width, height } = getDimensions(canvas, object);
    styleTag.textContent = getStyleTag(
      `${width}px`,
      `${height}px`,
      color,
      true,
    );
  }
};

const WorldClockWidget: FC = () => {
  const { canvas } = useFabricEditor();
  const { handleHistory } = useHandleHistory();

  return (
    <Button
      onClick={() =>
        canvas && handleWorldClockAndDate({ canvas, handleHistory })
      }
    >
      Add Clock
    </Button>
  );
};

export default WorldClockWidget;

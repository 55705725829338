import { SurveyJSON } from '../types';
import { AlignmentTypes } from './logoData';

const { CENTER, RIGHT, LEFT } = AlignmentTypes;

export default (surveyData?: SurveyJSON) => {
  const { alignContent } = surveyData || {};

  switch (alignContent) {
    case RIGHT:
      return 'svd_align_right';

    case LEFT:
      return 'svd_align_left';

    case CENTER:
      return 'svd_align_center';
  }
};

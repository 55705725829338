import { useEffect, useState } from 'react';

import { useGetPaginateKvs } from '../../../api-http/kvs';
import { useSnack } from '../../../util/useSnack';
import { OrderEnum } from '../../../types/pagination.types';

const PAGE_LIMIT = 100;

export const useKvsGroup = () => {
  const { showErrSnack } = useSnack();
  const [kvsGroupSearchValue, setKvsGroupSearchValue] = useState<string>('');

  const {
    isLoading: isKvsGroupsLoading,
    error,
    data: kvsGroups,
  } = useGetPaginateKvs({
    limit: PAGE_LIMIT,
    page: 1,
    sortby: 'createdAt',
    order: OrderEnum.desc,
    search: kvsGroupSearchValue,
  });

  useEffect(() => {
    if (error) {
      showErrSnack(error);
    }
  }, [error]);

  return {
    isKvsGroupsLoading,
    setKvsGroupSearchValue,
    kvsGroupData: kvsGroups?.data || [],
  };
};

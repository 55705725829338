import { ApkResponse } from '../../api-http/apk';
import { AlertBar } from '../../components/AlertBar';
import { StyledTableCell } from '../../components/TableTheme';
import { PagingResponse } from '../../types';
import {
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import React, { FC } from 'react';
import { Download } from 'react-feather';

interface ApksTableProps {
  data?: PagingResponse<ApkResponse>;
  isLoading: boolean;
  error?: any;
}

const ApkTable: FC<ApksTableProps> = ({ data, error, isLoading }) => {
  const TableRows: FC<Pick<ApksTableProps, 'data'>> = ({ data }) => {
    return (
      <>
        {data?.data?.map(({ _id, name, version, key }) => (
          <TableRow key={_id}>
            <TableCell>{name}</TableCell>
            <TableCell>{version}</TableCell>
            <TableCell>
              <a href={key} download>
                <Button>
                  <Download size={16} />
                </Button>
              </a>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <AlertBar severity="error" msg={error?.msg} />
      {isLoading && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Version</StyledTableCell>
              <StyledTableCell>Download</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRows data={data} />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ApkTable;

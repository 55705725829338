import React, { FC, useEffect } from 'react';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import * as yup from 'yup';

import { Button, Stack, TextField } from '@mui/material';

import {
  useCreateCategory,
  useGetPaginateCategory,
} from '../../api-http/categories';
import { AlertBar } from '../../components/AlertBar';
import { formMsg, notifyMsg } from '../../configs';
import { ERROR, SUCCESS } from '../../util/constant';
import { useSort } from '../../util/useSortHook';
import { PAGE, ROWS_PER_PAGE } from '../constant';
import { useIsUserRole } from '../../util/useIsUserRole';
import { UserRole } from '../../types/user.types';
import { useAuth } from '../../context/auth-context';

interface CategoryFormProps {
  onFinished: () => void;
}

const validationSchema = yup.object({
  title: yup.string().required(formMsg.TITLE_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const CategoryForm: FC<CategoryFormProps> = ({ onFinished }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { switchedOrgId } = useAuth();

  const { sortBy, order } = useSort();
  const { isLoading, error, data, mutate, isSuccess } = useCreateCategory();
  const { refetch } = useGetPaginateCategory({
    limit: ROWS_PER_PAGE,
    page: PAGE + 1,
    sortby: sortBy,
    order,
  });

  const isSuperAdmin = useIsUserRole([UserRole.superAdmin]);

  useEffect(() => {
    if (data) {
      onFinished();
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(
        isSuperAdmin
          ? {
              name: values?.title,
              organizationId: switchedOrgId,
            }
          : { name: values?.title },
      );
    },
  });
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(notifyMsg.CATEGORY_CREATE_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (error) {
      enqueueSnackbar(notifyMsg.CATEGORY_CREATE_FAILED, {
        variant: ERROR,
      });
    }
    refetch();
  }, [isSuccess, error]);

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            id="title"
            name="title"
            label="Category Name"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />

          <Button
            color="primary"
            disabled={!formik.values.title || isLoading}
            variant="contained"
            fullWidth
            type="submit"
          >
            Create
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

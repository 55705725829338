import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Button, Link, Grid, Typography } from '@mui/material';
import { DialogModal } from '../../../components';
import { AssignSchedulerForm } from './AssignSchedulerForm';
import { AddSchedulerModal } from '../../scheduler/components/AddSchedulerModal';
import { AssignFormForm } from './AssignFormForm';
import { FormResponse } from '../../../types/form.types';
import { SchedulerResponse } from '../../../types/scheduler.types';
import { AddFormModal } from '../../form/AddFormModal';
import { PresentationActionWrapper, Wrapper } from '../styles';

interface CombinedFormProps {
  deviceName?: string;
  id?: string;
  info?: boolean;
  handleModalStatus: Dispatch<SetStateAction<boolean>>;
  modalStatus: boolean;
  form?: FormResponse;
  scheduler?: SchedulerResponse;
  deviceId?: string;
  selectedDeviceId?: string;
  refetch: () => void;
}

interface AssignFormFormProps {
  onFinished: () => void;
  selectedDeviceId?: string;
  refetch: () => void;
}

interface AssignSchedulerFormProps {
  onFinished: () => void;
  selectedDeviceId?: string;
  refetch: () => void;
}

const CombinedForm: FC<CombinedFormProps> = ({ id, refetch, info }) => {
  const [openSchedulerModal, setOpenSchedulerModal] = useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [assignSchedulerModalOpen, setAssignSchedulerModalOpen] =
    useState(false);
  const [assignFormModalOpen, setAssignFormModalOpen] = useState(false);

  const handleAssignFormFormFinished: AssignFormFormProps['onFinished'] =
    () => {
      setAssignFormModalOpen(false);
      refetch();
    };

  const handleAssignSchedulerFormFinished: AssignSchedulerFormProps['onFinished'] =
    () => {
      setAssignSchedulerModalOpen(false);
      refetch();
    };

  return (
    <Wrapper>
      <Typography variant="h6" sx={{ margin: '16px' }} textAlign="center">
        No Scheduler or Form assigned to this device
      </Typography>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <PresentationActionWrapper>
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setAssignSchedulerModalOpen(true)}
                >
                  Assign Scheduler
                </Button>
              </Grid>
              <Grid item xs={6} justifyContent="center" alignItems="center">
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => setOpenSchedulerModal(true)}
                >
                  Create Scheduler
                </Link>
              </Grid>
            </Grid>
            <DialogModal
              title="Assign a scheduler"
              onClose={() => setAssignSchedulerModalOpen(false)}
              open={assignSchedulerModalOpen}
            >
              <AssignSchedulerForm
                onFinished={handleAssignSchedulerFormFinished}
                selectedDeviceId={id}
              />
            </DialogModal>
            <AddSchedulerModal
              open={openSchedulerModal}
              onClose={() => setOpenSchedulerModal(false)}
            />
          </Grid>
        </PresentationActionWrapper>
        <PresentationActionWrapper>
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setAssignFormModalOpen(true)}
                  disabled={!info}
                >
                  Assign Form
                </Button>
              </Grid>
              <Grid item xs={6} justifyContent="center" alignItems="center">
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => setOpenFormModal(true)}
                  disabled={!info}
                >
                  Create Form
                </Link>
              </Grid>
            </Grid>
            <DialogModal
              title="Assign a form"
              onClose={() => setAssignFormModalOpen(false)}
              open={assignFormModalOpen}
            >
              <AssignFormForm
                onFinished={handleAssignFormFormFinished}
                selectedDeviceId={id}
              />
            </DialogModal>
            <AddFormModal
              open={openFormModal}
              onClose={() => setOpenFormModal(false)}
            />
          </Grid>
        </PresentationActionWrapper>
      </Grid>
    </Wrapper>
  );
};

export default CombinedForm;

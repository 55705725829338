import React from 'react';
import SettingsWrapper from './common/SettingsWrapper';
import { Tooltip } from '@mui/material';
import ButtonComponent from './common/ButtonComponent';
import { useEditorData, useFabricEditor } from '../lib/FabricJs';

import groupAlignLeft from '../assets/editor/groupAlignLeft.svg';
import groupAlignCenterX from '../assets/editor/groupAlignCenterX.svg';
import groupAlignRight from '../assets/editor/groupAlignRight.svg';
import groupAlignTop from '../assets/editor/groupAlignTop.svg';
import groupAlignCenterY from '../assets/editor/groupAlignCenterY.svg';
import groupAlignBottom from '../assets/editor/groupAlignBottom.svg';
import groupAlignAverageY from '../assets/editor/groupAlignAverageY.svg';
import { useHandleHistory } from '../lib/FabricJs/hooks/useHandleHistory';

export default function Align() {
  const { selectMode } = useEditorData();
  const { canvas } = useFabricEditor();
  const { handleHistory } = useHandleHistory();

  if (!canvas) return null;

  const left = () => {
    const activeObject = canvas.getActiveObject();
    const activeObjects = canvas.getActiveObjects();
    if (activeObject && activeObjects.length) {
      const activeObjectLeft = -(activeObject.width ?? 0) / 2;
      activeObjects.forEach((object) => {
        object.set({
          left: activeObjectLeft,
        });
      });
      canvas.renderAll();
      handleHistory();
    }
  };

  const right = () => {
    const activeObject = canvas.getActiveObject();
    const activeObjects = canvas.getActiveObjects();
    if (activeObject && activeObjects.length) {
      const activeObjectLeft = (activeObject.width ?? 0) / 2;
      activeObjects.forEach((object) => {
        object.set({
          left: activeObjectLeft - (object.width ?? 0) * (object.scaleX ?? 0),
        });
      });
      canvas.renderAll();
      handleHistory();
    }
  };

  const centerX = () => {
    const activeObject = canvas.getActiveObject();
    const activeObjects = canvas.getActiveObjects();
    if (activeObject && activeObjects.length) {
      activeObjects.forEach((object) => {
        object.set({
          left: 0 - ((object.width ?? 0) * (object.scaleX ?? 0)) / 2,
        });
      });
      canvas.renderAll();
      handleHistory();
    }
  };

  const centerY = () => {
    const activeObject = canvas.getActiveObject();
    const activeObjects = canvas.getActiveObjects();
    if (activeObject && activeObjects.length) {
      activeObjects.forEach((object) => {
        object.set({
          top: 0 - ((object.height ?? 0) * (object.scaleY ?? 0)) / 2,
        });
      });
      canvas.renderAll();
      handleHistory();
    }
  };

  const top = () => {
    const activeObject = canvas.getActiveObject();
    const activeObjects = canvas.getActiveObjects();
    if (activeObject && activeObjects.length) {
      const activeObjectTop = -((activeObject.height ?? 0) / 2);
      activeObjects.forEach((object) => {
        object.set({
          top: activeObjectTop,
        });
      });
      canvas.renderAll();
      handleHistory();
    }
  };

  const bottom = () => {
    const activeObject = canvas.getActiveObject();
    const activeObjects = canvas.getActiveObjects();
    if (activeObject && activeObjects.length) {
      const activeObjectTop = (activeObject.height ?? 0) / 2;
      activeObjects.forEach((object) => {
        object.set({
          top: activeObjectTop - (object.height ?? 0) * (object.scaleY ?? 0),
        });
      });
      canvas.renderAll();
      handleHistory();
    }
  };

  const equationY = () => {
    const activeObject = canvas.getActiveObject();
    // @ts-ignore
    function getItemHeight(item) {
      return item.aCoords.bl.y - item.aCoords.tl.y;
    }
    function getAllItemHeight() {
      let count = 0;
      // @ts-ignore
      activeObject.forEachObject((item) => {
        count += getItemHeight(item);
      });
      return count;
    }
    function spaceHeight() {
      const count = getAllItemHeight();
      const allSpace = (activeObject?.height ?? 0) - count;
      // @ts-ignore
      return allSpace / (activeObject._objects.length - 1);
    }

    // @ts-ignore
    function getItemTop(i) {
      if (i === 0) return 0;
      let height = 0;
      for (let index = 0; index < i; index++) {
        // @ts-ignore
        height += getItemHeight(activeObject._objects[index]);
      }
      return height;
    }

    if (activeObject) {
      // @ts-ignore
      activeObject._objects.sort((a, b) => a.top - b.top);

      const itemSpace = spaceHeight();
      const yHeight = (activeObject?.height ?? 0) / 2;

      // @ts-ignore
      activeObject.forEachObject((item, i) => {
        const preHeight = getItemTop(i);
        const top = itemSpace * i + preHeight - yHeight;
        item.set('top', top);
      });
      canvas.renderAll();
      handleHistory();
    }
  };

  if (selectMode !== 'multiple') return null;

  return (
    <SettingsWrapper>
      <Tooltip title="left">
        <ButtonComponent onClick={left}>
          <img src={groupAlignLeft} />
        </ButtonComponent>
      </Tooltip>
      <Tooltip title="centerX">
        <ButtonComponent onClick={centerX}>
          <img src={groupAlignCenterX} />
        </ButtonComponent>
      </Tooltip>
      <Tooltip title="right">
        <ButtonComponent onClick={right}>
          <img src={groupAlignRight} />
        </ButtonComponent>
      </Tooltip>
      <Tooltip title="top" onClick={top}>
        <ButtonComponent>
          <img src={groupAlignTop} />
        </ButtonComponent>
      </Tooltip>
      <Tooltip title="centerY" onClick={centerY}>
        <ButtonComponent>
          <img src={groupAlignCenterY} />
        </ButtonComponent>
      </Tooltip>
      <Tooltip title="bottom" onClick={bottom}>
        <ButtonComponent>
          <img src={groupAlignBottom} />
        </ButtonComponent>
      </Tooltip>
      <Tooltip title="averageY" onClick={equationY}>
        <ButtonComponent>
          <img src={groupAlignAverageY} />
        </ButtonComponent>
      </Tooltip>
    </SettingsWrapper>
  );
}

const plusOrMinus = Math.random() > 0.5 ? 1 : -1;
const getRandomValueWithin = (min: number) =>
  (Math.random() * min * plusOrMinus) / 3;
export const getRanCoord = (canvas: fabric.Canvas) => {
  const { left, top } = canvas.getCenter();
  const width = canvas.getWidth();
  const height = canvas.getHeight();

  return {
    left: left + getRandomValueWithin(width),
    top: top + getRandomValueWithin(height),
  };
};

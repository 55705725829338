import React, { FormEvent, useMemo } from 'react';
import styled from 'styled-components';
import FormPage from './FormPage';
import { InputType, SurveyPageObject, UserFocusData } from '../types';
import { useEditorData } from '../EditorProvider';
import { changeSurveyData } from '../utils/surveyDataUtils';

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 90%;
  overflow: scroll;
`;

const FormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 80vmin;
  margin-top: 20px;
  padding-vertical: 15px;
`;

const SurveyForm = () => {
  const {
    setSelectedItem,
    setHistory,
    history,
    selectedItem,
    logoSize,
    selectedLogo,
    setIsSettingsOpen,
  } = useEditorData();

  const { present: surveyData } = history;
  const { pages } = surveyData;
  const { pageId } = selectedItem as UserFocusData;

  const onChange = (e: FormEvent<HTMLSpanElement>) => {
    const { textContent } = e.currentTarget;
    const params = {
      surveyData,
      data: { title: textContent as string },
      setHistory,
    };
    changeSurveyData(params);
    setIsSettingsOpen(true);
  };

  const PAGES = useMemo(() => {
    const pageArray = Object.values(pages as SurveyPageObject);

    return pageArray.map((page, index) => {
      ++index;
      const { id } = page;
      page.name = `Page ${index}`;
      setIsSettingsOpen(true);
      return (
        <FormPage key={id} pageNumber={index} pageId={id} pageData={page} />
      );
    });
  }, [pages]);

  return (
    <FormContainer>
      <FormItemContainer>
        <div
          className="svc-tab-designer_content"
          onClick={() => {
            setSelectedItem({ pageId, elementId: '', type: InputType.SURVEY });
          }}
        >
          <div
            className="sd-container-modern sd-container-modern--static"
            style={{ marginBottom: 0 }}
          >
            <div className="svc-designer-header">
              <div
                className="sd-title sd-container-modern__title"
                style={{ overflow: 'initial' }}
              >
                <div
                  className="sd-header__text"
                  style={{ gap: 0, padding: '10px' }}
                >
                  <h3 className="sd-title" style={{ margin: '10px 0' }}>
                    <span className="svc-string-editor">
                      <span className="svc-string-editor__content">
                        <div className="svc-string-editor__border">
                          <svg
                            className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                            role="img"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-edit" />
                          </svg>
                        </div>
                        <span className="svc-string-editor__input">
                          <span
                            id="title"
                            role="textbox"
                            className="sv-string-editor"
                            contentEditable="true"
                            spellCheck="false"
                            aria-placeholder="Survey Title"
                            aria-label="Survey Title"
                            onBlur={onChange}
                            suppressContentEditableWarning
                          >
                            {surveyData?.title}
                          </span>
                          <div className="svc-string-editor__controls" />
                        </span>
                      </span>
                    </span>
                  </h3>
                  <h5 className="sd-description" style={{ margin: '10px 0' }}>
                    <span className="svc-string-editor">
                      <span className="svc-string-editor__content">
                        <div className="svc-string-editor__border">
                          <svg
                            className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                            role="img"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-edit" />
                          </svg>
                        </div>
                        <span className="svc-string-editor__input">
                          <span
                            role="textbox"
                            className="sv-string-editor"
                            spellCheck="false"
                            aria-placeholder="Description"
                            aria-label="Description"
                            data-gramm="false"
                          >
                            {surveyData?.description}
                          </span>
                          <div className="svc-string-editor__controls" />
                        </span>
                      </span>
                    </span>
                  </h5>
                </div>
                <div className="svc-logo-image">
                  {selectedLogo && (
                    <img
                      style={{
                        width: logoSize.width,
                        height: logoSize.height,
                      }}
                      src={selectedLogo}
                      alt="logo"
                    />
                  )}

                  <div className="svc-logo-image-placeholder" tabIndex={0}>
                    <svg>
                      <use xlinkHref="#icon-logo" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {PAGES}
      </FormItemContainer>
    </FormContainer>
  );
};

export default SurveyForm;

import { useGetSlideById } from '../../../api-http/slides-v2';

export const useGetSlidData = (paramId: string) => {
  const {
    data: slide,
    isLoading,
    error,
    refetch,
  } = useGetSlideById(paramId || '');

  return {
    isLoading,
    error,
    slide,
    projectData: slide?.projectData,
    updatedKvs: slide?.kvs,
    refetchSlideData: refetch,
  };
};

import { FC, useEffect } from 'react';
import {
  InputBase,
  Paper,
  Box,
  Stack,
  debounce,
  CircularProgress,
  ListItem,
  IconButton,
  ListItemButton,
  ListItemText,
  Chip,
} from '@mui/material';
import styled from 'styled-components';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { fabric } from 'fabric';

import { useFabricEditor, useTooling } from '../../lib/FabricJs';
import { defaultFontFamily } from '../../config';
import { getRanCoord } from '../../utils/getRanCoord';
import { KvsDataResponse } from '../../../../types/kvs.types';

const DEBOUNCE_TIME = 500;

const Text = styled.button`
  background: rgba(255, 255, 255, 0.07);
  border: none;
  color: white;
  outline-color: white;
  height: 45px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  padding-left: 15px;
  display: flex;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.09);
  }
`;

const KvsValue = styled.button`
  background: rgba(255, 255, 255, 0.07);
  border: none;
  color: white;
  outline-color: white;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  padding-left: 15px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.09);
  }
`;

const Key = styled.p`
  font-size: 10px;
  margin: 10px 0;
`;

const Value = styled.p`
  font-size: 14px;
  margin: 10px 0;
`;

const KvsWidget: FC = () => {
  const {
    kvsProps: {
      kvsGroupData,
      setKvsGroupSearchValue,
      setKvsDataSearchValue,
      activeGroup,
      setActiveGroup,
      isKvsLoading,
      kvsData,
    },
  } = useTooling(); // useTooling is a custom hook

  const { canvas } = useFabricEditor();

  const addKvsText = (kvsItem: KvsDataResponse) => {
    if (canvas) {
      const newText = new fabric.Text(kvsItem.value, {
        fontSize: 160,
        fontFamily: defaultFontFamily,
        ...getRanCoord(canvas),
        data: {
          type: 'kvs',
          kvsId: kvsItem._id,
          kvsGroupId: kvsItem.kvsGroup,
          kvsKey: kvsItem.key,
        },
      });
      canvas?.add(newText);
    }
  };

  useEffect(() => {
    return () => {
      setActiveGroup(null);
      setKvsGroupSearchValue('');
      setKvsDataSearchValue('');
    };
  }, []);

  if (!activeGroup) {
    return (
      <>
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', mb: 2 }}
        >
          <InputBase
            id="kvs-group-search"
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Keys by group name"
            inputProps={{ 'aria-label': 'Search Keys by group name' }}
            onChange={debounce((e) => {
              setKvsGroupSearchValue(e.target.value);
            }, DEBOUNCE_TIME)}
          />
          {isKvsLoading && <CircularProgress size={20} />}
        </Paper>
        <Box sx={{ width: '100%', marginTop: '15px' }}>
          <Stack spacing={2}>
            {kvsGroupData?.map((item, i) => {
              return (
                <Text
                  key={i}
                  onClick={() => {
                    setActiveGroup(item);
                    // @ts-ignore;
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    document.getElementById('kvs-group-search')!.value = '';
                  }}
                >
                  <p>{item.name}</p>
                  <Chip
                    label={item.totalKeys}
                    variant="outlined"
                    size="small"
                    sx={{ color: 'white' }}
                  />
                </Text>
              );
            })}
          </Stack>
        </Box>
      </>
    );
  }
  return (
    <>
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', mb: 2 }}
      >
        <InputBase
          id="kvs-search"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Keys by key & value"
          inputProps={{ 'aria-label': 'Search Keys by key & value"' }}
          onChange={debounce((e) => {
            setKvsDataSearchValue(e.target.value);
          }, DEBOUNCE_TIME)}
        />
        {isKvsLoading && <CircularProgress size={20} />}
      </Paper>
      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="comments"
            onClick={() => {
              setActiveGroup(null);
              setKvsGroupSearchValue('');
              setKvsDataSearchValue('');
              // @ts-ignore;
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              document.getElementById('kvs-search')!.value = '';
            }}
          >
            <ArrowBack sx={{ color: 'white' }} />
          </IconButton>
        }
        disablePadding
        sx={{
          backgroundColor: '#709bcb',
          color: 'white',
          borderRadius: '5px',
        }}
      >
        <ListItemButton role={undefined} dense>
          <ListItemText primary={activeGroup?.name} />
        </ListItemButton>
      </ListItem>
      <Box sx={{ width: '100%', marginTop: '15px' }}>
        <Stack spacing={2}>
          {kvsData?.map((item, i) => {
            return (
              <KvsValue
                key={i}
                onClick={() => {
                  addKvsText(item);
                }}
              >
                <Key>{item.key}</Key>
                <Value>{item.value}</Value>
              </KvsValue>
            );
          })}
        </Stack>
      </Box>
    </>
  );
};

export default KvsWidget;

import { ApiServiceErr } from '../../../api-http/apiService';
import { FormsSummary } from '../../../api-http/formResponses';
import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../../components/TableTheme';
import { PagingResponse } from '../../../types';
import { OrderEnum, SortEnum } from '../../../types/pagination.types';
import { UserRole } from '../../../types/user.types';
import { displayDistance } from '../../../util/dateTime';
import { useIsUserRole } from '../../../util/useIsUserRole';
import { useSnack } from '../../../util/useSnack';
import { Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useEffect } from 'react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { PagingParams, TableProps } from '../../../types/form.types';

const { name, createdAt, updatedAt } = SortEnum;
const { asc } = OrderEnum;

interface TableProp {
  refresh: boolean;
  search: string;
  isLoading: boolean;
  isError: boolean;
  responses?: PagingResponse<FormsSummary>;
  refetch: () => void;
  error: ApiServiceErr | null;
  sortOnClick: (value: string) => void;
  pagingParams: PagingParams;
  tableProps: TableProps;
}

const FormResponsesTable: FC<TableProp> = ({
  refresh,
  search,
  isLoading,
  isError,
  responses,
  refetch,
  error,
  sortOnClick,
  pagingParams,
  tableProps,
}) => {
  const isViewOnly = useIsUserRole([UserRole.user, UserRole.dataEditor]);

  const { sortby, order } = pagingParams;
  const { showErrSnack } = useSnack();

  useEffect(() => {
    if (isError && error) {
      showErrSnack(error.msg);
    }
  }, [error]);

  useEffect(() => {
    refetch();
  }, [refresh, search]);

  return (
    <>
      {isLoading && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <StyledTableSortLabel
                  active={sortby === name || true}
                  direction={sortby === name ? order : asc}
                  onClick={() => sortOnClick(name)}
                >
                  Name
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" width={'20%'}>
                <StyledTableSortLabel
                  active={sortby === updatedAt || true}
                  direction={sortby === updatedAt ? order : asc}
                  onClick={() => sortOnClick(updatedAt)}
                >
                  Updated
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left" width={'20%'}>
                <StyledTableSortLabel
                  active={sortby === createdAt || true}
                  direction={sortby === createdAt ? order : asc}
                  onClick={() => sortOnClick(createdAt)}
                >
                  Created
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center" width={'20%'}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!responses || responses?.data.length == 0) && (
              <p
                style={{
                  display: 'flex',
                  fontSize: '14px',
                  justifyContent: 'center',
                  width: '250%',
                }}
              >
                No data to display
              </p>
            )}
            {responses?.data.map((form) => {
              return (
                <TableRow
                  key={form._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {isViewOnly ? (
                      form.name
                    ) : (
                      <NavLink to={`/forms-preview/${form._id}`}>
                        {form.name}
                      </NavLink>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {displayDistance(form.updatedAt)}
                  </TableCell>
                  <TableCell align="left">
                    {displayDistance(form.createdAt)}
                  </TableCell>

                  <TableCell align="center">
                    <NavLink to={`/forms-preview/${form._id}`}>
                      <Button>Preview</Button>
                    </NavLink>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={responses?.pagination.total === 0}
          count={responses?.pagination.total || 0}
        />
      </TableContainer>
    </>
  );
};

export default FormResponsesTable;

import React, { FC, useState } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import styled from 'styled-components';

import { FormsResponse } from '../api-http/forms';
import getAlignment from './survey-editor/utils/getAlignment';
import { useCreateResponse } from '../api-http/formResponses';
import { prepareJSON } from './survey-editor/utils/surveyDataUtils';

const SurveyContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: scroll;
`;

interface SurveyPreviewProps {
  data: FormsResponse;
  formId?: string;
}

const SurveyPreview: FC<SurveyPreviewProps> = ({ data, formId }) => {
  const { mutate } = useCreateResponse();

  const [isCompleted, setIsCompleted] = useState(false);
  const survey = new Model(prepareJSON(data?.formBody));

  survey.css = {
    question: {
      title: `${getAlignment(data?.formBody)} sv_question_title`,
      description: `${getAlignment(data?.formBody)} sv_question_description`,
      content: getAlignment(data?.formBody),
      contentleft: getAlignment(data?.formBody),
      mainRoot: 'sd-element sd-question sv-row__question',
    },
    rating: {
      itemSmiley: 'sv_rating_smiley_item_preview',
    },
  };

  const alertResults = (sender: { data: object }) => {
    mutate({ formId, response: JSON.stringify(sender.data) });
    setIsCompleted(true);
    setTimeout(() => {
      setIsCompleted(false);
    }, 5000);
  };

  survey.onComplete.add(alertResults);

  survey.showCompletedPage = false;

  return (
    <>
      {isCompleted ? (
        <div
          style={{
            padding: 70,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h2 style={{ color: '#19B394', fontSize: 50 }}> Thank You </h2>
          <p
            style={{
              color: '#666',
              textAlign: 'center',
              fontSize: 16,
              marginBottom: 55,
            }}
          >
            Your submission has been received!
          </p>
          <span
            style={{
              display: 'inline-block',
              width: 100,
              height: 100,
              borderRadius: '50%',
              textAlign: 'center',
              fontSize: 80,
              backgroundColor: '#19B394',
              color: 'white',
            }}
          >
            &#10003;
          </span>
        </div>
      ) : (
        <SurveyContainer>
          <Survey model={survey} />
        </SurveyContainer>
      )}
    </>
  );
};

export default SurveyPreview;

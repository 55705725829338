import { useEffect, useState } from 'react';

import { useGetPaginateAssets } from '../../../api-http/assets';
import { useSnack } from '../../../util/useSnack';
import { OrderEnum } from '../../../types/pagination.types';
import { getStaticFileUrl } from '../../../util/getStaticFileUrl';

import { AssetFileType } from '../../../types/assets.types';

const PAGE_LIMIT = 25;

export const useVideoAssets = () => {
  const { showErrSnack } = useSnack();
  const [page, setPage] = useState(1);
  const {
    isLoading: isImagesLoading,
    error,
    data: videoData,
    refetch,
  } = useGetPaginateAssets({
    limit: PAGE_LIMIT,
    page: page,
    sortby: 'createdAt',
    order: OrderEnum.desc,
    fileType: AssetFileType.Video,
  });

  const [videos, setVideos] = useState(videoData?.data || []);

  const loadMoreVideos = () => {
    setPage(page + 1);
  };

  const resetVideos = () => {
    setPage(1);
    setVideos([]);
    refetch();
  };

  useEffect(() => {
    if (error) {
      showErrSnack(error);
    }
  }, [error]);

  useEffect(() => {
    const newVideos =
      videoData?.data.map((item) => ({
        ...item,
        path: getStaticFileUrl(item.path),
      })) || [];
    setVideos((prevVideos) => [...prevVideos, ...newVideos]);
  }, [videoData]);

  return {
    videos,
    isLoading: isImagesLoading,
    loadMoreVideos,
    resetVideos,
    hasMoreVideos: videoData && videoData.pagination.total > videos.length,
  };
};

import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface StyleProps {
  color?: string;
}

const StyledSpan = styled.span<StyleProps>`
  font-weight: 700;
  color: ${({ color = 'white' }) => color};
  font-size: 14px;
  line-height: 22px;
  padding: 12px;
  padding-left: 8px;
  display: block;
`;

const Span: FC<PropsWithChildren & StyleProps> = (props) => (
  <StyledSpan {...props} />
);

export default Span;

import { FormHelperText, TextField } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  value: number;
  onChange: (value: number) => void;
  length: number;
  error?: string;
}

const MainWrapper = styled.div`
  padding: 0 30px;
  margin-bottom: 40px;
`;

const PinCodeWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;

  input {
    text-align: center;
    font-size: 30px;
  }
`;

function useDigitInputs(length: number, digits: string[] = []) {
  const values: any = [];
  const setters: any = [];
  const refs: any = [];

  new Array(length).fill(1).forEach(() => {
    const [value, setter, ref] = useDigitInput(digits[values.length]);
    values.push(value);
    setters.push(setter);
    refs.push(ref);
  });

  return [values, setters, refs];
}

function useDigitInput(initialValue = '') {
  const [value, setter] = useState(initialValue);
  const ref = useRef(null);

  return [value, setter, ref];
}

const PinComponent: FC<Props> = ({
  value: sourceWholeValue,
  onChange,
  length,
  error,
}) => {
  const partialDigits = sourceWholeValue.toString().split('');
  const digits = partialDigits.length === length ? partialDigits : [];
  const [values, setters, refs] = useDigitInputs(length, digits);

  return (
    <MainWrapper>
      <PinCodeWrapper>
        {values.map((_value: any, index: number) => (
          <>
            <TextField
              type="text"
              autoFocus={index === 0}
              inputRef={refs[index]}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              value={values[index]}
              error={Boolean(error)}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  // When backspace is pressed, clear value and focus previous input.

                  // onChange(parseInt(values[index].join(''), 10));
                  if (index >= 0) {
                    if (values[index] !== '') {
                      setters[index]('');
                      refs[index].current.focus();
                    }
                    if (values[index] === '' && index != 0) {
                      refs[index - 1].current.focus();
                    }
                  }
                  e.preventDefault();
                }
              }}
              onFocus={() => {
                // Keep track of the selected index (to display errors and validations).
                setters[index](values[index]);
              }}
              onChange={(e) => {
                let eventValue;

                if (values[index] !== '' && index < values.length - 1) {
                  const [, value2] = e.target.value;
                  eventValue = parseInt(value2 || '', 10);
                  refs[index + 1].current.focus();
                } else {
                  eventValue = parseInt(e.target.value || '', 10);
                }

                if (eventValue > 9) {
                  return;
                }
                // Interceptors, could be hooked via prop.
                const value = Number.isNaN(eventValue) ? '' : eventValue;

                // Set the current value and focus the next input.
                if (values[index] !== '' && index < values.length - 1) {
                  setters[index + 1](value);
                } else {
                  setters[index](value);
                  if (value !== '' && index < values.length - 1) {
                    refs[index + 1].current.focus();
                  }
                }

                // Calculate the next value and check if callback should be fired
                const nextValues = [...values];
                nextValues[index] = value;
                if (typeof value === 'number') {
                  if (index === nextValues.length - 1 && !isNaN(value)) {
                    onChange(parseInt(nextValues.join(''), 10));
                  }
                }
              }}
            />
          </>
        ))}
      </PinCodeWrapper>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </MainWrapper>
  );
};

export default PinComponent;

export enum SelectMode {
  ONE = 'one',
  MULTI = 'multiple',
  GROUP = 'group',
}

export enum SelectEvent {
  ONE = 'selectOne',
  MULTI = 'selectMultiple',
  CANCEL = 'selectCancel',
}

import { useEffect, useState } from 'react';

import { useGetPaginateAssets } from '../../../api-http/assets';
import { useSnack } from '../../../util/useSnack';
import { OrderEnum } from '../../../types/pagination.types';
import { getStaticFileUrl } from '../../../util/getStaticFileUrl';

import { AssetFileType } from '../../../types/assets.types';

const PAGE_LIMIT = 25;

export const useImageAssets = () => {
  const { showErrSnack } = useSnack();
  const [page, setPage] = useState(1);
  const {
    isLoading: isImagesLoading,
    error,
    data: imageData,
    refetch,
  } = useGetPaginateAssets({
    limit: PAGE_LIMIT,
    page: page,
    sortby: 'createdAt',
    order: OrderEnum.desc,
    fileType: AssetFileType.Image,
  });

  const [images, setImages] = useState(imageData?.data || []);

  const loadMoreImages = () => {
    setPage(page + 1);
  };

  const resetImages = () => {
    setPage(1);
    setImages([]);
    refetch();
  };

  useEffect(() => {
    if (error) {
      showErrSnack(error);
    }
  }, [error]);

  useEffect(() => {
    const newImages =
      imageData?.data.map((item) => ({
        ...item,
        path: getStaticFileUrl(item.path),
      })) || [];
    setImages((prevImages) => [...prevImages, ...newImages]);
  }, [imageData]);

  return {
    images,
    isLoading: isImagesLoading,
    loadMoreImages,
    resetImages,
    hasMoreImages: imageData && imageData.pagination.total > images.length,
  };
};

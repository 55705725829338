import React, { FC } from 'react';
import {
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
} from '@mui/material';
import {
  ContentCut,
  ContentCopy,
  ContentPaste,
  DeleteOutline,
  FlipToFront,
  FlipToBack,
  CopyAll,
  LockOutlined,
  LockOpenOutlined,
} from '@mui/icons-material';
import styled, { css } from 'styled-components';

import { useFabricEditor } from '../../lib/FabricJs';
import { useHandleCopyPaste } from '../../lib/FabricJs/hooks/useKeyboardEvents';
import { getControlButtonText } from '../../utils/utils';
import { useHandleObjectLock } from '../../lib/FabricJs/hooks/useHandleObjectLock';
import { useHandleHistory } from '../../lib/FabricJs/hooks/useHandleHistory';

interface StyleProps {
  top?: number;
  left?: number;
}

interface MenuContextProps {
  points: {
    x: number;
    y: number;
  };
  handleClose: () => void;
}

export const MenuContextContainer = styled.div`
  border: 1px solid black;
  border-radius: 4px;
  padding: 18px;
  margin: 5px 0;
  box-sizing: border-box;
`;

export const ContextMenu = styled.div<StyleProps>`
  position: absolute;
  width: 200px;
  border-radius: 5px;
  box-sizing: border-box;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
`;

const MenuContext: FC<MenuContextProps> = ({ points, handleClose }) => {
  const { canvas } = useFabricEditor();
  const {
    handleCopy,
    handlePaste,
    handleCut,
    handleDelete,
    copiedObjects,
    handleDuplicate,
  } = useHandleCopyPaste(canvas);
  const { handleObjectLock, isLocked } = useHandleObjectLock();
  const { handleHistory } = useHandleHistory();

  const activeObjects = canvas?.getActiveObject();

  const controlButtonText = getControlButtonText();

  if (!canvas) return null;

  const handleCanvasHistory = () => {
    handleHistory();
    handleClose();
  };

  return (
    <ContextMenu top={points.y} left={points.x}>
      <Paper sx={{ width: 320, maxWidth: '100%' }}>
        <MenuList>
          <MenuItem
            onClick={() => {
              handleCut();
              handleClose();
            }}
            disabled={!activeObjects}
          >
            <ListItemIcon>
              <ContentCut fontSize="small" />
            </ListItemIcon>
            <ListItemText>Cut</ListItemText>
            <Typography variant="body2" color="text.secondary">
              {controlButtonText} + X
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCopy();
              handleClose();
            }}
            disabled={!activeObjects}
          >
            <ListItemIcon>
              <ContentCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText>Copy</ListItemText>
            <Typography variant="body2" color="text.secondary">
              {controlButtonText} + C
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePaste();
              handleCanvasHistory();
            }}
            disabled={!copiedObjects.length}
          >
            <ListItemIcon>
              <ContentPaste fontSize="small" />
            </ListItemIcon>
            <ListItemText>Paste</ListItemText>
            <Typography variant="body2" color="text.secondary">
              {controlButtonText} + V
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDuplicate();
              handleCanvasHistory();
            }}
            disabled={!activeObjects}
          >
            <ListItemIcon>
              <CopyAll fontSize="small" />
            </ListItemIcon>
            <ListItemText>Duplicate</ListItemText>
            <Typography variant="body2" color="text.secondary">
              {controlButtonText} + D
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDelete();
              handleCanvasHistory();
            }}
            disabled={!activeObjects}
          >
            <ListItemIcon>
              <DeleteOutline fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleObjectLock();
              handleClose();
            }}
            disabled={!activeObjects}
          >
            <ListItemIcon>
              {isLocked ? (
                <LockOpenOutlined fontSize="small" />
              ) : (
                <LockOutlined fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>{isLocked ? 'Unlock' : 'Lock'}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              if (activeObjects) {
                canvas?.bringToFront(activeObjects);
                handleCanvasHistory();
              }
            }}
            disabled={!activeObjects}
          >
            <ListItemIcon>
              <FlipToFront fontSize="small" />
            </ListItemIcon>
            <ListItemText>Move Front</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (activeObjects) {
                canvas?.bringForward(activeObjects);
                handleCanvasHistory();
              }
            }}
            disabled={!activeObjects}
          >
            <ListItemIcon>
              <FlipToFront fontSize="small" />
            </ListItemIcon>
            <ListItemText>Move Forward</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (activeObjects) {
                canvas?.sendToBack(activeObjects);
                handleCanvasHistory();
              }
            }}
            disabled={!activeObjects}
          >
            <ListItemIcon>
              <FlipToBack fontSize="small" />
            </ListItemIcon>
            <ListItemText>Move Back</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (activeObjects) {
                canvas?.sendBackwards(activeObjects);
                handleCanvasHistory();
              }
            }}
            disabled={!activeObjects}
          >
            <ListItemIcon>
              <FlipToBack fontSize="small" />
            </ListItemIcon>
            <ListItemText>Move Backward</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    </ContextMenu>
  );
};

export default MenuContext;

import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import {
  PaginateCategoryOptions,
  PaginateSearchOptions,
} from '../types/pagination.types';
import {
  DraftResponse,
  DraftWithPresentationResponse,
  SlideOption,
} from '../types/drafts.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';

export interface CreateDraftBody
  extends Pick<DraftResponse, 'name' | 'status' | 'slides'> {
  category: string;
  organizationId?: string;
}

type UpdateDraftResponse = Pick<DraftResponse, '_id'>;

export interface DraftUpdateBody
  extends Partial<Pick<DraftResponse, 'name' | 'status' | 'category'>> {
  id: string;
  slides: SlideOption[];
}

export interface DraftId {
  id: string;
}

export const useCreateDraft = (opt?: MutOptions<DraftResponse>) =>
  useMutation<DraftResponse, ApiServiceErr, CreateDraftBody>(async (data) => {
    const response = await axiosApi.post('/drafts', data);
    return response.data;
  }, opt);

export const useGetDraft = () =>
  useMutation<DraftWithPresentationResponse, ApiServiceErr, DraftId>(
    async (data) => {
      const response = await axiosApi.get(`/drafts/${data.id}`);
      return response.data;
    },
  );

export const useDeleteDraft = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/drafts/${data.id}`);
    return response.data;
  }, opt);

export const useUpdateDraft = (opt?: MutOptions<UpdateDraftResponse>) =>
  useMutation<UpdateDraftResponse, ApiServiceErr, DraftUpdateBody>(
    async (data) => {
      const response = await axiosApi.put(`/drafts/${data.id}`, data);
      return response.data;
    },
    opt,
  );

export const usePublishDraft = (opt?: MutOptions<UpdateDraftResponse>) =>
  useMutation<UpdateDraftResponse, ApiServiceErr, DraftId>(async (data) => {
    const response = await axiosApi.post(`/drafts/publish/${data.id}`);
    return response.data;
  }, opt);

export const useDiscardDraft = (opt?: MutOptions<UpdateDraftResponse>) =>
  useMutation<UpdateDraftResponse, ApiServiceErr>(async (draftId) => {
    const response = await axiosApi.put(`/drafts/revert/${draftId}`);
    return response.data;
  }, opt);

export const useGetPaginateDraft = ({
  limit,
  page,
  sortby,
  order,
  category,
  search,
}: PaginateCategoryOptions & PaginateSearchOptions) =>
  useQuery<PagingResponse<DraftResponse>, ApiServiceErr>(
    [
      '/drafts?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&category=${category}&search=${search}',
      limit,
      page,
      sortby,
      order,
      category,
      search,
    ],
    async () => {
      const response = await axiosApi.get('/drafts', {
        params: { limit, page, sortby, order, category, search },
      });
      return response.data;
    },
  );

import { FC, MouseEvent, useEffect, useState } from 'react';
import {
  Button,
  LinearProgress,
  TablePagination,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel, {
  tableSortLabelClasses,
} from '@mui/material/TableSortLabel';
import { styled } from '@mui/material/styles';
import { useConfirm } from 'material-ui-confirm';
import { ChevronDown, Edit, AlertTriangle } from 'react-feather';
import { NavLink, useLocation } from 'react-router-dom';

import { DialogModal } from '../../components';
import { AlertBar } from '../../components/AlertBar';
import { confirmationMsgs, notifyMsg } from '../../configs';
import { useAuth } from '../../context/auth-context';
import { displayDistance } from '../../util/dateTime';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useSnack } from '../../util/useSnack';
import { useTable } from '../../util/useTable';
import { PresentationActionMenu } from './PresentationActionMenu';
import { RenamePresentationForm } from './RenamePresentationForm';
// types
import { DraftResponse } from '../../types/drafts.types';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import {
  useGetPaginateDraft,
  useCreateDraft,
  useDeleteDraft,
} from '../../api-http/drafts';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#243044',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  [`& .${tableSortLabelClasses.icon}`]: {
    color: `${theme.palette.common.white} !important`,
  },
  [`&.${tableSortLabelClasses.root}`]: {
    color: theme.palette.common.white,
  },
}));

const Row = styled('div')`
  display: flex;
  justify-content: space-between;
`;

interface PresentationTableProp {
  selectedCategory: string;
  searchedPresentation: string;
  onRefresh: boolean;
}

export const PresentationTable: FC<PresentationTableProp> = ({
  selectedCategory,
  searchedPresentation,
  onRefresh,
}) => {
  const confirm = useConfirm();
  const { showErrSnack, showSuccessSnack } = useSnack();

  const { switchedOrgId } = useAuth();
  const { sortOnClick, pagingParams, tableProps, setPage } = useTable(true);
  const { sortby, order } = pagingParams;
  const location = useLocation();

  const disableEditOrDelete = useIsUserRole([
    UserRole.user,
    UserRole.dataEditor,
  ]);
  const isSuperAdmin = useIsUserRole([UserRole.superAdmin]);

  const [renamePresentationModal, setRenamePresentationModal] = useState(false);
  const [activePresentation, setActivePresentation] = useState<
    DraftResponse | undefined
  >();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    data: list,
    isLoading,
    error,
    refetch,
  } = useGetPaginateDraft({
    ...pagingParams,
    category: selectedCategory,
    search: searchedPresentation,
  });

  const { mutate: createPresentation, error: errorPresentation } =
    useCreateDraft({
      onSuccess: () => {
        refetch();
        showSuccessSnack(notifyMsg.PRESENTATION_CLONE_SUCCESS);
      },
      onError: (err) => showErrSnack(err),
    });

  const {
    isLoading: isDeleteLoading,
    error: errorDelete,
    mutate: deletePresentation,
  } = useDeleteDraft({
    onSuccess: () => {
      refetch();
      showSuccessSnack(notifyMsg.PRESENTATION_DELETE_SUCCESS);
    },
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  useEffect(() => {
    if (selectedCategory) {
      setPage(0);
    }
  }, [selectedCategory]);

  useEffect(() => {
    refetch();
  }, [switchedOrgId, onRefresh]);

  useEffect(() => {
    if (searchedPresentation) {
      setPage(0);
    }
  }, [searchedPresentation]);

  const handleMenu = (
    event: MouseEvent<HTMLButtonElement>,
    presentation: DraftResponse,
  ) => {
    setActivePresentation(presentation);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const clone = () => {
    createPresentation({
      ...(isSuperAdmin && switchedOrgId && { organizationId: switchedOrgId }),
      status: activePresentation?.status || true,
      slides: activePresentation?.slides || [],
      name: `${activePresentation?.name}_clone`,
      category: activePresentation?.category?._id || '',
    });
    handleMenuClose();
  };

  const remove = async () => {
    try {
      if (activePresentation?._id) {
        await confirm({
          description: confirmationMsgs.PRESENTATION_DELETE,
          confirmationText: 'Yes',
        });
        deletePresentation({ id: activePresentation?._id });
        handleMenuClose();
      }
    } catch (error) {}
  };

  const isLinerLoading = isLoading || isDeleteLoading;

  return (
    <>
      {isLinerLoading && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg} />
      <AlertBar severity="error" msg={errorDelete?.msg} />
      <AlertBar severity="error" msg={errorPresentation?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <StyledTableSortLabel
                  active={sortby === SortEnum.title || true}
                  direction={sortby === SortEnum.title ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.title)}
                >
                  Presentation Title
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center">Category</StyledTableCell>
              <StyledTableCell align="center">Updated By</StyledTableCell>
              <StyledTableCell align="center">Updated</StyledTableCell>
              <StyledTableCell align="center">Created By</StyledTableCell>
              <StyledTableCell align="center">
                <StyledTableSortLabel
                  active={sortby === SortEnum.createdAt || true}
                  direction={
                    sortby === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Created
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!list?.data?.length && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {isLinerLoading ? <CircularProgress /> : 'No Records Found '}
                </TableCell>
              </TableRow>
            )}
            {list?.data?.map((presentation) => (
              <TableRow
                key={presentation._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Row>
                    <NavLink
                      style={{
                        textDecoration: 'underline',
                        textUnderlineOffset: '5px',
                      }}
                      to={`/presentations-editor/${presentation._id}`}
                      state={{
                        search: location?.search,
                      }}
                    >
                      <Edit size={12} /> {presentation.name}
                    </NavLink>
                    {presentation.haveUnpublishedChanges && (
                      <Tooltip title="Unpublished changes available">
                        <AlertTriangle size={20} color={'orange'} />
                      </Tooltip>
                    )}
                  </Row>
                </TableCell>
                <TableCell align="center">
                  {presentation.category?.name}
                </TableCell>
                <TableCell align="center">{presentation?.updatedBy}</TableCell>
                <TableCell align="center">
                  {displayDistance(presentation.updatedAt)}
                </TableCell>
                <TableCell align="center">{presentation?.createdBy}</TableCell>
                <TableCell align="center">
                  {displayDistance(presentation.createdAt)}
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={(event) => handleMenu(event, presentation)}
                    disabled={disableEditOrDelete}
                  >
                    <ChevronDown size={16} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>

      <PresentationActionMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClickRename={() => {
          setRenamePresentationModal(true);
          handleMenuClose();
        }}
        onClickClone={clone}
        onClickRemove={() => {
          remove();
          handleMenuClose();
        }}
      />

      <DialogModal
        title={'Edit Presentation'}
        onClose={() => setRenamePresentationModal(false)}
        open={renamePresentationModal}
      >
        <RenamePresentationForm
          onFinished={() => {
            setRenamePresentationModal(false);
            refetch();
          }}
          presentation={list && activePresentation}
        />
      </DialogModal>
    </>
  );
};

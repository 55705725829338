import { useQuery } from '@tanstack/react-query';
import { PaginateOptions } from '../types/pagination.types';
import { PagingResponse } from '../types';
import { ApiServiceErr, axiosApi } from './apiService';
import { BaseUser } from '../types/user.types';

export interface ActivityLogResponse {
  _id: string;
  requestedUser: BaseUser;
  action: string;
  collectionName: string;
  documentId: string;
  documentData: string;
  organization: string;
  createdAt: string;
  updatedAt: string;
}

export const useGetActivityLogs = ({
  limit,
  page,
  sortby,
  order,
  authoredUser,
  logType,
  startDate,
  endDate,
}: PaginateOptions & {
  authoredUser?: string;
  logType?: string;
  startDate?: string;
  endDate?: string;
}) =>
  useQuery<PagingResponse<ActivityLogResponse>, ApiServiceErr>(
    [
      '/activity-log/all?limit=${limit}&page=${page}&sortby=${sortby}&asc=${asc}',
      limit,
      page,
      sortby,
      order,
      authoredUser,
      startDate,
      endDate,
      logType,
    ],
    async () => {
      const response = await axiosApi.get(
        `/activity-log/all?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&authoredUser=${authoredUser}&logType=${logType}&startDate=${startDate}&endDate=${endDate}`,
      );
      return response.data;
    },
  );

import { Socket } from 'socket.io-client';
import { useLockSlideById } from '../api-http/slides-v2';
import { useSnack } from './useSnack';

export const updateSlideStatus = (socket: Socket, paramId: string) => {
  const { showErrSnack } = useSnack();

  if (paramId) {
    const { mutate } = useLockSlideById(paramId);

    mutate(
      { isEditing: false },
      {
        onSuccess: () => {
          socket.disconnect();
        },
        onError: (err) => {
          showErrSnack(err.msg);
        },
      },
    );
  }
};

import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const StyleTextfield = styled(TextField)`
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;

  & > :not(style) {
    width: 30ch;
    height: 50px;
    padding-left: 10px;
    padding-bottom: 2px;
    font-size: 14px;
  }

  input {
    padding: 0;
  }
` as typeof TextField;

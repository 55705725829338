import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Button, Stack, TextField } from '@mui/material';
import { AlertBar } from '../../components/AlertBar';
import styled from 'styled-components';
import * as yup from 'yup';
import { Nullable } from '../../types';
import { ApiServiceErr } from '../../api-http/apiService';
import { formMsg } from '../../configs';

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

interface SubmitData {
  name: string;
}

interface FormModalFormProps {
  onSubmit: (data: SubmitData) => void;
  isLoading: boolean;
  error?: Nullable<ApiServiceErr>;
}

const validationSchema = yup.object({
  name: yup.string().required(formMsg.FORM_NAME_REQUIRED),
});

export const FormModalForm: FC<FormModalFormProps> = ({
  isLoading,
  error,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            label="Form Name"
            id="name"
            title="Form Name"
            placeholder="Form Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
          >
            Create
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

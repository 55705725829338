import styled from 'styled-components';

const SettingsWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 12px;
  height: 40px;
  padding: 0 10px;
  background: #f6f7f9;
  border: none;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
`;

export default SettingsWrapper;

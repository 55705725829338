import React, { FC } from 'react';
import styled from 'styled-components';
import { ActivityLogResponse } from '../../../api-http/activityLogs';
import { Typography } from '@mui/material';
import { displayDistance } from '../../../util/dateTime';
import ReactJson from 'react-json-view';

interface ViewLogProps {
  log: ActivityLogResponse | null;
}

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

const FieldContainer = styled.div`
  display: flex;
  padding-bottom: 18px;
  margin-top: 10px;
  flex-direction: column;
`;

export const ViewLog: FC<ViewLogProps> = ({ log }) => {
  if (!log) {
    return <Typography>No Data To Display</Typography>;
  }
  return (
    <Container>
      <FieldContainer>
        <Typography fontSize={15}> Log ID</Typography>
        <Typography color={'gray'} fontSize={12}>
          {log._id}
        </Typography>
      </FieldContainer>

      <FieldContainer>
        <Typography fontSize={15}>Authored By</Typography>
        <Typography color={'gray'} fontSize={12}>
          {log?.requestedUser?.name}
        </Typography>
      </FieldContainer>

      <FieldContainer>
        <Typography fontSize={15}>Asset Type</Typography>
        <Typography color={'gray'} fontSize={12}>
          {log.collectionName}
        </Typography>
      </FieldContainer>

      <FieldContainer>
        <Typography fontSize={15}>Asset ID</Typography>
        <Typography color={'gray'} fontSize={12}>
          {log.documentId}
        </Typography>
      </FieldContainer>

      <FieldContainer>
        <Typography fontSize={15}>Action</Typography>
        <Typography color={'gray'} fontSize={12}>
          {log.action}
        </Typography>
      </FieldContainer>

      <FieldContainer>
        <Typography fontSize={15}>Date of Action</Typography>
        <Typography color={'gray'} fontSize={12}>
          {new Date(log.createdAt).toLocaleDateString()} (
          {displayDistance(log.createdAt)})
        </Typography>
      </FieldContainer>

      <FieldContainer>
        <Typography fontSize={15}>Previous Data Log</Typography>
        <Typography color={'gray'} fontSize={12}>
          <ReactJson
            src={JSON.parse(log.documentData)}
            displayDataTypes={false}
            displayObjectSize={false}
            name={false}
            indentWidth={0}
            collapsed={true}
            quotesOnKeys={false}
            style={{ color: 'black' }}
          />
        </Typography>
      </FieldContainer>
    </Container>
  );
};

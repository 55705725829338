import { createContext, PropsWithChildren, useContext } from 'react';
import { AssetsResponse } from '../../../../../types/assets.types';
import { KvsDataResponse, KvsResponse } from '../../../../../types/kvs.types';

interface EditorToolingProps extends PropsWithChildren {
  isLoadingWidget: boolean;
  imageProps: {
    onClickImageUploadButton?: () => void;
    loadMoreImages?: () => void;
    hasMoreImages?: boolean;
    images: AssetsResponse[];
  };
  videoProps: {
    onClickVideoUploadButton?: () => void;
    loadMoreVideos?: () => void;
    hasMoreVideos?: boolean;
    videos: AssetsResponse[];
  };
  slidesProps: {
    slide?: any;
    projectData?: any;
    updatedKvs?: { [key: string]: string };
    updateSlide?: any;
    paramId?: string;
  };
  kvsProps: {
    setKvsGroupSearchValue: (searchKey: string) => void;
    setKvsDataSearchValue: (searchKey: string) => void;
    setActiveGroup: (searchKey: KvsResponse | null) => void;
    kvsGroupData?: KvsResponse[];
    activeGroup?: KvsResponse | null;
    isKvsLoading?: boolean;
    kvsData?: KvsDataResponse[];
  };
  onEdited: (json?: object) => void;
  openPreview: boolean;
}

type State = EditorToolingProps;

const Context = createContext<State | null>(null);

function EditorTooling({ children, ...props }: EditorToolingProps) {
  return <Context.Provider value={{ ...props }}>{children}</Context.Provider>;
}

function useTooling() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useTooling must be used within a EditorTooling');
  }
  return context;
}

export { EditorTooling, useTooling };

import { PresentationResponse } from './presentation.types';

export interface EventResponse {
  _id: string;
  organization: string;
  presentation: string;
  startTime: string;
  endTime: string;
  // eslint-disable-next-line
  presentationSchedule: { [key: string]: any };
  scheduler: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
}
export interface EventEditResponse {
  _id: string;
  organization: string;
  presentation: PresentationResponse;
  startTime: string;
  endTime: string;
  // eslint-disable-next-line
  presentationSchedule: { [key: string]: any };
  scheduler: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
}

export enum FrequencyEnum {
  YEARLY = 'Yearly',
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
  DAILY = 'Daily',
  CUSTOM = 'Custom',
}

import { PagingResponse } from '../types';
import {
  AssetFileType,
  AssetsRenameBody,
  AssetsResponse,
  GetPaginateAssetsOptions,
} from '../types/assets.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetAssets = () =>
  useQuery<AssetsResponse[], ApiServiceErr>(['/assets'], async () => {
    const response = await axiosApi.get('/assets/all');
    return response.data;
  });

export const useDeleteAssets = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/assets/delete/${data.id}`);
    return response.data;
  }, opt);

export const useRenameAsset = (opt?: MutOptions<AssetsResponse>) =>
  useMutation<AssetsResponse, ApiServiceErr, AssetsRenameBody>(async (data) => {
    const response = await axiosApi.put('/assets/rename', data);
    return response.data;
  }, opt);

export const useUploadAsset = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, any>(async (formData) => {
    const response = await axiosApi.post(`/assets/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }, opt);

export const useGetPaginateAssets = ({
  limit,
  page,
  sortby,
  order,
  fileType,
  search = '',
}: GetPaginateAssetsOptions) =>
  useQuery<PagingResponse<AssetsResponse>, ApiServiceErr>(
    [
      `/assets/paginate/assets?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&fileType=${fileType}&search=${search}`,
    ],
    async () => {
      const response = await axiosApi.get(
        fileType === AssetFileType.All
          ? `/assets/paginate/assets?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}`
          : `/assets/paginate/assets?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&fileType=${fileType}&search=${search}`,
      );
      return response.data;
    },
  );

export const useGetAssetsWithSearch = (search?: string) =>
  useQuery<AssetsResponse[], ApiServiceErr>(['/assets/search'], async () => {
    const response = await axiosApi.get(`/assets/search?search=${search}`);
    return response.data;
  });

import { format, formatDistance, subDays } from 'date-fns';
import { TestFunction } from 'yup';
import { AnyObject } from 'yup/lib/types';

export const displayDate = (date?: string) =>
  date ? format(new Date(date), 'yyyy-MM-dd') : '-';
export const displayDateTime = (date?: string) =>
  date ? format(new Date(date), 'yyyy-MM-dd HH:mm') : '-';
export const displayDistance = (date?: string) =>
  date
    ? formatDistance(subDays(new Date(date), 0), new Date(), {
        addSuffix: true,
      })
    : '-';

export const eventEndTimeValidation = (): TestFunction<
  string | undefined,
  AnyObject
> => {
  return function (value): boolean {
    const { startTime, endDate, startDate } = this.parent as {
      [key: string]: string;
    };
    if (endDate > startDate) {
      return true;
    } else if (startTime && value) {
      const startTimeValue = parseInt(startTime.replace(':', ''), 10);
      const endTimeValue = parseInt(value.replace(':', ''), 10);
      return endTimeValue > startTimeValue;
    }
    return true;
  };
};

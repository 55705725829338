import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';

import { useGetDisplayPresentation } from '../../api-http/displays';
import { AlertBar } from '../../components/AlertBar';
import { CircularProgress, Box } from '@mui/material';
import { metaTitle } from '../../util/tabTitle';
import PresentationPreview from '../../components/preview/PresentationPreview';
import { WS_URL } from '../../configs';

const socket = io(WS_URL);

const PresentationPreviewPage: FC = () => {
  metaTitle('Preview');

  const params = useParams<{ id: string }>();
  const { data, error, isLoading, refetch } = useGetDisplayPresentation(
    params?.id || '',
  );
  const navigate = useNavigate();

  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    if (!isConnected) {
      socket.connect();
    }
  }, [isConnected]);

  useEffect(() => {
    socket.once('connect', () => {
      setIsConnected(true);
    });

    const onDeletePresentation = (deletedId: string) => {
      if (deletedId === data?._id) {
        navigate('/display-list');
      }
    };

    const onUpdateSlide = (updatedPresentationsList: string[]) => {
      if (data?._id) {
        const { _id } = data;
        if (
          updatedPresentationsList &&
          updatedPresentationsList.includes(_id)
        ) {
          refetch();
        }
      }
    };

    socket.once('updatePresentation', (updatedId) => {
      if (updatedId === data?._id) {
        refetch();
      }
    });

    socket.once('deletePresentation', (deletedId) => {
      if (deletedId === data?._id) {
        navigate('/display-list');
      }
    });

    socket.once('updateslide', onUpdateSlide);

    socket.once('deleteSlide', onDeletePresentation);

    socket.once('disconnect', () => {
      setIsConnected(false);
    });

    return () => {
      socket.removeAllListeners();
    };
  }, [data, refetch]);

  if (error) {
    return <AlertBar severity="error" msg={error?.msg} />;
  }
  if (isLoading) {
    return (
      <Box
        sx={{ display: 'flex', height: '100vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={100} />
      </Box>
    );
  }
  if (!data) {
    return null;
  }

  return (
    <div>
      <PresentationPreview presentation={data} />
      <div id="assets-container" />
    </div>
  );
};

export default PresentationPreviewPage;

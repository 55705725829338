import { FC, useState } from 'react';
import styled from 'styled-components';
import { useFabricEditor, useTooling } from '../../lib/FabricJs';
import Button from './components/Button';
import Span from './components/Span';
import { Tooltip } from '@mui/material';
import { AssetsResponse } from '../../../../types/assets.types';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 8px;
  cursor: pointer;
  background: gray;
`;

const VideoPreview = ({
  originalName,
  path,
  fileType,
  _id: assetId,
  thumbnailUrl,
}: Pick<
  AssetsResponse,
  'originalName' | 'path' | 'fileType' | '_id' | 'thumbnailUrl'
>) => {
  const { editor } = useFabricEditor();
  const [size, setSize] = useState({ width: 0, height: 0 });
  return (
    <Tooltip title={originalName}>
      <VideoContainer
        role="button"
        aria-label="Add this photo to the canvas"
        tabIndex={0}
        onClick={() => {
          editor?.addVideo({
            fileType,
            url: path,
            width: size.width,
            height: size.height,
            assetId,
            thumbnailUrl,
          });
        }}
      >
        <video
          width="100%"
          height="100%"
          src={path}
          poster={thumbnailUrl}
          preload="metadata"
          muted
          onLoadedMetadata={(e) => {
            const video = e.target as HTMLVideoElement;
            setSize({
              width: video.videoWidth,
              height: video.videoHeight,
            });
          }}
        ></video>
      </VideoContainer>
    </Tooltip>
  );
};

const VideoWidget: FC = () => {
  const {
    isLoadingWidget,
    videoProps: {
      loadMoreVideos,
      videos,
      onClickVideoUploadButton,
      hasMoreVideos,
    },
  } = useTooling();

  return (
    <>
      <Button disabled={isLoadingWidget} onClick={onClickVideoUploadButton}>
        Upload a new video
      </Button>
      <Span>Videos</Span>
      <Container>
        {videos.map(({ ...props }, index) => (
          <VideoPreview key={index} {...props} />
        ))}
      </Container>
      {hasMoreVideos && (
        <Button disabled={isLoadingWidget} secondary onClick={loadMoreVideos}>
          Load More Videos
        </Button>
      )}
    </>
  );
};

export default VideoWidget;

import { FC } from 'react';

const svgIcons = [
  { name: 'Not Good', icon: '#icon-not-good' },
  { name: 'Average', icon: '#icon-average' },
  { name: 'Normal', icon: '#icon-normal' },
  { name: 'Good', icon: '#icon-good' },
  { name: 'Very Good', icon: '#icon-very-good' },
];
const lastIcon = svgIcons[-1];

interface SmileyItemProps {
  value: number;
}

const SmileyItem: FC<SmileyItemProps> = ({ value }) => {
  return (
    <label className="sv_rating_smiley_item">
      <input
        type="radio"
        className="sv-visuallyhidden"
        name="question1"
        id="sq_216i_0"
        aria-required="false"
        aria-label="question1"
        aria-invalid="false"
        value={value.toString()}
      />
      <svg className="sv-svg-icon" role="img" aria-label="1">
        <use xlinkHref={svgIcons[value - 1].icon || lastIcon.icon}></use>
        <title>{svgIcons[value - 1].name || lastIcon.name}</title>
      </svg>
    </label>
  );
};

export default SmileyItem;

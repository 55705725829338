import { NavItemOption, NavItemPageOptions } from './types';
import {
  BookOpen,
  Film,
  User,
  Users,
  Video,
  Monitor,
  Layers,
  Activity,
  FileText,
  Clock,
} from 'react-feather';

const pagesSection: NavItemOption[] = [
  {
    href: '/presentations',
    icon: BookOpen,
    title: 'Presentations',
  },
  {
    href: '/slides',
    icon: Film,
    title: 'Slides',
  },
  {
    href: '/forms',
    icon: FileText,
    title: 'Forms',
  },
  {
    href: '/devices',
    icon: Monitor,
    title: 'Devices',
  },
  {
    href: '/assets',
    icon: Video,
    title: 'Assets',
  },
  {
    href: '/scheduler',
    icon: Clock,
    title: 'Scheduler',
  },

  {
    href: '/kvs',
    icon: Layers,
    title: 'KVS',
  },
];

const settingsSection: NavItemOption[] = [
  {
    href: '/profile',
    icon: User,
    title: 'Profile',
  },
  {
    href: '/users',
    icon: Users,
    title: 'Users',
  },
];

const navItems: NavItemPageOptions[] = [
  {
    title: 'SignageTec®',
    pages: pagesSection,
  },
  {
    title: 'Settings',
    pages: settingsSection,
  },
];

export const navSuperAdminItems: NavItemPageOptions[] = [
  {
    title: 'Platform Ops',
    pages: [
      {
        href: '/admin/organizations',
        icon: Users,
        title: 'Admin Panel',
      },
      {
        href: '/admin/activity-log',
        icon: Activity,
        title: 'Activity log',
      },
    ],
  },
];

export default navItems;

import { ComponentPropsWithoutRef, FC } from 'react';
import styled from 'styled-components';
import { ButtonStyleProps } from 'styled-system';

const StyledButton = styled.button`
  background: #7300e6;
  border: none;
  color: white;
  outline-color: white;
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #5a00b3;
  }
`;

type ButtonProps = ComponentPropsWithoutRef<'button'> &
  ButtonStyleProps & {
    secondary?: boolean;
  };

const Button: FC<ButtonProps> = ({ secondary, style, ...props }) => (
  <StyledButton
    type="button"
    {...props}
    style={{ ...style, ...(secondary && { backgroundColor: '#323132' }) }}
  />
);

export default Button;

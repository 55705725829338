import React from 'react';
import Lock from '../Lock';
import SettingsWrapper from '../common/SettingsWrapper';
import Delete from '../Delete';
import Duplicate from '../Duplicate';
import CenterAlign from '../CenterAlign';
import Align from '../Align';
import Group from '../Group';

export default function ObjectSettings() {
  return (
    <>
      <SettingsWrapper>
        <Lock />
        <Delete />
        <Duplicate />
      </SettingsWrapper>

      <CenterAlign />

      <Align />

      <Group />
    </>
  );
}

import { FC } from 'react';
import { Tooltip } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import ButtonComponent from './common/ButtonComponent';
import { useFabricEditor } from '../lib/FabricJs';
import { useHandleCopyPaste } from '../lib/FabricJs/hooks/useKeyboardEvents';

const Duplicate: FC = () => {
  const { canvas } = useFabricEditor();
  const { handleDuplicate } = useHandleCopyPaste(canvas);

  return (
    <Tooltip title="Duplicate selected items.">
      <ButtonComponent onClick={handleDuplicate}>
        <CopyAll fontSize="medium" />
      </ButtonComponent>
    </Tooltip>
  );
};

export default Duplicate;

import { useMutation, useQuery } from '@tanstack/react-query';
import { SlideBody } from '../types/slide.types';
import { PresentationResponse } from '../types/presentation.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';

interface CreatePresentationBody {
  name: string;
  status: boolean;
  slides: SlideWithOptions[];
  category: string;
  organizationId?: string;
}

export interface SlideWithOptions extends Omit<SlideOption, 'slideData'> {
  slideData?: SlideBody;
  id: string;
}

export interface UpdatePresentationResponse {
  _id: string;
}

interface SlideOption {
  slideId: string;
  slideData: string;
  animation: string;
  duration: number;
}

export interface PresentationUpdateBody {
  id: string;
  name?: string;
  slides?: SlideOption[];
  status?: boolean;
  category?: string;
}

export interface GetPresentationById {
  id: string;
}

export interface GetPresentationResponse extends PresentationResponse {
  draft?: string;
}

export interface GetOrganizationPresentationsResponse {
  id: string;
  name: string;
  createdBy: string;
}

export interface GetOrganizationPresentations {
  _id: string;
  name: string;
}

export interface PresentationKvsData {
  kvsId: string;
  kvsGroup: string;
  key: string;
  value: string;
  slideCount: number;
}

export const useCreatePresentation = (opt?: MutOptions<PresentationResponse>) =>
  useMutation<PresentationResponse, ApiServiceErr, CreatePresentationBody>(
    async (data) => {
      const response = await axiosApi.post('/presentations/create', data);
      return response.data;
    },
    opt,
  );

export const useGetPresentation = () =>
  useMutation<GetPresentationResponse, ApiServiceErr, GetPresentationById>(
    async (data) => {
      const response = await axiosApi.get(`/presentations/${data.id}`);
      const presentation = response.data;
      for (const [key, slide] of presentation?.slides?.entries() || []) {
        slide.slideData = presentation?.slideClones[key];
      }
      return presentation;
    },
  );

export const useDeletePresentation = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/presentations/delete/${data.id}`);
    return response.data;
  }, opt);

export const useUpdatePresentation = (
  opt?: MutOptions<UpdatePresentationResponse>,
) =>
  useMutation<
    UpdatePresentationResponse,
    ApiServiceErr,
    PresentationUpdateBody
  >(async (data) => {
    const response = await axiosApi.put(`/drafts/${data.id}`, data);
    return response.data;
  }, opt);

export const useGetOrganizationPresentations = () => {
  return useQuery<GetOrganizationPresentationsResponse[], ApiServiceErr>(
    ['presentations/organization/all'],
    async () => {
      const response = await axiosApi.get(`/presentations/organization/all`);
      return response.data;
    },
  );
};

export const useGetPresentationKvsData = (id: string, enabled: boolean) =>
  useQuery<PresentationKvsData[], ApiServiceErr>(
    [`/kvs-data/presentation/${id}`],
    async () => {
      const response = await axiosApi.get(`/kvs-data/presentation/${id}`);
      return response.data;
    },
    {
      enabled,
    },
  );

export const useGetPresentationsWithSearch = (search?: string) => {
  return useQuery<GetOrganizationPresentations[], ApiServiceErr>(
    ['presentations/all/by-search'],
    async () => {
      const response = await axiosApi.get(
        `/presentations/all/by-search?search=${search}`,
      );
      return response.data;
    },
  );
};

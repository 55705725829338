import { ElementType } from './types';

const defaultFontFamily = 'Roboto';
const fontFamilies = ['Great Vibes', 'Dancing Script', 'Allison', 'Roboto'];
const IFRAME_WIDGETS = {
  IFRAME: 'iframe',
  WORLD_CLOCK: 'world-clock',
  DATE: 'date',
};
const ELEMENTS: ElementType = {
  date: ['date', 'background'],
  'world-clock': ['digits', 'background'],
};
const NEWS_TYPES = {
  CNN: 'cnn',
  BBC: 'bbc',
};

export {
  defaultFontFamily,
  fontFamilies,
  IFRAME_WIDGETS,
  ELEMENTS,
  NEWS_TYPES,
};

import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

import { errorMsg } from '../../configs';
import { SocketError } from '../../util/useSocket';

interface ErrorDisplayPageProps {
  errorsArray: SocketError[];
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-left: 7vw;
  padding-right: 7vw;
`;

const StyledTypographyHeader = styled(Typography)`
  padding-bottom: 10px;
  color: red;
`;

const ErrorDisplayPage: FC<ErrorDisplayPageProps> = ({ errorsArray }) => {
  return (
    <StyledBox>
      <StyledTypographyHeader variant="h4" align="center" fontWeight="bold">
        {errorMsg.COMMON_ERROR}
      </StyledTypographyHeader>
      {errorsArray.length === 0 ? (
        <Typography variant="h4" align="center">
          No errors
        </Typography>
      ) : (
        errorsArray.map((error, index) => (
          <Typography key={index} variant="h4" align="center">
            •{' '}
            {error?.message === 'xhr poll error'
              ? errorMsg.SOCKET_ERROR
              : error?.message}
          </Typography>
        ))
      )}
    </StyledBox>
  );
};

export default ErrorDisplayPage;

import { FC } from 'react';
import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../components/TableTheme';
import { UserRole } from '../../types/user.types';
import { displayDistance } from '../../util/dateTime';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useTable } from '../../util/useTable';
import { Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import * as React from 'react';
import { ChevronDown } from 'react-feather';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FormActionMenu } from './FormActionMenu';
import { DialogModal } from '../../components';
import { FormEditModal } from './FormEditModal';
import { useEffect, useState } from 'react';
import {
  FormsResponse,
  useDeleteForms,
  useGetPaginateForms,
} from '../../api-http/forms';
import { AlertBar } from '../../components/AlertBar';
import { useSnack } from '../../util/useSnack';
import { notifyMsg } from '../../configs';
import { useConfirm } from 'material-ui-confirm';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { MouseEvent } from 'react';

const UsageContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NotUsedDot = styled('div')`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: gray;
  margin-right: 5px;
`;

const UsedDot = styled('div')`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: limegreen;
  margin-right: 5px;
`;

interface TableProp {
  refresh: boolean;
  search: string;
}

const FormsTable: FC<TableProp> = ({ refresh, search }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const isViewOnly = useIsUserRole([UserRole.user, UserRole.dataEditor]);
  const disableEdit = useIsUserRole([UserRole.user]);
  const { showErrSnack, showSuccessSnack } = useSnack();
  const location = useLocation();

  const { tableProps, pagingParams, sortOnClick } = useTable(true);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeForm, setActiveForm] = useState<FormsResponse | undefined>();
  const { sortby, order } = pagingParams;
  const [formEditModal, setFormEditModal] = useState(false);

  const {
    data: list,
    isLoading: listIsLoading,
    refetch,
    error,
  } = useGetPaginateForms({ ...pagingParams, search: search });

  const {
    error: deleteError,
    mutate: deleteForm,
    data: deletedForms,
  } = useDeleteForms({
    onSuccess: () => showSuccessSnack(notifyMsg.FORM_DELETE_SUCCESS),
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    forms: FormsResponse,
  ) => {
    setActiveForm(forms);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (id: string) => {
    try {
      await confirm({
        description: notifyMsg.DELETE_CONFIRMATION,
        confirmationText: 'Yes',
      });
      deleteForm({ id });
    } catch (e) {}
  };

  useEffect(() => {
    refetch();
  }, [deletedForms, tableProps, refresh]);

  return (
    <>
      {listIsLoading && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg || deleteError?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <StyledTableSortLabel
                  active={sortby === SortEnum.name || true}
                  direction={sortby === SortEnum.name ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.name)}
                >
                  Form Title
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">
                <StyledTableSortLabel
                  active={sortby === SortEnum.updatedAt || true}
                  direction={
                    sortby === SortEnum.updatedAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.updatedAt)}
                >
                  Updated
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">
                <StyledTableSortLabel
                  active={sortby === SortEnum.createdAt || true}
                  direction={
                    sortby === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Created
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">Usage</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.data.map((form: FormsResponse) => {
              const { _id, name, updatedAt, createdAt, device } = form;
              return (
                <TableRow
                  key={_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {isViewOnly ? (
                      name
                    ) : (
                      <NavLink
                        to={`/forms/responses/${_id}`}
                        state={{
                          search: location?.search,
                        }}
                      >
                        {name}
                      </NavLink>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {displayDistance(updatedAt)}
                  </TableCell>
                  <TableCell align="left">
                    {displayDistance(createdAt)}
                  </TableCell>
                  <TableCell align="left">
                    {device?.length === 0 ? (
                      <UsageContainer>
                        <NotUsedDot />
                        <span>Not Used</span>
                      </UsageContainer>
                    ) : (
                      <UsageContainer>
                        <UsedDot />
                        <span>Used</span>
                      </UsageContainer>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      disabled={disableEdit}
                      onClick={(event) => handleClick(event, form)}
                    >
                      <ChevronDown size={16} />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>
      <FormActionMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClickDelete={() => {
          handleDelete(activeForm?._id || '');
          handleClose();
        }}
        onClickEdit={() => {
          navigate(`/survey-editor/${activeForm?._id || ''}`);
        }}
        onClickRename={() => {
          setFormEditModal(true);
          handleClose();
        }}
      />
      <DialogModal
        title={'Edit Form'}
        onClose={() => setFormEditModal(false)}
        open={formEditModal}
      >
        <FormEditModal
          forms={activeForm}
          onFinished={() => {
            setFormEditModal(false);
          }}
          refetch={refetch}
        />
      </DialogModal>
    </>
  );
};

export default FormsTable;

interface GetFitToWidthOption {
  originalWidth: number;
  originalHeight: number;
  margin?: number;
  name: string;
}

export const getFitToWidthRatio = ({
  originalWidth,
  originalHeight,
  margin = 0,
  name,
}: GetFitToWidthOption) => {
  const isVertical = name.includes('Vertical');
  const { innerWidth, innerHeight } = window;

  let boxHeight;
  let boxWidth;
  let ratio;

  if (isVertical) {
    boxHeight = innerHeight - margin / 4;

    ratio = boxHeight / originalHeight;

    const heightToWidthRatio = originalWidth / originalHeight;
    boxWidth = boxHeight * heightToWidthRatio;
  } else {
    boxWidth = innerWidth - margin;

    ratio = boxWidth / originalWidth;

    const heightToWidthRatio = originalHeight / originalWidth;
    boxHeight = boxWidth * heightToWidthRatio;
  }

  return {
    boxHeight,
    boxWidth,
    ratio,
  };
};

export const getControlButtonText = () => {
  const isMac = window?.navigator?.userAgent.indexOf('Mac') > -1;

  return isMac ? '⌘' : 'ctrl';
};

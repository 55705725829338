import { FC } from 'react';
import { useCreateOrg } from '../../../../api-http/organizations';
import { notifyMsg } from '../../../../configs';
import { OrganizationForm } from './OrganizationForm';
import { DialogModal } from '../../../../components';
import { useSnack } from '../../../../util/useSnack';

interface AddOrganizationModalProps {
  onFinished: () => void;
  onClose: () => void;
  open: boolean;
}

export const AddOrganizationModal: FC<AddOrganizationModalProps> = ({
  onFinished,
  open,
  onClose,
}) => {
  const { showSuccessSnack, showErrSnack } = useSnack();
  const { isLoading, mutate, error, reset } = useCreateOrg({
    onSuccess() {
      showSuccessSnack(notifyMsg.ORGANIZATION_ADDED_SUCCESS);
      onFinished();
      closeModal();
    },
    onError: (err) => showErrSnack(err),
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <DialogModal title="Add organization" onClose={onClose} open={open}>
      <OrganizationForm
        onSubmit={(data) => mutate(data)}
        isLoading={isLoading}
        error={error}
      />
    </DialogModal>
  );
};

import { ApiServiceErr } from '../../../api-http/apiService';
import { useVerifyPin, VerifyPinBody } from '../../../api-http/devices';
import { notifyMsg } from '../../../configs';
import { Nullable } from '../../../types';
import { useSnack } from '../../../util/useSnack';
import PinComponent from './PinComponent';
import { Alert as MuiAlert, Button, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import { useFormik } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

interface VerifyPinProps {
  onSuccess: (data: VerifyPinBody) => void;
  isLoading: boolean;
  error?: Nullable<ApiServiceErr>;
}

interface FormValues {
  pinCode: number;
}

const validationSchema = yup.object({
  pinCode: yup
    .string()
    .required('Pin code is required')
    .matches(/^[0-9]{4}$/, 'Pin code must be a 4 digit number'),
});

const Alert = styled(MuiAlert)(spacing);

export const VerifyPinCode: FC<VerifyPinProps> = ({
  isLoading,
  onSuccess,
  error,
}) => {
  const { showErrSnack } = useSnack();
  const { mutate } = useVerifyPin({
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: () => {
      showErrSnack(notifyMsg.PIN_INVALID);
    },
  });
  const handleSubmit = (values: FormValues) => {
    mutate(values.pinCode);
  };

  const formik = useFormik({
    initialValues: {
      pinCode: 0,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        {error && (
          <Alert mt={2} mb={3} severity="warning">
            {error.msg}
          </Alert>
        )}
        <Typography align="center">Enter Device Generated Pin code</Typography>
        <PinComponent
          length={4}
          value={formik.values.pinCode}
          onChange={(value) => {
            formik.setFieldValue('pinCode', value);
          }}
          error={formik.errors.pinCode}
        />
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={isLoading}
        >
          Verify
        </Button>
      </form>
    </Container>
  );
};

export const getUrl: (type: 'APP_ROUTE' | 'WS_URL') => string = (type) => {
  const reactAppEnv = process.env.REACT_APP_ENV;
  if (reactAppEnv === 'stage') {
    switch (type) {
      case 'APP_ROUTE':
        return 'https://app-stage.stec.tv';
      case 'WS_URL':
        return 'https://api-stage.stec.tv';
      default:
        return 'http://localhost:3001';
    }
  } else if (reactAppEnv === 'production') {
    switch (type) {
      case 'APP_ROUTE':
        return 'https://app-v3.stec.tv';
      case 'WS_URL':
        return 'https://api-v3.stec.tv';
      default:
        return 'http://localhost:3000';
    }
  } else {
    switch (type) {
      case 'APP_ROUTE':
        return 'http://localhost:3000';
      case 'WS_URL':
        return 'http://localhost:3001';
    }
  }
};

import React, { FC, FormEvent, useState } from 'react';
import { InputType, SurveyElement } from '../../types';
import { useEditorData } from '../../EditorProvider';
import { changeElementData } from '../../utils/surveyDataUtils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { RemoveButton } from './RemoveButton';
import getAlignment from '../../utils/getAlignment';

interface RadioInput {
  id: number;
  value: string;
}

interface RadioButtonProps {
  index: number;
  elementId: string;
  pageId: string;
  elementData: SurveyElement;
}

const RadioButton: FC<RadioButtonProps> = ({
  index,
  elementId,
  pageId,
  elementData,
}) => {
  const [itemNoneAdded, setItemNoneAdded] = useState(false);
  const [itemOtherAdded, setItemOtherAdded] = useState(false);
  const [radioInputs, setRadioInputs] = useState<RadioInput[]>([
    { id: 1, value: 'Item 1' },
    { id: 2, value: 'Item 2' },
    { id: 3, value: 'Item 3' },
  ]);

  const {
    history,
    setHistory,
    setSelectedItem,
    selectedItem: focus,
    setIsSettingsOpen,
  } = useEditorData();

  const { present: surveyData } = history;

  const onChange = (e: FormEvent<HTMLSpanElement>) => {
    if (!elementData) return;

    const { id, textContent } = e.currentTarget;

    const params = {
      surveyData,
      data: {
        [id]: textContent as string,
        showOtherItem: itemOtherAdded,
        showNoneItem: itemNoneAdded,
      },
      focus,
      setHistory,
    };
    changeElementData(params);
    setIsSettingsOpen(true);
  };

  const addRadioInput = () => {
    const newInputId = radioInputs.length + 1;
    const newRadioInput: RadioInput = {
      id: newInputId,
      value: `Item ${newInputId}`,
    };
    setRadioInputs([...radioInputs, newRadioInput]);
  };
  const removeRadioInput = (id: number) => {
    const updatedInputs = radioInputs.filter((input) => input.id !== id);
    setRadioInputs(updatedInputs);
  };

  return (
    <div
      className="svc-row"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedItem({ pageId, elementId, type: InputType.RADIO_BUTTON });
      }}
    >
      <div className="sd-row sd-clearfix sd-page__row">
        <div
          data-key="question10"
          style={{ flex: '1 1 100%', minWidth: '300px', maxWidth: '100%' }}
        >
          <div
            data-sv-drop-target-survey-element="question1"
            className="svc-question__adorner"
          >
            <div
              className="svc-question__content svc-question__content--radiogroup"
              tabIndex={0}
              style={{ paddingBottom: 0 }}
            >
              <div className="svc-question__drag-area">
                <svg
                  className="sv-svg-icon svc-question__drag-element"
                  role="img"
                  style={{ width: '24px', height: '24px' }}
                >
                  <use xlinkHref="#icon-drag-area-indicator_24x16" />
                </svg>
              </div>
              <div
                id="sq_1940"
                className="sd-element sd-question sd-row__question"
                role="radiogroup"
                aria-required="false"
                aria-invalid="false"
                aria-labelledby="sq_1940_ariaTitle"
                data-rendered="r"
                data-name="question1"
              >
                <div
                  className={`sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top ${getAlignment(
                    surveyData,
                  )}`}
                >
                  <h5
                    className="sd-title sd-element__title sd-question__title sd-element__title--collapsed"
                    id="sq_1940_ariaTitle"
                  >
                    <span
                      data-key="q_num"
                      className="sd-element__num"
                      aria-hidden="true"
                      style={{ position: 'static' }}
                    >
                      {index}.
                    </span>
                    <span data-key="num-sp">&nbsp;</span>
                    <span className="svc-string-editor">
                      <span className="svc-string-editor__content">
                        <div className="svc-string-editor__border">
                          <svg
                            className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                            role="img"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-edit" />
                          </svg>
                        </div>
                        <span className="svc-string-editor__input">
                          <span
                            id="title"
                            role="textbox"
                            className="sv-string-editor"
                            contentEditable="true"
                            spellCheck="false"
                            aria-label="content editable"
                            data-gramm="false"
                            data-quillbot-element="UKbJGBj996H6wVqjhz_pO"
                            aria-placeholder={`Question ${index}`}
                            onBlur={onChange}
                            suppressContentEditableWarning
                          >
                            {elementData?.title}
                          </span>
                          <div className="svc-string-editor__controls" />
                        </span>
                      </span>
                    </span>
                  </h5>
                  <div className="sd-description sd-question__description">
                    <span
                      className={`svc-string-editor ${
                        !elementData?.description && 'svc-string-editor--hidden'
                      }`}
                    >
                      <span className="svc-string-editor__content">
                        <div className="svc-string-editor__border">
                          <svg
                            className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                            role="img"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <use xlinkHref="#icon-edit" />
                          </svg>
                        </div>
                        <span className="svc-string-editor__input">
                          <span
                            id="description"
                            role="textbox"
                            className="sv-string-editor"
                            spellCheck="false"
                            aria-label="content editable"
                            data-gramm="false"
                            onInput={onChange}
                          />
                          <div className="svc-string-editor__controls" />
                          {elementData?.description}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div className="sd-question__content" role="presentation">
                  <fieldset
                    className="sd-selectbase"
                    role="presentation"
                    data-rendered="r"
                  >
                    {/* div */}
                    <div>
                      {radioInputs.map((input) => (
                        <div className="svc-item-value-wrapper" key={input.id}>
                          <div className="svc-item-value__ghost" />
                          <div className="svc-item-value-controls">
                            <span className="svc-item-value-controls__button svc-item-value-controls__drag">
                              {/* Drag icon */}
                            </span>
                            <span
                              className="svc-item-value-controls__button svc-item-value-controls__remove"
                              aria-label="Click to remove the item..."
                              tabIndex={0}
                              onClick={() => removeRadioInput(input.id)}
                            >
                              <svg
                                className="sv-svg-icon"
                                role="img"
                                aria-label="Click to remove the item..."
                                style={{ width: '16px', height: '16px' }}
                              >
                                <RemoveCircleOutlineIcon color="error" />
                                <title>Click to remove the item...</title>
                              </svg>
                            </span>
                          </div>
                          <div className="svc-item-value__item">
                            <div
                              className="sd-item sd-radio sd-selectbase__item sv-q-col-1"
                              role="presentation"
                            >
                              <label
                                className="sd-selectbase__label"
                                aria-label={input.value}
                              >
                                <input
                                  className="sd-visuallyhidden sd-item__control sd-radio__control"
                                  id={`sq_${input.id}`}
                                  type="radio"
                                  name={`question1_sq_${input.id}`}
                                  disabled
                                  defaultValue={input.value}
                                />
                                <span className="sd-item__decorator sd-radio__decorator" />
                                <span className="sd-item__control-label">
                                  <span className="svc-string-editor">
                                    <span className="svc-string-editor__content">
                                      <div className="svc-string-editor__border">
                                        {/* Edit icon */}
                                      </div>
                                      <span className="svc-string-editor__input">
                                        <span
                                          id={`item${input.id}`}
                                          role="textbox"
                                          className="sv-string-editor"
                                          contentEditable={true}
                                          spellCheck={false}
                                          aria-placeholder=""
                                          aria-label="content editable"
                                          data-gramm="false"
                                          onBlur={onChange}
                                          suppressContentEditableWarning
                                        >
                                          {input.value}
                                        </span>
                                        <div className="svc-string-editor__controls" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* div */}
                    <div className="svc-item-value-wrapper svc-item-value--new">
                      <div className="svc-item-value__ghost" />
                      <div className="svc-item-value-controls">
                        <span
                          className="svc-item-value-controls__button svc-item-value-controls__add"
                          aria-label="Click to add an item..."
                          tabIndex={0}
                          onClick={addRadioInput}
                        >
                          <svg
                            className="sv-svg-icon"
                            role="img"
                            aria-label="Click to add an item..."
                            style={{ width: '16px', height: '16px' }}
                          >
                            {/* <use xlinkHref="#icon-add_16x16" /> */}
                            <AddCircleOutlineIcon color="success" />
                            <title>Click to add an item...</title>
                          </svg>
                        </span>
                      </div>
                      <div className="svc-item-value__item">
                        <div
                          className="sd-item sd-radio sd-selectbase__item sv-q-col-1"
                          role="presentation"
                        >
                          <label
                            className="sd-selectbase__label"
                            aria-label="Item 4"
                          >
                            <input
                              className="sd-visuallyhidden sd-item__control sd-radio__control"
                              id="sq_1940i_3"
                              type="radio"
                              name="question1_sq_1940"
                              // disabled
                              defaultValue="Item 4"
                            />
                            <span className="sd-item__decorator sd-radio__decorator" />
                            <span className="sd-item__control-label">
                              <span className="sv-string-viewer">{`Item ${
                                radioInputs.length + 1
                              }`}</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        itemNoneAdded === false
                          ? 'svc-item-value-wrapper svc-item-value--new'
                          : 'svc-item-value-wrapper'
                      }
                    >
                      <div className="svc-item-value__ghost" />
                      <div className="svc-item-value-controls">
                        <span
                          className={
                            itemNoneAdded === false
                              ? 'svc-item-value-controls__button svc-item-value-controls__add'
                              : 'svc-item-value-controls__button svc-item-value-controls__remove'
                          }
                          aria-label="Click to add an item..."
                          tabIndex={0}
                          onClick={() => setItemNoneAdded(!itemNoneAdded)}
                        >
                          <svg
                            className="sv-svg-icon"
                            role="img"
                            aria-label="Click to add an item..."
                            style={{ width: '16px', height: '16px' }}
                          >
                            {/* <use xlinkHref="#icon-add_16x16" /> */}
                            {itemNoneAdded === false ? (
                              <AddCircleOutlineIcon color="success" />
                            ) : (
                              <RemoveCircleOutlineIcon color="error" />
                            )}

                            <title>Click to add an item...</title>
                          </svg>
                        </span>
                      </div>
                      <div className="svc-item-value__item">
                        <div
                          className="sd-item sd-radio sd-selectbase__item sv-q-col-1"
                          role="presentation"
                        >
                          <label
                            className="sd-selectbase__label"
                            aria-label="None"
                          >
                            <input
                              className="sd-visuallyhidden sd-item__control sd-radio__control"
                              id="sq_1940i_4"
                              type="radio"
                              name="question1_sq_1940"
                              disabled
                              defaultValue="none"
                            />
                            <span className="sd-item__decorator sd-radio__decorator" />
                            <span className="sd-item__control-label">
                              <span className="svc-string-editor svc-string-editor--readonly">
                                <span className="svc-string-editor__content">
                                  <div className="svc-string-editor__border">
                                    <svg
                                      className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                                      role="img"
                                      style={{ width: '16px', height: '16px' }}
                                    >
                                      <use xlinkHref="#icon-edit" />
                                    </svg>
                                  </div>
                                  <span className="svc-string-editor__input">
                                    <span
                                      id="noneText"
                                      role="textbox"
                                      className="sv-string-editor"
                                      contentEditable={itemNoneAdded}
                                      spellCheck="false"
                                      aria-placeholder=""
                                      aria-label="content editable"
                                      data-gramm="false"
                                      onBlur={onChange}
                                      suppressContentEditableWarning
                                    >
                                      {elementData.noneText || 'None'}
                                    </span>
                                    <div className="svc-string-editor__controls" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        itemOtherAdded === false
                          ? 'svc-item-value-wrapper svc-item-value--new'
                          : 'svc-item-value-wrapper'
                      }
                    >
                      <div className="svc-item-value__ghost" />
                      <div className="svc-item-value-controls">
                        <span
                          className={
                            itemOtherAdded === false
                              ? 'svc-item-value-controls__button svc-item-value-controls__add'
                              : 'svc-item-value-controls__button svc-item-value-controls__remove'
                          }
                          aria-label="Click to add an item..."
                          tabIndex={0}
                          onClick={() => setItemOtherAdded(!itemOtherAdded)}
                        >
                          <svg
                            className="sv-svg-icon"
                            role="img"
                            aria-label="Click to add an item..."
                            style={{ width: '16px', height: '16px' }}
                          >
                            {/* <use xlinkHref="#icon-add_16x16" /> */}
                            {itemOtherAdded === false ? (
                              <AddCircleOutlineIcon color="success" />
                            ) : (
                              <RemoveCircleOutlineIcon color="error" />
                            )}
                            <title>Click to add an item...</title>
                          </svg>
                        </span>
                      </div>
                      <div className="svc-item-value__item">
                        <div
                          className="sd-item sd-radio sd-selectbase__item sv-q-col-1"
                          role="presentation"
                        >
                          <label
                            className="sd-selectbase__label"
                            aria-label="Other (describe)"
                          >
                            <input
                              className="sd-visuallyhidden sd-item__control sd-radio__control"
                              id="sq_1940i_5"
                              type="radio"
                              name="question1_sq_1940"
                              disabled
                              defaultValue="other"
                            />
                            <span className="sd-item__decorator sd-radio__decorator" />
                            <span className="sd-item__control-label">
                              <span className="svc-string-editor svc-string-editor--readonly">
                                <span className="svc-string-editor__content">
                                  <div className="svc-string-editor__border">
                                    <svg
                                      className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                                      role="img"
                                      style={{ width: '16px', height: '16px' }}
                                    >
                                      <use xlinkHref="#icon-edit" />
                                    </svg>
                                  </div>
                                  <span className="svc-string-editor__input">
                                    <span
                                      id="otherText"
                                      role="textbox"
                                      className="sv-string-editor"
                                      contentEditable={itemOtherAdded}
                                      spellCheck="false"
                                      aria-placeholder=""
                                      aria-label="content editable"
                                      data-gramm="false"
                                      onBlur={onChange}
                                      suppressContentEditableWarning
                                    >
                                      {elementData.otherText ||
                                        'Other (describe)'}
                                    </span>
                                    <div className="svc-string-editor__controls" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <RemoveButton
                surveyData={surveyData}
                elementId={elementId}
                pageId={pageId}
                setHistory={setHistory}
                setSelectedItem={setSelectedItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadioButton;

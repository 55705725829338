import { useLocation, useNavigate } from 'react-router-dom';
import {
  PAGE,
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_OPTION,
} from '../containers/constant';
import { useAuth } from '../context/auth-context';
import { Order, OrderEnum, SortEnum } from '../types/pagination.types';
import { useEffect, useState } from 'react';
import { getValueFromUrl } from './helpers';

type Event = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export const useTable = (
  searchInUrl = false,
  sortBy: string = SortEnum.title,
) => {
  const location = useLocation();
  const locationSearch = location?.search;
  const previousSortBy = getValueFromUrl(locationSearch, 'sortBy');
  const previousOrder =
    getValueFromUrl(locationSearch, 'order') === OrderEnum.asc
      ? OrderEnum.asc
      : OrderEnum.desc;
  const previousPage = Number(getValueFromUrl(locationSearch, 'page'));
  const previousRowPP = Number(getValueFromUrl(locationSearch, 'rowsPerPage'));

  const [sortby, setSortBy] = useState<string>(previousSortBy || sortBy);
  const [order, setOrder] = useState<Order>(previousOrder || OrderEnum.asc);
  const [page, setPage] = useState(previousPage || PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(
    previousRowPP || ROWS_PER_PAGE,
  );
  const { switchedOrgId } = useAuth();
  const [currentOrgId, setCurrentOrgId] = useState(switchedOrgId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchInUrl) {
      return;
    }
    const searchParams = new URLSearchParams(location?.search);
    const search = searchParams.get('search');

    if (page) {
      searchParams.set('page', `${page}`);
    } else {
      searchParams.delete('page');
    }

    if (order) {
      searchParams.set('order', `${order}`);
    }

    if (sortby) {
      searchParams.set('sortby', `${sortby}`);
    }

    if (rowsPerPage) {
      searchParams.set('rowsPerPage', `${rowsPerPage}`);
    }

    if (search) {
      searchParams.set('search', search);
    }
    const newUrl = `?${searchParams.toString()}`;

    navigate(newUrl, {
      state: {
        search,
        page,
        order,
        sortby,
      },
    });
  }, [page, order, sortby, rowsPerPage, searchInUrl]);

  useEffect(() => {
    if (currentOrgId !== switchedOrgId) {
      setPage(0);
      setCurrentOrgId(switchedOrgId);
    }
  }, [switchedOrgId]);

  const sortOnClick = (header: string) => {
    const isAsc = sortby === header && order === OrderEnum.asc;
    setOrder(isAsc ? OrderEnum.desc : OrderEnum.asc);
    if (sortby !== header) {
      setSortBy(header);
    }
  };

  return {
    sortOnClick,
    setPage,
    switchedOrgId,
    pagingParams: {
      sortby,
      order,
      limit: rowsPerPage,
      page: page + 1,
    },
    tableProps: {
      page,
      rowsPerPage,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTION,
      onPageChange: (_: unknown, p: number) => setPage(p),
      onRowsPerPageChange: (e: Event) => {
        setRowsPerPage(parseInt(`${e.target.value}`, 10));
        setPage(0);
      },
    },
  };
};

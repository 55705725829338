import { useDeleteEvents, useGetSchedulersEvents } from '../../api-http/event';
import { DialogModal } from '../../components';
import { AlertBar } from '../../components/AlertBar';
import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../components/TableTheme';
import { notifyMsg } from '../../configs';
import { EventEditResponse } from '../../types/event.types';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import { displayDate } from '../../util/dateTime';
import { frequency } from '../../util/frequency';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useSnack } from '../../util/useSnack';
import { useTable } from '../../util/useTable';
import { EventActionMenu } from './EventActionMenu';
import { SchedulerEditForm } from './SchedulerEditForm';
import { Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useConfirm } from 'material-ui-confirm';
import { FC, useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useParams } from 'react-router-dom';

interface EventTableProp {
  refresh: boolean;
}

export const EventTable: FC<EventTableProp> = ({ refresh }) => {
  const params = useParams<{ id: string }>();
  const confirm = useConfirm();
  const [openSchedule, setOpenSchedule] = useState(false);
  const [schedulerId, setSchedulerId] = useState('');
  const disableDelete = useIsUserRole([UserRole.user, UserRole.dataEditor]);
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { sortOnClick, pagingParams, tableProps } = useTable();
  const { sortby, order } = pagingParams;

  const {
    data: list,
    isLoading: listIsLoading,
    refetch,
    error,
  } = useGetSchedulersEvents({
    ...pagingParams,
    scheduler: params.id,
  });

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    eventScheduler: EventEditResponse,
  ) => {
    setActiveEvent(eventScheduler);
    setAnchorEl(event.currentTarget);
  };
  const {
    error: deleteError,
    mutate: deleteEvent,
    data: deletedEvent,
  } = useDeleteEvents({
    onSuccess: () => showSuccessSnack(notifyMsg.EVENT_DELETE_SUCCESS),
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeEvent, setActiveEvent] = useState<
    EventEditResponse | undefined
  >();

  useEffect(() => {
    refetch();
  }, [deletedEvent, refresh, tableProps]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (id: string) => {
    try {
      await confirm({
        description: notifyMsg.DELETE_CONFIRMATION,
        confirmationText: 'Yes',
      });
      deleteEvent({ id });
    } catch (e) {}
  };

  return (
    <>
      {listIsLoading && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg || deleteError?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" width={200}>
                <StyledTableSortLabel
                  active={sortby === SortEnum.presentationName || true}
                  direction={
                    sortby === SortEnum.presentationName ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.presentationName)}
                >
                  Presentation Name
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="left">
                <StyledTableSortLabel
                  active={sortby === SortEnum.startDate || true}
                  direction={
                    sortby === SortEnum.startDate ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.startDate)}
                >
                  Start Date
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">
                <StyledTableSortLabel
                  active={sortby === SortEnum.endDate || true}
                  direction={
                    sortby === SortEnum.endDate ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.endDate)}
                >
                  End Date
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">Start Time</StyledTableCell>

              <StyledTableCell align="left">End Time</StyledTableCell>
              <StyledTableCell align="left">Frequency</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {list?.data?.map((eventScheduler, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  id={eventScheduler._id}
                  onClick={() => {
                    setSchedulerId(eventScheduler._id);
                  }}
                >
                  <TableCell align="left">
                    {eventScheduler?.presentation.name}
                  </TableCell>
                  <TableCell align="left">
                    {' '}
                    {displayDate(eventScheduler?.presentationSchedule.dtstart)}
                  </TableCell>
                  <TableCell align="left">
                    {displayDate(eventScheduler?.presentationSchedule.until)}
                  </TableCell>
                  <TableCell align="left">
                    {eventScheduler?.startTime}
                  </TableCell>

                  <TableCell align="left">{eventScheduler?.endTime}</TableCell>
                  <TableCell align="left">
                    {frequency(
                      eventScheduler?.presentationSchedule.freq,
                      eventScheduler?.presentationSchedule.byweekday,
                      eventScheduler?.presentationSchedule.bysetpos,
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={(event) => handleClick(event, eventScheduler)}
                      disabled={disableDelete}
                    >
                      <ChevronDown size={16} />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>
      <EventActionMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClickDelete={() => {
          handleDelete(activeEvent?._id || '');
          handleClose();
        }}
        onClickEdit={() => {
          setOpenSchedule(true);
          handleClose();
        }}
        onClickView={() => {}}
      />
      <DialogModal
        title="Edit an Event"
        onClose={() => setOpenSchedule(false)}
        open={openSchedule}
        style={{ width: '30%' }}
      >
        <SchedulerEditForm
          onFinished={() => setOpenSchedule(false)}
          schedulerId={schedulerId || ''}
          event={list && activeEvent}
        />
      </DialogModal>
    </>
  );
};
export default EventTable;

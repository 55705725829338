import React, { ChangeEvent, FC, useEffect } from 'react';

import { useFormik, getIn } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { Button, Divider, Stack, TextField } from '@mui/material';
import { formMsg, notifyMsg } from '../../configs';
import { Trash, PlusCircle } from 'react-feather';
import { useConfirm } from 'material-ui-confirm';
import { useCreateKvs } from '../../api-http/kvs';
import { AlertBar } from '../../components/AlertBar';
import { useSnack } from '../../util/useSnack';
import { formatInput, formatInputKvsKey } from '../../util/validations';

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

interface KvsFormProps {
  onFinished: () => void;
  onRefetchData: () => void;
}

const validationSchema = yup.object({
  groupName: yup
    .string()
    .required(formMsg.GROUP_NAME_REQUIRED)
    .matches(/^[0-9a-z_]+$/, formMsg.INVALID_GROUP_NAME),
  description: yup.string().required(formMsg.KVS_DESCRIPTION_REQUIRED),
  formKeyValues: yup.array().of(
    yup.object().shape({
      key: yup
        .string()
        .required(formMsg.KEY_REQUIRED)
        .matches(/^[0-9A-Z_]+$/, formMsg.INVALID_KEY),
      value: yup.string().required(formMsg.VALUE_REQUIRED),
    }),
  ),
});

enum KeyValueActionKind {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export const KvsForm: FC<KvsFormProps> = ({ onFinished, onRefetchData }) => {
  const confirm = useConfirm();
  const { showErrSnack, showSuccessSnack } = useSnack();

  const { isLoading, data, mutate, error } = useCreateKvs({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.KVS_GROUP_CREATE_SUCCESS);
      onRefetchData();
    },
    onError: (error) => {
      showErrSnack(error || notifyMsg.KVS_GROUP_CREATE_FAILED);
    },
  });

  const formik = useFormik({
    initialValues: {
      groupName: '',
      description: '',
      formKeyValues: [
        {
          key: '',
          value: '',
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { groupName, description, formKeyValues } = values;
      mutate({
        name: groupName,
        description: description,
        data: formKeyValues,
      });
    },
  });

  useEffect(() => {
    if (data) {
      onFinished();
    }
  }, [data]);

  const onKeyValueAddOrRemove = async (
    keyValueAction: KeyValueActionKind,
    keyValueIndex?: number,
  ) => {
    const { values, setValues } = formik;
    const formKeyValues = [...values.formKeyValues];

    switch (keyValueAction) {
      case KeyValueActionKind.ADD:
        formKeyValues.push({ key: '', value: '' });
        setValues({ ...values, formKeyValues });
        break;

      case KeyValueActionKind.REMOVE:
        if (formKeyValues.length > 1) {
          try {
            await confirm({
              description: notifyMsg.DELETE_CONFIRMATION,
              confirmationText: 'Yes',
            });
            formKeyValues.splice(keyValueIndex || 0, 1);
            setValues({ ...values, formKeyValues });
          } catch (e) {}
        }
        break;

      default:
        setValues({ ...values });
        break;
    }
  };

  return (
    <Container>
      <AlertBar
        style={{ marginBottom: '10px' }}
        severity="error"
        msg={error?.msg}
      />
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            id="groupName"
            name="groupName"
            label="Group Name"
            value={formik.values.groupName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formatInput(e, 'groupName', formik)
            }
            error={formik.touched.groupName && Boolean(formik.errors.groupName)}
            helperText={formik.touched.groupName && formik.errors.groupName}
          />
          <TextField
            fullWidth
            id="description"
            name="description"
            label="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
          <Divider />
          {formik.values.formKeyValues.map((keyValue, index) => {
            const keyValueErrors =
              (formik.errors.formKeyValues?.length &&
                formik.errors.formKeyValues[index]) ||
              {};

            const keyValueTouched =
              (formik.touched.formKeyValues?.length &&
                formik.touched.formKeyValues[index]) ||
              {};

            return (
              <Stack direction="row" spacing={2} key={index}>
                <TextField
                  fullWidth
                  id={`formKeyValues.${index}.key`}
                  name={`formKeyValues.${index}.key`}
                  label="Key"
                  value={formik.values.formKeyValues[index].key}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formatInputKvsKey(e, `formKeyValues.${index}.key`, formik)
                  }
                  size="small"
                  error={
                    keyValueTouched.key && Boolean(getIn(keyValueErrors, 'key'))
                  }
                  helperText={
                    keyValueTouched.key && getIn(keyValueErrors, 'key')
                  }
                />
                <TextField
                  fullWidth
                  id={`formKeyValues.${index}.value`}
                  name={`formKeyValues.${index}.value`}
                  value={formik.values.formKeyValues[index].value}
                  onChange={formik.handleChange}
                  label="Value"
                  size="small"
                  error={
                    keyValueTouched.value &&
                    Boolean(getIn(keyValueErrors, 'value'))
                  }
                  helperText={
                    keyValueTouched.value && getIn(keyValueErrors, 'value')
                  }
                />
                <div>
                  <Button
                    size="small"
                    onClick={() =>
                      onKeyValueAddOrRemove(KeyValueActionKind.REMOVE, index)
                    }
                    style={{ padding: '12px' }}
                  >
                    <Trash size={16} />
                  </Button>
                </div>
              </Stack>
            );
          })}
          <div>
            <Button
              size="small"
              endIcon={<PlusCircle />}
              onClick={() => onKeyValueAddOrRemove(KeyValueActionKind.ADD)}
            >
              ADD NEW KEY
            </Button>
          </div>

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
          >
            Save Values
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

import {
  Button,
  debounce,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { AlertCircle } from 'react-feather';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUpdateDevice } from '../../../api-http/devices';
import { DEBOUNCE_TIME, notifyMsg } from '../../../configs';
import { useSnack } from '../../../util/useSnack';
import { useGetSchedulerList } from '../../../api-http/scheduler';
import { StyleAutocomplete } from '../../../components/AutoComplete';

interface AssignSchedulerFormProps {
  onFinished: () => void;
  selectedDeviceId?: string;
}

const Container = styled.div`
  display: flex;
  padding: 18px 5px 15px;
  flex-direction: column;
`;

export const AssignSchedulerForm: FC<AssignSchedulerFormProps> = ({
  onFinished,
  selectedDeviceId,
}) => {
  const { showSuccessSnack, showErrSnack } = useSnack();

  const [searchText, setSearchText] = useState('');

  const [selectedScheduler, setSelectedScheduler] = useState();

  const { data: schedulerList, refetch } = useGetSchedulerList(searchText);

  useEffect(() => {
    refetch();
  }, [searchText]);

  const { mutate, error, data, isLoading, isSuccess, isError } =
    useUpdateDevice();

  useEffect(() => {
    if (data && isSuccess) {
      showSuccessSnack(notifyMsg.ASSIGNED_SCHEDULER);
      onFinished();
    } else if (isError) {
      showErrSnack(error.msg);
      onFinished();
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      id: selectedDeviceId,
      scheduler: '',
    },
    onSubmit: () => {
      mutate({
        id: selectedDeviceId || '',
        scheduler: selectedScheduler,
      });
    },
  });

  const {
    values: {},
    handleSubmit,
  } = formik;

  return (
    <Container>
      <Typography fontSize={11} color={'GrayText'} display={'flex'}>
        <AlertCircle
          size={32}
          style={{ marginRight: '8px', marginBottom: '8px' }}
        />
        Please note that rotated videos are not being played on Amazon Fire
        Stick devices.
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl
          sx={{ width: '100%', minWidth: '200' }}
          style={{ marginTop: '8px', marginBottom: '5px' }}
        >
          <StyleAutocomplete
            options={schedulerList || []}
            getOptionLabel={(option) => `${option.name}`}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Scheduler"
                onChange={debounce(
                  (event) => setSearchText(event.target.value),
                  DEBOUNCE_TIME,
                )}
              />
            )}
            onChange={(_event, option) =>
              setSelectedScheduler(option?.id as any)
            }
          />
        </FormControl>
        <Button
          color="primary"
          disabled={!selectedScheduler || isLoading}
          variant="contained"
          fullWidth
          type="submit"
          style={{ marginTop: '10px' }}
        >
          Assign
        </Button>
      </form>
    </Container>
  );
};

import { FC, useEffect, useState } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import { useGetDraft } from '../../api-http/drafts';
import { useGetPresentation } from '../../api-http/presentations';
import { AlertBar } from '../../components/AlertBar';
import FullScreenMode from '../../components/FullScreenMode';
import { metaTitle } from '../../util/tabTitle';
import PresentationPreview from '../../components/preview/PresentationPreview';
import { GetPresentationResponse } from '../../api-http/presentations';
import { GetDisplayPresentationResponse } from '../../api-http/displays';

const PresentationPreviewPage: FC = () => {
  metaTitle('Preview');
  const [presentation, setPresentation] = useState<
    GetDisplayPresentationResponse | GetPresentationResponse
  >();
  const [searchParams] = useSearchParams();
  const isDraft = searchParams.get('isDraft');

  const params = useParams<{ id: string }>();
  const { data: draft, error, isLoading, mutate } = useGetDraft();
  const {
    data: publishedPresentation,
    error: getPresentationError,
    isLoading: isLoadingGetPresentation,
    mutate: getPublishedPresentation,
  } = useGetPresentation();

  useEffect(() => {
    if (isDraft) {
      setPresentation(draft);
    } else {
      setPresentation(publishedPresentation);
    }
  }, [draft, publishedPresentation]);

  useEffect(() => {
    if (params.id) {
      if (isDraft) {
        mutate({ id: params.id });
      } else {
        getPublishedPresentation({ id: params.id });
      }
    }
  }, []);

  const errMessage = error?.msg || getPresentationError?.msg;

  if (errMessage) return <AlertBar severity="error" msg={errMessage} />;

  const isLinerLoading = isLoading || isLoadingGetPresentation;

  if (isLinerLoading) return <LinearProgress />;

  if (!presentation) {
    return null;
  }

  return (
    <FullScreenMode
      backUrl={`/presentations-editor/${presentation.draft || params.id}`}
      hideFullScreenBtn
    >
      <PresentationPreview
        presentation={presentation}
        margin={window.innerWidth * 0.3}
      />
    </FullScreenMode>
  );
};

export default PresentationPreviewPage;

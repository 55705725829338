import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

import { WS_URL } from '../configs';

export interface SocketError {
  name: string;
  message: string;
  stack?: string;
}

const socket = io(WS_URL);

const useSocket = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [socketErrors, setSocketErrors] = useState<SocketError[]>([]);

  useEffect(() => {
    if (!isConnected) {
      socket.connect();
    }
  }, [isConnected]);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    socket.on('error', (err) => {
      setSocketErrors((prevErrors) => {
        if (!prevErrors.some((prevErr) => prevErr.message === err.message)) {
          return [...prevErrors, err];
        }
        return prevErrors;
      });
    });

    socket.on('connect_error', (err) => {
      setSocketErrors((prevErrors) => {
        if (!prevErrors.some((prevErr) => prevErr.message === err.message)) {
          return [...prevErrors, err];
        }
        return prevErrors;
      });
    });

    return () => {
      socket.removeAllListeners();
    };
  }, []);

  return { socket, socketErrors };
};

export default useSocket;

import React, { FC, useEffect, useState } from 'react';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  debounce,
} from '@mui/material';

import { useGetAllCategory } from '../../api-http/categories';
import { DialogModal } from '../../components';
import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { PresentationForm } from './PresentationForm';
import { PresentationTable } from './PresentationTable';
import { FilterSelect } from '../../components/canvas-editor-v2/components/common/FilterSelect';
import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import { useAuth } from '../../context/auth-context';
import { StyleTextfield } from '../../components/TextField';
import { DEBOUNCE_TIME } from '../../configs';
import Breadcrumbs from '../../components/Breadcrumb';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import RefreshButton from '../../components/RefreshButton';
import { useHandleSearch } from '../../util/useHandleSearch';
import { useLocationSearch } from '../../util/useLocationSearch';

const TopContainer = styled('div')`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PresentationPage: FC = () => {
  metaTitle('Presentations');
  const location = useLocation();
  const searchVal = useLocationSearch();
  const [open, setOpen] = React.useState(false);
  const { data: categoryData, refetch } = useGetAllCategory();
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [search, setSearch] = useState(searchVal || '');
  const [searchedPresentation, setSearchedPresentation] = useState<string>(
    searchVal || '',
  );
  const [refresh, setRefresh] = useState(false);
  useHandleSearch(search);

  const { switchedOrgId } = useAuth();
  const handleChange = (event: SelectChangeEvent<typeof selectedCategory>) => {
    const {
      target: { value },
    } = event;
    setSelectedCategory(value);
  };

  useEffect(() => {
    refetch();
  }, [switchedOrgId]);

  const disableAddNew = useIsUserRole([UserRole.user, UserRole.dataEditor]);

  const searchPresentation = (searchTerm: string) => {
    setSearchedPresentation(searchTerm);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: 'Presentations',
            to: `${location?.pathname}${location?.search}`,
            description:
              'This page houses all created presentations, allowing users to build presentations using existing slides from the Slides page. Users can search and filter presentations based on assigned categories. They can also view the latest update details, including time, date, and person.',
          },
        ]}
      />
      <Stack>
        <FilterSelectWrapper>
          <TopContainer>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                disabled={disableAddNew}
                sx={{ marginRight: '15px' }}
              >
                Add new
              </Button>

              <NavLink to={`/categories`} style={{ textDecoration: 'none' }}>
                <Button variant="outlined" color="primary">
                  Manage Categories
                </Button>
              </NavLink>

              <RefreshButton onClick={() => setRefresh(!refresh)} />
            </div>
          </TopContainer>
          <div>
            <FilterSelect>
              <FormControl
                sx={{
                  '& > :not(style)': {
                    width: '30ch',
                    height: '50px',
                    paddingLeft: '10px',
                    fontSize: '14px',
                  },
                }}
              >
                <InputLabel id="category">Filter by Category</InputLabel>
                <Select
                  id="category"
                  name="category"
                  label="Filter by Category"
                  title="category"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categoryData?.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FilterSelect>
            <StyleTextfield
              label="Search Presentations"
              variant="outlined"
              onChange={(event) => {
                setSearch(event.target.value);
                debounce(
                  () => searchPresentation(event.target.value),
                  DEBOUNCE_TIME,
                )();
              }}
              value={search}
            />
          </div>
        </FilterSelectWrapper>

        <PresentationTable
          selectedCategory={selectedCategory}
          searchedPresentation={searchedPresentation}
          onRefresh={refresh}
        />
      </Stack>

      <DialogModal
        title="Add New Presentation"
        onClose={() => setOpen(false)}
        open={open}
      >
        <PresentationForm onFinished={() => setOpen(false)} />
      </DialogModal>
    </>
  );
};

export default PresentationPage;

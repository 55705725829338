import { FC, MouseEvent } from 'react';

import { v4 as uid } from 'uuid';
import { useEditorData } from '../../EditorProvider';
import { addElement } from '../../utils/surveyDataUtils';
import { InputType } from '../../types';

interface AddQuestionButtonProps {
  pageId: string;
}

const ButtonAddQuestion: FC<AddQuestionButtonProps> = ({ pageId }) => {
  const { history, setHistory, setSelectedItem } = useEditorData();

  const { present: surveyData } = history;

  const onClickHandle = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const elementId = uid();

    setSelectedItem({ pageId: pageId, elementId, type: InputType.SINGLE_LINE });

    const params = {
      surveyData,
      pageId,
      elementId,
      setHistory,
    };
    addElement(params);
  };

  return (
    <div
      className="svc-page__add-new-question svc-btn"
      tabIndex={0}
      onClick={onClickHandle}
    >
      <span className="svc-text svc-text--normal svc-text--bold">
        Add Question
      </span>
      <button
        type="button"
        className="svc-page__question-type-selector"
        title="Add Question"
        tabIndex={0}
      >
        <span className="svc-page__question-type-selector-icon">
          <svg
            className="sv-svg-icon"
            role="img"
            aria-label="Add Question"
            style={{ width: '24px', height: '24px' }}
          >
            <use xlinkHref="#icon-more" />
            <title>Add Question</title>
          </svg>
        </span>
        <div />
      </button>
    </div>
  );
};

export default ButtonAddQuestion;

import { FC } from 'react';
import Header from './components/Header';
import SidePanel from './components/SidePanel';
import styled from 'styled-components';
import { EditorProvider, EditorTooling, useTooling } from './lib/FabricJs';
import ToolBar from './components/Toolbar/Toolbar';
import FooterBar from './components/Footer/FooterBar';
import CanvasBody from './components/CanvasBody';
import { RealSize } from './types';
import { UpdateSlideTitle } from '../../types/slide.types';
import { Socket } from 'socket.io-client';

const Body = styled.div`
  background: white;
  display: flex;
  flex-grow: 1;
`;

const MainContainer = styled.div`
  background: #ebecf0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

// https://clubmate.fi/tabs#result-tabpanel-1

const GlobalStyle = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  * {
    font-family: 'roboto', sans-serif;
    box-sizing: border-box;

    button {
      border-radius: 4px;
      border: none;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      &:active {
        opacity: 0.7;
      }
    }
  }
`;

type CanvasEditorV2Props = RealSize & {
  onClickPreview: () => void;
  updateTitle?: UpdateSlideTitle;
  socket: Socket;
};

const CanvasEditorV2: FC<CanvasEditorV2Props> = ({
  realHeight,
  realWidth,
  onClickPreview,
  updateTitle,
  socket,
}) => {
  return (
    <EditorProvider>
      <GlobalStyle>
        <Header
          socket={socket}
          onClickPreview={onClickPreview}
          updateTitle={updateTitle}
        />
        <Body>
          <SidePanel />
          <MainContainer>
            <ToolBar />
            <CanvasBody realHeight={realHeight} realWidth={realWidth} />
            <FooterBar />
          </MainContainer>
        </Body>
      </GlobalStyle>
    </EditorProvider>
  );
};

export default CanvasEditorV2;

export { useTooling, EditorTooling };

import { useGetResponse } from '../../../api-http/formResponses';
import Breadcrumbs from '../../../components/Breadcrumb';
import getAlignment from '../../../components/survey-editor/utils/getAlignment';
import { prepareJSON } from '../../../components/survey-editor/utils/surveyDataUtils';
import { metaTitle } from '../../../util/tabTitle';
import { useSnack } from '../../../util/useSnack';
import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

const FormPreview = () => {
  metaTitle('Form Response Preview');

  const [formData, setFormData] = useState({});
  const [responseData, setResponseData] = useState({});
  const { showErrSnack } = useSnack();

  const { id } = useParams();

  const { data, isError, error, isLoading } = useGetResponse(id);

  useEffect(() => {
    if (isError) {
      showErrSnack(error.msg);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setFormData(prepareJSON(data.formJson));
      setResponseData(data.responseJson);
    }
  }, [data]);

  const survey = new Model(formData);

  survey.css = {
    question: {
      title: `${getAlignment(data?.formJson)} sv_question_title`,
      description: `${getAlignment(data?.formJson)} sv_question_description`,
      content: getAlignment(data?.formJson),
      contentleft: getAlignment(data?.formJson),
      mainRoot: 'sd-element sd-question sv-row__question',
    },
    rating: {
      itemSmiley: 'sv_rating_smiley_item',
    },
  };

  // Set the saved feedback data
  survey.data = responseData;

  // turned on read only mode
  survey.mode = 'display';

  useEffect(() => {
    const elements = document.getElementsByClassName(
      'sd-rating__item-star--disabled',
    );
    const elementsArray = Array.prototype.slice.call(elements);

    elementsArray.forEach((element) => {
      element.classList.remove('sd-rating__item-star--disabled');
      element.classList.add('sd-rating__item-star');
    });
  }, [survey]);

  return (
    <>
      <Breadcrumbs
        items={[
          { label: 'Forms', to: '/forms' },
          { label: 'Responses', to: `/forms/responses/${data?.id}` },
          {
            label: data?.name || 'Form Response',
            to: `/forms-preview/${id}`,
          },
        ]}
      />
      {isLoading && <LinearProgress />}

      <div>
        <h1>{data?.name || 'Survey Response'}</h1>
        <Survey model={survey} />
      </div>
    </>
  );
};

export default FormPreview;

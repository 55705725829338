import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import * as yup from 'yup';
import styled from 'styled-components';
import { AlertBar } from '../../components/AlertBar';
import { useUserUpdate } from '../../api-http/users';
import { MeResponse } from '../../api-http/auth';
import { Nullable } from '../../types';
import { formMsg, notifyMsg } from '../../configs';
import { useSnackbar } from 'notistack';
import { getApiError } from '../../api-http/apiService';
import { ERROR, SUCCESS } from '../../util/constant';

interface ProfileUpdateFormProps {
  onFinished: (formValues: any) => void;
  profileData: Nullable<MeResponse>;
}

const validationSchema = yup.object({
  name: yup.string().required(formMsg.NAME_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const ProfileUpdateForm: FC<ProfileUpdateFormProps> = ({
  onFinished,
  profileData,
}) => {
  const { isLoading, error, data, mutate, isSuccess, isError } =
    useUserUpdate();

  const [formValues, setFormValues] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?._id) {
      onFinished(formValues);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(notifyMsg.PROFILE_DETAILS_UPDATE_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      const errorMsg = getApiError(error);
      enqueueSnackbar(errorMsg, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError]);

  const formik = useFormik({
    initialValues: {
      name: profileData?.name || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFormValues(values);
      mutate({
        id: profileData?._id || '',
        name: values.name,
      });
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <Box mt={'10px'} />
        <Button
          color="primary"
          disabled={!formik.dirty || isLoading}
          variant="contained"
          fullWidth
          type="submit"
        >
          Update
        </Button>
      </form>
    </Container>
  );
};

import { SurveyJSON } from '../components/survey-editor/types';
import { PagingResponse } from '../types';
import { PaginateOptions } from '../types/pagination.types';
import { ApiServiceErr, DeleteBody, MutOptions, axiosApi } from './apiService';
import { useMutation, useQuery } from '@tanstack/react-query';

interface CreatedByResponse {
  updatedBy: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  email: string;
  role: string;
  organization: string;
}

export interface FormsResponse {
  _id: string;
  name: string;
  formBody: SurveyJSON;
  createdBy: CreatedByResponse;
  organization: string;
  device: [];
  attachedPresentations: [];
  createdAt: string;
  updatedAt: string;
}

type GetFormsResponse = FormsResponse;

export interface UpdateFormBody {
  _id: string;
  name?: string;
  formBody?: string;
}

export type FormsNameBody = Pick<FormsResponse, 'name'>;

export const useGetPaginateForms = ({
  limit,
  page,
  sortby,
  order,
  search = '',
}: PaginateOptions) =>
  useQuery<PagingResponse<GetFormsResponse>, ApiServiceErr>(
    ['/forms/all?', limit, page, sortby, order, search],
    async () => {
      const response = await axiosApi.get('/forms/all', {
        params: { limit, page, sortby, order, search },
      });
      return response.data;
    },
  );

export const useGetForm = (id: string) =>
  useQuery<FormsResponse, ApiServiceErr>(['forms/{id}', id], async () => {
    const response = await axiosApi.get(`/forms/${id}`);
    return response.data;
  });

export const useGetFormList = (search: string) =>
  useQuery<FormsResponse[], ApiServiceErr>(
    ['forms/form-list?search=', search],
    async () => {
      const response = await axiosApi.get(`forms/form-list?search=${search}`);

      return response.data;
    },
  );

export const useGetPublicForm = (id?: string) =>
  useQuery<FormsResponse, ApiServiceErr>(
    ['/forms/form-data/${id}', id],
    async () => {
      if (id) {
        const response = await axiosApi.get(`/forms/form-data/${id}`);
        return response.data;
      }
      return null;
    },
    {
      enabled: false,
    },
  );

export const useFormCreate = (opt?: MutOptions<FormsResponse>) =>
  useMutation<FormsResponse, ApiServiceErr, FormsNameBody>(async (data) => {
    const response = await axiosApi.post('/forms/create', data);
    return response.data;
  }, opt);

export const useDeleteForms = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/forms/delete/${data.id}`);
    return response.data;
  }, opt);

export const useUpdateForms = (opt?: MutOptions<FormsResponse>) =>
  useMutation<FormsResponse, ApiServiceErr, UpdateFormBody>(async (data) => {
    const response = await axiosApi.put(`/forms/update`, {
      id: data._id,
      name: data.name,
      formBody: data.formBody,
    });
    return response.data;
  }, opt);

import { RRule } from 'rrule';
import { SchedulerResponse } from '../types/scheduler.types';
import { EventResponse } from '../types/event.types';

const today = new Date();
const startOfTheDay = new Date(today.setHours(0, 0, 0, 0));
const endOfTheDay = new Date(today.setHours(23, 59, 59, 999));

const setTimeToCurrentDate = (time: string, cacheInterval: number) => {
  const currentDate = new Date();
  // eslint-disable-next-line prefer-const
  let [hours, mins, secs] = time.split(':').map(Number);

  // Convert hours to minutes, subtract cacheInterval, and calculate new hours and minutes
  let totalMinutes = hours * 60 + mins - cacheInterval;
  hours = Math.floor(totalMinutes / 60);
  mins = totalMinutes % 60;

  // Adjust for negative minutes which indicates a day change
  if (totalMinutes < 0) {
    // Adding 24 hours (1440 minutes) ensures we correctly handle negative values
    totalMinutes += 1440; // 24 hours * 60 minutes
    hours = Math.floor(totalMinutes / 60) % 24; // Ensure hours wrap correctly at 24
    mins = totalMinutes % 60;
  }

  currentDate.setHours(hours, mins, secs, 0);
  return currentDate;
};

const getEventSchedules = (
  event: EventResponse,
  startOfTheDay: Date,
  endOfTheDay: Date,
) => {
  const { presentationSchedule } = event;

  const rruleOptions = {
    ...presentationSchedule,
    dtstart: new Date(presentationSchedule.dtstart),
    ...(presentationSchedule.until && {
      until: new Date(presentationSchedule.until),
    }),
  };

  delete rruleOptions.bymonthday;
  const rule = new RRule(rruleOptions);
  return rule.between(startOfTheDay, endOfTheDay);
};

interface SchedulePresentationsProps {
  scheduler: SchedulerResponse;
  cacheInterval: number;
}

export default ({ scheduler, cacheInterval }: SchedulePresentationsProps) => {
  const { events } = scheduler;

  if (!events.length) {
    return scheduler.defaultPresentation;
  }

  for (const event of events) {
    const schedules = getEventSchedules(event, startOfTheDay, endOfTheDay);

    if (schedules.length) {
      for (let i = 0; i < schedules.length; i++) {
        const currentDateAndTime = new Date();
        const startDateAndTime = setTimeToCurrentDate(
          event.startTime,
          cacheInterval,
        );
        const endDateAndTime = setTimeToCurrentDate(event.endTime, 0);

        if (
          currentDateAndTime >= startDateAndTime &&
          currentDateAndTime <= endDateAndTime
        ) {
          return event.presentation;
        }
      }
    }
  }
};

import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import { metaTitle } from '../../util/tabTitle';
import { SchedulerTable } from './SchedulerTable';
import { ChangeEvent, FC, useState } from 'react';
import { Button, debounce, Stack } from '@mui/material';
import { StyleTextfield } from '../../components/TextField';
import { DEBOUNCE_TIME } from '../../configs';
import Breadcrumbs from '../../components/Breadcrumb';
import { useIsUserRole } from '../../util/useIsUserRole';
import { UserRole } from '../../types/user.types';
import { AddSchedulerModal } from './components/AddSchedulerModal';
import RefreshButton from '../../components/RefreshButton';
import { useHandleSearch } from '../../util/useHandleSearch';
import { useLocationSearch } from '../../util/useLocationSearch';

const SchedulerPage: FC = () => {
  metaTitle('Scheduler');

  const searchVal = useLocationSearch();
  const [search, setSearch] = useState(searchVal || '');
  const [schedulerSearchTxt, setSchedulerSearchTxt] = useState(searchVal || '');
  const [refreshTable, setRefreshTable] = useState(false);
  const [open, setOpen] = useState(false);
  useHandleSearch(search);

  const setSearchText = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSchedulerSearchTxt(event.target.value);
  };

  const disableAddNew = useIsUserRole([
    UserRole.user,
    UserRole.layoutEditor,
    UserRole.dataEditor,
  ]);

  return (
    <>
      <Stack>
        <Breadcrumbs
          items={[
            {
              label: 'Schedulers',
              to: '/scheduler',
              description:
                'This page showcases all the presentation schedules. Each scheduler consists of multiple events, which represent specific time frames allocated for presentations. Schedulers are assigned to devices, and based on the events within the schedule, the device will display the corresponding presentations accordingly.',
            },
          ]}
        />
        <FilterSelectWrapper>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              style={{ marginBottom: 0 }}
              disabled={disableAddNew}
            >
              Add New
            </Button>

            <RefreshButton onClick={() => setRefreshTable(!refreshTable)} />
          </div>
          <div>
            <StyleTextfield
              label={'Search Scheduler'}
              variant={'outlined'}
              onChange={(event) => {
                setSearch(event.target.value);
                debounce(() => setSearchText(event), DEBOUNCE_TIME)();
              }}
              value={search}
            />
          </div>
        </FilterSelectWrapper>
      </Stack>

      <SchedulerTable
        refresh={refreshTable}
        schedulerSearchTxt={schedulerSearchTxt as string}
      />
      <AddSchedulerModal onClose={() => setOpen(false)} open={open} />
    </>
  );
};

export default SchedulerPage;

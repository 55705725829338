import { FC, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import { DialogModal } from '../../../components';
import { useGetPresentationKvsData } from '../../../api-http/presentations';
import { AlertBar } from '../../../components/AlertBar';
import { formMsg, notifyMsg } from '../../../configs';
import { useUpdateKeyValue } from '../../../api-http/kvs';
import { useSnack } from '../../../util/useSnack';

interface EditKvsValuesModalProps {
  id: string;
  open: boolean;
  onClose: (open: boolean) => void;
  refetch: (id: string) => void;
}

interface Column {
  id: 'kvsGroup' | 'key' | 'value' | 'slideCount';
  label: string;
  minWidth?: number;
  align?: 'left';
}

type KvsStateType = { [key: string]: { value: string; edited: boolean } };

const columns: readonly Column[] = [
  { id: 'kvsGroup', label: 'Group', minWidth: 170 },
  { id: 'key', label: 'Key', minWidth: 100 },
  {
    id: 'value',
    label: 'Value',
    minWidth: 320,
    align: 'left',
  },
  {
    id: 'slideCount',
    label: 'Used',
    minWidth: 170,
    align: 'left',
  },
];

const EditKvsValuesModal: FC<EditKvsValuesModalProps> = ({
  id,
  open,
  onClose,
  refetch,
}) => {
  const { showErrSnack, showSuccessSnack } = useSnack();

  const [kvsValues, setKvsValues] = useState<KvsStateType>({});

  const {
    data: kvsData,
    error: kvsDataError,
    isLoading: isKvsDataLoading,
  } = useGetPresentationKvsData(id, open);
  const { mutate: updateValue, error } = useUpdateKeyValue({
    onSuccess: (data) => {
      showSuccessSnack(notifyMsg.KVS_KEY_VALUE_UPDATE_SUCCESS);
      const values = kvsValues;
      values[data._id].edited = false;
      setKvsValues(values);
      refetch(id);
    },
    onError: () => {
      showErrSnack(notifyMsg.KVS_KEY_VALUE_UPDATE_FAILED);
    },
  });

  useEffect(() => {
    if (kvsData) {
      const data = {} as KvsStateType;
      kvsData.map(({ kvsId, value }) => {
        data[kvsId] = { value, edited: false };
      });
      setKvsValues(data);
    }
  }, [kvsData]);

  const handleSubmit = (_id: string, key: string, value: string) => {
    updateValue({
      _id,
      key,
      value,
    });
  };

  return (
    <DialogModal
      open={open}
      onClose={() => onClose(false)}
      width={'60%'}
      title="Edit KVS Values"
    >
      <small style={{ color: 'gray' }}>
        Note: Changes in KVS values here are permanent and will update in every
        place.
      </small>
      <Divider />
      <AlertBar
        style={{ marginBottom: '10px' }}
        severity="error"
        msg={error?.msg}
      />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {kvsData
              ?.sort((a, b) => a.key.localeCompare(b.key))
              .map((row, index) => {
                const { kvsId, kvsGroup, key, slideCount } = row;
                const isEmpty = kvsValues[kvsId]?.value === '';

                return (
                  <TableRow hover key={kvsId}>
                    <TableCell key={'kvsGroup'} align={'left'}>
                      {kvsGroup}
                    </TableCell>
                    <TableCell key={'key'} align={'left'}>
                      {key}
                    </TableCell>
                    <TableCell key={'value'} align={'left'}>
                      <TextField
                        id={`formKeyValues.${index}.value`}
                        name={`formKeyValues.${index}.value`}
                        required
                        size="small"
                        value={kvsValues?.[kvsId]?.value || ''}
                        onChange={(e: any) => {
                          const data = JSON.parse(JSON.stringify(kvsValues));
                          data[kvsId] = { value: e.target.value, edited: true };
                          setKvsValues(data);
                        }}
                        error={isEmpty}
                        helperText={isEmpty && formMsg.VALUE_REQUIRED}
                      />
                      {kvsValues?.[kvsId]?.edited && (
                        <Button
                          disabled={isEmpty}
                          variant="text"
                          onClick={() =>
                            handleSubmit(kvsId, key, kvsValues[kvsId]?.value)
                          }
                        >
                          Save
                        </Button>
                      )}
                    </TableCell>
                    <TableCell key={'Used'} align={'left'}>
                      {slideCount}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {kvsDataError && <AlertBar severity="error" msg={kvsDataError.msg} />}

      {isKvsDataLoading && <LinearProgress />}
    </DialogModal>
  );
};

export default EditKvsValuesModal;

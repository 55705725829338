import { Button } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { UserFocusData } from '../../types';
import { removeComponent } from '../../utils/surveyDataUtils';

export interface RemoveButtonProps {
  surveyData: any;
  pageId: string;
  elementId: string;
  setHistory: (history: any) => void;
  setSelectedItem: (data: UserFocusData) => void;
}

export const RemoveButton: FC<RemoveButtonProps> = ({
  surveyData,
  pageId,
  elementId,
  setHistory,
  setSelectedItem,
}) => {
  const onRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const params = {
      surveyData,
      pageId,
      elementId,
      setHistory,
      setSelectedItem,
    };
    removeComponent(params);
  };
  return (
    <div
      style={{
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
      }}
    >
      <Button
        size="small"
        style={{
          backgroundColor: '#e74c3c',
          color: 'white',
          fontWeight: 'bold',
          padding: '5px',
          width: '90px',
        }}
        sx={{
          color: 'black',
          fontFamily: 'roboto, sans-serif',
          textTransform: 'none',
        }}
        onClick={onRemove}
      >
        Remove
      </Button>
    </div>
  );
};

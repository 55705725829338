export enum ShapeType {
  Circle = 'circle',
  Rectangle = 'rect',
}

interface RealSize {
  realHeight: number;
  realWidth: number;
}

type ElementType = {
  [key: string]: string[];
};

interface MainWrapperProps {
  scaleX: number;
  scaleY: number;
  backGround: string;
}

type ForecastData = {
  date: string;
  description: string;
  icon: string;
  temperature: { min: number; max: number };
  wind: number;
  humidity: number;
};

type GeoCoordinatesData = {
  latitude: number;
  longitude: number;
};

type WidgetData = {
  name: string;
  current: {
    date: string;
    description: string;
    icon: string;
    temperature: { current: number; min: number; max: number };
    wind: number;
    humidity: number;
  };
  forecasts: ForecastData[];
};

export type {
  RealSize,
  ElementType,
  MainWrapperProps,
  ForecastData,
  GeoCoordinatesData,
  WidgetData,
};

import React from 'react';
import { Button } from '@mui/material';
import { Refresh } from '@mui/icons-material';

interface RefreshButtonProps {
  onClick: () => void;
}

const RefreshButton: React.FC<RefreshButtonProps> = ({ onClick }) => {
  const defaultButtonStyle = {
    style: { margin: '0px 4px', minWidth: 32 },
    sx: {
      textTransform: 'none',
    },
  };

  return (
    <Button {...defaultButtonStyle} onClick={onClick} size="small">
      <Refresh />
    </Button>
  );
};

export default RefreshButton;

import { FC, useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';
import NodeCache from 'node-cache';
import CircularProgress from '@mui/material/CircularProgress';

import { APPEARANCE_MAP } from '../constants/weather';
import { MainWrapperProps } from '../../../types';
import { useGetWeatherData } from '../../../../../api-http/weather';
import { Typography } from '@mui/material';

const TIME_INTERVAL = 1000 * 60 * 60 * 3; // 3 hours

const MainWrapper = styled.div<MainWrapperProps>`
  width: 1440px;
  height: 788px;
  padding: 20px;
  box-sizing: border-box;
  transform: ${({ scaleX, scaleY }) =>
    `scale(${scaleX}, ${scaleY}) translate(0, 0)`};
  transform-origin: left top;
  background-size: cover;
  background-position: center;
  background-image: ${({
    backGround,
  }) => `linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .5)),
  url(${backGround})`};
`;

const ContentWrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  color: white;
  width: 100%;
  height: 100%;
`;

const UpperContent = styled.div`
  font-size: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 60%;
  text-shadow: 10px 2px black;
`;

const LowerContent = styled.div`
  display: flex;
  height: 40%;
  font-size: 40px;
  text-shadow: 5px 2px black;
  font-weight: 600;
`;

const ForeCastBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
  box-sizing: border-box;
  padding: 15px 15px;
  * {
    margin-bottom: 30px;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledSVG = styled.svg`
  fill: ${({ color }) => color};
  filter: drop-shadow(11px 4px 0px rgba(0, 0, 0, 0.8));
`;

//ttl 3 hours and check period 30 minutes
const weatherCache = new NodeCache({
  stdTTL: 60 * 60 * 1,
  checkperiod: 60 * 30,
});

const WeatherWidget2: FC = () => {
  const mainWrapperRef = useRef<HTMLDivElement>(null);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  const { data, isLoading, error, refetch } = useGetWeatherData({
    city: weatherCache.get('city'),
  });

  const handleResize = () => {
    if (mainWrapperRef.current) {
      const width = 1440;
      const height = 788;
      setScaleX(window.innerWidth / width);
      setScaleY(window.innerHeight / height);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => handleResize(), [mainWrapperRef.current, data]);

  useEffect(() => {
    if (data) {
      weatherCache.set('city', data.name);
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => refetch(), TIME_INTERVAL); //runs every 3 hours

    return () => clearInterval(interval);
  }, []);

  const WidgetContent = useMemo(() => {
    if (!data) return null;

    const {
      name,
      current: {
        temperature: { current: currentTemp },
        description,
        icon: climateCode,
      },
      forecasts,
    } = data;
    const { backGround, icon } = APPEARANCE_MAP[climateCode];

    return (
      <MainWrapper
        ref={mainWrapperRef}
        scaleX={scaleX}
        scaleY={scaleY}
        backGround={backGround}
      >
        <ContentWrapper>
          <UpperContent>
            <span>{name}</span>
            <span>{`${Math.round(currentTemp || 0)} °C`}</span>

            <StyledSVG
              color={'white'}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-0.0010007 3.158 37.03 24.28"
              width="130px"
              height="130px"
            >
              <title>{description}</title>
              <path d={icon || ''} />
            </StyledSVG>
          </UpperContent>
          <LowerContent>
            {forecasts.map((forecast, index) => {
              const {
                date,
                temperature: { min, max },
                icon: forecastClimateCode,
                description,
              } = forecast;
              const { icon } = APPEARANCE_MAP[forecastClimateCode];
              return (
                <ForeCastBlock key={index}>
                  <span>{date}</span>
                  <StyledSVG
                    color={'white'}
                    width="80px"
                    height="80px"
                    viewBox="-0.0010007 3.158 37.03 24.28"
                  >
                    <title>{description}</title>
                    <path d={icon || ''} />
                  </StyledSVG>
                  <span>{`${Math.floor(min)}/${Math.ceil(max)} °C`}</span>
                </ForeCastBlock>
              );
            })}
          </LowerContent>
        </ContentWrapper>
      </MainWrapper>
    );
  }, [data, scaleX, scaleY]);

  // eslint-disable-next-line no-nested-ternary
  return isLoading ? (
    <CenteredContainer>
      <CircularProgress />
    </CenteredContainer>
  ) : error ? (
    <CenteredContainer>
      <Typography>Something went wrong</Typography>
    </CenteredContainer>
  ) : (
    WidgetContent
  );
};

export default WeatherWidget2;

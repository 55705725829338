import React, { FC, useEffect, useState } from 'react';
import { useGetSchedulersEvents } from '../../api-http/event';
import { useSchedulerEvents } from '../../api-http/scheduler';
import { AlertBar } from '../../components/AlertBar';
import { formMsg, notifyMsg } from '../../configs';
import { FrequencyEnum } from '../../types/event.types';
import { eventEndTimeValidation } from '../../util/dateTime';
import { rRuleGenerator } from '../../util/rRule';
import { useSnack } from '../../util/useSnack';
import {
  Button,
  InputLabel,
  SelectChangeEvent,
  Stack,
  TextField,
  FormControl,
  Autocomplete,
  debounce,
} from '@mui/material';
import { Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import { Frequency, RRule } from 'rrule';
import { Weekday } from 'rrule/dist/esm';
import { parseString } from 'rrule/dist/esm/parsestring';
import styled from 'styled-components';
import {
  GetOrganizationPresentations,
  useGetPresentationsWithSearch,
} from '../../api-http/presentations';
import {
  DAY_FREQUENCY,
  FREQUENCY,
  MONTHS,
  MONTH_DATES,
  WEEK_DAYS,
} from '../scheduler/constant';
import { Select } from '@mui/material';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useTable } from '../../util/useTable';
import { DEBOUNCE_TIME } from '../../configs';
import FormErrorText from '../../components/FormErrorText';
import getMinDate from '../../util/getMinDate';

interface DayContainerProps {
  selected?: boolean;
}

const MIN_DATE = getMinDate();

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

const CustomDaysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const DayContainer = styled.div<DayContainerProps>`
  background-color: ${(props) => (props.selected ? '#1f77df' : '#e1e1e1')};
  color: ${(props) => (props.selected ? 'white' : 'black')};
  cursor: pointer;
  border-radius: 100px;
  margin-right: 6px;
  height: 40px;
  width: 40px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DateContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

interface PresentationSchedularFormProps {
  onFinished: () => void;
  schedulerId: string;
}

const validationSchema = Yup.object().shape({
  presentation: Yup.mixed().required(formMsg.EVENT_PRESENTATION_REQUIRED),
  frequency: Yup.string().required(formMsg.EVENT_FREQUENCY_REQUIRED),
  startDate: Yup.date()
    .min(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      formMsg.EVENT_START_DATE_CANNOT_BE_PAST,
    )
    .required(formMsg.EVENT_START_DATE_REQUIRED),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), formMsg.EVENT_END_DATE_CANNOT_BE_LOWER)
    .required(formMsg.EVENT_END_DATE_REQUIRED),
  startTime: Yup.string().required(formMsg.EVENT_START_TIME_REQUIRED),
  endTime: Yup.string()
    .required(formMsg.EVENT_END_TIME_REQUIRED)
    .test(
      'end time greater than start time',
      formMsg.EVENT_END_TIME_GREATER_THAN_START_TIME,
      eventEndTimeValidation(),
    ),
});

export const PresentationSchedularForm: FC<PresentationSchedularFormProps> = ({
  onFinished,
  schedulerId,
}) => {
  const params = useParams<{ id: string }>();
  const [searchText, setSearchText] = useState('');

  const { data: presentationData, refetch: refetchPresentation } =
    useGetPresentationsWithSearch(searchText);

  useEffect(() => {
    refetchPresentation();
  }, [searchText]);

  const { pagingParams } = useTable();
  const { refetch } = useGetSchedulersEvents({
    ...pagingParams,
    scheduler: params.id,
  });

  const { showErrSnack, showSuccessSnack } = useSnack();
  const { mutate, isLoading, error } = useSchedulerEvents({
    onSuccess: () => {
      refetch();
      showSuccessSnack(notifyMsg.EVENT_CREATE_SUCCESS);
      onFinished();
    },
    onError: () => {
      showErrSnack(notifyMsg.EVENT_CREATE_FAILED);
    },
  });
  const [radioSelect, setRadioSelect] = useState('');
  const [frequency, setFrequency] = useState('');
  const [rRuleFrequency, setRRuleFrequency] = useState<Frequency>(RRule.DAILY);
  const [selectedDays, setSelectedDays] = useState<any[]>();
  const [weekDays, setWeekDays] = useState<Weekday[]>();
  const [dayFrequency, setDayFrequency] = useState('1');
  const [fullDay, setFullDay] = useState<Weekday>(RRule.MO);
  const [selectMonth, setSelectMonth] = useState('1');
  const [selectDate, setSelectDate] = useState('1');

  const handleDaySelector = (dayId: number) => {
    if (selectedDays !== undefined && selectedDays.includes(dayId)) {
      setSelectedDays(selectedDays.filter((id) => id !== dayId));
    } else {
      setSelectedDays(
        selectedDays !== undefined ? [...selectedDays, dayId] : [dayId],
      );
      {
        const selectedDay = WEEK_DAYS.find((value) => value.id === dayId);
        if (selectedDay !== undefined) {
          setWeekDays([
            ...(weekDays !== undefined ? weekDays : []),
            selectedDay.value,
          ]);
        }
      }
    }
  };

  const handleFrequencyChange = (event: SelectChangeEvent) => {
    setFrequency(event.target.value);
    if (frequency !== undefined) {
      setRRuleFrequency(
        FREQUENCY.filter((value) => value.frequency === event.target.value)?.[0]
          .value,
      );
    }
  };

  const daySelectorHandle = (event: SelectChangeEvent<Weekday>) => {
    const selectedDay = event.target.value;
    const matchingDay = WEEK_DAYS.find((value) => value.day === selectedDay);

    if (matchingDay) {
      setFullDay(matchingDay.value);
    }
  };

  const formik = useFormik({
    initialValues: {
      presentation: '',
      scheduler: schedulerId,
      presentationSchedule: null,
      frequency: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const {
        presentation,
        scheduler,
        frequency,
        startDate,
        endDate,
        startTime,
        endTime,
      } = values;

      const rule = await rRuleGenerator({
        frequency: frequency,
        rRuleFrequency,
        startDate: startDate,
        endDate: endDate,
        selectedDays,
        weekDays,
        dayFrequency,
        fullDay,
        radioSelect,
        selectMonth,
        selectDate,
      });

      if (rule) {
        const presentationScheduleRule = JSON.stringify(
          parseString(rule.toString()),
        );

        mutate({
          presentation: presentation,
          scheduler,
          presentationSchedule: presentationScheduleRule,
          startTime: startTime.padEnd(startTime.length + 3, ':00'),
          endTime: endTime.padEnd(endTime.length + 3, ':00'),
        });
        onFinished();
      }
    },
  });

  const selectedDate = `On Day ${formik.values.startDate.slice(8, 10)}`;
  const SelectedPresentation =
    presentationData?.find(
      (option) => option?._id === formik.values.presentation,
    ) || null;

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              id="presentation"
              options={presentationData || []}
              getOptionLabel={(option) =>
                (option as GetOrganizationPresentations)?.name || ''
              }
              value={
                SelectedPresentation?.name as
                  | GetOrganizationPresentations
                  | null
                  | undefined
              }
              onChange={(
                _event,
                value: GetOrganizationPresentations | null | undefined,
              ) => {
                if (value) {
                  formik.setFieldValue('presentation', value._id);
                } else {
                  formik.setFieldValue('presentation', null);
                  setSearchText('');
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a presentation"
                  onChange={debounce(
                    (event) => setSearchText(event.target.value),
                    DEBOUNCE_TIME,
                  )}
                  error={
                    formik.touched.presentation &&
                    Boolean(formik.errors.presentation)
                  }
                />
              )}
            />
          </FormControl>
          {Boolean(formik.errors.presentation) && (
            <FormErrorText
              formikTouched={formik.touched.presentation}
              formikErrors={formik.errors.presentation}
            />
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateContainer>
              <TextField
                type="date"
                sx={{ width: '100%' }}
                inputProps={{
                  min: MIN_DATE,
                }}
                id="startDate"
                name="startDate"
                title="startDate"
                label="Start Date"
                value={formik.values.startDate}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
              />

              <TextField
                type="date"
                sx={{ width: '100%' }}
                inputProps={{
                  min: MIN_DATE,
                }}
                id="endDate"
                name="endDate"
                title="endDate"
                label="End Date"
                value={formik.values.endDate}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
              />
            </DateContainer>
            <FormControl fullWidth>
              <InputLabel id="frequency">Select a Frequency</InputLabel>
              <Select
                fullWidth
                id="frequency"
                name="frequency"
                label="Select a Frequency"
                title="frequency"
                value={formik.values.frequency}
                onChange={(event) => {
                  formik.handleChange(event);
                  handleFrequencyChange(event);
                }}
                error={
                  formik.touched.frequency && Boolean(formik.errors.frequency)
                }
              >
                {FREQUENCY.map((option) => (
                  <MenuItem key={option.frequency} value={option.frequency}>
                    {option.frequency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {Boolean(formik.errors.frequency) && (
              <FormErrorText
                formikTouched={formik.touched.frequency}
                formikErrors={formik.errors.frequency}
              />
            )}

            {(formik.values.frequency === FrequencyEnum.CUSTOM ||
              formik.values.frequency === FrequencyEnum.WEEKLY) && (
              <div>
                <CustomDaysContainer>
                  {WEEK_DAYS.map((day) => (
                    <DayContainer
                      key={day.id}
                      onClick={() => handleDaySelector(day.id)}
                      selected={selectedDays?.includes(day.id) ?? false}
                    >
                      {day.day}
                    </DayContainer>
                  ))}
                </CustomDaysContainer>
              </div>
            )}
            {formik.values.frequency === FrequencyEnum.MONTHLY && (
              <div>
                <FormControl>
                  <FormLabel id="MonthDaySelector">Repeat</FormLabel>
                  <RadioGroup
                    aria-labelledby="MonthDaySelector"
                    name="MonthDaySelector"
                    value={radioSelect || selectedDate}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setRadioSelect(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={selectedDate}
                      control={<Radio />}
                      label={selectedDate}
                    />
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <FormControlLabel
                        value="On the"
                        control={<Radio />}
                        label="On the"
                      />
                      <FormControl>
                        <Select
                          id="weekSelector"
                          value={dayFrequency}
                          name="dayFrequency"
                          onChange={(e) => setDayFrequency(e.target.value)}
                          sx={{ width: '150px' }}
                          disabled={
                            radioSelect === selectedDate ||
                            radioSelect !== 'On the'
                          }
                        >
                          {DAY_FREQUENCY.map(({ dayFrequency, value }) => (
                            <MenuItem
                              key={dayFrequency}
                              value={value}
                              defaultValue={'First'}
                            >
                              {dayFrequency}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl>
                        <Select
                          id="daySelector"
                          name="fullDay"
                          value={fullDay}
                          onChange={(event) => daySelectorHandle(event)}
                          sx={{ width: '150px' }}
                          disabled={
                            radioSelect === selectedDate ||
                            radioSelect !== 'On the'
                          }
                        >
                          {WEEK_DAYS.map(
                            ({ fullDay, day }) =>
                              fullDay && (
                                <MenuItem
                                  key={fullDay}
                                  value={day}
                                  defaultValue={'Monday'}
                                >
                                  {fullDay}
                                </MenuItem>
                              ),
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {formik.values.frequency === FrequencyEnum.YEARLY && (
              <div>
                <FormControl>
                  <FormLabel id="selectMonth">Repeat</FormLabel>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <FormControl>
                      <Select
                        id="selectMonth"
                        name="selectMonth"
                        value={selectMonth}
                        onChange={(e) => setSelectMonth(e.target.value)}
                        sx={{ width: '150px' }}
                      >
                        {MONTHS.map(({ month, id }) => (
                          <MenuItem key={month} value={id}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        id="selectDate"
                        name="selectDate"
                        value={selectDate}
                        onChange={(e) => setSelectDate(e.target.value)}
                        sx={{ width: '150px' }}
                      >
                        {MONTH_DATES.map(({ monthDate, id }) => (
                          <MenuItem key={monthDate} value={id}>
                            {monthDate}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </FormControl>
              </div>
            )}

            <DateContainer>
              <TextField
                type="time"
                sx={{ width: '100%' }}
                id="startTime"
                name="startTime"
                title="startTime"
                label="Start time"
                value={formik.values.startTime}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                error={
                  formik.touched.startTime && Boolean(formik.errors.startTime)
                }
                helperText={formik.touched.startTime && formik.errors.startTime}
              />

              <TextField
                type="time"
                sx={{ width: '100%' }}
                id="endTime"
                name="endTime"
                title="endTime"
                label="End time"
                value={formik.values.endTime}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                helperText={formik.touched.endTime && formik.errors.endTime}
              />
            </DateContainer>
          </LocalizationProvider>

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
          >
            Save
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

import debounce from '@mui/material/utils/debounce';
import { fabric } from 'fabric';
import { DEBOUNCE_TIME } from '../../../../../configs/constance';
const INITIAL_WIDTH = 640;
const INITIAL_HEIGHT = 360;
const INITIAL_LEFT = 80;
const INITIAL_TOP = 100;

export const getDimensions = (
  canvas?: fabric.Canvas | null,
  obj?: fabric.Object,
  propRatio?: number,
) => {
  const ratio = propRatio || canvas?.getZoom() || 1;
  const realWidth =
    (obj?.width && obj.scaleX && obj?.width * obj.scaleX) || INITIAL_WIDTH;
  const realHeight =
    (obj?.height && obj.scaleY && obj?.height * obj.scaleY) || INITIAL_HEIGHT;
  const realLeft = obj?.left || INITIAL_LEFT;
  const realTop = obj?.top || INITIAL_TOP;

  const width = realWidth * ratio;
  const height = realHeight * ratio;
  const left = realLeft * ratio;
  const top = realTop * ratio;

  return {
    ratio,
    realWidth,
    realHeight,
    realLeft,
    realTop,
    width,
    height,
    left,
    top,
  };
};

export const handleRatio = (
  iframeDimensions: {
    width: number;
    height: number;
    left: number;
    top: number;
  },
  element: HTMLElement & { width?: string; height?: string },
) => {
  const { width, height, left, top } = iframeDimensions;
  element.style.cssText += `width: ${width}px; height: ${height}px; left: ${left}px; top: ${top}px;`;
  element.width = `${width}`;
  element.height = `${height}`;
};

export const dragElement = (
  element: HTMLDivElement,
  iframe: HTMLIFrameElement,
  canvas: fabric.Canvas | null,
  handleHistory?: () => void,
) => {
  if (!element) {
    return;
  }
  let positionX = 0,
    positionY = 0,
    activeObject = null as fabric.Object | null;

  const dragMouseDown = (e: any) => {
    const canvasObjects = canvas?.getObjects() || [];
    const iframeObject = canvasObjects.find(
      (canvasObject) => canvasObject.data?.className === iframe.className,
    );
    if (iframeObject) {
      activeObject = iframeObject;
      canvas?.setActiveObject(activeObject);
    }
    if (iframeObject?.lockMovementX) return null;
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    positionX = e.clientX;
    positionY = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  };

  const dragArrowDown = (e: any) => {
    const canvasObjects = canvas?.getActiveObjects() || [];

    const iframeObject = canvasObjects.find(
      (canvasObject) => canvasObject.data?.className === iframe.className,
    );

    if (iframeObject) {
      activeObject = iframeObject;
      canvas?.setActiveObject(activeObject);
    }

    if (iframeObject?.lockMovementX) return null;
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    if (activeObject) {
      positionX = parseInt(`${activeObject.left}`, 10);
      positionY = parseInt(`${activeObject.top}`, 10);
      document.addEventListener('keydown', handleArrowKeys);
    }
  };

  element.onmousedown = dragMouseDown;
  document.addEventListener('click', dragArrowDown);

  const elementDrag = (e: any) => {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    const { clientX, clientY } = e;
    const deltaX = positionX - clientX;
    const deltaY = positionY - clientY;
    positionX = clientX;
    positionY = clientY;
    // set the element's new position:
    elementNewPosition(deltaX, deltaY);
  };

  const handleArrowKeys = (e: any) => {
    const arrowStep = 1; // Adjust this value as needed
    let deltaX = 0;
    let deltaY = 0;

    if (e.key === 'ArrowRight') {
      deltaX = -arrowStep;
    } else if (e.key === 'ArrowLeft') {
      deltaX = arrowStep;
    }
    if (e.key === 'ArrowDown') {
      deltaY = -arrowStep;
    } else if (e.key === 'ArrowUp') {
      deltaY = arrowStep;
    }

    positionX += deltaX;
    positionY += deltaY;

    // Update element and iframe positions
    elementNewPosition(deltaX, deltaY);
    // handleHistory
    handleHistory && debounce(handleHistory, DEBOUNCE_TIME);
    element.onmousedown = closeElement;
  };

  const closeElement = () => {
    activeObject = null;
    document.removeEventListener('keydown', handleArrowKeys);
    document.removeEventListener('click', dragArrowDown);
  };

  const elementNewPosition = (deltaX: number, deltaY: number) => {
    element.style.top = `${element.offsetTop - deltaY}px`;
    element.style.left = `${element.offsetLeft - deltaX}px`;
    iframe.style.top = `${element.offsetTop - deltaY}px`;
    iframe.style.left = `${element.offsetLeft - deltaX}px`;

    if (activeObject && element.offsetTop && element.offsetLeft) {
      const ratio = canvas?.getZoom() || 1;
      activeObject.set({
        left: (element.offsetLeft - deltaX) / ratio,
        top: (element.offsetTop - deltaY) / ratio,
      });
    }
  };

  const closeDragElement = () => {
    /* stop moving when mouse button is released:*/
    if (activeObject) {
      // @ts-ignore;
      activeObject.auto = false;
      handleHistory && handleHistory();
    }
    document.onmouseup = null;
    document.onmousemove = null;
    activeObject = null;
  };
};

import React, { FC, useState } from 'react';

import { Button } from '@mui/material';

import { DialogModal } from '../../components';
import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { CategoryForm } from './CategoryForm';
import CategoryTable from './CategoryTable';
import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import Breadcrumbs from '../../components/Breadcrumb';
import RefreshButton from '../../components/RefreshButton';

const CategoryPage: FC = () => {
  metaTitle('Categories');
  const [open, setOpen] = useState(false);

  const disableAddNew = useIsUserRole([
    UserRole.user,
    UserRole.layoutEditor,
    UserRole.dataEditor,
  ]);

  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <Breadcrumbs
        items={[
          { label: 'Presentations', to: '/presentations' },
          {
            label: 'Categories',
            to: '/categories',
            description:
              'This page displays all the categories available for classifying presentations. Categories allow users to easily filter presentations in the presentations list based on their classification.',
          },
        ]}
      />
      <FilterSelectWrapper>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            disabled={disableAddNew}
          >
            Add new
          </Button>

          <RefreshButton onClick={() => setRefresh(!refresh)} />
        </div>
      </FilterSelectWrapper>
      <CategoryTable onRefresh={refresh} />
      <DialogModal
        title="Add New Category"
        onClose={() => setOpen(false)}
        open={open}
      >
        <CategoryForm onFinished={() => setOpen(false)} />
      </DialogModal>
    </>
  );
};

export default CategoryPage;

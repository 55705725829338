import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const NotAssignPage = () => {
  return (
    <StyledBox>
      <Typography variant="h1" align="center" fontWeight="bold">
        Oops!
      </Typography>
      <Typography variant="h4" align="center">
        No scheduler or form assigned.
      </Typography>
    </StyledBox>
  );
};

export default NotAssignPage;

import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { BBC_NEWS_URL, CNN_NEWS_URL } from '../../../../../configs';
import { NEWS_TYPES } from '../../../config';
import { CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ItemContainer = styled.div`
  position: relative;
  max-height: 100vh;
  text-align: center;
`;

const ContentContainer = styled.div`
  position: absolute;
  color: black;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
`;

const News = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [newsItems, setNewsItems] = useState([
    { image: '', title: '', content_text: '' },
  ]);
  const { newsType } = useParams();
  const responsive = {
    0: {
      items: 1,
    },
    512: {
      items: 1,
    },
  };

  const fetchNews = async () => {
    let newsUrl = '';
    setError('');

    if (newsType === NEWS_TYPES.BBC) {
      newsUrl = BBC_NEWS_URL;
    } else if (newsType === NEWS_TYPES.CNN) {
      newsUrl = CNN_NEWS_URL;
    }
    setIsLoading(true);
    const newsResponse = await fetch(newsUrl);

    if (!newsResponse.ok) {
      setError('Could not load data!');
    } else {
      const newsList = await newsResponse.json();
      setNewsItems(newsList.items);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchNews();

    const interval = setInterval(() => fetchNews(), 1000 * 60 * 60 * 1); //runs every 1 hours

    return () => clearInterval(interval);
  }, []);

  const items = newsItems.map((newsItem, key) => {
    return (
      <ItemContainer key={key}>
        <img
          src={newsItem?.image}
          alt={newsItem?.title}
          style={{ width: '100%' }}
        />

        <ContentContainer>
          <Typography gutterBottom style={{ fontSize: '4vw' }}>
            {newsItem?.title}
          </Typography>

          <Typography
            variant="subtitle1"
            style={{
              fontSize: '2vw',
              paddingLeft: '50px',
              paddingRight: '50px',
            }}
          >
            {newsItem?.content_text}
          </Typography>
        </ContentContainer>
      </ItemContainer>
    );
  });

  /*eslint no-nested-ternary: 0*/
  return isLoading ? (
    <CenteredContainer>
      <CircularProgress />
    </CenteredContainer>
  ) : error ? (
    <CenteredContainer>
      <Typography gutterBottom style={{ fontSize: '4vw' }}>
        {error}
      </Typography>
    </CenteredContainer>
  ) : (
    <Container>
      <AliceCarousel
        infinite
        autoPlayInterval={10000}
        animationDuration={1500}
        disableDotsControls
        disableButtonsControls
        responsive={responsive}
        autoPlay
        mouseTracking={false}
        items={items}
      />
    </Container>
  );
};

export default News;

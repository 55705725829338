import { FC } from 'react';
import { Tooltip } from '@mui/material';
import { Lock, Unlock } from 'react-feather';
import { useHandleObjectLock } from '../lib/FabricJs/hooks/useHandleObjectLock';
import ButtonComponent from './common/ButtonComponent';

const LockComponent: FC = () => {
  const { handleObjectLock, isLocked } = useHandleObjectLock();
  if (isLocked) {
    return (
      <Tooltip title="Unlock selected items.">
        <ButtonComponent onClick={() => handleObjectLock()}>
          <Lock color="red" />
        </ButtonComponent>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Lock selected items.">
      <ButtonComponent onClick={() => handleObjectLock()}>
        <Unlock />
      </ButtonComponent>
    </Tooltip>
  );
};

export default LockComponent;

import { State as HistoryState } from './hooks/useUndo';
import { AlignmentTypes, LogoSize } from './utils/logoData';

enum InputType {
  SINGLE_LINE = 'text',
  SURVEY = 'survey',
  RADIO_BUTTON = 'radiogroup',
  PAGE = 'page',
  MULTI_LINE = 'comment',
  DROP_DOWN = 'dropdown',
  MULTI_DROP_DOWN = 'tagbox',
  RATING_SCALE = 'rating',
  TOGGLE = 'boolean',
}

enum InputElement {
  TEXT_BOX = 'TEXT_BOX',
  CHECK_BUTTON = 'CHECK_BUTTON',
}

type UserFocusData = {
  pageId: string;
  elementId: string;
  type: InputType | '';
};

export interface LogoDetails {
  width: number;
  height: number;
  type: LogoSize;
}

type State = {
  history: HistoryState<SurveyJSON>;
  setHistory: (history: any) => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  isSettingsOpen: boolean;
  setIsSettingsOpen: (isSettings: boolean) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  selectedItem: UserFocusData;
  setSelectedItem: (data: UserFocusData) => void;
  showPreview: boolean;
  setShowPreview: (value: boolean) => void;
  logoSize: LogoDetails;
  setLogoSize: (value: LogoDetails) => void;
  selectedLogo: string | null;
  setSelectedLogo: (value: string | null) => void;
  alignment: AlignmentTypes;
  setAlignment: (value: AlignmentTypes) => void;
};

type SurveyElement = {
  id: string;
  type: string;
  name: string;
  [key: string]: string | string[] | boolean;
};

type SurveyElementObject = { [key: string]: SurveyElement };

type SurveyPage = {
  id: string;
  name: string;
  title: string;
  description: string;
  elements: SurveyElementObject | SurveyElement[];
};

type SurveyPageObject = { [key: string]: SurveyPage };

type SurveyJSON = {
  logo?: string;
  logoWidth?: number;
  logoHeight?: number;
  logoPosition: string;
  title: string;
  description: string;
  pages: SurveyPageObject | SurveyPage[];
  alignContent: AlignmentTypes;
};

interface SingleLineInputProps {
  index: number;
  elementId: string;
  pageId: string;
  elementData: SurveyElement;
}

interface RatingScaleInputProp {
  index: number;
  elementId: string;
  pageId: string;
  elementData: SurveyElement;
}

type ChangeProps = {
  surveyData: SurveyJSON;
  data: { [key: string]: string | SurveyElement | boolean | number };
  focus?: UserFocusData;
  setHistory: (data: SurveyJSON) => void;
};

type AddDeleteElementProps = {
  surveyData: SurveyJSON;
  pageId?: string;
  elementId: string;
  setHistory: (data: SurveyJSON) => void;
  setSelectedItem?: (data: UserFocusData) => void;
  type?: InputType;
};

export { InputType, InputElement };
export type {
  State,
  SurveyJSON,
  SurveyElement,
  UserFocusData,
  SurveyPage,
  SingleLineInputProps,
  SurveyElementObject,
  ChangeProps,
  AddDeleteElementProps,
  SurveyPageObject,
  RatingScaleInputProp,
};

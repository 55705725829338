import { getApiError } from '../../api-http/apiService';
import { useRenameAsset } from '../../api-http/assets';
import { AlertBar } from '../../components/AlertBar';
import { formMsg, notifyMsg } from '../../configs';
import { AssetsResponse } from '../../types/assets.types';
import { useSnack } from '../../util/useSnack';
import { Box, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

interface RenameAssetFormProps {
  onFinished: () => void;
  asset: AssetsResponse;
}

const validationSchema = yup.object({
  fileName: yup.string().required(formMsg.FILE_NAME_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const RenameAssetForm: FC<RenameAssetFormProps> = ({
  onFinished,
  asset,
}) => {
  const { showSuccessSnack, showErrSnack } = useSnack();

  const { isLoading, error, data, mutate, isSuccess, isError } =
    useRenameAsset();

  const fileName = asset?.fileName?.split('.')[0] || '';

  useEffect(() => {
    if (data?._id || isSuccess) {
      onFinished();
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      showSuccessSnack(notifyMsg.ASSETS_NAME_UPDATE_SUCCESS);
    }
    if (isError) {
      const errorMsg = getApiError(error);
      showErrSnack(errorMsg);
    }
  }, [isSuccess, isError]);

  const formik = useFormik({
    initialValues: {
      fileName: fileName,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (asset?._id) {
        mutate({
          id: asset._id,
          name: values.fileName,
        });
      }
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="fileName"
          name="fileName"
          label="Asset Title"
          value={formik.values.fileName}
          onChange={formik.handleChange}
          error={formik.touched.fileName && Boolean(formik.errors.fileName)}
          helperText={formik.touched.fileName && formik.errors.fileName}
        />

        <Box mt={'10px'} />
        <Button
          color="primary"
          disabled={!formik.dirty || isLoading}
          variant="contained"
          fullWidth
          type="submit"
        >
          Update
        </Button>
      </form>
    </Container>
  );
};

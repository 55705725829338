import Stack from '@mui/material/Stack';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  span {
    font-size: 0.7em;
  }
`;

const StyledStack = styled(Stack)`
  width: 100%;
`;

const StyledTextarea = styled(TextareaAutosize)`
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: black;
  background: white;
  border: 1px solid black;
  box-shadow: 0px 2px 24px '#003A75',;

  width: 100%;
  resize: vertical;
  max-height: 250px;
  min-height: 25px;
}

  &:hover {
    border-color: '#3399FF';
  }

  &:focus {
    border-color: '#3399FF';
    box-shadow: 0 0 0 3px '#d0d7de';
  }
  
  &:focus-visible {
    outline: 0;
  }
`;

export { Title, StyledStack, StyledTextarea };

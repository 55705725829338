import React, { FC, useEffect } from 'react';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';

import {
  CreateCategoryResponse,
  useGetAllCategory,
} from '../../api-http/categories';
import { useCreateDraft } from '../../api-http/drafts';
import { AlertBar } from '../../components/AlertBar';
import { formMsg } from '../../configs';
import { useIsUserRole } from '../../util/useIsUserRole';
import { UserRole } from '../../types/user.types';
import { useAuth } from '../../context/auth-context';

interface PresentationFormProps {
  onFinished: () => void;
}

const validationSchema = yup.object({
  title: yup.string().required(formMsg.TITLE_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const PresentationForm: FC<PresentationFormProps> = ({ onFinished }) => {
  const { switchedOrgId } = useAuth();
  const { isLoading, error, data, mutate } = useCreateDraft();
  const { data: categoryData } = useGetAllCategory();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      navigate(`/presentations-editor/${data?._id}`);
      onFinished();
    }
  }, [data]);

  const isSuperAdmin = useIsUserRole([UserRole.superAdmin]);

  const formik = useFormik({
    initialValues: {
      title: '',
      category: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(
        isSuperAdmin && switchedOrgId
          ? {
              name: values.title,
              status: true, // TODO: Get this data from the form creation
              slides: [],
              category: values.category,
              organizationId: switchedOrgId,
            }
          : {
              name: values.title,
              status: true, // TODO: Get this data from the form creation
              slides: [],
              category: values.category,
            },
      );
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            id="title"
            name="title"
            label="Presentation Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />

          <FormControl sx={{ width: '100%', minWidth: 200 }}>
            <InputLabel id="category">Add Category</InputLabel>
            <Select
              fullWidth
              id="category"
              name="category"
              value={formik.values.category}
              title="category"
              input={<OutlinedInput label="category" />}
              placeholder=" Select category"
              onChange={formik.handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {categoryData?.map((categoryName: CreateCategoryResponse) => (
                <MenuItem key={categoryName?._id} value={categoryName?._id}>
                  {categoryName?.name || ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            color="primary"
            disabled={!formik.values.title || isLoading}
            variant="contained"
            fullWidth
            type="submit"
          >
            Create
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

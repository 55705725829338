import React, { ChangeEvent, FC, useEffect } from 'react';

import { useFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { Button, Stack, TextField } from '@mui/material';
import { formMsg, notifyMsg } from '../../configs';
import { useUpdateKvs } from '../../api-http/kvs';
import { AlertBar } from '../../components/AlertBar';
import { useSnack } from '../../util/useSnack';
import { KvsResponse } from '../../types/kvs.types';
import { formatInput } from '../../util/validations';

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

interface KvsEditFormProps {
  kvsGroup: KvsResponse | undefined;
  onFinished: () => void;
  refetch: () => void;
}

const validationSchema = yup.object({
  groupName: yup
    .string()
    .required(formMsg.GROUP_NAME_REQUIRED)
    .matches(/^[0-9a-z_]+$/, formMsg.INVALID_GROUP_NAME),
  description: yup.string().required(formMsg.KVS_DESCRIPTION_REQUIRED),
});

export const KvsEditForm: FC<KvsEditFormProps> = ({
  kvsGroup,
  onFinished,
  refetch,
}) => {
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { isLoading, error, data, mutate } = useUpdateKvs({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.KVS_GROUP_UPDATE_SUCCESS);
      refetch();
    },
    onError: (error) => {
      showErrSnack(error || notifyMsg.KVS_GROUP_UPDATE_FAILED);
    },
  });

  const formik = useFormik({
    initialValues: {
      groupName: kvsGroup?.name || '',
      description: kvsGroup?.description || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate({
        _id: kvsGroup?._id.toString() || '',
        name: values.groupName,
        description: values.description,
      });
    },
  });

  useEffect(() => {
    if (data) {
      onFinished();
    }
  }, [data]);

  return (
    <Container>
      <AlertBar
        style={{ marginBottom: '10px' }}
        severity="error"
        msg={error?.msg}
      />
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            id="groupName"
            name="groupName"
            label="Group Name"
            value={formik.values.groupName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formatInput(e, 'groupName', formik)
            }
            error={formik.touched.groupName && Boolean(formik.errors.groupName)}
            helperText={formik.touched.groupName && formik.errors.groupName}
          />
          <TextField
            fullWidth
            id="description"
            name="description"
            label="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!formik.dirty || isLoading}
          >
            Save Values
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

import React, { FC } from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NavLinkWithNoStyle } from './NavLinkWithNoStyle';
import { Home } from '@mui/icons-material';
import styled from '@emotion/styled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface BreadcrumbItem {
  label: string;
  to: string;
  description?: string;
  onClick?: () => void;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
}

const StyledMUIBreadcrumbs = styled(MUIBreadcrumbs)`
  margin-bottom: 8px;
  .MuiBreadcrumbs-li {
    line-height: 1px;
  }
`;

const StyledLink = styled(NavLinkWithNoStyle)`
  color: #1976d2;
`;

const InfoWrapper = styled.span`
  line-height: 0;
  height: 15px;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;

  svg {
    width: 15px;
    margin: auto;
    height: 15px;
  }
`;

const Breadcrumbs: FC<BreadcrumbsProps> = ({ items }) => {
  const theme = useTheme();

  return (
    <StyledMUIBreadcrumbs aria-label="breadcrumb">
      <NavLinkWithNoStyle style={{ lineHeight: 1 }} to="/">
        <Home color={'primary'} fontSize="small" style={{ lineHeight: 1 }} />
      </NavLinkWithNoStyle>
      {items.map((item, index) => {
        const { label, to, onClick, description } = item;
        return (
          <span key={index + label}>
            <StyledLink theme={theme} to={to} onClick={onClick}>
              {label}
            </StyledLink>
            {description && (
              <InfoWrapper>
                <Tooltip
                  title={description}
                  componentsProps={{ tooltip: { sx: { fontSize: '14px' } } }}
                >
                  <HelpOutlineIcon />
                </Tooltip>
              </InfoWrapper>
            )}
          </span>
        );
      })}
    </StyledMUIBreadcrumbs>
  );
};

export default Breadcrumbs;

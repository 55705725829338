export function frequency(
  frequencyCode: number,
  isCustom?: boolean,
  isMonthly?: boolean,
) {
  if (isMonthly) return 'Monthly';
  if (isCustom) return 'Custom';
  switch (frequencyCode) {
    case 0:
      return 'Yearly';
    case 1:
      return 'Monthly';
    case 2:
      return 'Weekly';
    default:
      return 'Daily';
  }
}

export enum LogoSize {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
}

export enum AlignmentTypes {
  LEFT = 'Left',
  CENTER = 'Center',
  RIGHT = 'Right',
}

export const logoDetails = [
  { type: LogoSize.SMALL, width: 60, height: 60 },
  { type: LogoSize.MEDIUM, width: 100, height: 100 },
  { type: LogoSize.LARGE, width: 200, height: 200 },
];

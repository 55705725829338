import { DialogModal } from '../../components';
import { StyleTextfield } from '../../components/TextField';
import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { AddAssetForm } from './AddAssetForm';
import { AssetsTable } from './AssetsTable';
import {
  Button,
  debounce,
  FormControlLabel,
  Stack,
  Checkbox,
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { DEBOUNCE_TIME } from '../../configs';
import Breadcrumbs from '../../components/Breadcrumb';
import RefreshButton from '../../components/RefreshButton';

const AssetsPage: FC = () => {
  metaTitle('Assets');

  const [openAddAssetModal, setOpenAddAssetModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [assetSearchTxt, setAssetSearchTxt] = useState('');
  const [showImage, setShowImage] = useState(true);
  const [showVideo, setShowVideo] = useState(true);

  const disableUploadAssets = useIsUserRole([
    UserRole.user,
    UserRole.dataEditor,
  ]);

  const setSearchText = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setAssetSearchTxt(event.target.value);
  };

  return (
    <>
      <Stack>
        <FilterSelectWrapper>
          <Breadcrumbs
            items={[
              {
                label: 'Assets Library',
                to: '/assets',
                description:
                  'This page serves as a central hub for all assets used in creating slides. Users can efficiently manage their assets here by adding or removing them. The page also offers filtering options specifically for images and videos.',
              },
            ]}
          />

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  onClick={() => setShowImage(!showImage)}
                  disabled={!showVideo}
                />
              }
              label={'Show Images'}
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  onClick={() => setShowVideo(!showVideo)}
                  disabled={!showImage}
                />
              }
              label={'Show Videos'}
            />
          </div>
        </FilterSelectWrapper>
      </Stack>
      <Stack>
        <FilterSelectWrapper>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAddAssetModal(true)}
              disabled={disableUploadAssets}
              style={{ marginBottom: 0 }}
            >
              Add Asset
            </Button>

            <RefreshButton onClick={() => setRefreshTable(!refreshTable)} />
          </div>
          <StyleTextfield
            label={'Search Assets'}
            variant={'outlined'}
            onChange={debounce((event) => setSearchText(event), DEBOUNCE_TIME)}
          />
        </FilterSelectWrapper>

        <AssetsTable
          refresh={refreshTable}
          assetSearchTxt={assetSearchTxt as string}
          showImage={showImage}
          showVideo={showVideo}
        />
      </Stack>

      <DialogModal
        title={'Upload assets'}
        onClose={() => {
          setOpenAddAssetModal(false);
        }}
        open={openAddAssetModal}
        width={600}
      >
        <AddAssetForm
          onFinished={() => {
            setOpenAddAssetModal(false);
            setRefreshTable(!refreshTable);
          }}
        />
      </DialogModal>
    </>
  );
};

export default AssetsPage;

const colors = {
  white: '#FFFFFF',
  black: '#000000',
};

const defaultColorSet: string[] = [
  '#000000',
  '#545454',
  '#a6a6a6',
  '#d9d9d9',
  '#ffffff',

  '#ff3131',
  '#ff5757',
  '#cb6ce6',
  '#8c52ff',
  '#5e17eb',

  '#0097b2',
  '#0cc0df',
  '#38b6ff',
  '#5271ff',
  '#004aad',

  '#00bf63',
  '#7ed957',
  '#ffde59',
  '#ffbd59',
  '#ff914d',
];

export { colors, defaultColorSet };

import { useEffect } from 'react';
import { RealSize } from '../../../types';
import { getMaxSize } from '../../../utils/ratio';
import { useEditorData } from '../providers/EditorProvider';
import handleAddWebSite from '../utils/handleWebSite';
import handleWorldClockAndDate from '../utils/handleWorldClockAndDate';
import { IFRAME_WIDGETS } from '../../../config';
import { useHandleHistory } from './useHandleHistory';

const TAB_COLLAPSED_WIDTH = 72;
const TAB_EXPANDED_WIDTH = 422;
const PADDING = 100;
const HEIGHT_MARGIN = 250;

const { WORLD_CLOCK, DATE, IFRAME } = IFRAME_WIDGETS;

const getSize = (isCollapsed: boolean, realSize: RealSize) => {
  const canvasWidth =
    window.innerWidth -
    (isCollapsed ? TAB_COLLAPSED_WIDTH : TAB_EXPANDED_WIDTH) -
    PADDING;
  const canvasHeight = window.innerHeight - HEIGHT_MARGIN;

  return getMaxSize({
    canvasWidth,
    canvasHeight,
    width: realSize.realWidth,
    height: realSize.realHeight,
  });
};

export const useHandleResize = (
  canvasParentRef: React.RefObject<HTMLDivElement>,
  realSize: { realWidth: number; realHeight: number },
  openPreview?: boolean,
) => {
  const { canvas, activeTab } = useEditorData();
  const { handleHistory } = useHandleHistory();

  const handleResize = () => {
    const parentElement = canvasParentRef.current?.parentElement;
    if (canvas && parentElement) {
      const isCollapsed = activeTab === '';
      const { width, height } = getSize(isCollapsed, realSize);
      const ratio = width / realSize.realWidth;
      canvas.setZoom(ratio);
      canvas.setHeight(height);
      canvas.setWidth(width);
      canvas.renderAll();
      for (const obj of canvas.getObjects()) {
        if (Object.values(IFRAME_WIDGETS).includes(obj?.data?.type)) {
          document.getElementsByClassName(obj?.data?.className)?.[0]?.remove();
        }
        switch (obj?.data?.type) {
          case IFRAME:
            handleAddWebSite(canvas, obj?.data.src, handleHistory, obj);
            break;
          case WORLD_CLOCK:
            handleWorldClockAndDate({ canvas, obj, handleHistory });
            break;
          case DATE:
            handleWorldClockAndDate({
              canvas,
              obj,
              isTime: false,
              handleHistory,
            });
            break;
        }
      }
    }
  };

  useEffect(() => {
    if (!openPreview) handleResize();
  }, [openPreview]);

  useEffect(() => {
    try {
      handleResize();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Ignore error', error);
    }
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [canvas, activeTab]);
};

import React, { FC, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { Refresh, FileCopy } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

import { UserRole } from '../../types/user.types';

import { Nullable } from '../../types';
import { notifyMsg } from '../../configs';
import { useSnack } from '../../util/useSnack';
import { DialogModal } from '../../components';
import { metaTitle } from '../../util/tabTitle';
import { useAuth } from '../../context/auth-context';
import Breadcrumbs from '../../components/Breadcrumb';
import { ProfileUpdateForm } from './ProfileUpdateForm';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useUserRoleName } from '../../util/useUserTypes';
import { useUpdateOrgApiToken } from '../../api-http/organizations';
import { ProfileChangePasswordForm } from './ProfileChangePasswordForm';

const KeyTitle = styled('p')`
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const StyledButton = styled(Button)`
  padding: 0;
`;

const StyledTextField = styled(TextField)`
  margin-top: 6px;
`;

const Row = ({ title, value }: { title: string; value?: string }) => (
  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    <KeyTitle>{title}</KeyTitle>
    {value || ''}
  </Typography>
);

const ProfilePage: FC = () => {
  metaTitle(' Profile');

  const { showSuccessSnack, showErrSnack } = useSnack();

  const { currentUser, refetchMe } = useAuth();
  const disableEdit = useIsUserRole([UserRole.user]);

  const initOrgApiToken = currentUser?.organization?.orgApiToken || null;

  const [orgApiToken, setOrgApiToken] =
    useState<Nullable<string>>(initOrgApiToken);
  const [userUpdateModal, setUserUpdateModal] = useState(false);
  const [userChangePasswordModal, setUserChangePasswordModal] = useState(false);

  const { mutate } = useUpdateOrgApiToken({
    onSuccess: (res) => {
      setOrgApiToken(res?.orgApiToken);
      refetchMe();
      showSuccessSnack(notifyMsg.NEW_TOKEN_REGENERATED_SUCCESS);
    },
    onError: (err) => showErrSnack(err),
  });

  useEffect(() => {
    setOrgApiToken(initOrgApiToken);
  }, [initOrgApiToken]);

  const handleCopy = () => {
    if (typeof orgApiToken === 'string') {
      navigator.clipboard.writeText(orgApiToken);
      showSuccessSnack(notifyMsg.TOKEN_COPIED_SUCCESS);
    }
  };

  const handleRegenerate = () => {
    mutate('');
  };

  const onFinishProfileUpdate = () => {
    setUserUpdateModal(false);
    refetchMe();
  };

  return (
    <>
      <Breadcrumbs items={[{ label: 'Profile', to: '/profile' }]} />

      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Row title={'Name'} value={currentUser?.name} />
          <Row title={'Email'} value={currentUser?.email} />
          <Row
            title={'User Role'}
            value={useUserRoleName(currentUser?.role || '')}
          />
          <StyledButton
            disabled={disableEdit}
            onClick={() => setUserUpdateModal(true)}
          >
            Edit Your Profile
          </StyledButton>
        </CardContent>

        <CardContent>
          <Row title={'Organization'} value={currentUser?.organization.name} />

          <Row
            title={'Organization Pin Code'}
            value={currentUser?.organization.pinCode}
          />
        </CardContent>

        <CardContent>
          {orgApiToken === null ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleRegenerate}
            >
              Create API Token
            </Button>
          ) : (
            <StyledTextField
              disabled
              label="Organization API Token"
              variant="outlined"
              value={orgApiToken}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopy}>
                      <FileCopy />
                    </IconButton>
                    <IconButton onClick={handleRegenerate}>
                      <Refresh />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </CardContent>

        <CardContent>
          <StyledButton onClick={() => setUserChangePasswordModal(true)}>
            Change Password
          </StyledButton>
        </CardContent>
      </Card>

      <DialogModal
        onClose={() => setUserUpdateModal(false)}
        open={userUpdateModal}
        title={'Edit profile'}
      >
        <ProfileUpdateForm
          profileData={currentUser}
          onFinished={onFinishProfileUpdate}
        />
      </DialogModal>
      <DialogModal
        onClose={() => setUserChangePasswordModal(false)}
        open={userChangePasswordModal}
        title={'Change password'}
      >
        <ProfileChangePasswordForm
          onFinished={() => setUserChangePasswordModal(false)}
        />
      </DialogModal>
    </>
  );
};

export default ProfilePage;

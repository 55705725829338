import React, { ChangeEvent, ElementRef, FC, useRef, useState } from 'react';

import { Button, Stack, debounce } from '@mui/material';

import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import KvsTable from './KvsTable';
import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import { StyleTextfield } from '../../components/TextField';
import { DialogModal } from '../../components';
import { KvsForm } from './KvsForm';
import { DEBOUNCE_TIME } from '../../configs';
import Breadcrumbs from '../../components/Breadcrumb';
import RefreshButton from '../../components/RefreshButton';
import { useHandleSearch } from '../../util/useHandleSearch';
import { useLocationSearch } from '../../util/useLocationSearch';

const KvsPage: FC = () => {
  metaTitle('KVS Groups');

  const searchVal = useLocationSearch();
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState(searchVal || '');
  const [search, setSearch] = useState(searchVal || '');
  type KvsTableHandle = ElementRef<typeof KvsTable>;
  const kvsRef = useRef<KvsTableHandle>(null);
  const [refresh, setRefresh] = useState(false);

  const disableAddNew = useIsUserRole([
    UserRole.user,
    UserRole.layoutEditor,
    UserRole.dataEditor,
  ]);

  const onSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value);
  };

  const onRefetchData = () => {
    kvsRef.current?.onRefetch();
  };

  useHandleSearch(searchText);

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: 'KVS',
            to: '/kvs',
            description:
              'This page stores dynamic values used in slides. Users can set values for keys and organize them into groups. Updating a value here automatically reflects changes in slides and presentations using the value. Note that values used in slides cannot be deleted.',
          },
        ]}
      />

      <Stack>
        <FilterSelectWrapper>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              disabled={disableAddNew}
            >
              Add new
            </Button>

            <RefreshButton onClick={() => setRefresh(!refresh)} />
          </div>
          <StyleTextfield
            id="groupSearch"
            label="Search Groups"
            variant="outlined"
            onChange={(event) => {
              setSearchText(event.target.value);
              debounce(() => onSearch(event), DEBOUNCE_TIME)();
            }}
            value={searchText}
          />
        </FilterSelectWrapper>
      </Stack>
      <KvsTable searchText={search} ref={kvsRef} onRefresh={refresh} />
      <DialogModal
        title="Add a New Key Group"
        onClose={() => setOpen(false)}
        open={open}
        style={{ width: '40%' }}
      >
        <KvsForm
          onFinished={() => setOpen(false)}
          onRefetchData={onRefetchData}
        />
      </DialogModal>
    </>
  );
};

export default KvsPage;

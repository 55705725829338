import { FC, FormEvent, ReactElement } from 'react';
import ButtonAddQuestion from './components/ButtonAddQuestion';
import {
  InputType,
  SurveyElementObject,
  SurveyPage,
  UserFocusData,
} from '../types';
import { useEditorData } from '../EditorProvider';
import { changePageData } from '../utils/surveyDataUtils';
import SingleLineInput from './components/SingleLineInput';
import styled from 'styled-components';
import MultiLineInput from './components/MultiLineInput';
import MultiSelectDropDown from './components/MultiSelectDropDown';
import DropDown from './components/DropDown';
import RadioButton from './components/RadioButton';
import BooleanSelect from './components/BooleanSelect';
import RatingScale from './components/RatingScale';

interface PageProps {
  pageNumber: number;
  pageId: string;
  pageData: SurveyPage;
}

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormPage: FC<PageProps> = ({ pageNumber, pageId, pageData }) => {
  const { history, selectedItem, setHistory, setSelectedItem } =
    useEditorData();

  const { present: surveyData } = history;
  const { title, description, elements } = pageData;

  const onChange = (e: FormEvent<HTMLSpanElement>) => {
    const { id, textContent } = e.currentTarget;

    const params = {
      surveyData,
      data: { [id]: textContent as string },
      focus: selectedItem as UserFocusData,
      setHistory,
    };
    changePageData(params);
  };

  const renderElements = () => {
    const componentList: ReactElement[] = [];
    const elementList = Object.values(elements as SurveyElementObject);

    elementList.forEach((element, index) => {
      const { id } = element;
      ++index;
      switch (element.type) {
        case InputType.SINGLE_LINE:
          componentList.push(
            <div className="svc-row" key={index}>
              <SingleLineInput
                index={index}
                elementId={id}
                pageId={pageId}
                elementData={element}
              />
            </div>,
          );
          break;
        case InputType.TOGGLE:
          componentList.push(
            <div className="svc-row" key={index}>
              <BooleanSelect
                index={index}
                elementId={id}
                pageId={pageId as string}
                elementData={element}
              />
            </div>,
          );
          break;
        case InputType.RADIO_BUTTON:
          componentList.push(
            <div className="svc-row" key={index}>
              <RadioButton
                index={index}
                elementId={id}
                pageId={pageId as string}
                elementData={element}
              />
            </div>,
          );
          break;
        case InputType.DROP_DOWN:
          componentList.push(
            <div className="svc-row" key={index}>
              <DropDown
                index={index}
                elementId={id}
                pageId={pageId as string}
                elementData={element}
              />
            </div>,
          );
          break;
        case InputType.MULTI_DROP_DOWN:
          componentList.push(
            <div className="svc-row" key={index}>
              <MultiSelectDropDown
                index={index}
                elementId={id}
                pageId={pageId as string}
                elementData={element}
              />
            </div>,
          );
          break;
        case InputType.MULTI_LINE:
          componentList.push(
            <div className="svc-row" key={index}>
              <MultiLineInput
                index={index}
                elementId={id}
                pageId={pageId as string}
                elementData={element}
              />
            </div>,
          );
          break;

        case InputType.RATING_SCALE:
          componentList.push(
            <div className="svc-row" key={index}>
              <RatingScale
                index={index}
                elementId={id}
                pageId={pageId as string}
                elementData={element}
              />
            </div>,
          );
          break;
        default:
          componentList.push(
            <div className="svc-row" key={index}>
              <SingleLineInput
                index={index}
                elementId={id}
                pageId={pageId}
                elementData={element}
              />
            </div>,
          );
          break;
      }
    });
    return componentList;
  };

  return (
    <div
      className="svc-page"
      data-sv-drop-target-page="page1"
      data-sv-drop-target-survey-element="page1"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedItem({ pageId, elementId: '', type: InputType.PAGE });
      }}
    >
      <div className="svc-page__content " id="sp_203" tabIndex={0}>
        <div className="svc-page__content-actions">
          <div className="sv-action-bar sv-action-bar--small-size-mode">
            <div
              className="sv-action sv-action-bar-item--secondary"
              id="duplicate"
            >
              <div className="sv-action__content">
                <button
                  className="sv-action-bar-item"
                  type="button"
                  title="Duplicate"
                  tabIndex={0}
                  role="button"
                >
                  <svg
                    className="sv-svg-icon sv-action-bar-item__icon"
                    role="img"
                    aria-label="Duplicate"
                    style={{ width: '16px', height: '16px' }}
                  >
                    <use xlinkHref="#icon-duplicate_16x16" />
                    <title>Duplicate</title>
                  </svg>
                  <span className="sv-action-bar-item__title sv-action-bar-item__title--with-icon">
                    Duplicate
                  </span>
                </button>
              </div>
            </div>
            <div
              className="sv-action sv-action-bar-item--secondary sv-action--hidden"
              id="settings"
            >
              <div className="sv-action__content">
                <button
                  className="sv-action-bar-item"
                  type="button"
                  title="Open settings"
                  tabIndex={0}
                  role="button"
                >
                  <svg
                    className="sv-svg-icon sv-action-bar-item__icon"
                    role="img"
                    aria-label="Open settings"
                    style={{ width: '16px', height: '16px' }}
                  >
                    <use xlinkHref="#icon-settings_16x16" />
                    <title>Open settings</title>
                  </svg>
                  <span className="sv-action-bar-item__title sv-action-bar-item__title--with-icon">
                    Settings
                  </span>
                </button>
              </div>
            </div>
            <div
              className="sv-action sv-action-bar-item--secondary"
              id="delete"
            >
              <div className="sv-action__content">
                <button
                  className="sv-action-bar-item"
                  type="button"
                  title="Delete"
                  tabIndex={0}
                  role="button"
                >
                  <svg
                    className="sv-svg-icon sv-action-bar-item__icon"
                    role="img"
                    aria-label="Delete"
                    style={{ width: '16px', height: '16px' }}
                  >
                    <use xlinkHref="#icon-delete_16x16" />
                    <title>Delete</title>
                  </svg>
                  <span className="sv-action-bar-item__title sv-action-bar-item__title--with-icon">
                    Delete
                  </span>
                </button>
              </div>
            </div>
            <div
              className="sv-action sv-dots sv-action--hidden"
              id="dotsItem-id32"
            >
              <div className="sv-action__content">
                <button
                  className="sv-action-bar-item sv-dots__item"
                  type="button"
                  title="More"
                  tabIndex={0}
                  role="button"
                >
                  <svg
                    className="sv-svg-icon sv-action-bar-item__icon"
                    role="img"
                    aria-label="More"
                    style={{ width: '24px', height: '24px' }}
                  >
                    <use xlinkHref="#icon-more" />
                    <title>More</title>
                  </svg>
                  <div />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="sd-page sd-body__page">
          <h4 className="sd-title sd-page__title">
            <span className="svc-string-editor">
              <span className="svc-string-editor__content">
                <div className="svc-string-editor__border">
                  <svg
                    className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                    role="img"
                    style={{ width: '16px', height: '16px' }}
                  >
                    <use xlinkHref="#icon-edit" />
                  </svg>
                </div>
                <span className="svc-string-editor__input">
                  <span
                    id="title"
                    role="textbox"
                    className="sv-string-editor"
                    contentEditable="true"
                    spellCheck="false"
                    aria-placeholder={`Page ${pageNumber}`}
                    aria-label={`Page ${pageNumber}`}
                    onBlur={onChange}
                    suppressContentEditableWarning
                  >
                    {title}
                  </span>
                  <div className="svc-string-editor__controls" />
                </span>
              </span>
            </span>
          </h4>
          <div className="sd-description sd-page__description">
            <span className="svc-string-editor">
              <span className="svc-string-editor__content">
                <div className="svc-string-editor__border">
                  <svg
                    className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                    role="img"
                    style={{ width: '16px', height: '16px' }}
                  >
                    <use xlinkHref="#icon-edit" />
                  </svg>
                </div>
                <span className="svc-string-editor__input">
                  <span
                    id="description"
                    role="textbox"
                    className="sv-string-editor"
                    spellCheck="false"
                    aria-placeholder="Description"
                    aria-label="Description"
                  >
                    {description}
                  </span>
                  <div className="svc-string-editor__controls" />
                </span>
              </span>
            </span>
          </div>
          {renderElements()}
          <ButtonContainer>
            <ButtonAddQuestion pageId={pageId} />
          </ButtonContainer>
        </div>
      </div>
    </div>
  );
};

export default FormPage;

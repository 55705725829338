import { RRule } from 'rrule';

export const FREQUENCY = [
  { id: 1, frequency: 'Daily', value: RRule.DAILY },
  {
    id: 2,
    frequency: 'Weekly',
    value: RRule.WEEKLY,
  },
  {
    id: 3,
    frequency: 'Monthly',
    value: RRule.MONTHLY,
  },
  {
    id: 4,
    frequency: 'Yearly',
    value: RRule.YEARLY,
  },
  {
    id: 5,
    frequency: 'Custom',
    value: RRule.DAILY,
  },
];

export const WEEK_DAYS = [
  { id: 1, day: 'MO', value: RRule.MO, fullDay: 'Monday' },
  { id: 2, day: 'TU ', value: RRule.TU, fullDay: 'Tuesday' },
  { id: 3, day: 'WE', value: RRule.WE, fullDay: 'Wednesday' },
  { id: 4, day: 'TH', value: RRule.TH, fullDay: 'Thursday' },
  { id: 5, day: 'FR', value: RRule.FR, fullDay: 'Friday' },
  { id: 6, day: 'SA', value: RRule.SA, fullDay: 'Saturday' },
  { id: 7, day: 'SU', value: RRule.SU, fullDay: 'Sunday' },
];

export const DAY_FREQUENCY = [
  { id: 1, dayFrequency: 'First', value: 1 },
  {
    id: 2,
    dayFrequency: 'Second',
    value: 2,
  },
  {
    id: 3,
    dayFrequency: 'Third',
    value: 3,
  },
  {
    id: 4,
    dayFrequency: 'Fourth',
    value: 4,
  },
  {
    id: 5,
    dayFrequency: 'Last',
    value: -1,
  },
];

export const MONTHS = [
  { id: 1, month: 'January' },
  { id: 2, month: 'February' },
  { id: 3, month: 'March' },
  { id: 4, month: 'April' },
  { id: 5, month: 'May' },
  { id: 6, month: 'June' },
  { id: 7, month: 'July' },
  { id: 8, month: 'August' },
  { id: 9, month: 'September' },
  { id: 10, month: 'October' },
  { id: 11, month: 'November' },
  { id: 12, month: 'December' },
];

export const MONTH_DATES = [
  { id: 1, monthDate: '1' },
  { id: 2, monthDate: '2' },
  { id: 3, monthDate: '3' },
  { id: 4, monthDate: '4' },
  { id: 5, monthDate: '5' },
  { id: 6, monthDate: '6' },
  { id: 7, monthDate: '7' },
  { id: 8, monthDate: '8' },
  { id: 9, monthDate: '9' },
  { id: 10, monthDate: '10' },
  { id: 11, monthDate: '11' },
  { id: 12, monthDate: '12' },
  { id: 13, monthDate: '13' },
  { id: 14, monthDate: '14' },
  { id: 15, monthDate: '15' },
  { id: 16, monthDate: '16' },
  { id: 17, monthDate: '17' },
  { id: 18, monthDate: '18' },
  { id: 19, monthDate: '19' },
  { id: 20, monthDate: '20' },
  { id: 21, monthDate: '21' },
  { id: 22, monthDate: '22' },
  { id: 23, monthDate: '23' },
  { id: 24, monthDate: '24' },
  { id: 25, monthDate: '25' },
  { id: 26, monthDate: '26' },
  { id: 27, monthDate: '27' },
  { id: 28, monthDate: '28' },
  { id: 29, monthDate: '29' },
  { id: 30, monthDate: '30' },
  { id: 31, monthDate: '31' },
];

import { Button } from '@mui/material';
import React, { FC } from 'react';
import { Lock } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface SlideLockprops {
  resetSocket: () => void;
  editingUser: string;
}

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SlideLock: FC<SlideLockprops> = ({ resetSocket, editingUser }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <div
        style={{
          fontFamily: 'Roboto,sans serif',
          display: 'flex',
          marginBottom: '10px',
        }}
      >
        <Lock />
        <span style={{ marginLeft: '10px', textAlign: 'center' }}>
          Slide Has Been Locked
        </span>
      </div>
      <p
        style={{
          fontFamily: 'Roboto,sans serif',
          fontSize: '14px',
          color: 'GrayText',
        }}
      >
        {`${editingUser} is currently editing this slide to minimize editing
        conflicts we have prevented more than two user's from editing one slide.`}
      </p>
      <div>
        <Button
          onClick={() => navigate('/slides')}
          style={{ marginRight: '10px' }}
        >
          Back To Slides Page
        </Button>
        <Button onClick={resetSocket} style={{ marginRight: '10px' }}>
          Retry
        </Button>
      </div>
    </Container>
  );
};

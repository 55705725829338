import { FC } from 'react';
import {
  UpdateOrgBody,
  useUpdateOrg,
} from '../../../../api-http/organizations';
import { notifyMsg } from '../../../../configs';
import { OrganizationForm } from './OrganizationForm';
import { DialogModal } from '../../../../components';
import { useSnack } from '../../../../util/useSnack';
import { Nullable } from '../../../../types';
import { BaseOrganization } from '../../../../types/organization.types';

interface UpdateOrganizationModalProps {
  onFinished: () => void;
  onClose: () => void;
  open: boolean;
  organizationData?: Nullable<BaseOrganization>;
}

export const UpdateOrganizationModal: FC<UpdateOrganizationModalProps> = ({
  onFinished,
  organizationData,
  open,
  onClose,
}) => {
  const { showSuccessSnack, showErrSnack } = useSnack();
  const { isLoading, mutate, error, reset } = useUpdateOrg({
    onSuccess() {
      showSuccessSnack(notifyMsg.ORGANIZATION_UPDATE_SUCCESS);
      onFinished();
      closeModal();
    },
    onError: (err) => showErrSnack(err),
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <DialogModal title="Update Organization" onClose={onClose} open={open}>
      <OrganizationForm
        onSubmit={(data) => mutate(data as UpdateOrgBody)}
        organizationData={organizationData}
        isLoading={isLoading}
        error={error}
      />
    </DialogModal>
  );
};

import { useEffect, useState } from 'react';
import { FC } from 'react';
import { notifyMsg } from '../../configs';
import { Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { ChevronDown } from 'react-feather';
import TableRow from '@mui/material/TableRow';
import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../components/TableTheme';
import { AlertBar } from '../../components/AlertBar';
import {
  useGetPaginateSchedulers,
  useDeleteSchedulers,
} from '../../api-http/scheduler';
import { SchedulerActionMenu } from './SchedulerActionMenu';

import { useTable } from '../../util/useTable';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { useSnack } from '../../util/useSnack';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useConfirm } from 'material-ui-confirm';
import { UserRole } from '../../types/user.types';
import { SchedulerGetResponse } from '../../types/scheduler.types';
import { displayDistance } from '../../util/dateTime';

import { NavLink } from 'react-router-dom';
import { SchedulerEditModal } from './components/SchedulerEditModal';
import { DialogModal } from '../../components';

interface SchedulerTableProp {
  refresh: boolean;
  schedulerSearchTxt: string;
}

export const SchedulerTable: FC<SchedulerTableProp> = ({
  refresh,
  schedulerSearchTxt,
}) => {
  const confirm = useConfirm();

  const disableDelete = useIsUserRole([UserRole.user, UserRole.dataEditor]);
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { sortOnClick, pagingParams, tableProps } = useTable(true);
  const { sortby, order } = pagingParams;
  const [schedulerEditModal, setSchedulerEditModal] = useState(false);
  const {
    data: list,
    isLoading: listIsLoading,
    refetch,
    error,
  } = useGetPaginateSchedulers({ ...pagingParams, search: schedulerSearchTxt });

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    scheduler: SchedulerGetResponse,
  ) => {
    setActiveScheduler(scheduler);
    setAnchorEl(event.currentTarget);
  };
  const {
    error: deleteError,
    mutate: deleteScheduler,
    data: deletedScheduler,
  } = useDeleteSchedulers({
    onSuccess: () => showSuccessSnack(notifyMsg.SCHEDULER_DELETE_SUCCESS),
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeScheduler, setActiveScheduler] = useState<
    SchedulerGetResponse | undefined
  >();

  useEffect(() => {
    refetch();
  }, [deletedScheduler, refresh, tableProps]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (id: string) => {
    try {
      await confirm({
        description: notifyMsg.DELETE_CONFIRMATION,
        confirmationText: 'Yes',
      });
      deleteScheduler({ id });
    } catch (e) {}
  };

  return (
    <>
      {listIsLoading && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg || deleteError?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" width={200}>
                <StyledTableSortLabel
                  active={sortby === SortEnum.name || true}
                  direction={sortby === SortEnum.name ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.name)}
                >
                  Scheduler Name
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="left">Note</StyledTableCell>
              <StyledTableCell align="left">
                Default Presentation
              </StyledTableCell>
              <StyledTableCell align="left">Time Zone</StyledTableCell>
              <StyledTableCell align="left">
                <StyledTableSortLabel
                  active={sortby === SortEnum.updatedAt || true}
                  direction={
                    sortby === SortEnum.updatedAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.updatedAt)}
                >
                  Updated
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {list?.data?.map((scheduler) => {
              return (
                <TableRow
                  key={scheduler._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <NavLink
                      to={`/event/${scheduler._id}`}
                      state={{
                        search: location?.search,
                        timezone: scheduler.timeZone,
                      }}
                    >
                      {scheduler?.name}
                    </NavLink>
                  </TableCell>
                  <TableCell align="left">{scheduler?.description}</TableCell>
                  <TableCell align="left">
                    {scheduler?.defaultPresentation?.name}
                  </TableCell>
                  <TableCell align="left">{scheduler?.timeZone}</TableCell>
                  <TableCell align="left">
                    {displayDistance(scheduler?.updatedAt)}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={(event) => handleClick(event, scheduler)}
                      disabled={disableDelete}
                    >
                      <ChevronDown size={16} />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>
      <SchedulerActionMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClickDelete={() => {
          handleDelete(activeScheduler?._id || '');
          handleClose();
        }}
        onClickEdit={() => {
          setSchedulerEditModal(true);
          handleClose();
        }}
      />
      <DialogModal
        title={'Edit Scheduler'}
        onClose={() => setSchedulerEditModal(false)}
        open={schedulerEditModal}
      >
        <SchedulerEditModal
          scheduler={activeScheduler}
          onFinished={() => {
            setSchedulerEditModal(false);
          }}
          refetch={refetch}
        />
      </DialogModal>
    </>
  );
};
export default SchedulerTable;

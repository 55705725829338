import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface BouncingLoaderProps {
  size: number;
  color?: string;
  dotSize?: number;
}

const BouncingLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
`;

const bouncingAnimation = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-5px);
  }
`;

interface BouncingLoaderDotProps {
  size: number;
  color: string;
}

const BouncingLoaderDot = styled.div<BouncingLoaderDotProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin: 0 2px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  opacity: 1;
  animation: ${bouncingAnimation} 0.6s infinite alternate;
`;

export const BouncingLoader: FC<BouncingLoaderProps> = ({
  size,
  color = 'black',
  dotSize = 3,
}) => {
  const dots = [];
  for (let i = 0; i < dotSize; i++) {
    dots.push(
      <BouncingLoaderDot
        key={i}
        size={size}
        color={color}
        style={{ animationDelay: `${i * 0.1}s` }}
      />,
    );
  }
  return <BouncingLoaderContainer>{dots}</BouncingLoaderContainer>;
};

import { FC } from 'react';

import { Menu, MenuItem } from '@mui/material';
import { Trash2, Edit3, Copy } from 'react-feather';

interface PresentationActionMenuProps {
  onClickRename: () => void;
  onClickClone: () => void;
  onClickRemove: () => void;
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
}

export const PresentationActionMenu: FC<PresentationActionMenuProps> = ({
  onClickRename,
  onClickClone,
  onClickRemove,
  onClose,
  open,
  anchorEl,
}) => {
  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      <MenuItem onClick={onClickRename}>
        <Edit3 size={16} style={{ marginRight: 10 }} />
        Edit Presentation
      </MenuItem>
      <MenuItem onClick={onClickClone}>
        <Copy size={16} style={{ marginRight: 10 }} />
        Clone Presentation
      </MenuItem>
      <MenuItem onClick={onClickRemove}>
        <Trash2 size={16} style={{ marginRight: 10 }} />
        Remove Presentation
      </MenuItem>
    </Menu>
  );
};

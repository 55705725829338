import { useQuery } from '@tanstack/react-query';
import {
  GeoCoordinatesData,
  WidgetData,
} from '../components/canvas-editor-v2/types';
import { ApiServiceErr, axiosApi } from './apiService';

const getGeoLocation = (): Promise<GeoCoordinatesData> => {
  return new Promise((resolve, rejects) => {
    const success = (position: GeolocationPosition) => {
      const { coords } = position;
      return resolve(coords);
    };

    const error = () => {
      return rejects({ message: "Couldn't retrieve location data" });
    };

    navigator.geolocation.getCurrentPosition(success, error);
  });
};

export const useGetWeatherData = ({ city }: { city?: string }) =>
  useQuery<WidgetData, ApiServiceErr>(
    ['/weather?lat=${latitude}&lon=${longitude}&city=${city}', city],
    async () => {
      const coords = await getGeoLocation();
      const { latitude, longitude } = coords;
      const response = await axiosApi.get(`/weather`, {
        params: { lat: latitude, lon: longitude, city },
      });
      return response.data;
    },
  );

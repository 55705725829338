import React, { ChangeEvent, FC, useEffect } from 'react';

import { useFormik } from 'formik';
import styled from 'styled-components';

import * as yup from 'yup';

import { Button, Stack, TextField } from '@mui/material';
import { useSnack } from '../../util/useSnack';
import { formMsg, notifyMsg } from '../../configs';
import { AlertBar } from '../../components/AlertBar';
import { formatInput } from '../../util/validations';
import { FormsResponse, useUpdateForms } from '../../api-http/forms';

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

interface FormEditModalProps {
  forms: FormsResponse | undefined;
  onFinished: () => void;
  refetch: () => void;
}

const validationSchema = yup.object({
  formName: yup.string().required(formMsg.FORM_NAME_REQUIRED),
});

export const FormEditModal: FC<FormEditModalProps> = ({
  onFinished,
  refetch,
  forms,
}) => {
  const { showErrSnack, showSuccessSnack } = useSnack();

  const { isLoading, error, data, mutate } = useUpdateForms({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.FORM_UPDATE_SUCCESS);
      refetch();
    },
    onError: (error) => {
      showErrSnack(error || notifyMsg.FORM_UPDATE_FAILED);
    },
  });

  const formik = useFormik({
    initialValues: {
      formName: forms?.name || '',
    },
    validationSchema: validationSchema,
    onSubmit: ({ formName }) => {
      mutate({
        _id: forms?._id || '',
        name: formName,
      });
    },
  });

  useEffect(() => {
    if (data) {
      onFinished();
    }
  }, [data]);

  return (
    <Container>
      <AlertBar
        style={{ marginBottom: '10px' }}
        severity="error"
        msg={error?.msg}
      />
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            id="formName"
            name="formName"
            label="Form Name"
            value={formik.values.formName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formatInput(e, 'formName', formik)
            }
            error={formik.touched.formName && Boolean(formik.errors.formName)}
            helperText={
              formik.touched.formName && Boolean(formik.errors.formName)
            }
          />

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!formik.dirty || isLoading}
          >
            Update
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

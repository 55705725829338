/**
 * Tab component is originally taken from
 * https://clubmate.fi/tabs#result-tabpanel-1
 */

import React, { FC, useRef } from 'react';
import { X } from 'react-feather';
import styled from 'styled-components';
import { useEditorData } from '../../lib/FabricJs';
import { Tooltip } from '@mui/material';

const TAB_PANEL_WIDTH = 350;
const TAB_ICON_SIZE = 72;

interface StyleProps {
  isActive: boolean;
}

interface TabProps {
  tabs: Array<{ title: string; icon?: JSX.Element; children?: JSX.Element }>;
  id: string;
}

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const TabList = styled.ul`
  display: flex;
  list-style-type: none;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background-color: #18191b;
`;

const TabItem = styled.li`
  margin: 0;
`;

const CloseButton = styled.button`
  margin: 0;
  display: flex;
  justify-content: center;
  align-item: center;
  cursor: pointer;
  background: white;
`;

const IconWrapper = styled.div<StyleProps>`
  justify-content: center;
  align-items: center;
  display: flex;
  width: ${TAB_ICON_SIZE}px;
  height: ${TAB_ICON_SIZE}px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: ${({ isActive }) => (isActive ? '#252627' : 'transparent')};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.6')};
  &: hover {
    opacity: 1;
  }
`;

const Tab = styled.a`
  display: flex;
  flex-grow: 1;
`;

const TabPanel = styled.div<StyleProps>`
  margin-bottom: 1px;
  padding-top: 16px;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  width: ${TAB_PANEL_WIDTH}px;
`;

const TabPanelHeader = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 24px;
  padding-left: 18px;
  padding-right: 24px;
  align-item: center;
`;

const TabPanelBody = styled.div`
  height: calc(100vh - 122px);
  overflow-y: auto;
  padding-left: 24px;
  padding-right: 24px;
`;

const Tabs: FC<TabProps> = ({ tabs = [], id }) => {
  const { activeTab, setActiveTab } = useEditorData();

  const tabRefs = useRef<any>({});
  const handleClick = (
    tab: string,
    event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event?.preventDefault();
    setActiveTab(tab);
  };
  const getId = (...rest: string[]) => [...rest, id].join('-');

  const focusTab = (tab: string) => {
    setActiveTab(tab);
    // Get the reference from the `tabRefs` and actual move focus to the tab
    tabRefs.current[tab].focus();
  };

  const handleKeyboard = (
    tab: string,
    event?: React.KeyboardEvent<HTMLAnchorElement>,
  ) => {
    const tabCount = tabs.length - 1;
    const currentTabIndex = tabs.findIndex((t) => t.title === tab);
    const [firstTab] = tabs;
    const lastTab = tabs[tabCount];
    const nextTab = tabs[currentTabIndex + 1];
    const prevTab = tabs[currentTabIndex - 1];
    if (event) {
      if (event.key === 'ArrowDown') {
        if (tabCount > currentTabIndex) return focusTab(nextTab.title);
        return focusTab(firstTab.title);
      }

      if (event.key === 'ArrowUp') {
        if (currentTabIndex > 0) return focusTab(prevTab.title);
        return focusTab(lastTab.title);
      }

      if (event.key === 'Home') {
        event.preventDefault();
        return focusTab(firstTab.title);
      }

      if (event.key === 'End') {
        event.preventDefault();
        return focusTab(lastTab.title);
      }
    }
  };

  return (
    <Wrap>
      <TabList role="tablist" aria-label="Side Panel">
        {tabs.map(({ title, icon }) => {
          const isActive = activeTab === title;
          return (
            <TabItem key={title}>
              <Tab
                aria-selected={isActive}
                href={'#' + getId(title, 'tabpanel')}
                id={getId(title)}
                onClick={(e) => handleClick(title, e)}
                onKeyDown={(e) => handleKeyboard(title, e)}
                tabIndex={isActive ? 0 : -1}
                ref={(ref) => (tabRefs.current[title] = ref)}
                role="tab"
              >
                {icon && (
                  <IconWrapper isActive={isActive} title={title}>
                    <Tooltip title={title}>{icon}</Tooltip>
                  </IconWrapper>
                )}
              </Tab>
            </TabItem>
          );
        })}
      </TabList>
      {activeTab &&
        tabs.map(({ title, children }) => (
          <TabPanel
            aria-labelledby={getId(title)}
            id={getId(title, 'tabpanel')}
            isActive={activeTab === title}
            key={title}
            tabIndex={0}
            role="tabpanel"
          >
            <TabPanelHeader>
              <CloseButton
                onClick={() => {
                  setActiveTab('');
                }}
                aria-label="Close button"
              >
                <X />
              </CloseButton>
            </TabPanelHeader>
            {activeTab === title && (
              <TabPanelBody>{children || 'No content yet'}</TabPanelBody>
            )}
          </TabPanel>
        ))}
    </Wrap>
  );
};

export default Tabs;

import * as React from 'react';
import { FC } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { UserRole } from '../../types/user.types';
import { Lock, Trash } from 'react-feather';
interface UserActionMenuProps {
  onClickResetPassword: () => void;
  onClickDelete: () => void;
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
  activeUserRole?: string;
}

export const UserActionMenu: FC<UserActionMenuProps> = ({
  onClickResetPassword,
  onClickDelete,
  onClose,
  open,
  anchorEl,
  activeUserRole,
}) => {
  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      {activeUserRole !== UserRole.superAdmin && (
        <MenuItem style={{ fontSize: '16px' }} onClick={onClickResetPassword}>
          <Lock size={16} style={{ marginRight: 10 }} />
          Reset Password
        </MenuItem>
      )}
      <MenuItem onClick={onClickDelete}>
        <Trash size={16} style={{ marginRight: 10 }} />
        Remove User
      </MenuItem>
    </Menu>
  );
};

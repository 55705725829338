import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel, {
  tableSortLabelClasses,
} from '@mui/material/TableSortLabel';
import styled from 'styled-components';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#243044',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  [`& .${tableSortLabelClasses.icon}`]: {
    color: `${theme.palette.common.white} !important`,
  },
  [`&.${tableSortLabelClasses.root}`]: {
    color: `${theme.palette.common.white} !important`,
  },
}));

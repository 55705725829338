import { FC, useEffect, useState } from 'react';
import { Sliders } from 'react-feather';
import styled from 'styled-components';
import { useEditorData, useFabricEditor } from '../../lib/FabricJs';
import { FontPicker } from './FontPicker';
import { Position } from './Position';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from '@mui/material';
import ButtonComponent from '../common/ButtonComponent';
import Lock from '../Lock';
import Delete from '../Delete';

interface StyleProps {
  activeColor: string;
}

const ToolBarContainer = styled.div`
  background: white;
  display: flex;
  height: 50px;
  border-bottom: 1px solid lightgray;
  justify-content: space-between;
`;

const ColorPicker = styled.div<StyleProps>`
  height: 30px;
  width: 30px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid gray;
  background-color: ${({ activeColor }) => activeColor};
  cursor: pointer;
`;

const Left = styled.div`
  align-items: center;
  display: flex;
`;

const Right = styled.div`
  padding-right: 16px;
  display: flex;
`;

const KvsView = styled.div`
  align-items: center;
  display: flex;
  background-color: #709bcb;
  padding: 0 16px;
  margin: 5px 0;
  border-radius: 4px;
  color: white;
`;

const CustomTooltip = styled(Tooltip)`
  padding-left: 16px;
`;

const ToolBar: FC = () => {
  const { activeTab, setActiveTab, activeColor, selectMode } = useEditorData();
  const { canvas } = useFabricEditor();
  const [kvsKey, setKvsKey] = useState(null);

  useEffect(() => {
    const activeObjects = canvas?.getActiveObjects();
    if (activeObjects?.length === 1) {
      const [activeObject] = activeObjects;
      if (activeObject?.data?.type === 'kvs') {
        setKvsKey(activeObject?.data?.kvsKey);
      } else {
        setKvsKey(null);
      }
    } else {
      setKvsKey(null);
    }
  }, [canvas?.getActiveObjects()]);

  return (
    <ToolBarContainer>
      <Left>
        {selectMode && (
          <Tooltip title="Settings">
            <ButtonComponent onClick={() => setActiveTab('Settings')}>
              <Sliders />
            </ButtonComponent>
          </Tooltip>
        )}
        <ColorPicker
          activeColor={activeColor}
          onClick={() => {
            setActiveTab(activeTab === 'Colors' ? '' : 'Colors');
          }}
        />
        <FontPicker />
      </Left>
      {kvsKey && <KvsView>KVS: {kvsKey}</KvsView>}
      <Right>
        <Position />
        {selectMode && (
          <>
            <Lock />
            <CustomTooltip title="Deselect selected items.">
              <FormControlLabel
                onClick={() => {
                  canvas?.discardActiveObject();
                }}
                control={<Checkbox defaultChecked />}
                label="Deselect"
              />
            </CustomTooltip>
            <Delete />
          </>
        )}
      </Right>
    </ToolBarContainer>
  );
};

export default ToolBar;

import * as React from 'react';
import { FC, useEffect, useState } from 'react';

import { useConfirm } from 'material-ui-confirm';
import { Edit } from 'react-feather';

import { Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ChevronDown } from 'react-feather';

import { useGetPaginateUsers, useUserDelete } from '../../api-http/users';
import { DialogModal } from '../../components';
import { AlertBar } from '../../components/AlertBar';
import { notifyMsg } from '../../configs';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { BaseUserWithOrg, UserRole } from '../../types/user.types';
import { displayDate } from '../../util/dateTime';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useUserRoleName } from '../../util/useUserTypes';
import { UserUpdateForm } from './UserUpdateForm';
import { UserActionMenu } from './UserActionMenu';
import { ResetPasswordForm } from '../admin/users/ResetPasswordForm';
import { useAuth } from '../../context/auth-context';
import { useTable } from '../../util/useTable';
import { useSnack } from '../../util/useSnack';
import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../components/TableTheme';

interface UsersTableProp {
  refresh: boolean;
  search: string;
}

export const UsersTable: FC<UsersTableProp> = ({ refresh, search }) => {
  const confirm = useConfirm();
  const { switchedOrgId } = useAuth();
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { sortOnClick, pagingParams, tableProps } = useTable();
  const { sortby, order } = pagingParams;

  const [userUpdateModal, setUserUpdateModal] = useState(false);
  const [activeUser, setActiveUser] = useState<BaseUserWithOrg>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userResetPasswordModal, setUserResetPasswordModal] = useState(false);

  const open = Boolean(anchorEl);
  const disableAccess = useIsUserRole([
    UserRole.user,
    UserRole.dataEditor,
    UserRole.layoutEditor,
  ]);

  const {
    isLoading: isLoadingUserDelete,
    error: errorUserDelete,
    mutate,
  } = useUserDelete({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.USER_DELETE_SUCCESS);
      refetch();
    },
    onError: () => showErrSnack(notifyMsg.DELETE_ATTEMPT_FAILED),
  });

  const handleDelete = async () => {
    try {
      await confirm({
        description: notifyMsg.DELETE_CONFIRMATION,
        confirmationText: 'Yes',
      });
      mutate({ id: activeUser?._id || '' });
    } catch (error) {}
  };

  const onEditValue = (user: BaseUserWithOrg) => {
    setActiveUser(user);
    setUserUpdateModal(true);
  };

  const {
    data: list,
    isLoading,
    error,
    refetch,
    isRefetching,
  } = useGetPaginateUsers({ ...pagingParams, search, organization: '' });

  useEffect(() => {
    refetch();
  }, [refresh, switchedOrgId, search]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: BaseUserWithOrg,
  ) => {
    setActiveUser(user);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {(isLoading || isLoadingUserDelete || isRefetching) && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg || errorUserDelete?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <StyledTableSortLabel
                  active={sortby === SortEnum.title || true}
                  direction={sortby === SortEnum.title ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.title)}
                >
                  User Name
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right" />
              <StyledTableCell align="left">User Role</StyledTableCell>
              <StyledTableCell align="right">Email</StyledTableCell>
              <StyledTableCell align="right">
                <StyledTableSortLabel
                  active={sortby === SortEnum.updatedAt || true}
                  direction={
                    sortby === SortEnum.updatedAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.updatedAt)}
                >
                  Updated At
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right">
                <StyledTableSortLabel
                  active={sortby === SortEnum.createdAt || true}
                  direction={
                    sortby === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Created At
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.data.map((user) => {
              return (
                <TableRow
                  key={user._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {user.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Button
                      onClick={() => onEditValue(user)}
                      disabled={disableAccess}
                    >
                      <Edit size={16} />
                    </Button>
                  </StyledTableCell>
                  <TableCell align="left">
                    {useUserRoleName(user.role)}
                  </TableCell>
                  <StyledTableCell align="right">{user.email}</StyledTableCell>
                  <StyledTableCell align="right">
                    {displayDate(user.updatedAt)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {displayDate(user.createdAt)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Button
                      disabled={disableAccess}
                      onClick={(event) => handleClick(event, user)}
                    >
                      <ChevronDown size={16} />
                    </Button>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>

      <UserActionMenu
        anchorEl={anchorEl}
        open={open}
        activeUserRole={activeUser?.role}
        onClose={handleClose}
        onClickDelete={() => {
          handleDelete();
          handleClose();
        }}
        onClickResetPassword={() => {
          setUserResetPasswordModal(true);
          handleClose();
        }}
      />

      <DialogModal
        title={'User update form'}
        onClose={() => setUserUpdateModal(false)}
        open={userUpdateModal}
      >
        <UserUpdateForm
          user={activeUser}
          onFinished={() => {
            refetch();
            setUserUpdateModal(false);
          }}
        />
      </DialogModal>
      <DialogModal
        onClose={() => setUserResetPasswordModal(false)}
        open={userResetPasswordModal}
        title={'Reset password'}
      >
        <ResetPasswordForm
          onFinished={() => {
            refetch();
            setUserResetPasswordModal(false);
          }}
          userData={activeUser}
        />
      </DialogModal>
    </>
  );
};

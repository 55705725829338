import { FC, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import styled from 'styled-components';
import { useFabricEditor } from '../../lib/FabricJs';
import Button from './components/Button';
import Span from './components/Span';
import { Box, CircularProgress, Tooltip, debounce } from '@mui/material';
import { DEBOUNCE_TIME } from '../../../../configs';
import { useGetAssetsWithSearch } from '../../../../api-http/assets';
import { useSnack } from '../../../../util/useSnack';
import { DialogModal } from '../../../DialogModal';
import { AddAssetForm } from '../../../../containers/assets/AddAssetForm';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ImageContainer = styled.div`
  max-width: 50%;
  display: flex;
  padding: 8px;
  max-height: 150px;
  cursor: pointer;
`;

const Img = styled.img`
  width: 100%;
  max-width: 160px;
  object-fit: contain;
`;

const SearchBox = styled.input`
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 10px;
  margin-bottom: 5px;
  outline: none;
`;

const ImageWidget: FC = () => {
  const [searchText, setSearchText] = useState();
  const [openAddAssetModal, setOpenAddAssetModal] = useState(false);
  const { canvas } = useFabricEditor();

  const {
    data: images,
    isLoading,
    error,
    refetch,
    isError,
  } = useGetAssetsWithSearch(searchText || '');
  const { showErrSnack } = useSnack();

  useEffect(() => {
    refetch();
  }, [searchText]);

  useEffect(() => {
    if (isError) showErrSnack(error.msg);
  }, [images]);

  const onAddImage = (path: string, assetId: string) => {
    fabric.Image.fromURL(
      path,
      function (oImg) {
        oImg.set('data', { assetId });
        oImg.scaleToWidth(600, true);
        oImg.scaleToHeight(600, true);
        oImg.applyResizeFilters();
        oImg.setControlsVisibility({
          mt: false, // middle top disable
          mb: false, // middle bottom
          ml: false, // middle left
          mr: false, // I think you get it
        });
        canvas?.add(oImg);
      },
      {
        ...canvas?.getCenter(),
      },
    );
  };
  const onFinished = () => {
    setOpenAddAssetModal(false);
    refetch();
  };

  return (
    <>
      <Button
        onClick={() => setOpenAddAssetModal(true)}
        style={{ marginTop: '20px', marginBottom: '10px' }}
      >
        Upload Image
      </Button>
      <Span>Images</Span>
      <SearchBox
        placeholder="Search Images"
        onChange={debounce((e) => setSearchText(e.target.value), DEBOUNCE_TIME)}
      />
      {isLoading && (
        <Box
          sx={{ display: 'flex', height: '80vh' }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="info" size={100} />
        </Box>
      )}
      <Container>
        {images?.map(({ url, fileName, _id }, index) => {
          return (
            <Tooltip key={index} title={fileName}>
              <ImageContainer
                onClick={() => onAddImage(url, _id)}
                role="button"
                aria-label="Add this photo to the canvas"
                tabIndex={0}
              >
                <Img src={url} alt={fileName} />
              </ImageContainer>
            </Tooltip>
          );
        })}
      </Container>
      <DialogModal
        title="Add asset"
        onClose={() => setOpenAddAssetModal(false)}
        open={openAddAssetModal}
      >
        <AddAssetForm onFinished={() => onFinished()} />
      </DialogModal>
    </>
  );
};

export default ImageWidget;

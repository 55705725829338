import { notifyMsg } from '../../../configs';
import { useSnack } from '../../../util/useSnack';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  ActivityWrapper,
  ScreenshotBlock,
  ScreenshotsMainWrapper,
  ScreenshotsWrapper,
} from '../styles';
import {
  useDeleteSnapshot,
  useGetSnapshots,
  useRequestSnapshot,
} from '../../../api-http/devices';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStaticFileUrl } from '../../../util/getStaticFileUrl';
import useSocket from '../../../util/useSocket';
import { useEffect, useRef, useState } from 'react';
import { EVENTS, SOCKET_EVENTS } from '../../../util/constant';
import { displayDistance } from '../../../util/dateTime';
import ImageViewer from 'react-simple-image-viewer';
import HideImageIcon from '@mui/icons-material/HideImage';

const ProofOfPlay = ({ deviceId }: { deviceId?: string }) => {
  const { showSuccessSnack, showErrSnack } = useSnack();
  const { data, refetch } = useGetSnapshots(deviceId || '');
  const [currentImage, setCurrentImage] = useState('');
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const selectedKey = useRef('');

  const { socket } = useSocket();

  const openImageViewer = (image: string) => {
    setCurrentImage(image);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage('');
    setIsViewerOpen(false);
  };

  useEffect(() => {
    deviceId && refetch();
  }, [deviceId]);

  useEffect(() => {
    if (socket) {
      socket.on(SOCKET_EVENTS.dataConnection, (socketData) => {
        const { event, data } = socketData;

        if (event === EVENTS.uploadSnapShot && data.includes(deviceId)) {
          refetch();
          showSuccessSnack(notifyMsg.CAPTURE_REQUEST_SUCCESS);
        }
      });
    }

    return () => {
      if (socket) {
        socket.off(SOCKET_EVENTS.dataConnection);
      }
    };
  }, [deviceId, socket]);

  const { mutate, isLoading } = useRequestSnapshot({
    onSuccess() {
      showSuccessSnack(notifyMsg.CAPTURE_REQUEST_SENT);
    },
    onError: (err) => showErrSnack(err || notifyMsg.CAPTURE_REQUEST_FAILED),
  });

  const { mutate: deleteScreenshot, isLoading: deleting } = useDeleteSnapshot(
    deviceId || '',
    {
      onSuccess() {
        showSuccessSnack(notifyMsg.SCREENSHOT_DELETED);
        refetch();
      },
      onError: (err) => showErrSnack(err || notifyMsg.SCREENSHOT_DELETE_FAILED),
    },
  );

  const deleteImage = (key: string) => {
    deleteScreenshot({ key });
  };

  const screenShots = data?.map((screenshot, index) => {
    const { key, url, uploadedTime, fileName } = screenshot;
    const imageUrl = getStaticFileUrl(url);

    return (
      <ScreenshotBlock key={index}>
        <img
          onClick={() => {
            openImageViewer(imageUrl);
          }}
          src={imageUrl}
          alt={fileName}
        />
        <div>
          <Typography gutterBottom={false} variant="body1">
            {fileName}
          </Typography>
          <Typography variant="caption">
            {displayDistance(uploadedTime.toString())}
          </Typography>
        </div>
        <div>
          {deleting && selectedKey.current === key ? (
            <IconButton>
              <CircularProgress size="20px" />
            </IconButton>
          ) : (
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => {
                selectedKey.current = key;
                deleteImage(key);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </ScreenshotBlock>
    );
  });

  const captureScreen = () => {
    if (deviceId) {
      mutate(deviceId);
    }
  };

  return (
    <ScreenshotsMainWrapper>
      {isViewerOpen && (
        <ImageViewer
          src={[currentImage]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            zIndex: 9999,
          }}
        />
      )}
      <Grid container>
        <Grid item xs={9}>
          <Typography fontWeight={'bold'} variant="h6" gutterBottom>
            Screenshots
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="flex-end">
          <Button
            style={{ fontWeight: 'bold', fontSize: '12px', width: '140px' }}
            variant="contained"
            color="success"
            disabled={isLoading}
            onClick={captureScreen}
          >
            Capture
          </Button>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Stack divider={<Divider flexItem />}>
            {screenShots && screenShots.length > 0 ? (
              <ScreenshotsWrapper>{screenShots}</ScreenshotsWrapper>
            ) : (
              <ActivityWrapper>
                <Typography
                  marginLeft={'-15px'}
                  color={'gray'}
                  marginBottom={'10px'}
                  fontSize={15}
                >
                  This device has no screenshots
                </Typography>
                <HideImageIcon style={{ color: 'gray', fontSize: '45px' }} />
              </ActivityWrapper>
            )}
          </Stack>
        </Grid>
      </Grid>
    </ScreenshotsMainWrapper>
  );
};

export default ProofOfPlay;

import React, { PropsWithChildren, createContext, useContext } from 'react';
import { useState } from 'react';
import { LogoDetails, State, UserFocusData } from './types';
import useUndo from './hooks/useUndo';
import { AlignmentTypes, logoDetails } from './utils/logoData';

const Context = createContext<State | null>(null);

const SURVEY_INIT_DATA = {
  title: '',
  description: '',
  logoPosition: 'right',
  pages: {},
};

const FOCUS_INIT_DATA: UserFocusData = {
  pageId: '',
  elementId: '',
  type: '',
};

const EditorProvider = ({ children }: PropsWithChildren) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedItem, setSelectedItem] =
    useState<UserFocusData>(FOCUS_INIT_DATA);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [history, { set: setHistory, undo, redo, canUndo, canRedo }] =
    useUndo<any>(SURVEY_INIT_DATA);
  const [logoSize, setLogoSize] = useState<LogoDetails>(logoDetails[0]);
  const [selectedLogo, setSelectedLogo] = useState<string | null>(null);
  const [alignment, setAlignment] = useState<AlignmentTypes>(
    AlignmentTypes.LEFT,
  );

  const contextValues = {
    history,
    setHistory,
    undo,
    redo,
    canUndo,
    canRedo,
    isSettingsOpen,
    setIsSettingsOpen,
    currentPage,
    setCurrentPage,
    selectedItem,
    setSelectedItem,
    showPreview,
    setShowPreview,
    logoSize,
    setLogoSize,
    selectedLogo,
    setSelectedLogo,
    alignment,
    setAlignment,
  };

  return <Context.Provider value={contextValues}>{children}</Context.Provider>;
};

const useEditorData = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useEditorData must be used within a EditorProvider');
  }
  return context;
};

export { EditorProvider, useEditorData };

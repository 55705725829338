import {
  useGetFormResponses,
  useGetResponsesList,
} from '../../../api-http/formResponses';
import Breadcrumbs from '../../../components/Breadcrumb';
import RefreshButton from '../../../components/RefreshButton';
import { StyleTextfield } from '../../../components/TextField';
import { FilterSelectWrapper } from '../../../components/common/FilterSelectWrapper';
import { DEBOUNCE_TIME } from '../../../configs';
import { UserRole } from '../../../types/user.types';
import { metaTitle } from '../../../util/tabTitle';
import { useIsUserRole } from '../../../util/useIsUserRole';
import { useSnack } from '../../../util/useSnack';
import { useTable } from '../../../util/useTable';
import FormResponsesTable from './FormResponsesTable';
import { Button, debounce } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useLocation, useParams } from 'react-router-dom';

const FormResponsesPage: FC = () => {
  const { id } = useParams();
  const { tableProps, pagingParams, sortOnClick } = useTable();

  const { state } = useLocation();

  const { showErrSnack } = useSnack();

  metaTitle('Form Responses');

  const disabledCsvExportAccess = useIsUserRole([
    UserRole.superAdmin,
    UserRole.orgAdmin,
  ]);

  const [search, setSearch] = useState('');
  const [refreshTable, setRefreshTable] = useState(false);
  const [transformedObjects, setTransformedObjects] = useState<Array<any>>([]);

  const setSearchText = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSearch(event.target.value);
  };

  const {
    isLoading,
    isError,
    data: responses,
    refetch,
    error,
  } = useGetFormResponses({
    ...pagingParams,
    formid: id,
    search,
  });

  const {
    isLoading: responsesListIsLoading,
    isError: responsesListIsError,
    data: responsesListData,
    error: responsesListError,
  } = useGetResponsesList(id);

  useEffect(() => {
    const questions = responsesListData?.questions;
    const responses = responsesListData?.responses;

    if (questions && responses) {
      for (const page of questions) {
        for (const response of responses) {
          let responseData = {};
          page.pageQuestions.forEach((qes: { label: string; key: string }) => {
            const pageQuestion = qes.label;
            const answer = response.response[qes.key];
            const newResponseData = { [pageQuestion]: answer };
            responseData = { ...responseData, ...newResponseData };
          });

          setTransformedObjects((prevState) => {
            return [...prevState, responseData];
          });
        }
      }
    }
  }, [responsesListData]);

  useEffect(() => {
    if (responsesListIsError && responsesListError) {
      showErrSnack(responsesListError.msg);
    }
  }, [responsesListError]);

  const searchParams = state?.search;
  const formsPageUrl = searchParams ? `/forms${searchParams}` : '/forms';

  return (
    <div>
      <Breadcrumbs
        items={[
          { label: 'Forms', to: formsPageUrl },
          {
            label: 'Responses',
            to: '/forms/responses',
            description:
              'This document encompasses all the responses received for a specific form, presenting them as a concise overview of all the collected data for that particular form.',
          },
        ]}
      />
      <FilterSelectWrapper>
        <div>
          <Button
            variant="outlined"
            color="primary"
            disabled={
              responsesListIsLoading ||
              responsesListData?.responses.length === 0 ||
              !disabledCsvExportAccess
            }
          >
            <CSVLink
              data={transformedObjects}
              filename={`${responsesListData?.name}.csv`}
              style={{
                textDecoration: 'none',
                color: '#376fd0',
              }}
            >
              Export to CSV
            </CSVLink>
          </Button>
          <RefreshButton onClick={() => setRefreshTable(!refreshTable)} />
        </div>
        <StyleTextfield
          label={'Search Responses'}
          variant={'outlined'}
          onChange={debounce((event) => setSearchText(event), DEBOUNCE_TIME)}
        />
      </FilterSelectWrapper>
      <FormResponsesTable
        search={search}
        refresh={refreshTable}
        isLoading={isLoading}
        isError={isError}
        responses={responses}
        refetch={refetch}
        error={error}
        sortOnClick={sortOnClick}
        pagingParams={pagingParams}
        tableProps={tableProps}
      />
    </div>
  );
};

export default FormResponsesPage;

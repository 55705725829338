import { useMutation, useQuery } from '@tanstack/react-query';
import { UserRole } from '../types/user.types';
import { ApiServiceErr, axiosApi, MutOptions } from './apiService';

interface LoginBody {
  email: string;
  password: string;
}

export interface LoginResponse {
  name: string;
  email: string;
  role: UserRole;
  organization: string;
  authToken: string;
}

export interface MeResponse {
  _id: string;
  name: string;
  email: string;
  role: UserRole;
  organization: {
    _id: string;
    name: string;
    email: string;
    pinCode: string;
    orgApiToken?: string;
  };
  authToken: string;
}

export const useLogin = (opt?: MutOptions<LoginResponse>) =>
  useMutation<LoginResponse, ApiServiceErr, LoginBody>(async (data) => {
    const response = await axiosApi.post('/users/login', data);
    return response.data;
  }, opt);

export const useGetMeApi = () =>
  useQuery<MeResponse, ApiServiceErr>(
    ['me'],
    async () => {
      const response = await axiosApi.get('/users/me');
      return response.data;
    },
    {
      enabled: false,
      retry: false,
    },
  );

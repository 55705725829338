import { PagingResponse } from '../types';
import { SurveyJSON } from '../components/survey-editor/types';
import { PaginateOptions } from '../types/pagination.types';
import { ApiServiceErr, axiosApi, MutOptions } from './apiService';
import { useMutation, useQuery } from '@tanstack/react-query';

export interface FormsSummary {
  _id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface FormResponseJson {
  id: string;
  name: string;
  formJson: SurveyJSON;
  responseJson: string;
}

export interface FormResponsesList {
  name: string;
  responses: Array<any>;
  questions: Array<any>;
}

type FormResponsePaginateOptions = PaginateOptions & { formid?: string };

interface FormResponseCreate {
  formId?: string;
  response: string;
}

export const useGetFormResponses = ({
  limit,
  page,
  sortby,
  order,
  formid,
  search,
}: FormResponsePaginateOptions) =>
  useQuery<PagingResponse<FormsSummary>, ApiServiceErr>(
    ['/form-response/all?', limit, page, sortby, order, formid],
    async () => {
      if (formid) {
        const response = await axiosApi.get('/form-response/all', {
          params: { limit, page, sortby, order, formid, search },
        });
        return response.data;
      }
      return null;
    },
  );

export const useGetResponse = (id?: string) =>
  useQuery<FormResponseJson, ApiServiceErr>(
    [`/form-response/${id}`, id],
    async () => {
      if (id) {
        const response = await axiosApi.get(`/form-response/${id}`);
        return response.data;
      }
      return null;
    },
  );

export const useCreateResponse = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, FormResponseCreate>(async (data) => {
    const response = await axiosApi.post('/form-response/create', data);
    return response.data;
  }, opt);

export const useGetResponsesList = (id?: string) =>
  useQuery<FormResponsesList, ApiServiceErr>(
    [`/forms/response-data/${id}`, id],
    async () => {
      if (id) {
        const response = await axiosApi.get(`/forms/response-data/${id}`);
        return response.data;
      }
      return null;
    },
  );

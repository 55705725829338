import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Console, Hook, Unhook } from 'console-feed';
import styled from 'styled-components';

import { SocketDataDebugMode } from '../types';
import { Message as MessageConsole } from 'console-feed/lib/definitions/Console';
import { Message as MessageComponent } from 'console-feed/lib/definitions/Component';

import useSocket from '../util/useSocket';
import { EVENTS, SOCKET_EVENTS } from '../util/constant';
import { useGetDeviceDebugMode } from '../api-http/devices';

const StyledConsoleOverlay = styled.div`
  display: flex;
  position: fixed;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.9);
  padding: 5px;
  height: 100vh;
  width: 50vw;
  overflow-y: auto;
`;

const LogsContainer = () => {
  const { socket } = useSocket();
  const { deviceId } = useParams<{ deviceId: string }>();

  const { data: deviceDebugMode } = useGetDeviceDebugMode(deviceId);

  const [logs, setLogs] = useState<MessageConsole[]>([]);
  const [deviceDebugModeStatus, setDeviceDebugModeStatus] = useState(false);

  useEffect(() => {
    if (socket) {
      const updateDeviceListener = (socketData: SocketDataDebugMode) => {
        const { data: debugModeSocketData, event } = socketData;
        if (
          event === EVENTS.toggleDeviceMode &&
          deviceId === debugModeSocketData[0].deviceId
        ) {
          setDeviceDebugModeStatus(debugModeSocketData[0].currentMode);
        }
      };

      socket.on(SOCKET_EVENTS.updateDevice, updateDeviceListener);

      return () => {
        socket.off(SOCKET_EVENTS.updateDevice, updateDeviceListener);
      };
    }
  }, [socket]);

  useEffect(() => {
    if (deviceDebugMode) {
      setDeviceDebugModeStatus(deviceDebugMode);
    }
  }, [deviceDebugMode]);

  useEffect(() => {
    const hookedConsole = Hook(
      window.console,
      (log: MessageConsole) =>
        setLogs((currLogs: MessageConsole[]) => [log, ...currLogs]),
      false,
    );
    return () => {
      Unhook(hookedConsole);
      return undefined;
    };
  }, []);

  return (
    <>
      {deviceDebugModeStatus && (
        <StyledConsoleOverlay>
          <Console logs={logs as MessageComponent[]} variant="dark" />
        </StyledConsoleOverlay>
      )}
    </>
  );
};

export default LogsContainer;

import { ChangeEvent } from 'react';
import { useEditorData } from '../../EditorProvider';
import { changeElementData, getElementData } from '../../utils/surveyDataUtils';
import { StyledStack, StyledTextarea, Title } from './common';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { InputElement } from '../../types';

type TextChangeEvent = ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

const { TEXT_BOX, CHECK_BUTTON } = InputElement;

const Toggle = () => {
  const { selectedItem: focus, history, setHistory } = useEditorData();
  const { present: surveyData } = history;
  const { pageName, element } = getElementData(surveyData, focus);

  if (!element) return null;

  const {
    name,
    title,
    description,
    labelFalse,
    labelTrue,
    visible = false,
    isRequired = false,
    readOnly = false,
  } = element;

  const onChange = (e: TextChangeEvent, type: string) => {
    const { id, value, checked } = e.target as HTMLInputElement;

    const params = {
      surveyData,
      data: { [id]: type === TEXT_BOX ? value : checked },
      focus,
      setHistory,
    };
    changeElementData(params);
  };

  return (
    <StyledStack spacing={2}>
      <Title>
        {name} <span>{` (${pageName})`}</span>
      </Title>
      <StyledTextarea
        aria-label="textarea"
        placeholder={name}
        id="title"
        name="title"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        value={title}
        onChange={(e: TextChangeEvent) => onChange(e, TEXT_BOX)}
      />
      <StyledTextarea
        aria-label="textarea"
        placeholder="Description"
        id="description"
        name="description"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        value={description}
        onChange={(e: TextChangeEvent) => onChange(e, TEXT_BOX)}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              id="visible"
              checked={visible || false}
              onChange={(e: TextChangeEvent) => onChange(e, CHECK_BUTTON)}
            />
          }
          label="Visible"
        />
        <FormControlLabel
          control={
            <Checkbox
              id="isRequired"
              checked={isRequired || false}
              onChange={(e: TextChangeEvent) => onChange(e, CHECK_BUTTON)}
            />
          }
          label="Required"
        />
        <FormControlLabel
          control={
            <Checkbox
              id="readOnly"
              checked={readOnly || false}
              onChange={(e) => onChange(e, CHECK_BUTTON)}
            />
          }
          label="Read-only"
        />
      </FormGroup>
      <StyledTextarea
        aria-label="textarea"
        placeholder="False value"
        id="labelFalse"
        name="labelFalse"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        value={labelFalse}
        onChange={(e: TextChangeEvent) => onChange(e, TEXT_BOX)}
      />
      <StyledTextarea
        aria-label="textarea"
        placeholder="True value"
        id="labelTrue"
        name="labelTrue"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        value={labelTrue}
        onChange={(e: TextChangeEvent) => onChange(e, TEXT_BOX)}
      />
    </StyledStack>
  );
};

export default Toggle;

import React, { FC } from 'react';
import {
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Paper,
  Chip,
} from '@mui/material';
import Link from '@mui/material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Mode } from '../types';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { Refresh } from '@mui/icons-material';

const defualtButtonStyle = {
  style: { marginLeft: '4px', marginRight: '4px', minWidth: 32 },
  sx: {
    textTransform: 'none',
  },
};

const Title = styled('div')`
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 3px 0;
`;

const BoxContainer = muiStyled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  marginBottom: 15,
  marginTop: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

interface DraftPublishSwitcherProps {
  haveUnpublishedChanges: boolean;
  onChange: (mode: Mode) => void;
  onDiscard: () => void;
  onPublish: () => void;
  onRefetch: () => void;
  isPublishedMode: boolean;
  hasPublishedVersion: boolean;
}

const ActionMenu: FC<Pick<DraftPublishSwitcherProps, 'onDiscard'>> = ({
  onDiscard,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box style={{ display: 'inline-block' }}>
        <Tooltip title="More Actions">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={onDiscard}>Discard Draft</MenuItem>
      </Menu>
    </>
  );
};

const DraftPublishSwitcher: FC<DraftPublishSwitcherProps> = ({
  onChange,
  onDiscard,
  onPublish,
  haveUnpublishedChanges,
  isPublishedMode,
  hasPublishedVersion,
  onRefetch,
}) => {
  return (
    <BoxContainer elevation={1}>
      <Box>
        <Title>
          Now, You are in
          {isPublishedMode ? (
            <Chip
              label="Published mode"
              color="success"
              size="small"
              sx={{ marginLeft: '5px', marginRight: '5px' }}
            />
          ) : (
            <Chip
              label="Draft mode"
              color="warning"
              size="small"
              sx={{ marginLeft: '5px', marginRight: '5px' }}
            />
          )}
          {!isPublishedMode && hasPublishedVersion && (
            <Link
              {...defualtButtonStyle}
              component="button"
              variant="body2"
              onClick={() => onChange(Mode.Published)}
            >
              Show published version
            </Link>
          )}
          {isPublishedMode && (
            <Badge
              color="secondary"
              badgeContent={haveUnpublishedChanges ? 1 : 0}
            >
              <Button
                {...defualtButtonStyle}
                onClick={() => onChange(Mode.Draft)}
                size="small"
              >
                Show draft version
              </Button>
            </Badge>
          )}
        </Title>
      </Box>
      <Box>
        <Button
          {...defualtButtonStyle}
          onClick={() => onRefetch()}
          size="small"
        >
          <Refresh />
        </Button>
        {!isPublishedMode && (
          <Button
            {...defualtButtonStyle}
            onClick={() => {
              onPublish();
              onChange(Mode.Published);
            }}
            color="success"
            size="small"
            variant="contained"
          >
            {haveUnpublishedChanges ? 'Save & ' : ''}Publish
          </Button>
        )}

        {!isPublishedMode && <ActionMenu onDiscard={onDiscard} />}
      </Box>
    </BoxContainer>
  );
};

export default DraftPublishSwitcher;

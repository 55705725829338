import { FormsResponse } from '../api-http/forms';
import { EventResponse } from './event.types';

export enum DeviceType {
  FORM_DEVICE = 'form_device',
  SCHEDULED_DEVICE = 'scheduled_device',
}

export interface SchedulerResponse {
  id: string;
  _id: string;
  name: string;
  description: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  events: [EventResponse];
  defaultPresentation: string;
}

export interface ShowSchedulerResponse {
  operatingType: string;
  scheduler: SchedulerResponse;
  form?: FormsResponse;
}
export interface SchedulerGetResponse {
  id: string;
  _id: string;
  name: string;
  description: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  events: [EventResponse];
  defaultPresentation: DefaultPresentationResponse;
}

export interface DefaultPresentationResponse {
  _id: string;
  name: string;
}

export interface SchedulerEventResponse {
  _id: string;
  scheduler: string;
  presentation: string;
  presentationSchedule: string;
  organization: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
}

type RRuleData = {
  dtstart: string;
  freq: number;
  until: string;
};
export interface SchedulerEventResponseBody {
  _id: string;
  presentation: string;
  presentationSchedule: RRuleData | string | null;
  organization: string;
  startTime: string;
  endTime: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}
export interface SchedularData {
  presentation: string;
  scheduler: string;
  presentationSchedule: RRuleData | string | null;
  startTime: string;
  endTime: string;
}

export interface UpdateEventBody {
  id: string;
  startTime: string;
  endTime: string;
  presentationSchedule?: string;
  presentation?: string;
}

export type SchedulerEventBody = SchedularData;

import { Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { Trash2, Edit3 } from 'react-feather';

interface ActionMenuProps {
  onClickDelete: () => void;
  onClickRename: () => void;
  onClose: () => void;
  open: boolean;
  disableDelete: boolean;
  anchorEl: HTMLElement | null;
}

export const ActionMenu: FC<ActionMenuProps> = ({
  onClickDelete,
  onClickRename,
  onClose,
  open,
  disableDelete,
  anchorEl,
}) => {
  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      <MenuItem onClick={onClickDelete} disabled={disableDelete}>
        <Trash2 size={16} style={{ marginRight: 10 }} />
        Delete Asset
      </MenuItem>
      <MenuItem onClick={onClickRename}>
        <Edit3 size={16} style={{ marginRight: 10 }} />
        Rename
      </MenuItem>
    </Menu>
  );
};

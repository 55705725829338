import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FC } from 'react';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
}));

const iconButtonStyle = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'black',
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled.h2`
  font-family: 'roboto', sans-serif;
  font-size: 18px;
  margin: 0;
`;

interface DialogModalProp extends React.PropsWithChildren {
  onClose: () => void;
  open: boolean;
  title?: string;
  style?: React.CSSProperties;
  width?: number | string;
}

export const DialogModal: FC<DialogModalProp> = ({
  open,
  onClose,
  title,
  children,
  style = {},
  width = 450,
}) => {
  const classes = useStyles();
  const defaultStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '5px',
    overflowY: 'auto',
    maxHeight: '80%',
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <Box sx={[defaultStyle, style]}>
        <Header>
          <HeaderText style={{ fontWeight: 'bold' }}>{title}</HeaderText>
          <IconButton aria-label="close" onClick={onClose} sx={iconButtonStyle}>
            <CloseIcon />
          </IconButton>
        </Header>
        {children}
      </Box>
    </Modal>
  );
};

import { useEditorData } from '../EditorProvider';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import getAlignment from '../utils/getAlignment';

interface Props {
  data: any;
}

const useStyles = makeStyles(() => ({
  backDrop: {
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0,0,30,0.8)',
  },
}));

const iconStyle = {
  color: 'white',
  alignSelf: 'end',
};

const SurveyContainer = styled.div`
  width: 50vw;
  height: 90vh;
  display: flex;
  overflow: scroll;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PreviewModal: FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { showPreview, setShowPreview, history } = useEditorData();
  const { present: surveyData } = history;
  const containerRef = useRef(null);

  useEffect(() => {
    const elements = document.getElementsByClassName(
      'sd-navigation__complete-btn',
    );

    const elementsArray = Array.prototype.slice.call(elements);

    elementsArray.forEach((element) => {
      element.remove();
    });
  }, []);

  const survey = new Model(data);

  survey.css = {
    question: {
      title: `${getAlignment(surveyData)} sv_question_title`,
      description: `${getAlignment(surveyData)} sv_question_description`,
      content: getAlignment(surveyData),
      contentleft: getAlignment(surveyData),
      mainRoot: 'sd-element sd-question sv-row__question',
    },
    rating: {
      itemSmiley: 'sv_rating_smiley_item_preview',
    },
  };

  return (
    <Modal
      open={showPreview}
      onClose={() => setShowPreview(false)}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <Content id="test">
        <IconButton
          aria-label="close"
          onClick={() => setShowPreview(false)}
          sx={iconStyle}
        >
          <Close />
        </IconButton>
        <SurveyContainer ref={containerRef}>
          <Survey model={survey} />
        </SurveyContainer>
      </Content>
    </Modal>
  );
};

export default PreviewModal;

import { useMutation, useQuery } from '@tanstack/react-query';

import { axiosDisplayApi } from './displayApiService';
import { ApiServiceErr, axiosApi, MutOptions } from './apiService';
import { DynamicKeyValue, ProjectData } from '../types/slide.types';

interface LoginWithPinCodeBody {
  pincode: string;
}

export interface LoginWithPinCodeResponse {
  authToken: string;
}

interface Slide {
  animation: string;
  duration: number;
  slideData: string;
  slideId: string;
}

export interface GetDisplayPresentationsResponse {
  _id: string;
  name: string;
  slides: Slide[];
  status: boolean;
  organization: string;
  createdAt: string;
  updatedAt: string;
}

interface SlideDataBody {
  _id: string;
  title: string;
  status: boolean;
  projectData: ProjectData;
  dynamicData: DynamicKeyValue[];
  organization: string;
  createdAt: string;
  updatedAt: string;
}

interface SlideData extends Omit<Slide, 'slideId'> {
  slideId: SlideDataBody;
}

export interface AssetData {
  assetId: string;
  assetSrc: string;
}

export interface GetDisplayPresentationResponse {
  _id: string;
  name: string;
  slides: SlideData[];
  status: boolean;
  organization: string;
  createdAt: string;
  updatedAt: string;
  draft?: string;
  assets?: AssetData[];
  slideClones?: any;
  kvs?: any;
}

export const useLoginWithPinCode = (
  opt?: MutOptions<LoginWithPinCodeResponse>,
) =>
  useMutation<LoginWithPinCodeResponse, ApiServiceErr, LoginWithPinCodeBody>(
    async (data) => {
      const response = await axiosApi.post(
        `/organizations/display-app/login`,
        data,
      );
      return response.data;
    },
    opt,
  );

export const useGetDisplayPresentations = () =>
  useQuery<GetDisplayPresentationsResponse[], ApiServiceErr>(
    ['display-app/auth/presentations'],
    async () => {
      const response = await axiosDisplayApi.get(
        `/presentations/display-app/auth/presentations`,
      );
      return response.data;
    },
  );

export const useGetDisplayPresentation = (id?: string) =>
  useQuery<GetDisplayPresentationResponse, ApiServiceErr>(
    ['display-app/auth/{id}', id],
    async () => {
      if (id) {
        const response = await axiosDisplayApi.get(
          `/presentations/display-app/auth/${id}`,
        );
        const presentation = response.data;
        for (const [key, slide] of presentation?.slides?.entries() || []) {
          slide.slideData = presentation?.slideClones[key];
        }
        return presentation;
      }

      return null;
    },
  );

import { v4 as uid } from 'uuid';

import {
  AddDeleteElementProps,
  ChangeProps,
  InputType,
  SurveyJSON,
  SurveyPage,
  UserFocusData,
} from '../types';

const ITEM = 'item';

const deepClone = (object: any) => JSON.parse(JSON.stringify(object));

const convertItemsToChoices = (formObject: any) => {
  formObject.choices = Object.keys(formObject)
    .filter((key) => key.startsWith(ITEM))
    .map((key) => formObject[key]);
};

const prepareJSON = (surveyData: SurveyJSON) => {
  const { pages } = deepClone(surveyData);
  const pageKeys = Object.keys(pages);

  for (const key of pageKeys) {
    pages[key].elements = Object.values(pages[key].elements);
    const elementKeys = Object.keys(pages[key].elements);
    for (const index of elementKeys) {
      convertItemsToChoices(pages[key].elements[index]);
    }
  }

  return {
    ...surveyData,
    pages: Object.values(pages) as SurveyPage[],
  };
};

const getPageData = (surveyData: SurveyJSON, pageId: string) => {
  return deepClone(surveyData)?.pages[pageId];
};

const getElementData = (
  surveyData: SurveyJSON,
  focus: Omit<UserFocusData, 'type'>,
) => {
  const { pages } = deepClone(surveyData);

  const { pageId, elementId } = focus;
  return {
    pageName: pages[pageId]?.name,
    element: pages[pageId]?.elements?.[elementId],
  };
};

const changeElementData = (params: ChangeProps) => {
  const { surveyData, data, focus, setHistory } = params;
  const prevState = deepClone(surveyData);
  const { pages } = prevState;

  if (!focus || !pages) return;

  const { pageId, elementId } = focus;
  const prevElement = pages[pageId]?.elements[elementId];
  pages[pageId].elements[elementId] = { ...prevElement, ...data };
  setHistory({ ...prevState, pages });
};

const changePageData = (params: ChangeProps) => {
  const { surveyData, data, focus, setHistory } = params;
  const prevState = deepClone(surveyData);
  const { pages } = prevState;

  if (!focus || !pages) return;

  const { pageId } = focus;
  const id = pageId as string;
  pages[id] = { ...pages[id], ...data };
  setHistory({ ...prevState, ...pages });
};

const changeSurveyData = (params: ChangeProps) => {
  const { surveyData, data, setHistory } = params;
  const survey = deepClone(surveyData);
  setHistory({ ...survey, ...data });
};

const addElement = (params: AddDeleteElementProps) => {
  const { surveyData, elementId, setHistory, type } = params;
  let { pageId } = params;
  const json = deepClone(surveyData);

  if (!pageId) pageId = uid();

  const pages = json.pages[pageId];

  if (!pages) {
    json.pages[pageId] = {
      id: pageId,
      name: '',
      title: '',
      description: '',
      elements: {},
    };
  }
  const pageIds = Object.keys(json.pages);
  const isLastKey = pageId === pageIds.slice(-1)[0];

  if (isLastKey) {
    const newPageId = uid();
    json.pages[newPageId] = {
      id: newPageId,
      name: '',
      title: '',
      description: '',
      elements: {},
    };
  }

  const elementCount = Object.keys(json.pages[pageId].elements).length;

  json.pages[pageId].elements[elementId] = {
    id: elementId,
    title: '',
    description: '',
    type: type || InputType.SINGLE_LINE,
    name: `Question ${elementCount + 1}`,
    isRequired: false,
    readOnly: false,
    visible: true,
  };
  setHistory(json);
};

const removeComponent = (params: AddDeleteElementProps) => {
  const { surveyData, elementId, setHistory, setSelectedItem } = params;
  let { pageId } = params;
  const prevState = deepClone(surveyData);

  pageId = pageId as string;
  delete prevState.pages[pageId].elements[elementId];

  const keys = Object.keys(prevState.pages[pageId].elements);

  if (!keys.length) {
    delete prevState.pages[pageId];
  } else {
    for (const [index, key] of keys.entries()) {
      prevState.pages[pageId].elements[key].name = `Question ${index + 1}`;
    }
  }

  setHistory({ ...prevState });
  if (setSelectedItem)
    setSelectedItem({ pageId, elementId: '', type: InputType.PAGE });
};

export {
  getPageData,
  getElementData,
  changeElementData,
  prepareJSON,
  deepClone,
  addElement,
  changePageData,
  changeSurveyData,
  removeComponent,
  convertItemsToChoices,
};

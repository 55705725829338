import React, { FC, useState } from 'react';
import { Button, debounce } from '@mui/material';
import { PageTitle } from '../../components/PageTitle';
import { UserRole } from '../../types/user.types';
import { UsersTable } from './UsersTable';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { FilterSelectWrapper } from '../../components/common/FilterSelectWrapper';
import { AddUserModal } from '../admin/users/components/AddUserModal';
import { DEBOUNCE_TIME } from '../../configs';
import { StyleTextfield } from '../../components/TextField';
import Breadcrumbs from '../../components/Breadcrumb';
import RefreshButton from '../../components/RefreshButton';

const UsersPage: FC = () => {
  metaTitle('Users');
  const [openUserModal, setOpenUserModal] = useState(false);
  const [refreshUserTable, setRefreshUserTable] = useState(false);
  const [search, setSearch] = useState('');

  const disableAddUser = useIsUserRole([
    UserRole.user,
    UserRole.dataEditor,
    UserRole.layoutEditor,
  ]);

  return (
    <>
      <PageTitle>Users</PageTitle>
      <Breadcrumbs items={[{ label: 'Users', to: '/users' }]} />
      <FilterSelectWrapper>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenUserModal(true)}
            disabled={disableAddUser}
            style={{ marginBottom: 0 }}
          >
            Add user
          </Button>

          <RefreshButton
            onClick={() => setRefreshUserTable(!refreshUserTable)}
          />
        </div>
        <div>
          <StyleTextfield
            label={'Search User'}
            variant={'outlined'}
            onChange={debounce(
              (event) => setSearch(event.target.value),
              DEBOUNCE_TIME,
            )}
          />
        </div>
      </FilterSelectWrapper>

      <UsersTable search={search} refresh={refreshUserTable} />

      <AddUserModal
        onClose={() => setOpenUserModal(false)}
        open={openUserModal}
        onFinished={() => {
          setOpenUserModal(false);
          setRefreshUserTable(!refreshUserTable);
        }}
      />
    </>
  );
};

export default UsersPage;

import { FC } from 'react';
import { Divider, Grid, Typography } from '@mui/material';

import { DeviceStats } from './DeveloperOptions';
import { DialogModal } from '../../../components';
import { camelCaseToWords, deviceInfoValue } from './AdditionalInfo';

interface EditKvsValuesModalProps {
  open: boolean;
  onClose: (open: boolean) => void;
  deviceStats: DeviceStats;
}

const DeviceStatsModal: FC<EditKvsValuesModalProps> = ({
  open,
  onClose,
  deviceStats,
}) => {
  let infoKeys: string[];
  // eslint-disable-next-line prefer-const
  infoKeys = deviceStats && Object.keys({ ...deviceStats });

  const additionalInfo = infoKeys?.map((infoName: string) => {
    let displayValue;

    if (deviceStats) {
      // eslint-disable-next-line prefer-const
      displayValue = deviceStats[infoName];

      return (
        <Grid item xs={4} key={infoName}>
          <Typography
            style={{
              marginTop: '20px',
              fontSize: '15px',
              fontWeight: 12,
            }}
            fontWeight={600}
          >
            {camelCaseToWords(infoName)}
          </Typography>
          <Typography style={{ fontSize: '12px' }} variant="body2">
            {deviceInfoValue(infoName, displayValue)}
          </Typography>
        </Grid>
      );
    }
  });

  return (
    <DialogModal
      open={open}
      onClose={() => onClose(false)}
      width={'60%'}
      title="Device Statistics"
    >
      <Divider />
      <Grid container>{additionalInfo}</Grid>
    </DialogModal>
  );
};

export default DeviceStatsModal;

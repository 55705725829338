import { FC } from 'react';
import Button from '../components/Button';

import { useFabricEditor } from '../../../lib/FabricJs';
import handleAddNews from '../../../lib/FabricJs/utils/handleNews';
import { NEWS_TYPES } from '../../../config';
import { APP_ROUTE } from '../../../../../configs';

interface NewsWidgetProps {
  newsType: string;
}

const NewsWidget: FC<NewsWidgetProps> = ({ newsType }) => {
  const { canvas } = useFabricEditor();
  let buttonName = 'Add News';
  let iframeSrc = '';

  if (newsType === NEWS_TYPES.BBC) {
    buttonName = 'Add BBC News';
    iframeSrc = `${APP_ROUTE}/news/bbc`;
  } else if (newsType === NEWS_TYPES.CNN) {
    buttonName = 'Add CNN News';
    iframeSrc = `${APP_ROUTE}/news/cnn`;
  }

  return (
    <Button
      style={{ marginBottom: '20px' }}
      disabled={!newsType}
      onClick={() => canvas && handleAddNews(canvas, iframeSrc)}
    >
      {buttonName}
    </Button>
  );
};

export default NewsWidget;

import { useEffect, useState } from 'react';

import { useGetPaginateKvsData } from '../../../api-http/kvs';
import { useSnack } from '../../../util/useSnack';
import { OrderEnum } from '../../../types/pagination.types';
import { KvsResponse } from '../../../types/kvs.types';

const PAGE_LIMIT = 100;

export const useKvsData = () => {
  const { showErrSnack } = useSnack();
  const [kvsDataSearchValue, setKvsDataSearchValue] = useState<string>('');
  const [activeGroup, setActiveGroup] = useState<KvsResponse | null>(null);

  const {
    isLoading: isKvsDataLoading,
    error,
    data: kvsData,
  } = useGetPaginateKvsData({
    limit: PAGE_LIMIT,
    page: 1,
    sortby: 'createdAt',
    order: OrderEnum.desc,
    search: kvsDataSearchValue,
    kvsGroup: activeGroup?._id || '',
  });

  useEffect(() => {
    if (error) {
      showErrSnack(error);
    }
  }, [error]);

  return {
    isKvsDataLoading,
    setKvsDataSearchValue,
    setActiveGroup,
    activeGroup,
    kvsData: kvsData?.data || [],
  };
};

import { FC } from 'react';
import { notifyMsg } from '../../configs';
import { useSnack } from '../../util/useSnack';
import { DialogModal } from '../../components';
import { FormModalForm } from './FormModalForm';
import { useFormCreate } from '../../api-http/forms';
import { useNavigate } from 'react-router-dom';

interface AddFormModalProps {
  onClose: () => void;
  open: boolean;
}

export const AddFormModal: FC<AddFormModalProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { showSuccessSnack, showErrSnack } = useSnack();
  const { isLoading, mutate, error, reset } = useFormCreate({
    onSuccess: (data) => {
      showSuccessSnack(notifyMsg.FORM_ADDED_SUCCESS);
      closeModal();
      navigate(`/survey-editor/${data}`);
    },
    onError: (err) => showErrSnack(err),
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <DialogModal title="Add New Form" onClose={onClose} open={open}>
      <FormModalForm
        onSubmit={(data) => mutate(data)}
        isLoading={isLoading}
        error={error}
      />
    </DialogModal>
  );
};

import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { AlertTriangle } from 'react-feather';
import { Typography } from '@mui/material';

interface DisplayErrorProps extends PropsWithChildren {
  msg: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-family: Roboto;
  font-size: 20px;
`;

const DisplayError: FC<DisplayErrorProps> = ({ msg }) => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        <AlertTriangle style={{ marginRight: '10px' }} />
        Error occurred
      </Typography>
      <Typography variant="h5" gutterBottom>
        {msg}
      </Typography>
    </Container>
  );
};

export default DisplayError;

import { FC, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, debounce } from '@mui/material';

import { UserTable } from './UserTable';
import { metaTitle } from '../../../util/tabTitle';
import { UserRole } from '../../../types/user.types';
import { useAuth } from '../../../context/auth-context';
import { PageTitle } from '../../../components/PageTitle';
import { AddUserModal } from './components/AddUserModal';
import Breadcrumbs from '../../../components/Breadcrumb';
import { StyleTextfield } from '../../../components/TextField';
import { DEBOUNCE_TIME } from '../../../configs';

const TopContainer = styled('div')`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const UserPage: FC = () => {
  metaTitle('Super Admin - Users');

  const params = useParams<{ id: string }>();
  const { currentUser } = useAuth();

  const [openUserModal, setOpenUserModal] = useState(false);
  const [refreshUserTable, setRefreshUserTable] = useState(false);
  const [search, setSearch] = useState('');
  const { state } = useLocation();

  if (currentUser && currentUser.role !== UserRole.superAdmin) {
    return <Navigate to="/" />;
  }

  const searchParams = state?.search;
  const organizationPageUrl = searchParams
    ? `/admin/organizations${searchParams}`
    : '/admin/organizations';

  return (
    <>
      <PageTitle>Users Page (Super Admin)</PageTitle>
      <Breadcrumbs
        items={[
          { label: 'Organizations', to: organizationPageUrl },
          { label: 'Admin Users', to: `/admin/users/${params.id}` },
        ]}
      />
      <TopContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenUserModal(true)}
        >
          Add user
        </Button>

        <StyleTextfield
          label={'Search User'}
          variant={'outlined'}
          onChange={debounce(
            (event) => setSearch(event.target.value),
            DEBOUNCE_TIME,
          )}
        />
      </TopContainer>

      <UserTable
        selectedOrgId={params?.id || ''}
        search={search}
        refresh={refreshUserTable}
      />
      <AddUserModal
        onClose={() => setOpenUserModal(false)}
        open={openUserModal}
        onFinished={() => {
          setOpenUserModal(false);
          setRefreshUserTable(!refreshUserTable);
        }}
      />
    </>
  );
};

export default UserPage;

import { useGetSchedulersEvents } from '../../api-http/event';
import { AlertBar } from '../../components/AlertBar';
import { useTable } from '../../util/useTable';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';
import { LinearProgress } from '@mui/material';
import { useRef, FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Weekday } from 'rrule';

interface FullCalenderProps {
  refresh: boolean;
}

export const FullCalender: FC<FullCalenderProps> = ({ refresh }) => {
  const params = useParams<{ id: string }>();

  const calendarRef = useRef(null);
  const { pagingParams } = useTable();

  const {
    data,
    refetch,
    error,
    isLoading: listIsLoading,
  } = useGetSchedulersEvents({
    ...pagingParams,
    scheduler: params.id,
  });

  useEffect(() => {
    refetch();
  }, [refresh]);

  const SECONDS_PER_MINUTE = 60;
  const SECONDS_PER_HOUR = 3600;

  const events = data?.data.map((item) => {
    const splittedStartTime = item.startTime.split(':');
    const startDate = new Date(
      new Date(item.presentationSchedule.dtstart).setHours(
        Number(splittedStartTime[0]),
        Number(splittedStartTime[1]),
        Number(splittedStartTime[2]),
      ),
    );

    const [startHours, startMinutes, startSeconds] = item.startTime
      .split(':')
      .map(Number);
    const [endHours, endMinutes, endSeconds] = item.endTime
      .split(':')
      .map(Number);

    const startTimeInSeconds =
      startHours * SECONDS_PER_HOUR +
      startMinutes * SECONDS_PER_MINUTE +
      startSeconds;
    const endTimeInSeconds =
      endHours * SECONDS_PER_HOUR +
      endMinutes * SECONDS_PER_MINUTE +
      endSeconds;

    const durationInSeconds = endTimeInSeconds - startTimeInSeconds;

    const durationHours = Math.floor(durationInSeconds / SECONDS_PER_HOUR);
    const durationMinutes = Math.floor(
      (durationInSeconds % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE,
    );
    const durationSeconds = durationInSeconds % SECONDS_PER_MINUTE;

    const duration = `${String(durationHours).padStart(2, '0')}:${String(
      durationMinutes,
    ).padStart(2, '0')}:${String(durationSeconds).padStart(2, '0')}`;

    const endDate = new Date(item.presentationSchedule.until);
    endDate.setHours(23, 59, 59, 999);
    const frequency = item.presentationSchedule.freq;

    if (item.presentationSchedule.byweekday !== undefined) {
      const weekDays = item.presentationSchedule.byweekday.map(
        (day: Weekday) => day.weekday,
      );

      return {
        title: item.presentation.name,
        duration: duration,
        rrule: {
          freq: frequency,
          interval: 1,
          byweekday: weekDays,
          dtstart: startDate.toISOString(),
          until: endDate.toISOString(),
        },
      };
    } else {
      return {
        title: item.presentation.name,
        duration: duration,
        rrule: {
          freq: frequency,
          interval: 1,
          dtstart: startDate.toISOString(),
          until: endDate.toISOString(),
        },
      };
    }
  });

  return (
    <div>
      {listIsLoading && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg} />
      <div style={{ backgroundColor: 'white', padding: '5px' }}>
        <FullCalendar
          height={'70vh'}
          timeZone="local" // it can change "UTC" or Any other time zone
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            rrulePlugin,
          ]}
          selectable
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          ref={calendarRef}
          events={events}
        />
      </div>
    </div>
  );
};

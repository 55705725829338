import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  debounce,
} from '@mui/material';
import { StyledStack, StyledTextarea, Title } from './common';
import { useEditorData } from '../../EditorProvider';
import { changeSurveyData } from '../../utils/surveyDataUtils';
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../canvas-editor-v2/components/Widgets/components/Button';
import { AlignmentTypes, logoDetails } from '../../utils/logoData';
import { DialogModal } from '../../../DialogModal';
import {
  AddAssetForm,
  AssetAccept,
} from '../../../../containers/assets/AddAssetForm';
import { useParams } from 'react-router-dom';
import { useUpdateForms } from '../../../../api-http/forms';
import { useSnack } from '../../../../util/useSnack';
import { LogoDetails } from '../../types';
import { useGetAssetsWithSearch } from '../../../../api-http/assets';
import { DEBOUNCE_TIME } from '../../../../configs';

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 20px;
  margin-left: 15px;
`;

const ImageItem = styled.img`
  width: 150px;
  height: 140px;

  &:hover {
    cursor: pointer;
  }
`;

const SelectedImageItem = styled.img`
  width: 140px;
  height: 140px;
  border: 5px solid #16a085;
  padding: 5px;
  border-radius: 5px;
`;

const StyledLabel = styled.label`
  width: 100px;
  height: 35px;
  padding: 5px;
  border: 3px solid black;
  border-radius: 5px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 20px;
`;

const SearchBox = styled.input`
  width: 90%;
  padding: 14px;
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 5px;
  outline: none;
  margin-left: 15px;
`;

type TextChangeEvent = ChangeEvent<HTMLTextAreaElement>;

const SurveySettings = () => {
  const [searchText, setSearchText] = useState<string>();

  const {
    data: images,
    error,
    refetch,
    isLoading,
    isError,
  } = useGetAssetsWithSearch(searchText || '');

  const [openAddAssetModal, setOpenAddAssetModal] = useState(false);
  const [accept] = useState<AssetAccept>(AssetAccept.Images);

  const { showErrSnack } = useSnack();

  useEffect(() => {
    refetch();
  }, [searchText]);

  useEffect(() => {
    if (isError) showErrSnack(error.msg);
  }, [images]);

  const { id } = useParams();

  const { mutate: updateForm } = useUpdateForms({
    onError: (error) => {
      showErrSnack(error.msg);
    },
  });

  const {
    setHistory,
    history,
    logoSize,
    setLogoSize,
    setSelectedLogo,
    alignment,
    setAlignment,
  } = useEditorData();

  const { present: surveyData } = history;

  const onChange = (e: TextChangeEvent) => {
    const { id, value } = e.target;

    const params = { surveyData, data: { [id]: value }, setHistory };
    changeSurveyData(params);
  };

  useEffect(() => {
    if (id) {
      updateForm({
        _id: id,
        formBody: JSON.stringify(surveyData),
      });
    }
  }, [surveyData]);

  const onFinished = () => {
    setOpenAddAssetModal(false);
    refetch();
  };

  const handleLogoChange = (url: string) => {
    setSelectedLogo(url);
    const { width, height } = logoSize;
    const params = {
      surveyData,
      data: {
        logo: url,
        logoWidth: width,
        logoHeight: height,
      },
      setHistory,
    };

    changeSurveyData(params);
  };

  const changeLogoSize = (sizeData: LogoDetails) => {
    setLogoSize(sizeData);

    const { width, height } = sizeData;
    const params = {
      surveyData,
      data: {
        logoWidth: width,
        logoHeight: height,
      },
      setHistory,
    };
    changeSurveyData(params);
  };

  const removeLogo = () => {
    if (surveyData?.logo) {
      setLogoSize(logoDetails[0]);
      setSelectedLogo(null);

      delete surveyData?.logo;
      delete surveyData?.logoWidth;
      delete surveyData?.logoHeight;

      if (id) {
        updateForm({
          _id: id,
          formBody: JSON.stringify(surveyData),
        });
      }
    }
  };

  const handleAlign = (align: AlignmentTypes) => {
    setAlignment(align);
    const params = {
      surveyData,
      data: {
        alignContent: align,
      },
      setHistory,
    };
    changeSurveyData(params);
  };

  return (
    <StyledStack spacing={3}>
      <Title>Survey Settings</Title>
      <StyledTextarea
        aria-label="textarea"
        placeholder="Title"
        id="title"
        name="title"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        value={surveyData?.title}
        onChange={(e: TextChangeEvent) => onChange(e)}
      />
      <StyledTextarea
        aria-label="textarea"
        placeholder="Description"
        id="description"
        name="description"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        value={surveyData?.description}
        onChange={(e: TextChangeEvent) => onChange(e)}
      />
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          Editable or read-only
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="editable"
            control={<Radio />}
            label="Editable"
          />
          <FormControlLabel
            value="read-only"
            control={<Radio />}
            label="Read-only"
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Align Questions</FormLabel>
        <LabelContainer>
          {Object.values(AlignmentTypes).map((val) => (
            <StyledLabel
              style={{
                backgroundColor: alignment === val ? 'black' : 'white',
                color: alignment === val ? 'white' : 'black',
              }}
              key={val}
              onClick={() => handleAlign(val)}
            >
              {val}
            </StyledLabel>
          ))}
        </LabelContainer>
      </FormControl>
      <FormControl>
        <FormLabel>Select a Logo</FormLabel>
        <Button
          onClick={() => setOpenAddAssetModal(true)}
          style={{ marginTop: '20px', marginBottom: '10px' }}
        >
          Upload logo
        </Button>

        <Button
          onClick={removeLogo}
          style={{ marginTop: '20px', marginBottom: '10px' }}
        >
          Remove logo
        </Button>
        <FormLabel style={{ marginTop: '10px' }}>Size</FormLabel>
        <LabelContainer>
          {logoDetails.map((item) => (
            <StyledLabel
              key={item.type}
              style={{
                backgroundColor:
                  surveyData?.logoWidth === item.width ||
                  logoSize.type === item.type
                    ? 'black'
                    : 'white',
                color:
                  surveyData?.logoWidth === item.width ||
                  logoSize.type === item.type
                    ? 'white'
                    : 'black',
              }}
              onClick={() => changeLogoSize(item)}
            >
              {item.type}
            </StyledLabel>
          ))}
        </LabelContainer>

        <SearchBox
          placeholder="Search Image"
          onChange={debounce(
            (e) => setSearchText(e.target.value),
            DEBOUNCE_TIME,
          )}
        />

        {isLoading && (
          <Box
            sx={{ display: 'flex', height: '80vh' }}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="info" size={100} />
          </Box>
        )}

        {images && images?.length > 0 ? (
          <ImagesContainer>
            {images?.map((image) =>
              surveyData?.logo === image.url ? (
                <SelectedImageItem key={image._id} src={image.url} />
              ) : (
                <ImageItem
                  onClick={() => handleLogoChange(image.url)}
                  src={image.url}
                />
              ),
            )}
          </ImagesContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            No Images To Display
          </div>
        )}
      </FormControl>
      <DialogModal
        title="Add asset"
        onClose={() => setOpenAddAssetModal(false)}
        open={openAddAssetModal}
      >
        <AddAssetForm accept={accept} onFinished={() => onFinished()} />
      </DialogModal>
    </StyledStack>
  );
};

export default SurveySettings;

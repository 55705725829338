/* eslint-disable no-console */
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useIsOnline } from 'react-use-is-online';

import { SocketData } from '../../types';
import { DeviceType } from '../../types/scheduler.types';
import { GetPresentationResponse } from '../../api-http/presentations';

import { errorMsg } from '../../configs';
import useSocket from '../../util/useSocket';
import { metaTitle } from '../../util/tabTitle';
import { useGetPublicForm } from '../../api-http/forms';
import { handleRefresh } from '../../util/handleRefresh';
import SurveyPreview from '../../components/SurveyPreview';
import { EVENTS, SOCKET_EVENTS } from '../../util/constant';
import NotAssignPage from '../not-assign-page/NotAssignPage';
import DisplayError from '../../components/common/ApiErrorDisplay';
import schedulePresentations from '../../util/schedulePresentations';
import ErrorDisplayPage from '../error-display-page/ErrorDisplayPage';
import { EditorProvider } from '../../components/survey-editor/EditorProvider';
import PresentationPreview from '../../components/preview/PresentationPreview';
import { LinearProgressWithLabel } from '../../components/LinearProgressWithLabel';
import {
  useGetCacheInterval,
  useGetShowPresentationFormPreview,
} from '../../api-http/scheduler';
import {
  AssetData,
  GetDisplayPresentationResponse,
  useGetDisplayPresentation,
} from '../../api-http/displays';
import {
  addItemToDb,
  clearAllInDb,
  createDatabase,
  getById,
  getData,
  transferDataAndClear,
} from '../../indexedDb';
import Dexie from 'dexie';

function assetArraysAreEqual(
  array1: AssetData[],
  array2: AssetData[],
): boolean {
  // Check if the lengths are different
  if (array1.length !== array2.length) {
    return false;
  }

  // Check if every element in array1 is also present in array2
  return array1.every((element) => array2.includes(element));
}

const ShowPresentationFormPreview: FC = () => {
  metaTitle('Preview');

  const { isOnline } = useIsOnline();
  const { deviceId } = useParams<{ deviceId: string }>();
  const { socket, socketErrors } = useSocket();

  let intervalId: ReturnType<typeof setTimeout>;

  const [isSyncing, setIsSyncing] = useState(false);
  const [isCaching, setIsCaching] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isPreCacheNeed, setIsPreCacheNeed] = useState(true);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadAssetCount, setDownloadAssetCount] = useState('Loading...');
  const [presentationId, setPresentationId] = useState<string | undefined>('');
  const [presentation, setPresentation] = useState<
    GetDisplayPresentationResponse | GetPresentationResponse
  >();

  const {
    data: cacheIntervalData,
    error: cacheIntervalError,
    isLoading: isLoadingCacheInterval,
    refetch: refetchCacheInterval,
  } = useGetCacheInterval(deviceId);

  const preCacheDurationMilliSec = cacheIntervalData
    ? cacheIntervalData * 60 * 1000
    : 0;

  useEffect(() => {
    const updatePreCacheDuration = (socketData: SocketData) => {
      const { data: socketCacheIntervalData, event } = socketData;

      if (
        event === EVENTS.setCacheInterval &&
        deviceId &&
        socketCacheIntervalData[0] === deviceId
      ) {
        refetchCacheInterval();
      }
    };
    if (socket) {
      socket.on(SOCKET_EVENTS.dataConnection, updatePreCacheDuration);
    }
    return () => {
      socket.off(SOCKET_EVENTS.dataConnection, updatePreCacheDuration);
    };
  }, [socket]);

  const {
    data: previewData,
    error: apiError,
    isLoading: isLoadingPreview,
    refetch: refetchPreview,
  } = useGetShowPresentationFormPreview(deviceId);

  const {
    data: formData,
    isLoading: isLoadingForm,
    refetch: refetchForm,
  } = useGetPublicForm(previewData?.form?._id);

  const scheduleUpdate = () => {
    if (
      previewData &&
      previewData.operatingType === DeviceType.SCHEDULED_DEVICE
    ) {
      const id = schedulePresentations({
        scheduler: previewData.scheduler,
        cacheInterval: cacheIntervalData as number,
      });

      if (!id) {
        setPresentationId(previewData?.scheduler?.defaultPresentation);
      } else if (id != presentationId) {
        setPresentationId(id);
      }
    }
  };

  useEffect(() => {
    if (socket) {
      const updateDeviceListener = (socketData: SocketData) => {
        const { data: deviceIdList } = socketData;
        if (deviceId && deviceIdList.includes(deviceId)) {
          setIsPreCacheNeed(true);
          refetchPreview();
        }
      };

      socket.on(SOCKET_EVENTS.updateDevice, updateDeviceListener);

      return () => {
        socket.off(SOCKET_EVENTS.updateDevice, updateDeviceListener);
      };
    }
  }, [socket]);

  useEffect(() => {
    if (previewData) {
      refetchForm();
    }
    clearInterval(intervalId);
    // TODO: Find a proper way to do this.
    intervalId = setInterval(() => {
      scheduleUpdate();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [previewData]);

  const {
    data,
    error,
    isLoading,
    refetch: refetchPresentation,
  } = useGetDisplayPresentation(
    presentationId || previewData?.scheduler?.defaultPresentation,
  );

  useEffect(() => {
    const updateAssignedPresentation = (socketData: string) => {
      if (presentationId && socketData === presentationId) {
        setIsPreCacheNeed(false);
        refetchPresentation();
      }
    };
    if (socket) {
      socket.on(SOCKET_EVENTS.updatePresentation, updateAssignedPresentation);
    }
    return () => {
      socket.off(SOCKET_EVENTS.updatePresentation, updateAssignedPresentation);
    };
  }, [socket, presentationId]);

  useEffect(() => {
    const updateScheduler = (socketData: SocketData) => {
      const { data: deviceList } = socketData;

      if (deviceId && deviceList.includes(deviceId)) {
        setIsPreCacheNeed(true);
        refetchPreview();
      }
    };
    if (socket) {
      socket.on(SOCKET_EVENTS.dataConnection, updateScheduler);
    }
    return () => {
      socket.off(SOCKET_EVENTS.dataConnection, updateScheduler);
    };
  }, [socket]);

  const dataCachingFunc = async (indexedDb: Dexie) => {
    await transferDataAndClear(indexedDb);
    setPresentation(data);
    setIsCaching(false);
  };

  const cacheAssets = async () => {
    const indexedDb = await createDatabase();
    await clearAllInDb(indexedDb, 'newAssets');

    const initialData = await getData(indexedDb, 'assets');
    setIsCaching(!initialData);

    const assetsList = data?.assets;

    if (!assetsList) {
      return;
    }

    for (const [index, asset] of assetsList.entries()) {
      try {
        // Processing asset index
        setDownloadAssetCount(`${index}/${assetsList.length}`);

        const existingAsset = await getById(
          indexedDb,
          asset?.assetId,
          'assets',
        );

        if (existingAsset) {
          // Asset with the same ID already exists in the main DB
          // Transfer the existing blob to the newAssets DB
          await addItemToDb(
            indexedDb,
            { id: asset?.assetId, blob: existingAsset.blob },
            'newAssets',
          );
          setDownloadProgress(100);
        } else {
          // Asset with the same ID doesn't exist in the main DB
          // Fetch and store the new blob
          const response = await fetch(asset?.assetSrc, {
            mode: 'cors',
          });

          if (!response.ok) {
            console.log(`HTTP error! status: ${response.status}`);
          }

          const contentLength = response.headers.get('content-length');
          if (!contentLength) {
            console.log('Content Length not available!');
          }

          const totalSize = parseInt(contentLength || '0', 10); // Total size of the file
          let loadedSize = 0; // Amount of data loaded so far

          const reader = response.body?.getReader();
          if (!reader) {
            console.log('Reader not available!');
            return;
          }

          let mimeType = 'video/mp4'; // Default to video/mp4
          if (asset?.assetSrc.endsWith('.png')) {
            mimeType = 'image/png';
          } else if (
            asset?.assetSrc.endsWith('.jpg') ||
            asset?.assetSrc.endsWith('.jpeg')
          ) {
            mimeType = 'image/jpeg';
          }

          const chunks = []; // Array to store the chunks of data

          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              break;
            }

            chunks.push(value);
            loadedSize += value.length;

            const progress = (loadedSize / totalSize) * 100; // Calculate the progress
            setDownloadProgress(progress);
          }

          const blob = new Blob(chunks, { type: mimeType });

          // @ts-ignore
          await addItemToDb(
            indexedDb,
            { id: asset?.assetId, blob: blob },
            'newAssets',
          );
        }
      } catch (error) {
        console.log('Reading Error', error);
      }
    }

    setIsSyncing(false);

    if (isInitialLoad) {
      await dataCachingFunc(indexedDb);
      setIsInitialLoad(false);
    } else if (isPreCacheNeed) {
      setTimeout(async () => {
        setIsPreCacheNeed(false);
        await dataCachingFunc(indexedDb);
      }, preCacheDurationMilliSec);
    } else {
      await dataCachingFunc(indexedDb);
    }
  };

  useEffect(() => {
    let intervalId: ReturnType<typeof setTimeout>;
    if (data) {
      const oldAssets = presentation?.assets || [];
      const newAssets = data?.assets || [];
      const areArraysEqual = assetArraysAreEqual(oldAssets, newAssets);

      if (isInitialLoad || !areArraysEqual) {
        setIsSyncing(true);
        cacheAssets();
      } else if (isPreCacheNeed) {
        // eslint-disable-next-line prefer-const
        intervalId = setTimeout(() => {
          setIsPreCacheNeed(false);
          handleRefresh();
        }, preCacheDurationMilliSec);
      } else {
        handleRefresh();
      }
    }
    return () => clearTimeout(intervalId);
  }, [data]);

  if (!previewData && !isLoadingPreview && !isLoadingCacheInterval) {
    if (apiError || error || cacheIntervalError) {
      return (
        <DisplayError
          msg={
            apiError?.msg ||
            error?.msg ||
            cacheIntervalError?.msg ||
            errorMsg.COMMON_ERROR
          }
        />
      );
    } else if (isOnline && socketErrors.length) {
      return <ErrorDisplayPage errorsArray={socketErrors} />;
    }
  }

  if (previewData && !previewData?.operatingType) {
    return <NotAssignPage />;
  }

  const isScheduledDevice =
    previewData?.operatingType === DeviceType.SCHEDULED_DEVICE;

  if (
    isLoading ||
    (isScheduledDevice && isLoadingPreview) ||
    isLoadingForm ||
    isCaching ||
    isInitialLoad
  ) {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        height="100vh"
        flexDirection="column"
      >
        <CircularProgress size={100} />
        <p style={{ marginTop: 30 }}>Downloading media...</p>
        <p style={{ marginTop: 30, marginBottom: 30 }}>{downloadAssetCount}</p>
        <Box width={'80%'}>
          <LinearProgressWithLabel value={downloadProgress} />
        </Box>
      </Box>
    );
  }

  if (isScheduledDevice && presentation && !isCaching) {
    return (
      <PresentationPreview presentation={presentation} isSyncing={isSyncing} />
    );
  } else if (previewData?.operatingType === DeviceType.FORM_DEVICE) {
    if (formData?.formBody) {
      if (isLoadingForm) {
        return (
          <Box
            sx={{ display: 'flex', height: '100vh' }}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={100} />
          </Box>
        );
      }

      return (
        <EditorProvider>
          <SurveyPreview data={formData} formId={previewData?.form?._id} />
        </EditorProvider>
      );
    }
  }

  return null;
};

export default ShowPresentationFormPreview;

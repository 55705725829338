import { fabric } from 'fabric';
import verticalImg from '../../assets/editor/middleControl.svg';
import horizontalImg from '../../assets/editor/middleControlHoz.svg';
import edgeImg from '../../assets/editor/edgeControl.svg';
import lockButton from '../../assets/editor/lockButton.svg';
import { useHandleObjectLock } from '../../lib/FabricJs/hooks/useHandleObjectLock';

const drawImg = (
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  img: HTMLImageElement,
  wSize: number,
  hSize: number,
  angle: number | undefined,
) => {
  if (angle === undefined) angle = 0;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(angle));
  ctx.drawImage(img, -wSize / 2, -hSize / 2, wSize, hSize);
  ctx.restore();
};

const intervalControl = () => {
  const verticalImgIcon = document.createElement('img');
  verticalImgIcon.src = verticalImg;

  const horizontalImgIcon = document.createElement('img');
  horizontalImgIcon.src = horizontalImg;

  const renderIcon = (
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object,
  ) => {
    drawImg(ctx, left, top, verticalImgIcon, 20, 25, fabricObject.angle);
  };

  const renderIconHoz = (
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object,
  ) => {
    drawImg(ctx, left, top, horizontalImgIcon, 25, 20, fabricObject.angle);
  };
  fabric.Object.prototype.controls.ml = new fabric.Control({
    x: -0.5,
    y: 0,
    offsetX: -1,
    cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
    getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    render: renderIcon,
  });

  fabric.Object.prototype.controls.mr = new fabric.Control({
    x: 0.5,
    y: 0,
    offsetX: 1,
    cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
    getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    render: renderIcon,
  });

  fabric.Object.prototype.controls.mb = new fabric.Control({
    x: 0,
    y: 0.5,
    offsetY: 1,
    cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
    getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    render: renderIconHoz,
  });

  fabric.Object.prototype.controls.mt = new fabric.Control({
    x: 0,
    y: -0.5,
    offsetY: -1,
    cursorStyleHandler: fabric.controlsUtils.scaleSkewCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
    getActionName: fabric.controlsUtils.scaleOrSkewActionName,
    render: renderIconHoz,
  });
};

const peakControl = () => {
  const img = document.createElement('img');
  img.src = edgeImg;

  const renderIconEdge = (
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object,
  ) => {
    drawImg(ctx, left, top, img, 25, 25, fabricObject.angle);
  };
  fabric.Object.prototype.controls.tl = new fabric.Control({
    x: -0.5,
    y: -0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingEqually,
    render: renderIconEdge,
  });
  fabric.Object.prototype.controls.bl = new fabric.Control({
    x: -0.5,
    y: 0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingEqually,
    render: renderIconEdge,
  });
  fabric.Object.prototype.controls.tr = new fabric.Control({
    x: 0.5,
    y: -0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingEqually,
    render: renderIconEdge,
  });
  fabric.Object.prototype.controls.br = new fabric.Control({
    x: 0.5,
    y: 0.5,
    cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
    actionHandler: fabric.controlsUtils.scalingEqually,
    render: renderIconEdge,
  });
};

const lockControl = (
  handleObjectLock: (transform: fabric.Transform) => void,
) => {
  const lockImg = document.createElement('img');
  lockImg.src = lockButton;

  const roundRect = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    rectSize: number,
    radius: number,
  ) => {
    const r = Math.min(radius, Math.min(rectSize / 2, rectSize / 2));
    const x1 = x + r;
    const x2 = x + rectSize - r;
    const y1 = y + r;
    const y2 = y + rectSize - r;

    ctx.beginPath();
    ctx.moveTo(x1, y);
    ctx.arcTo(x2, y, x2, y1, r);
    ctx.arcTo(x2, y2, x1, y2, r);
    ctx.arcTo(x, y2, x, y1, r);
    ctx.arcTo(x, y, x1, y, r);
    ctx.closePath();
    ctx.fill();
  };

  const renderLockIcon = (icon: HTMLImageElement, size: number) => {
    return (
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object,
    ) => {
      if (fabricObject.lockMovementX) {
        const radius = 3;
        const rectSize = size + radius;
        ctx.save();
        ctx.translate(left, top);
        ctx.fillStyle = '#e2e4e7';
        roundRect(ctx, -size / 2, -size / 2, rectSize, radius);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle ?? 0));
        ctx.drawImage(icon, -size / 2, -size / 2, size, size);
        ctx.restore();
      }
    };
  };

  fabric.Object.prototype.controls.lockControl = new fabric.Control({
    x: 0,
    y: 0.6,
    offsetY: 16,
    cursorStyle: 'pointer',
    render: renderLockIcon(lockImg, 24),
    // @ts-ignore;
    mouseUpHandler: (event, transform) => {
      handleObjectLock(transform);
    },
  });
};

export const useInitControls = () => {
  const { handleObjectLock } = useHandleObjectLock();

  const initControls = () => {
    lockControl(handleObjectLock);

    peakControl();

    intervalControl();

    fabric.Object.prototype.set({
      transparentCorners: false,
      borderColor: '#51B9F9',
      cornerColor: '#FFF',
      borderScaleFactor: 2.5,
      cornerStyle: 'circle',
      cornerStrokeColor: '#0E98FC',
      borderOpacityWhenMoving: 1,
    });
  };
  return { initControls };
};

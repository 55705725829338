import styled from 'styled-components';
import Header from './components/Header';
import SidePanel from './components/SidePanel';
import SurveyForm from './form-components/SurveyForm';
import Toolbar from './components/Toolbar/Toolbar';
import SettingsPanel from './components/SettingsPanel/index';
import { EditorProvider, useEditorData } from './EditorProvider';
import ButtonAddQuestion from './form-components/components/ButtonAddQuestion';
import { FC, useEffect } from 'react';
import PreviewModal from './components/PreviewModal';
import { prepareJSON } from './utils/surveyDataUtils';
import { useGetForm } from '../../api-http/forms';
import { useParams } from 'react-router-dom';
import { InputType } from './types';
import { CircularProgress } from '@mui/material';
import { AlertBar } from '../AlertBar';
import { logoDetails } from './utils/logoData';

const GlobalStyle = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  * {
    font-family: 'roboto', sans-serif;
    box-sizing: border-box;

    button {
      border-radius: 4px;
      border: none;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      &:active {
        opacity: 0.7;
      }
    }
  }
`;

const Body = styled.div`
  background: white;
  display: flex;
  flex-grow: 1;
`;

const MainContainer = styled.div`
  background: #ebecf0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100vh;
`;

const AddQuestionContainer = styled.div`
  display: flex;
  height: 100vh;
  padding: 0 20vw;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const withProvider = (Component: FC) => (props: any) => {
  return (
    <EditorProvider>
      <Component {...props} />
    </EditorProvider>
  );
};

const SurveyEditor = () => {
  const { id } = useParams();
  const {
    setSelectedItem,
    history,
    setHistory,
    setSelectedLogo,
    setLogoSize,
    setAlignment,
  } = useEditorData();
  const { present } = history;
  const { data, isLoading, error } = useGetForm(id || '');

  useEffect(() => {
    if (data?.formBody && !isLoading) {
      const { logo, logoHeight, logoWidth, alignContent } = data.formBody || {};
      if (logo && logoWidth && logoHeight) {
        const selectedLogo = logo;
        const selectedLogoSize =
          logoDetails.find((item) => item.width === logoWidth) ||
          logoDetails[0];

        setSelectedLogo(selectedLogo);
        setLogoSize(selectedLogoSize);
      }

      if (alignContent) {
        setAlignment(alignContent);
      }

      setHistory(data.formBody);
      setSelectedItem({
        pageId: Object.keys(data.formBody.pages)[0],
        elementId: '',
        type: InputType.PAGE,
      });
    }
  }, [data, isLoading]);

  return (
    <GlobalStyle>
      <Header />
      {isLoading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <>
          <AlertBar severity="error" msg={error?.msg} />

          <Body>
            <SidePanel />
            <MainContainer>
              <Toolbar />
              {Object.keys(present.pages).length ? (
                <SurveyForm />
              ) : (
                <AddQuestionContainer>
                  <ButtonAddQuestion pageId="" />
                </AddQuestionContainer>
              )}
            </MainContainer>
            <SettingsPanel />
            <PreviewModal data={prepareJSON(present)} />
          </Body>
        </>
      )}
    </GlobalStyle>
  );
};

export default withProvider(SurveyEditor);

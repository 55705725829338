import React, { ChangeEvent, FC, useEffect } from 'react';

import { useFormik } from 'formik';
import styled from 'styled-components';

import * as yup from 'yup';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useUpdateSchedulers } from '../../../api-http/scheduler';
import { useSnack } from '../../../util/useSnack';
import { formMsg, notifyMsg } from '../../../configs';
import { AlertBar } from '../../../components/AlertBar';
import { formatInput } from '../../../util/validations';
import { SchedulerGetResponse } from '../../../types/scheduler.types';
import { useGetOrganizationPresentations } from '../../../api-http/presentations';

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

interface SchedulerEditModalProps {
  scheduler: SchedulerGetResponse | undefined;
  onFinished: () => void;
  refetch: () => void;
}

const validationSchema = yup.object({
  schedulerName: yup.string().required(formMsg.SCHEDULER_NAME_REQUIRED),
  description: yup.string().required(formMsg.DESCRIPTION_REQUIRED),
  defaultPresentation: yup
    .string()
    .required(formMsg.DEFAULT_PRESENTATION_REQUIRED),
});

export const SchedulerEditModal: FC<SchedulerEditModalProps> = ({
  onFinished,
  refetch,
  scheduler,
}) => {
  const { data: presentationData } = useGetOrganizationPresentations();
  const { showErrSnack, showSuccessSnack } = useSnack();

  const { isLoading, error, data, mutate } = useUpdateSchedulers({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.SCHEDULER_UPDATE_SUCCESS);
      refetch();
    },
    onError: (error) => {
      showErrSnack(error || notifyMsg.SCHEDULER_UPDATE_FAILED);
    },
  });

  const formik = useFormik({
    initialValues: {
      schedulerName: scheduler?.name || '',
      description: scheduler?.description || '',
      defaultPresentation: scheduler?.defaultPresentation._id,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate({
        id: scheduler?._id || '',
        name: values.schedulerName,
        description: values.description,
        defaultPresentation: values.defaultPresentation || '',
      });
    },
  });

  useEffect(() => {
    if (data) {
      onFinished();
    }
  }, [data]);

  return (
    <Container>
      <AlertBar
        style={{ marginBottom: '10px' }}
        severity="error"
        msg={error?.msg}
      />
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            id="schedulerName"
            name="schedulerName"
            label="Scheduler Name"
            value={formik.values.schedulerName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formatInput(e, 'schedulerName', formik)
            }
            error={
              formik.touched.schedulerName &&
              Boolean(formik.errors.schedulerName)
            }
            helperText={
              formik.touched.schedulerName && formik.errors.schedulerName
            }
          />
          <TextField
            fullWidth
            id="description"
            name="description"
            label="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />

          <FormControl sx={{ width: '100%', minWidth: 200 }}>
            <InputLabel id="defaultPresentation">
              Default Presentation
            </InputLabel>
            <Select
              fullWidth
              id="defaultPresentation"
              name="defaultPresentation"
              value={formik.values.defaultPresentation}
              title="defaultPresentation"
              input={<OutlinedInput label="defaultPresentation" />}
              placeholder=" Select Default Presentation"
              onChange={formik.handleChange}
            >
              {presentationData?.map((defaultPresentation) => (
                <MenuItem
                  key={defaultPresentation?.id}
                  value={defaultPresentation?.id}
                >
                  {defaultPresentation?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!formik.dirty || isLoading}
          >
            Update
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

import { fabric } from 'fabric';
import { FC } from 'react';
import styled from 'styled-components';
import { defaultFontFamily } from '../../config';
import { useFabricEditor } from '../../lib/FabricJs';
import { getRanCoord } from '../../utils/getRanCoord';
import Button from './components/Button';
import Span from './components/Span';

enum TextType {
  text = 'text',
  body = 'body',
  subHeading = 'subHeading',
  heading = 'heading',
}

const Text = styled.button`
  background: rgba(255, 255, 255, 0.07);
  border: none;
  color: white;
  outline-color: white;
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 8px;
  &:hover {
    background: rgba(255, 255, 255, 0.09);
  }
`;

const SubHeading = styled(Text)`
  font-size: 16px;
  height: 48px;
`;

const Heading = styled(Text)`
  font-size: 28px;
  height: 60px;
`;

const textObjects = {
  text: {
    title: 'Your paragraph text',
    options: {
      fontSize: 32,
      fontFamily: defaultFontFamily,
    },
  },
  body: {
    title: 'Add a little bit of text',
    options: {
      fontSize: 72,
      fontFamily: defaultFontFamily,
    },
  },
  subHeading: {
    title: 'Sub Heading',
    options: {
      fontSize: 96,
      fontFamily: defaultFontFamily,
    },
  },
  heading: {
    title: 'Heading',
    options: {
      fontSize: 160,
      fontFamily: defaultFontFamily,
    },
  },
};

const TextWidget: FC = () => {
  const { canvas } = useFabricEditor();
  const addText = (type: TextType) => {
    const textObject = textObjects[type];
    if (textObject && canvas) {
      const newText = new fabric.IText(textObject.title, {
        ...textObject.options,
        ...getRanCoord(canvas),
      });
      newText.lockScalingX = true;
      newText.lockScalingY = true;
      canvas?.add(newText);
    }
  };
  return (
    <>
      <Button onClick={() => addText(TextType.text)}>Add a text box</Button>
      <Span>Click text to add to Page</Span>
      <Heading onClick={() => addText(TextType.heading)}>Add a heading</Heading>
      <SubHeading onClick={() => addText(TextType.subHeading)}>
        Add a sub heading
      </SubHeading>
      <Text onClick={() => addText(TextType.body)}>
        Add a little bit of body text
      </Text>
    </>
  );
};

export default TextWidget;

import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';

import { useEditorData, useFabricEditor, useTooling } from '../../lib/FabricJs';
import { useHandleHistory } from '../../lib/FabricJs/hooks/useHandleHistory';
import { useEffect } from 'react';
import debounce from '@mui/material/utils/debounce';
import { DEBOUNCE_TIME } from '../../../../configs';
import { IFRAME_WIDGETS } from '../../config';

export const useUpdateCanvas = () => {
  const { setActiveColor } = useEditorData();
  const {
    onEdited,
    slidesProps: { updateSlide, paramId },
  } = useTooling();
  const { handleHistory } = useHandleHistory();
  const editor = useFabricEditor();
  const debouncedHandleHistory = debounce(handleHistory, DEBOUNCE_TIME);

  type PresentType = { backgroundColor: string } & common.ProjectDataHistory[];

  useEffect(() => {
    const moveSelected = (direction: number[]) => {
      const STEP = 1;
      const activeObject = editor.canvas?.getActiveObject();

      if (
        activeObject?.data?.type === IFRAME_WIDGETS.WORLD_CLOCK ||
        activeObject?.data?.type === IFRAME_WIDGETS.DATE ||
        activeObject?.data?.type === IFRAME_WIDGETS.IFRAME
      ) {
        return;
      }
      if (!activeObject || activeObject.lockMovementX) return;

      if (activeObject?.left && activeObject?.top) {
        activeObject.set({
          left: activeObject.left + direction[0] * STEP,
          top: activeObject.top + direction[1] * STEP,
        });
      }

      if (editor.canvas) {
        editor.canvas.renderAll();
        debouncedHandleHistory(editor.canvas);
      }
    };

    const keyHandler = (options: { repeat: boolean; keyCode: number }) => {
      if (options.repeat) {
        return;
      }
      const key = options.keyCode;
      if (key === 37) {
        // handle Left key
        moveSelected([-1, 0]);
      } else if (key === 38) {
        // handle Up key
        moveSelected([0, -1]);
      } else if (key === 39) {
        // handle Right key
        moveSelected([1, 0]);
      } else if (key === 40) {
        // handle Down key
        moveSelected([0, 1]);
      }
    };
    fabric.util.addListener(document.body, 'keydown', keyHandler);
    return () => {
      fabric.util.removeListener(document.body, 'keydown', keyHandler);
    };
  }, [editor]);

  const updateCanvas = (canvas: fabric.Canvas, present: PresentType) => {
    try {
      if (present.backgroundColor) {
        canvas?.setBackgroundColor(present.backgroundColor, () => {});
        setActiveColor(present.backgroundColor);
      } else {
        canvas._objects.splice(0, canvas._objects.length);
        present.map(({ object, data }) => {
          if (data?.data?.type === 'iframe' && data?.data?.className) {
            const elements = document.getElementsByClassName(
              data?.data?.className,
            );
            const elementsArray = Array.prototype.slice.call(elements);
            const ratio = canvas?.getZoom() || 1;
            const scaledWidth = `${data.width * data.scaleX * ratio}px`;
            const scaledHeight = `${data.height * data.scaleY * ratio}px`;
            const objectLeft = `${data.left * ratio}px`;
            const objectTop = `${data.top * ratio}px`;
            elementsArray.forEach((element: HTMLElement) => {
              element.style.left = objectLeft;
              element.style.top = objectTop;
              element.style.width = scaledWidth;
              element.style.height = scaledHeight;
              element.draggable = !data.lockMovementX;
            });
          }
          // Ignore duplicate objects.
          if (!object?.isDelete) {
            object.set({
              id: object?.id || uuidv4(),
              ...data,
              auto: true, // Identify Automatically added objects.
            });
            canvas.add(object);
          }
        });
        canvas.renderAll();
      }
      const json = canvas.toJSON([
        'data',
        'id',
        'deviceInfo',
        'lockMovementX',
        'lockMovementY',
        'lockRotation',
        'lockScalingX',
        'lockScalingY',
        'editable',
      ]);
      onEdited(json);
      updateSlide({
        id: paramId || '',
        projectData: json,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Ignore error', err);
    }
  };
  return { updateCanvas };
};

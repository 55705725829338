export interface PaginateOptions {
  scheduler?: string;
  limit?: number;
  page?: number;
  sortby?: string;
  sortByName?: string;
  order?: Order;
  search?: string;
}
export interface CategoryOptions {
  _id: string;
  name: string;
  createAt: string;
  updatedAt: string;
}

export interface PaginateSearchOptions extends PaginateOptions {
  search: string;
}
export interface PaginateCategoryOptions extends PaginateOptions {
  category: string;
}

export enum OrderEnum {
  asc = 'asc',
  desc = 'desc',
}

export enum SortEnum {
  name = 'name',
  title = 'title',
  fileName = 'fileName',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  key = 'key',
  lastOnline = 'lastOnline',
}

export enum SortEnum {
  presentationName = 'presentationName',
  startDate = 'startDate',
  startTime = 'startTime',
  endDate = 'endDate',
  endTime = 'endDate',
  frequency = 'frequency',
}

export type Order = OrderEnum.asc | OrderEnum.desc;

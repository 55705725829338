import { useUploadApk } from '../../api-http/apk';
import { notifyMsg } from '../../configs';
import { useSnack } from '../../util/useSnack';
import { Button, CircularProgress } from '@mui/material';
import React, { ChangeEvent, FC, FormEvent, useState } from 'react';

interface AddApkFormProps {
  onFinished: () => void;
}

const ApkUploadForm: FC<AddApkFormProps> = ({ onFinished }) => {
  const { showSuccessSnack, showErrSnack } = useSnack();

  const [file, setFile] = useState<File | null>(null);

  const { mutate, isLoading } = useUploadApk({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.APK_ADDED_SUCCESS);
      setFile(null);
      onFinished();
    },
    onError: (err) => showErrSnack(err),
  });

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) =>
    setFile(event.target.files?.[0] || null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      mutate(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: 20 }}>
      <input type="file" accept=".apk" onChange={handleFileChange} />
      <Button
        type="submit"
        variant="contained"
        disabled={isLoading || file === null}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Upload'}
      </Button>
    </form>
  );
};

export default ApkUploadForm;

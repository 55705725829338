import { FC } from 'react';
import { notifyMsg } from '../../../configs';
import { useCreateSchedulers } from '../../../api-http/scheduler';
import { useSnack } from '../../../util/useSnack';
import { DialogModal } from '../../../components';
import { SchedulerForm } from './SchedulerForm';

interface AddSchedulerModalProps {
  onClose: () => void;
  open: boolean;
}

export const AddSchedulerModal: FC<AddSchedulerModalProps> = ({
  open,
  onClose,
}) => {
  const { showSuccessSnack, showErrSnack } = useSnack();
  const { isLoading, mutate, error, reset } = useCreateSchedulers({
    onSuccess() {
      showSuccessSnack(notifyMsg.SCHEDULER_ADDED_SUCCESS);
      closeModal();
    },
    onError: (err) => showErrSnack(err),
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <DialogModal title="Add Scheduler" onClose={onClose} open={open}>
      <SchedulerForm
        onSubmit={(data) => mutate(data)}
        isLoading={isLoading}
        error={error}
      />
    </DialogModal>
  );
};

import React, { ReactNode } from 'react';
import { matchPath } from 'react-router-dom';

import SidebarNavListItem from './SidebarNavListItem';
import SidebarNavList from './SidebarNavList';
import { NavItemOption } from './types';

const reduceChildRoutes = (props: {
  items: ReactNode[];
  page: NavItemOption;
  currentRoute: string;
  depth: number;
}) => {
  const { items, page, depth, currentRoute } = props;

  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute,
        )
      : false;

    items.push(
      <SidebarNavListItem
        icon={page.icon}
        key={page.title}
        open={open}
        title={page.title}
        href={page.href}
        badge={page.badge || ''}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>,
    );
  } else {
    items.push(
      <SidebarNavListItem
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge || ''}
        title={page.title}
      />,
    );
  }

  return items;
};

export default reduceChildRoutes;

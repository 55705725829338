import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Stack, TextField } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import { AlertBar } from '../../../../components/AlertBar';

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

interface SetPackagesModalProps {
  onFinished: () => void;
}

export const SetPackagesModal: FC<SetPackagesModalProps> = ({ onFinished }) => {
  const handleSubmit = () => {
    onFinished();
  };
  const packageData = [
    { id: 1, package: 'Free - 1 screen' },
    { id: 2, package: ' Silver - 5 screens - Monthly subscription : TBD' },
    { id: 3, package: 'Gold - 15 screens - Monthly subscription : TBD' },
    { id: 4, package: 'Plat - 50 screens - Monthly subscription : TBD' },
    { id: 5, package: 'Enterprise - Unlimited screens - contact sales' },
  ];
  return (
    <Container>
      <AlertBar severity="error" />
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            label="Select Packages"
            id="package"
            title="packages"
            placeholder="packages"
            select
          >
            {packageData.map((option) => (
              <MenuItem key={option.package} value={option.package}>
                {option.package}
              </MenuItem>
            ))}
          </TextField>

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            onClick={() => onFinished()}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

import { AnimationEnum } from '../../../components/preview/constants';
import { DraftResponse } from '../../../types/drafts.types';
import { SlideResponse } from '../../../types/slide.types';
import usePresentationApi from './usePresentationApi';
export interface AddSlideResponse extends SlideResponse {
  id: string;
}
type DraftOption = Pick<DraftResponse, '_id' | 'name' | 'status' | 'slides'>;
type TransformedSlides = NonNullable<
  ReturnType<typeof transformDraft>
>['slides'];

const DEFAULT_TRANSITION_TIME = 6000;

export const transformDraft = (presentation?: DraftOption) => {
  if (!presentation) {
    return null;
  }
  const slidesList = presentation?.slides.map((slide) => {
    return {
      id: slide.id,
      animation: slide.animation,
      duration: slide.duration,
      slideId: slide.slideId,
      slideData: slide.slideId,
    };
  });
  return {
    id: presentation?._id,
    name: presentation?.name,
    slides: slidesList,
    status: presentation?.status,
  };
};

interface UseDraftEditOptions
  extends Pick<ReturnType<typeof usePresentationApi>, 'updatePresentation'> {
  draftData?: DraftOption;
}

export const useDraftEdit = ({
  updatePresentation,
  draftData,
}: UseDraftEditOptions) => {
  const onSlideRemove = (uniqId: string) => {
    const draft = transformDraft(draftData);
    if (draft) {
      const slideIndex = draft.slides.findIndex((slide) => slide.id === uniqId);
      if (slideIndex > -1) {
        draft.slides.splice(slideIndex, 1);
        updatePresentation(draft);
      }
    }
  };

  const durationOnChange = ({
    duration = DEFAULT_TRANSITION_TIME,
    uniqId = '',
  }) => {
    const draft = transformDraft(draftData);
    if (draft?.slides.length) {
      const foundSlide = draft.slides.find((slide) => slide.id === uniqId);
      if (foundSlide) {
        foundSlide.duration = duration;
        updatePresentation(draft);
      }
    }
  };

  const transitionOnChange = ({ animation = '', uniqId = '' }) => {
    const draftSlides = transformDraft(draftData);
    if (draftSlides?.slides.length) {
      const slide = draftSlides.slides.find((slide) => slide.id === uniqId);
      if (slide) {
        slide.animation = animation;
        updatePresentation(draftSlides);
      }
    }
  };

  const onSlideOrderChange = (slides: TransformedSlides) => {
    const draftSlides = transformDraft(draftData);
    if (draftSlides) {
      draftSlides.slides = slides;
      updatePresentation(draftSlides);
    }
  };

  const handleAddSlide = (slide: AddSlideResponse) => {
    const draftSlides = transformDraft(draftData);
    draftSlides?.slides.push({
      id: slide._id,
      slideId: slide._id,
      slideData: slide._id,
      animation: AnimationEnum.fade,
      duration: DEFAULT_TRANSITION_TIME,
    });
    draftSlides && updatePresentation(draftSlides);
  };

  return {
    onSlideRemove,
    durationOnChange,
    transitionOnChange,
    onSlideOrderChange,
    handleAddSlide,
  };
};

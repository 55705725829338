import { FC, MouseEvent, useEffect, useState } from 'react';

import { Badge, Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ChevronDown } from 'react-feather';

import { AlertBar } from '../../components/AlertBar';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import { useIsUserRole } from '../../util/useIsUserRole';
import { DeviceActionMenu } from './DeviceActionMenu';
import { useAuth } from '../../context/auth-context';
import { useTable } from '../../util/useTable';
import styled from 'styled-components';
import {
  DeviceRegisterResponse,
  useDeleteDevice,
  useGetDevices,
} from '../../api-http/devices';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../components/TableTheme';
import { displayDistance } from '../../util/dateTime';
import { notifyMsg } from '../../configs';
import { useSnack } from '../../util/useSnack';
import { useConfirm } from 'material-ui-confirm';
import { NavLink } from 'react-router-dom';

const OnlineStatus = styled(Badge)`
  margin-right: 10px;
  margin-top: -2px;
`;

interface UsersTableProp {
  search: string;
  refresh: boolean;
}

export const DevicesTable: FC<UsersTableProp> = ({ refresh, search }) => {
  const { switchedOrgId } = useAuth();
  const { sortOnClick, pagingParams, tableProps } = useTable(true);
  const { sortby, order } = pagingParams;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const { showErrSnack, showSuccessSnack } = useSnack();
  const confirm = useConfirm();

  const { error: deleteError, mutate: removeDevice } = useDeleteDevice({
    onSuccess: () => {
      refetch(), showSuccessSnack(notifyMsg.DEVICE_DELETED_SUCCESSFULLY);
    },
    onError: () =>
      deleteError
        ? showErrSnack(deleteError.msg)
        : showErrSnack(notifyMsg.FAILED_TO_REMOVE_DEVICE),
  });

  const {
    data: devices,
    isLoading,
    refetch,
    isRefetching,
    error,
  } = useGetDevices({ ...pagingParams, search });

  const open = Boolean(anchorEl);
  const disableAccess = useIsUserRole([
    UserRole.user,
    UserRole.dataEditor,
    UserRole.layoutEditor,
  ]);

  useEffect(() => {
    refetch();
  }, [refresh, switchedOrgId, search]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    device: DeviceRegisterResponse,
  ) => {
    setSelectedDeviceId(device.deviceId);
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = async (id: string) => {
    try {
      await confirm({
        description: notifyMsg.DELETE_CONFIRMATION,
        confirmationText: 'Yes',
      });
      handleClose();
      removeDevice({ id });
    } catch (e) {}
  };

  return (
    <>
      {(isLoading || isRefetching) && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Device ID</StyledTableCell>
              <StyledTableCell align="left">
                <StyledTableSortLabel
                  active={sortby === SortEnum.name || true}
                  direction={sortby === SortEnum.name ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.name)}
                >
                  Device Name
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center">
                <StyledTableSortLabel
                  active={sortby === SortEnum.lastOnline || true}
                  direction={
                    sortby === SortEnum.lastOnline ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.lastOnline)}
                >
                  Last Online
                </StyledTableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="center">
                <StyledTableSortLabel
                  active={sortby === SortEnum.updatedAt || true}
                  direction={
                    sortby === SortEnum.updatedAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.updatedAt)}
                >
                  Updated
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right">Status</StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices?.data?.map((device) => {
              const { deviceId, name, status, lastOnline, updatedAt } = device;
              return (
                <TableRow
                  key={deviceId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">
                    <NavLink
                      style={{
                        textDecoration: 'underline',
                        textUnderlineOffset: '5px',
                      }}
                      to={`/device/${deviceId}`}
                      state={{
                        search: location?.search,
                      }}
                    >
                      {deviceId}
                    </NavLink>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <NavLink
                      style={{
                        textDecoration: 'underline',
                        textUnderlineOffset: '5px',
                      }}
                      to={`/device/${deviceId}`}
                    >
                      {name}
                    </NavLink>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {displayDistance(lastOnline)}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {displayDistance(updatedAt)}
                  </TableCell>
                  <TableCell align="right">
                    <OnlineStatus
                      color={status ? 'success' : 'error'}
                      variant="dot"
                    />
                    {status ? 'Online' : 'Offline'}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      disabled={disableAccess}
                      onClick={(event) => handleClick(event, device)}
                    >
                      <ChevronDown size={16} />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={devices?.pagination.total === 0}
          count={devices?.pagination.total || 0}
        />
      </TableContainer>

      <DeviceActionMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClickDelete={() => handleDelete(selectedDeviceId || '')}
        onClickView={() => {
          navigate(`/device/${selectedDeviceId}`);
        }}
      />
    </>
  );
};

import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import * as yup from 'yup';
import styled from 'styled-components';
import { AlertBar } from '../../components/AlertBar';
import { useUserChangePassword } from '../../api-http/users';
import { authMsg, notifyMsg } from '../../configs';
import { useSnackbar } from 'notistack';
import { getApiError } from '../../api-http/apiService';
import { ERROR, SUCCESS } from '../../util/constant';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface ProfileChangePasswordFormProps {
  onFinished: () => void;
}

const validationSchema = yup.object({
  currentPassword: yup
    .string()
    .max(255)
    .required(authMsg.CURRENT_PASSWORD_REQUIRED),
  newPassword: yup
    .string()
    .max(255)
    .required(authMsg.NEW_PASSWORD_REQUIRED)
    .min(6, authMsg.PASSWORD_6CHAR_REQUIRED)
    .matches(/^[^\s]+$/, authMsg.PASSWORD_SPACES)
    .test(
      'passwordRequirements',
      authMsg.PASSWORD_COMBINATION_REQUIRED,
      (value: any) =>
        [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every((pattern) =>
          pattern.test(value),
        ),
    ),
  confirmNewPassword: yup
    .string()
    .max(255)
    .required(authMsg.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('newPassword')], authMsg.PASSWORD_UNMATCHED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const ProfileChangePasswordForm: FC<ProfileChangePasswordFormProps> = ({
  onFinished,
}) => {
  const { isLoading, error, isSuccess, mutate, isError } =
    useUserChangePassword();
  const { enqueueSnackbar } = useSnackbar();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      onFinished();
      enqueueSnackbar(notifyMsg.PASSWORD_CHANGED_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      const errorMsg = getApiError(error);
      enqueueSnackbar(errorMsg, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError]);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />

      <form onSubmit={formik.handleSubmit}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-currentpassword">
            Current Password
          </InputLabel>
          <OutlinedInput
            fullWidth
            id={'currentPassword'}
            name={'currentPassword'}
            label={'Current Password'}
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.currentPassword &&
              Boolean(formik.errors.currentPassword)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            style={{ marginBottom: 10 }}
            type={showCurrentPassword ? 'text' : 'password'}
          />
          <FormHelperText error id="accountId-error">
            {formik.touched.currentPassword && formik.errors.currentPassword}
          </FormHelperText>
        </FormControl>

        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-newpassword">
            New Password
          </InputLabel>
          <OutlinedInput
            fullWidth
            placeholder="New Password"
            id={'newPassword'}
            name={'newPassword'}
            label={'New Password'}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            type={showNewPassword ? 'text' : 'password'}
            style={{ marginBottom: 10 }}
          />
          <FormHelperText error id="accountId-error">
            {formik.touched.newPassword && formik.errors.newPassword}
          </FormHelperText>
        </FormControl>

        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-confirmpassword">
            Confirm Password
          </InputLabel>
          <OutlinedInput
            fullWidth
            placeholder="Confirm Password"
            id={'confirmNewPassword'}
            name={'confirmNewPassword'}
            label={'Confirm Password'}
            value={formik.values.confirmNewPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmNewPassword &&
              Boolean(formik.errors.confirmNewPassword)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            type={showConfirmPassword ? 'text' : 'password'}
            style={{ marginBottom: 10 }}
          />
          <FormHelperText error id="accountId-error">
            {formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword}
          </FormHelperText>
        </FormControl>

        <Box mt={'10px'} />
        <Button
          color={'primary'}
          disabled={isLoading}
          variant={'contained'}
          fullWidth
          type={'submit'}
        >
          Update
        </Button>
      </form>
    </Container>
  );
};

import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../components/TableTheme';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useTable } from '../../util/useTable';
import {
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  TablePagination,
  Tooltip,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { Edit, Trash } from 'react-feather';
import { useConfirm } from 'material-ui-confirm';
import { notifyMsg } from '../../configs';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { DialogModal } from '../../components';
import { EditKeyValueForm } from './EditKeyValueForm';
import { displayDistance } from '../../util/dateTime';
import { KvsDataResponse } from '../../types/kvs.types';
import { useDeleteKvsKeyValue, useGetKvsGroup } from '../../api-http/kvs';
import { AlertBar } from '../../components/AlertBar';
import { useSnack } from '../../util/useSnack';
import { EditAllKeyValuesForm } from './EditAllKeyValuesForm';

interface KvsKeyValueTableProps {
  searchText: string;
  kvsGroupId: string;
}

interface KvsProps {
  onRefetch: () => void;
}

const KvsKeyValuesTable: ForwardRefRenderFunction<
  KvsProps,
  KvsKeyValueTableProps
> = ({ searchText, kvsGroupId }, forwardedRef) => {
  const confirm = useConfirm();
  const [openEditor, setOpenEditor] = useState(false);
  const [openAllEditor, setOpenAllEditor] = useState(false);
  const { sortOnClick, pagingParams, tableProps } = useTable(
    false,
    SortEnum.key,
  );
  const { sortby, order } = pagingParams;
  const [activeKeyValue, setActiveKeyValue] = useState<
    KvsDataResponse | undefined
  >();
  const { showErrSnack, showSuccessSnack } = useSnack();

  const isViewOnly = useIsUserRole([UserRole.user, UserRole.layoutEditor]);

  const {
    data: kvsGroupData,
    isLoading,
    error,
    refetch,
  } = useGetKvsGroup({
    ...pagingParams,
    search: searchText,
    kvsGroupId: kvsGroupId,
  });

  const { error: deleteError, mutate } = useDeleteKvsKeyValue({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.KVS_KEY_VALUE_REMOVE_SUCCESS);
      refetch();
    },
    onError: (error) =>
      showErrSnack(error || notifyMsg.KVS_KEY_VALUE_REMOVE_FAILED),
  });

  useImperativeHandle(forwardedRef, () => ({
    onRefetch() {
      refetch();
    },
  }));

  const onDeleteRecord = async (id: string) => {
    try {
      await confirm({
        description: notifyMsg.DELETE_CONFIRMATION,
        confirmationText: 'Yes',
      });
      mutate({ id });
    } catch (e) {}
  };

  const onEdit = (keyValue: KvsDataResponse) => {
    setOpenEditor(true);
    setActiveKeyValue(keyValue);
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg || deleteError?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <StyledTableSortLabel
                  active={sortby === SortEnum.key}
                  direction={sortby === SortEnum.key ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.key)}
                >
                  Key
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right">
                Value
                <IconButton
                  disabled={isViewOnly}
                  size="small"
                  sx={{ marginLeft: '10px' }}
                  onClick={() => setOpenAllEditor(true)}
                >
                  <Edit size={16} color="white" />
                </IconButton>
              </StyledTableCell>
              <StyledTableCell align="right">Updated By</StyledTableCell>
              <StyledTableCell align="right">
                <StyledTableSortLabel
                  active={sortby === SortEnum.updatedAt}
                  direction={
                    sortby === SortEnum.updatedAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.updatedAt)}
                >
                  Updated On
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right">Used</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!kvsGroupData?.data.length && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {isLoading ? <CircularProgress /> : 'No Records Found '}
                </TableCell>
              </TableRow>
            )}
            {kvsGroupData?.data?.map((keyValue) => (
              <TableRow
                key={keyValue._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="category">
                  {keyValue.key}
                </TableCell>
                <TableCell align="right">{keyValue.value}</TableCell>
                <TableCell align="right">{keyValue.updatedBy || '-'}</TableCell>
                <TableCell align="right">
                  {displayDistance(keyValue.updatedAt)}
                </TableCell>
                <TableCell align="right">
                  {keyValue.slides.length ? 'Yes' : 'No'}
                </TableCell>
                <TableCell align="right">
                  <Button
                    disabled={isViewOnly}
                    onClick={() => onEdit(keyValue)}
                  >
                    <Edit size={16} />
                  </Button>
                  <Tooltip
                    title={
                      !isViewOnly && keyValue.slides.length
                        ? 'Used in slides'
                        : ''
                    }
                  >
                    <span>
                      <Button
                        disabled={isViewOnly || keyValue.slides.length}
                        onClick={() => onDeleteRecord(keyValue._id)}
                      >
                        <Trash size={16} />
                      </Button>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={kvsGroupData?.pagination.total === 0}
          count={kvsGroupData?.pagination.total || 0}
        />
      </TableContainer>
      <DialogModal
        title="Edit Key Values"
        onClose={() => setOpenEditor(false)}
        open={openEditor}
        style={{ width: '40%' }}
      >
        <EditKeyValueForm
          onFinished={() => setOpenEditor(false)}
          activeKeyValue={activeKeyValue}
          refetch={refetch}
        />
      </DialogModal>
      <DialogModal
        title="Edit Key Values"
        onClose={() => setOpenAllEditor(false)}
        open={openAllEditor}
        style={{ width: '40%' }}
      >
        <EditAllKeyValuesForm
          onFinished={() => setOpenAllEditor(false)}
          kvsGroup={kvsGroupId}
          refetch={refetch}
        />
      </DialogModal>
    </>
  );
};

export default forwardRef(KvsKeyValuesTable);

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  FormHelperText,
  InputLabel,
  SelectChangeEvent,
  Stack,
  TextField,
  FormControl,
} from '@mui/material';
import { AlertBar } from '../../components/AlertBar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import { useFormik } from 'formik';
import { Frequency, RRule } from 'rrule';
import { Weekday } from 'rrule/dist/esm';
import { parseString } from 'rrule/dist/esm/parsestring';
import { useGetOrganizationPresentations } from '../../api-http/presentations';
import { FREQUENCY, WEEK_DAYS } from '../scheduler/constant';
import { Select } from '@mui/material';
import * as Yup from 'yup';
import { rRuleGenerator } from '../../util/rRule';
import { formMsg, notifyMsg } from '../../configs';
import { useSnack } from '../../util/useSnack';
import { useGetSchedulersEvents, useUpdateEvent } from '../../api-http/event';
import { useParams } from 'react-router-dom';
import { useTable } from '../../util/useTable';
import { EventEditResponse } from '../../types/event.types';
import { frequency as CustomFrequency } from '../../util/frequency';
import getMinDate from '../../util/getMinDate';
interface DayContainerProps {
  selected?: boolean;
}

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

const CustomDaysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const DayContainer = styled.div<DayContainerProps>`
  background-color: ${(props) => (props.selected ? '#1f77df' : '#e1e1e1')};
  color: ${(props) => (props.selected ? 'white' : 'black')};
  cursor: pointer;
  border-radius: 100px;
  margin-right: 6px;
  height: 40px;
  width: 40px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DateContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;
interface SchedulerEditFormProps {
  onFinished: () => void;
  schedulerId: string;
  event: EventEditResponse | undefined;
}

const validationSchema = Yup.object().shape({
  presentation: Yup.string().required(formMsg.EVENT_PRESENTATION_REQUIRED),
  frequency: Yup.string().required(formMsg.EVENT_FREQUENCY_REQUIRED),
  startDate: Yup.date().required(formMsg.EVENT_START_DATE_REQUIRED),
  endDate: Yup.date().required(formMsg.EVENT_END_DATE_REQUIRED),
  startTime: Yup.string().required(formMsg.EVENT_START_TIME_REQUIRED),
  endTime: Yup.string().required(formMsg.EVENT_END_TIME_REQUIRED),
});

const MIN_DATE = getMinDate();

export const SchedulerEditForm: FC<SchedulerEditFormProps> = ({
  onFinished,
  schedulerId,
  event,
}) => {
  const params = useParams<{ id: string }>();
  const { data: presentationData } = useGetOrganizationPresentations();
  const { pagingParams } = useTable();
  const { refetch } = useGetSchedulersEvents({
    ...pagingParams,
    scheduler: params.id,
  });

  const { showErrSnack, showSuccessSnack } = useSnack();
  const { mutate, isLoading, error } = useUpdateEvent({
    onSuccess: () => {
      refetch();
      showSuccessSnack(notifyMsg.EVENT_UPDATED_SUCCESS);
      onFinished();
    },
    onError: () => {
      showErrSnack(notifyMsg.EVENT_UPDATED_FAILED);
    },
  });
  const [frequency, setFrequency] = useState('');
  const [rRuleFrequency, setRRuleFrequency] = useState<Frequency>(RRule.DAILY);
  const [selectedDays, setSelectedDays] = useState<any[]>();
  const [weekDays, setWeekDays] = useState<Weekday[]>();

  const handleDaySelector = (dayId: number) => {
    if (selectedDays !== undefined && selectedDays.includes(dayId)) {
      setSelectedDays(selectedDays.filter((id) => id !== dayId));
    } else {
      setSelectedDays(
        selectedDays !== undefined ? [...selectedDays, dayId] : [dayId],
      );
      {
        const selectedDay = WEEK_DAYS.find((value) => value.id === dayId);
        if (selectedDay !== undefined) {
          setWeekDays([
            ...(weekDays !== undefined ? weekDays : []),
            selectedDay.value,
          ]);
        }
      }
    }
  };

  const handleFrequencyChange = (event: SelectChangeEvent) => {
    setFrequency(event.target.value);
    if (frequency !== undefined) {
      setRRuleFrequency(
        FREQUENCY.filter((value) => value.frequency === event.target.value)?.[0]
          .value,
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      presentation: event?.presentation._id || '',
      presentationSchedule: null,
      frequency:
        CustomFrequency(
          event?.presentationSchedule.freq,
          event?.presentationSchedule.byweekday,
        ) || '',
      startDate: event?.presentationSchedule.dtstart.slice(0, 10) || '',
      endDate: event?.presentationSchedule.until.slice(0, 10) || '',
      startTime: event?.startTime || '',
      endTime: event?.endTime || '',
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const {
        presentation,
        frequency,
        startDate,
        endDate,
        startTime,
        endTime,
      } = values;

      const rule = await rRuleGenerator({
        frequency: frequency,
        rRuleFrequency,
        startDate: startDate,
        endDate: endDate,
        selectedDays,
        weekDays,
      });

      if (rule) {
        const presentationScheduleRule = JSON.stringify(
          parseString(rule.toString()),
        );

        mutate({
          id: schedulerId || '',
          presentation: presentation || '',
          presentationSchedule: presentationScheduleRule,
          startTime:
            startTime.length === 8
              ? startTime
              : startTime.padEnd(startTime.length + 3, ':00'),
          endTime:
            endTime.length === 8
              ? endTime
              : endTime.padEnd(endTime.length + 3, ':00'),
        });
        onFinished();
      }
    },
  });

  return (
    <Container>
      <AlertBar severity="error" msg={error?.msg} />
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="presentation">Select a presentation</InputLabel>
            <Select
              fullWidth
              labelId="presentationId"
              id="presentation"
              name="presentation"
              label="Select a presentation"
              title="presentation"
              placeholder="Select a presentation"
              value={formik.values.presentation}
              onChange={formik.handleChange}
              style={{ marginBottom: 10 }}
              error={
                formik.touched.presentation &&
                Boolean(formik.errors.presentation)
              }
            >
              {presentationData?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name || ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {Boolean(formik.errors.presentation) && (
            <FormHelperText style={{ color: 'red', margin: '0' }}>
              {formik.touched.presentation && formik.errors.presentation}
            </FormHelperText>
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateContainer>
              <TextField
                type="date"
                inputProps={{
                  min: MIN_DATE,
                }}
                sx={{ width: '100%' }}
                id="startDate"
                name="startDate"
                title="startDate"
                label="Start Date"
                value={formik.values.startDate}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
              />

              <TextField
                type="date"
                sx={{ width: '100%' }}
                inputProps={{
                  min: MIN_DATE,
                }}
                id="endDate"
                name="endDate"
                title="endDate"
                label="End time"
                value={formik.values.endDate}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              />
            </DateContainer>
            <FormControl fullWidth>
              <InputLabel id="frequency">Select a Frequency</InputLabel>
              <Select
                fullWidth
                id="frequency"
                name="frequency"
                label="Select a Frequency"
                title="frequency"
                value={formik.values.frequency}
                onChange={(event) => {
                  formik.handleChange(event);
                  handleFrequencyChange(event);
                }}
                error={
                  formik.touched.frequency && Boolean(formik.errors.frequency)
                }
              >
                {FREQUENCY.map((option) => (
                  <MenuItem key={option.frequency} value={option.frequency}>
                    {option.frequency || ''}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {Boolean(formik.errors.frequency) && (
              <FormHelperText style={{ color: 'red', margin: '0' }}>
                {formik.touched.frequency && formik.errors.frequency}
              </FormHelperText>
            )}

            {formik.values.frequency === 'Custom' && (
              <div>
                <CustomDaysContainer>
                  {WEEK_DAYS.map((day) => (
                    <DayContainer
                      key={day.id}
                      onClick={() => handleDaySelector(day.id)}
                      selected={selectedDays?.includes(day.id) ?? false}
                    >
                      {day.day}
                    </DayContainer>
                  ))}
                </CustomDaysContainer>
              </div>
            )}

            <DateContainer>
              <TextField
                type="time"
                sx={{ width: '100%' }}
                id="startTime"
                name="startTime"
                title="startTime"
                label="Start time"
                value={formik.values.startTime}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                error={
                  formik.touched.startTime && Boolean(formik.errors.startTime)
                }
                helperText={formik.touched.startTime && formik.errors.startTime}
              />

              <TextField
                type="time"
                sx={{ width: '100%' }}
                id="endTime"
                name="endTime"
                title="endTime"
                label="End time"
                value={formik.values.endTime}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                helperText={formik.touched.endTime && formik.errors.endTime}
              />
            </DateContainer>
          </LocalizationProvider>

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
          >
            Update
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

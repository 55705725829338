import styled from 'styled-components';
import { ArrowRight } from 'react-feather';

import SurveySettings from './Survey';
import SingleLineInput from './SingleLineInput';
import RadioButtonGroup from './RadioButtonGroup';
import Page from './Page';
import MultiLineInput from './MultiLineInput';
import DropDown from './DropDown';
import MultiSelectDropDown from './MultiSelectDropDown';
import RatingScale from './RatingScale';
import Toggle from './Toggle';
import { useEditorData } from '../../EditorProvider';
import { InputType } from '../../types';
import { IconButton } from '@mui/material';
import { FC } from 'react';

type ContainerProps = {
  isOpen: boolean;
};

const SETTINGS_PANEL_WIDTH = 400;

const SettingsPanelContainer = styled.div<ContainerProps>`
  background: white;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  border-left: 1px solid lightgray;
  width: ${SETTINGS_PANEL_WIDTH}px;
  overflow: auto;
  height: 100vh;
`;

const Head = styled.div`
  background: white;
  display: flex;
  justify-content: flex-start;
  height: 50px;
  border-top: 1px solid lightgray;
  padding: 10px;
  border-bottom: 1px solid lightgray;
`;

const Body = styled.div`
  display: flex;
  padding: 10px 10px;
`;

const {
  SURVEY,
  SINGLE_LINE,
  RADIO_BUTTON,
  PAGE,
  MULTI_LINE,
  DROP_DOWN,
  MULTI_DROP_DOWN,
  RATING_SCALE,
  TOGGLE,
} = InputType;

const SETTINGS_CONTENT = {
  [PAGE]: <Page />,
  [SURVEY]: <SurveySettings />,
  [SINGLE_LINE]: <SingleLineInput />,
  [RADIO_BUTTON]: <RadioButtonGroup />,
  [MULTI_LINE]: <MultiLineInput />,
  [DROP_DOWN]: <DropDown />,
  [MULTI_DROP_DOWN]: <MultiSelectDropDown />,
  [RATING_SCALE]: <RatingScale />,
  [TOGGLE]: <Toggle />,
};

const SettingsPanel: FC = () => {
  const { isSettingsOpen, setIsSettingsOpen, selectedItem } = useEditorData();

  return (
    <SettingsPanelContainer isOpen={isSettingsOpen}>
      <Head>
        <IconButton onClick={() => setIsSettingsOpen(false)}>
          <ArrowRight />
        </IconButton>
      </Head>
      <Body>
        {selectedItem && selectedItem.type !== '' ? (
          SETTINGS_CONTENT[selectedItem.type]
        ) : (
          <div>Please select an element</div>
        )}
      </Body>
    </SettingsPanelContainer>
  );
};

export default SettingsPanel;

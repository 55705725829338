import { FC } from 'react';

interface LableItemProps {
  value: number;
}

const LableItem: FC<LableItemProps> = ({ value }) => {
  return (
    <label
      className="sd-rating__item sd-rating__item--fixed-size"
      style={{
        marginRight: '10px',
        marginLeft: '0px',
      }}
    >
      <input
        type="radio"
        className="sv-visuallyhidden"
        name="question1"
        id="sq_216i_2"
        aria-required="false"
        aria-label="question1"
        aria-invalid="false"
        value={value}
      />
      <span className="sd-rating__item-text">
        <span className="svc-string-editor">
          <span className="svc-string-editor__content">
            <div className="svc-string-editor__border">
              <svg
                className="sv-svg-icon svc-string-editor__button svc-string-editor__button--edit"
                role="img"
                style={{ width: '16px', height: '16px' }}
              >
                <use xlinkHref="#icon-edit"></use>
              </svg>
            </div>
            <span className="svc-string-editor__input">
              <span
                role="textbox"
                className="sv-string-editor"
                contentEditable="false"
                spellCheck="false"
                aria-placeholder=""
                aria-label="content editable"
              >
                {value}
              </span>
              <div className="svc-string-editor__controls"></div>
            </span>
          </span>
        </span>
      </span>
    </label>
  );
};

export default LableItem;

import React, { FC, useEffect } from 'react';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';

import { getApiError } from '../../api-http/apiService';
import {
  CreateCategoryResponse,
  useGetAllCategory,
} from '../../api-http/categories';
import { useUpdatePresentation } from '../../api-http/presentations';
import { PresentationResponse } from '../../types/presentation.types';
import { AlertBar } from '../../components/AlertBar';
import { formMsg, notifyMsg } from '../../configs';
import { ERROR, SUCCESS } from '../../util/constant';

interface RenamePresentationFormProps {
  onFinished: () => void;
  presentation: PresentationResponse | undefined;
}

const validationSchema = yup.object({
  name: yup.string().required(formMsg.TITLE_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const RenamePresentationForm: FC<RenamePresentationFormProps> = ({
  onFinished,
  presentation,
}) => {
  const { isLoading, error, data, mutate, isSuccess, isError } =
    useUpdatePresentation();
  const { data: categoryData } = useGetAllCategory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?._id) {
      onFinished();
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(notifyMsg.PRESENTATION_DETAILS_UPDATE_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      const errorMsg = getApiError(error);
      enqueueSnackbar(errorMsg, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError]);

  const formik = useFormik({
    initialValues: {
      name: presentation?.name || '',
      category: presentation?.category?._id || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate({
        id: presentation?._id || '',
        name: values.name,
        category: values.category || '',
      });
    },
  });

  return (
    <Container>
      <AlertBar
        severity="error"
        msg={error?.msg}
        style={{ marginBottom: 10 }}
      />

      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Presentation Title"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <FormControl sx={{ width: '100%', minWidth: 200 }}>
            <InputLabel id="category">Add Category</InputLabel>
            <Select
              fullWidth
              id="category"
              name="category"
              value={formik.values.category}
              title="category"
              input={<OutlinedInput label="category" />}
              placeholder=" Select category"
              onChange={formik.handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {categoryData?.map(
                (categoryName: CreateCategoryResponse | undefined) => (
                  <MenuItem key={categoryName?._id} value={categoryName?._id}>
                    {categoryName?.name || ''}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>

          <Button
            color="primary"
            disabled={!formik.dirty || isLoading}
            variant="contained"
            fullWidth
            type="submit"
          >
            Update
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

import React, { FC, useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import styled from 'styled-components/macro';

import SignInComponent from './SignInForm';
import { metaTitle } from '../../../util/tabTitle';
import { useNavigate } from 'react-router-dom';
import { DISPLAY_HOST_NAME } from '../../../configs';
import { getTokenInLocal } from '../../../util/cacheStorage';
import Logo from '../../../components/Logo';

const MainWrapper = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7ebf0;
`;

const Wrapper = styled(Paper)`
  padding: 28px;
  max-width: 400px;
  margin: 12px;
`;

const SignIn: FC = () => {
  metaTitle('Sign In');
  const navigate = useNavigate();

  const [token] = useState(getTokenInLocal());

  useEffect(() => {
    if (window.location.host === DISPLAY_HOST_NAME) {
      navigate('/display-login');
    }
    if (token) {
      navigate('/');
    }
  }, []);
  return (
    <MainWrapper>
      <Wrapper>
        <Logo />

        <Typography component="h2" variant="body1" align="center" gutterBottom>
          Sign in to your account to continue
        </Typography>
        <br />

        <SignInComponent />
      </Wrapper>
    </MainWrapper>
  );
};

export default SignIn;

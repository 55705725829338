import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { IFRAME_WIDGETS } from '../../../config';
import { dragElement, getDimensions, handleRatio } from './common';

const { WORLD_CLOCK, DATE } = IFRAME_WIDGETS;

type DateAndTimeProps = {
  canvas: fabric.Canvas | null;
  obj?: fabric.Object;
  canvasContainer?: HTMLCollectionOf<Element>;
  ratio?: number;
  isPreview?: boolean;
  isTime?: boolean;
  handleHistory?: () => void;
};

const handleWorldClockAndDate = (props: DateAndTimeProps) => {
  const {
    canvas,
    obj,
    ratio: propRatio,
    isTime = true,
    isPreview,
    handleHistory,
  } = props;

  let { canvasContainer } = props;

  const color = obj?.data.color || '#000000';
  if (!canvasContainer) {
    const canvasEditorContainer = document.getElementById(
      'canvas-editor-container',
    );
    canvasContainer =
      canvasEditorContainer?.getElementsByClassName('canvas-container');
  }
  if (obj?.data?.className) {
    const existsElements = document.getElementsByClassName(
      obj?.data?.className,
    );
    const elementsArray = Array.prototype.slice.call(existsElements);

    elementsArray.forEach((element) => {
      element.remove();
    });
  }

  const iframeDimensions = getDimensions(canvas, obj, propRatio);
  const { width, height, realHeight, realLeft, realTop, realWidth } =
    iframeDimensions;

  const className = obj?.data?.className || uuidv4();

  const iframe = document.createElement('iframe');
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const iframeDiv = document.createElement('div');
  iframeDiv.className = className;
  iframeDiv.id = 'overlay';
  iframeDiv.style.position = 'absolute';
  iframeDiv.style.backgroundColor = 'transparent';
  iframeDiv.style.zIndex = '2';
  handleRatio(iframeDimensions, iframeDiv);

  canvasContainer?.[0].appendChild(iframe);
  canvasContainer?.[0].appendChild(iframeDiv);

  iframe.id = 'canvas-iframe';
  iframe.className = className;
  iframe.srcdoc = getDateAndTimeSource(timezone, width, height, color, isTime);
  handleRatio(iframeDimensions, iframe);
  !isPreview && dragElement(iframeDiv, iframe, canvas, handleHistory);

  const iframeRect =
    obj ||
    new fabric.Rect({
      left: realLeft,
      top: realTop,
      width: realWidth,
      height: realHeight,
      fill: 'rgba(255, 255, 255, 0)',
      data: {
        type: isTime ? WORLD_CLOCK : DATE,
        className,
        color,
        isTime,
      },
    });

  function updateIframePosition(e: any) {
    const object = e.target;
    const ratio = canvas?.getZoom() || 1;

    if (object === iframeRect) {
      const scaledWidth = `${object.width * object.scaleX * ratio}px`;
      const scaledHeight = `${object.height * object.scaleY * ratio}px`;
      const objectLeft = `${object.left * ratio}px`;
      const objectTop = `${object.top * ratio}px`;

      iframeDiv.style.left = objectLeft;
      iframeDiv.style.top = objectTop;
      iframeDiv.style.width = scaledWidth;
      iframeDiv.style.height = scaledHeight;

      iframe.style.left = objectLeft;
      iframe.style.top = objectTop;
      iframe.style.width = scaledWidth;
      iframe.style.height = scaledHeight;
      iframe.srcdoc = getDateAndTimeSource(
        timezone,
        scaledWidth,
        scaledHeight,
        object?.data.color,
        object?.data.isTime,
      );
    }
  }

  canvas?.on('object:scaling', updateIframePosition);

  if (!obj) canvas?.add(iframeRect);
  canvas?.renderAll();
};

const getStyleTag = (
  width: string,
  height: string,
  color: string,
  isTime: boolean,
) => {
  return `
    <style>
      .zero_margin {
        margin: 0;
        overflow: hidden;
      }

      #clock_widget_text {
        font-family: 'Roboto', sans-serif;
        width: ${width};
        height: ${height};
        display: flex;
        justify-content: center;
        align-items: center;
      }

      #clock_widget_text .cwt_time {
        font-size: 20vw;
        text-align: center;
        font-weight: 600;
        color: ${color};
        ${!isTime && 'display: none;'}
      }

      #clock_widget_text .cwt_header,
      #clock_widget_text .cwt_header a {
        display: none;
      }

      #clock_widget_text .cwt_footer {
        font-size: 8vw;
        text-align: center;
        font-weight: 600;
        color: ${color};
        ${isTime && 'display: none;'}
      }

      @media (max-width: 400px) {
        #clock_widget_text .cwt_time {
            font-size: 15vw;
        }

        #clock_widget_text .cwt_footer {
          font-size: 7vw;
      }
      }
    </style>
  `;
};

const getDateAndTimeSource = (
  timezone: string,
  width: number | string,
  height: number | string,
  color: string,
  isTime = true,
) => {
  if (typeof width === 'number') width = `${width}px`;
  if (typeof height === 'number') height = `${height}px`;
  return `
    <html>
      <head>
        ${getStyleTag(width, height, color, isTime)}
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
      </head>
      <body class="zero_margin" style="margin: 0;">
        <input type="hidden" id="js_uid" value="499367">
        <input type="hidden" id="js_language" value="en">
        <input type="hidden" id="js_tz" value="${timezone}">
        <div id="clock_widget_text">
          <div class="cwt_header"></div>
          <div class="cwt_time" id="js_cwt_time"></div>
          <div class="cwt_footer" id="js_cwt_footer"></div>
          <div id="full_screen"></div>
        </div>
          <script src="https://web.logwork.com/cores/182/tpl/main/widget/text/js/clock_text.js"></script>
      </body>
    </html>
    `;
};

export { getDateAndTimeSource, getStyleTag, getDimensions };

export default handleWorldClockAndDate;

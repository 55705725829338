import * as React from 'react';
import { FC } from 'react';
import { Trash, Edit3, Lock } from 'react-feather';

import { Menu, MenuItem } from '@mui/material';

interface UserActionMenuProps {
  onClickRename: () => void;
  onClickResetPassword: () => void;
  onClickDelete: () => void;
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
}

export const UserActionMenu: FC<UserActionMenuProps> = ({
  onClickRename,
  onClickResetPassword,
  onClickDelete,
  onClose,
  open,
  anchorEl,
}) => {
  return (
    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      <MenuItem onClick={onClickRename}>
        <Edit3 style={{ marginRight: 10 }} size={16} />
        Rename
      </MenuItem>
      <MenuItem onClick={onClickResetPassword}>
        <Lock style={{ marginRight: 10 }} size={16} />
        Reset Password
      </MenuItem>
      <MenuItem onClick={onClickDelete}>
        <Trash style={{ marginRight: 10 }} size={16} />
        Delete
      </MenuItem>
    </Menu>
  );
};

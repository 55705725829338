import { useEditorData } from '../providers/EditorProvider';
import { useFabricEditor } from './useFabricEditor';

export const useHandleHistory = () => {
  const { canvas } = useFabricEditor();
  const { setHistory } = useEditorData();

  const handleHistory = (fabricCanvas?: fabric.Canvas) => {
    const newPresent = (fabricCanvas || canvas)?._objects.map(
      (object: fabric.Object) => {
        return {
          object,
          data: object.toJSON([
            'data',
            'id',
            'deviceInfo',
            'lockMovementX',
            'lockMovementY',
            'lockRotation',
            'lockScalingX',
            'lockScalingY',
            'editable',
          ]),
        };
      },
    );
    setHistory(newPresent);
  };

  return { handleHistory };
};

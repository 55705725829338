import { FC } from 'react';

interface StartItemProps {
  value: number;
}

const StarItem: FC<StartItemProps> = ({ value }) => {
  return (
    <label
      className="sd-rating__item-star"
      style={{
        marginRight: '20px',
      }}
    >
      <input
        type="radio"
        className="sv-visuallyhidden"
        name="question1"
        id="sq_216i_4"
        aria-required="false"
        aria-label="question1"
        aria-invalid="false"
        value={value}
      />
      <svg
        className="sv-svg-icon sv-star"
        role="img"
        aria-label={value.toString()}
      >
        <use xlinkHref="#icon-rating-star"></use>
        <title>{value}</title>
      </svg>
      <svg
        className="sv-svg-icon sv-star-2"
        role="img"
        aria-label={value.toString()}
      >
        <use xlinkHref="#icon-rating-star-2"></use>
        <title>{value}</title>
      </svg>
    </label>
  );
};

export default StarItem;

import React, { FC, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { Stage } from 'transition-hook';

import { initCanvas } from './utils';
import { metaTitle } from '../../util/tabTitle';
import { AnimationEnum, DEFAULT_TRANS_TIME } from './constants';
import { getFitToWidthRatio } from '../canvas-editor-v2/utils/utils';

const transition = `${DEFAULT_TRANS_TIME / 1000}s`;

const PreviewMainWrapper = styled.div<{
  backgroundColor: string;
}>`
  overflow: hidden;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const PreviewWrapper = styled.div<{
  ratio: number;
  width: string;
  height: string;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  transform: ${({ ratio }) => `scale(${ratio}) translate(0, 0)`};
  transform-origin: left top;
`;

const getAnimation = (animation: string, stage: Stage) => {
  if (animation === AnimationEnum.fade) {
    return {
      transition,
      opacity: stage === 'enter' ? 1 : 0,
    };
  }
  if (animation === AnimationEnum.zoom) {
    return {
      transition,
      opacity: stage === 'enter' ? 1 : 0,
      transform: {
        from: `scale(2, 2) translate(-50%, -50%)`,
        enter: `scale(1) translate(0, 0)`,
        leave: `scale(1) translate(100%, 100%)`,
      }[stage],
    };
  }
  if (animation === AnimationEnum.slide) {
    return {
      transition,
      opacity: stage === 'enter' ? 1 : 0,
      transform: {
        from: `scale(1) translate(100%, 0)`,
        enter: `scale(1) translate(0, 0)`,
        leave: `scale(1) translate(-100%, 0)`,
      }[stage],
    };
  }
  return {};
};

interface PresentationSlideProps {
  margin?: number;
  currentSlide: any;
  kvsData?: any;
  stage?: any;
  assetsList?: any;
}

const PresentationSlide: FC<PresentationSlideProps> = ({
  currentSlide,
  kvsData,
  stage,
  assetsList,
  margin = 0,
}) => {
  metaTitle('Preview');

  const ref = useRef<HTMLDivElement>(null);
  const [displayRatio, setDisplayRatio] = useState(1);

  const [searchParams] = useSearchParams();
  let intervalId: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (stage === 'enter') {
      initCanvas(
        currentSlide,
        displayRatio,
        kvsData,
        assetsList,
        searchParams.get('brand') || '',
        stage,
      );
    }

    return () => videoMute(currentSlide, assetsList);
  }, [currentSlide, displayRatio, kvsData, assetsList, stage]);

  useEffect(() => {
    clearInterval(intervalId);
    intervalId = setInterval(setVideoDetails, 1000);
    return () => clearInterval(intervalId);
  }, [currentSlide]);

  const setVideoDetails = () => {
    const objects = currentSlide?.slideData?.projectData?.objects || [];
    for (const object of objects) {
      if (object.type === 'image' && object?.data?.type === 'video') {
        if (object.data.assetId) {
          const videoEl = document.getElementsByTagName('video');
          const videoName = document.getElementById(
            'vid-detail-name-p',
          ) as HTMLParagraphElement;
          const videoCurrentTime = document.getElementById(
            'vid-detail-current-time-p',
          ) as HTMLParagraphElement;
          const regex = /\/([^\/]+)$/;
          if (videoName) {
            videoName.innerHTML = `${object?.data?.url
              .match(regex)[1]
              .substring(25)}`;
          }
          if (videoCurrentTime) {
            videoCurrentTime.innerHTML = `${secondsToMinutesAndSeconds(
              Math.round(videoEl[0]?.currentTime),
            )}/${secondsToMinutesAndSeconds(Math.round(videoEl[0]?.duration))}`;
          }
        }
      }
    }
  };

  const secondsToMinutesAndSeconds = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const videoMute = (slide: any, assetsList: any) => {
    const mainDiv = document?.getElementsByClassName(
      `${slide?.id}_minimap`,
    )?.[0];
    if (!mainDiv) {
      return;
    }
    const objects = slide?.slideData?.projectData?.objects || [];

    for (const object of objects) {
      if (object.type === 'image' && object?.data?.type === 'video') {
        if (object.data.assetId) {
          const elementId = `wrapper-div_${object.data.assetId}`;
          const existingWrapperDiv = document.getElementById(
            elementId,
          ) as HTMLDivElement;
          if (existingWrapperDiv) {
            existingWrapperDiv.remove();
          }
          const videoEl = assetsList[object.data.assetId] as HTMLVideoElement;
          if (videoEl) {
            videoEl.muted = true;
          }
        }
      }
    }
  };

  const slide = currentSlide?.slideData as any;

  if (!slide?.projectData?.deviceInfo) {
    return <LinearProgress />;
  }
  const {
    projectData: {
      deviceInfo: { width, height, name },
      background,
    },
  } = slide;

  const { boxHeight, boxWidth, ratio } = getFitToWidthRatio({
    originalHeight: parseInt(height),
    originalWidth: parseInt(width),
    margin: margin,
    name,
  });

  useEffect(() => {
    setDisplayRatio(ratio);
  }, [ratio]);

  return (
    <PreviewMainWrapper
      backgroundColor={background}
      ref={ref}
      style={{
        width: boxWidth,
        maxHeight: boxHeight,
        position: 'absolute',
        ...(stage && getAnimation(currentSlide.animation, stage)),
      }}
    >
      <PreviewWrapper
        ratio={1}
        width={`${boxWidth}px`}
        height={`${boxHeight}px`}
      >
        <div
          id={`slide-${currentSlide?.id}`}
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className={`${currentSlide?.id}_minimap`}
            style={{
              height: '100vh',
              width: '100vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </div>
      </PreviewWrapper>
    </PreviewMainWrapper>
  );
};

export default React.memo(PresentationSlide);

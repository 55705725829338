import { useGetPublicForm } from '../../api-http/forms';
import { metaTitle } from '../../util/tabTitle';
import NotFoundPage from '../not-found/NotFoundPage';
import { LinearProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import SurveyPreview from '../../components/SurveyPreview';
import { EditorProvider } from '../../components/survey-editor/EditorProvider';

const FormPublicPreview = () => {
  metaTitle('Form Public Preview');
  const { formId } = useParams();

  const { data, isLoading } = useGetPublicForm(formId);

  if (data !== undefined) {
    return (
      <EditorProvider>
        <SurveyPreview data={data} formId={formId} />
      </EditorProvider>
    );
  } else if (isLoading) {
    return <LinearProgress />;
  } else {
    return <NotFoundPage />;
  }
};

export default FormPublicPreview;

import { useFabricEditor } from '../../lib/FabricJs';
import { useHandleHistory } from '../../lib/FabricJs/hooks/useHandleHistory';
import handleAddWebSite from '../../lib/FabricJs/utils/handleWebSite';
import Button from './components/Button';
import { InputBase, Paper } from '@mui/material';
import { FC, useState } from 'react';

const WebSiteWidget: FC = () => {
  const { canvas } = useFabricEditor();
  const { handleHistory } = useHandleHistory();

  const [siteUrl, setSiteUrl] = useState('');
  const [validUrl, setValidUrl] = useState(false);

  const validateURL = (url: string) => {
    // Regular expression pattern for URL validation
    const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;

    setValidUrl(urlPattern.test(url));
  };

  return (
    <>
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', mb: 2 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Site Url"
          inputProps={{ 'aria-label': 'Add Website' }}
          onChange={(e) => {
            setSiteUrl(e.target.value);
            validateURL(e.target.value);
          }}
          value={siteUrl}
        />
      </Paper>
      <Button
        disabled={!validUrl}
        onClick={() => {
          canvas && handleAddWebSite(canvas, siteUrl, handleHistory);
          setSiteUrl('');
        }}
        style={validUrl ? {} : { backgroundColor: '#808080' }}
      >
        Add WebSite
      </Button>
    </>
  );
};

export default WebSiteWidget;

import { Button } from '@mui/material';
import React, { FC } from 'react';
import { AlertTriangle } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const MAX_TIMOUT_COUNT_IN_MINS = 5;

interface TimeoutProps {
  refresh: () => void;
}

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const Timeout: FC<TimeoutProps> = ({ refresh }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <div
        style={{
          fontFamily: 'Roboto,sans serif',
          display: 'flex',
          marginBottom: '10px',
        }}
      >
        <AlertTriangle />
        <span style={{ marginLeft: '10px' }}>
          Disconnected Due To Inactivity
        </span>
      </div>
      <p
        style={{
          fontFamily: 'Roboto,sans serif',
          fontSize: '14px',
          color: 'GrayText',
        }}
      >
        {`Oops! It seems you've been inactive for more than ${MAX_TIMOUT_COUNT_IN_MINS} minutes, For
        security reasons your session has been automatically disconnected`}
      </p>
      <div>
        <Button
          onClick={() => navigate('/slides')}
          style={{ marginRight: '10px' }}
        >
          Back To Slides Page
        </Button>
        <Button onClick={refresh} style={{ marginRight: '10px' }}>
          Ok
        </Button>
      </div>
    </Container>
  );
};

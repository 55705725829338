import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { InputType, RatingScaleInputProp } from '../../types';
import { useEditorData } from '../../EditorProvider';
import { changeElementData } from '../../utils/surveyDataUtils';
import { RemoveButton } from './RemoveButton';
import StartItem from './rate-types/Star';
import LableItem from './rate-types/Lable';
import SmileyItem from './rate-types/Smiley';
import { rateTypes } from '../../utils/RateTypes';
import { capitalize } from '../../utils/capitalize';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { MinusCircle, PlusCircle } from 'react-feather';
import getAlignment from '../../utils/getAlignment';

const MAX_VALUES = 5;
const MIN_VALUES = 2;

const { Stars, Smileys } = rateTypes;
enum ValueCountChangeTypes {
  increment = 'increment',
  decrement = 'decrement',
}

const StyleOption = styled.option`
  padding: 8px;
  border-radius: 3px;
  border: 1px solid gray;
  font-size: 14px;
`;

const RatingScale: FC<RatingScaleInputProp> = ({
  elementData,
  elementId,
  pageId,
  index,
}) => {
  const [rateType, setRateType] = useState<string>();
  const [values, setValues] = useState<number[]>([]);
  const [valueCount, setValueCount] = useState<number>(5);

  useEffect(() => {
    setValues(Array.from({ length: valueCount }, (_, index) => index + 1));
  }, [valueCount]);

  useEffect(() => {
    if (!elementData) return;

    const params = {
      surveyData,
      data: { rateCount: valueCount as number, rateMax: valueCount as number },
      focus,
      setHistory,
    };
    changeElementData(params);
  }, [valueCount]);

  const {
    history,
    setHistory,
    setSelectedItem,
    selectedItem: focus,
    setIsSettingsOpen,
  } = useEditorData();

  const { present: surveyData } = history;

  const onChange = (e: FormEvent<HTMLSpanElement>) => {
    if (!elementData) return;

    const { id, textContent } = e.currentTarget;

    const params = {
      surveyData,
      data: { [id]: textContent as string },
      focus,
      setHistory,
    };
    changeElementData(params);
    setIsSettingsOpen(true);
  };

  const changeValueCount = (type: ValueCountChangeTypes) => {
    if (type === ValueCountChangeTypes.increment) {
      if (values?.length >= MAX_VALUES) return;
      setValueCount(valueCount + 1);
    } else {
      if (values?.length <= MIN_VALUES) return;
      setValueCount(valueCount - 1);
    }
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value, id } = e.currentTarget;
    setRateType(value);

    if (!elementData) return;

    const params = {
      surveyData,
      data: { [id]: value as string },
      focus,
      setHistory,
    };
    changeElementData(params);
  };

  return (
    <div
      className="svc-row"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedItem({ pageId, elementId, type: InputType.RATING_SCALE });
      }}
    >
      <div className="sd-row sd-clearfix sd-page__row">
        <div
          data-key="question10"
          style={{ flex: '1 1 100%', minWidth: '300px', maxWidth: '100%' }}
        >
          <div
            data-sv-drop-target-survey-element="question1"
            className="svc-question__adorner"
          >
            <div
              className="svc-question__content svc-question__content--rating svc-question__content--selected"
              tabIndex={0}
            >
              <div
                id="sq_4106"
                className="sd-element sd-question sd-row__question"
                role="textbox"
                aria-required="false"
                aria-invalid="false"
                aria-labelledby="sq_4106_ariaTitle"
                data-rendered="r"
                data-name="question1"
              >
                <div
                  className={`sd-question__header sd-element__header sd-question__header--location-top sd-element__header--location-top ${getAlignment(
                    surveyData,
                  )}`}
                >
                  <h5
                    className="sd-title sd-element__title sd-question__title sd-element__title--collapsed"
                    id="sq_4106_ariaTitle"
                    aria-label="question1"
                  >
                    <span
                      data-key="q_num"
                      className="sd-element__num"
                      aria-hidden="true"
                      style={{ position: 'static' }}
                    >
                      {index}.
                    </span>
                    <span data-key="num-sp">&nbsp;</span>
                    <span className="svc-string-editor">
                      <span className="svc-string-editor__content">
                        <span className="svc-string-editor__input">
                          <span
                            id="title"
                            role="textbox"
                            className="sv-string-editor"
                            contentEditable="true"
                            spellCheck="false"
                            aria-label="content editable"
                            data-gramm="false"
                            data-quillbot-element="MTCX7UuXoywoDl_zpdDIr"
                            aria-placeholder={`Question ${index}`}
                            onBlur={onChange}
                            suppressContentEditableWarning
                          >
                            {elementData?.title}
                          </span>
                          <div className="svc-string-editor__controls" />
                        </span>
                      </span>
                    </span>
                  </h5>
                  <div className="sd-description sd-question__description">
                    <span
                      className={`svc-string-editor  ${
                        !elementData?.description && 'svc-string-editor--hidden'
                      }`}
                    >
                      <span
                        className="svc-string-editor__content"
                        style={{ fontSize: '14px', marginTop: '5px' }}
                      >
                        {elementData?.description}
                        <div className="svc-string-editor__border"></div>
                        <span className="svc-string-editor__input">
                          <span
                            id="description"
                            role="textbox"
                            className="sv-string-editor"
                            spellCheck="false"
                            aria-label="content editable"
                            data-gramm="false"
                            onInput={onChange}
                          ></span>
                          <div className="svc-string-editor__controls"></div>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div className="svc-rating-question-content">
                  <div className="sd-question__content" role="presentation">
                    <div
                      className="sd-scrollable-container sd-rating sd-rating--wrappable"
                      data-rendered="r"
                      style={{ marginTop: '25px', marginLeft: '0px' }}
                    >
                      <fieldset role="radiogroup">
                        <legend role="presentation" className="sv-hidden" />
                        {values?.map((val: number) => {
                          let item: React.ReactNode;

                          switch (rateType) {
                            case Stars:
                              item = <StartItem value={val} />;
                              break;

                            case Smileys:
                              item = <SmileyItem value={val} />;
                              break;

                            default:
                              item = <LableItem value={val} />;
                          }

                          return item;
                        })}
                      </fieldset>

                      <div style={{ marginTop: '20px' }}>
                        <Button
                          style={{ marginLeft: '-15px' }}
                          onClick={() =>
                            changeValueCount(ValueCountChangeTypes.increment)
                          }
                        >
                          <PlusCircle />
                        </Button>
                        <Button
                          onClick={() =>
                            changeValueCount(ValueCountChangeTypes.decrement)
                          }
                          style={{ marginLeft: '-15px', marginRight: '10px' }}
                        >
                          <MinusCircle />
                        </Button>
                        <select
                          disabled={focus.elementId != elementId}
                          style={{
                            padding: '10px',
                            borderRadius: '3px',
                            fontSize: '14px',
                            marginTop: '10px',
                            border: 'none',
                            outline: 'none',
                          }}
                          id="rateType"
                          onChange={(e) => handleSelect(e)}
                        >
                          {rateType}
                          <StyleOption value="">Lables</StyleOption>
                          {Object.values(rateTypes).map((val, index) => {
                            if (val != rateTypes.Lables) {
                              return (
                                <StyleOption key={index} value={val}>
                                  {capitalize(val)}
                                </StyleOption>
                              );
                            }
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <RemoveButton
                surveyData={surveyData}
                pageId={pageId}
                elementId={elementId}
                setHistory={setHistory}
                setSelectedItem={setSelectedItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingScale;

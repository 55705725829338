import { useMutation, useQuery } from '@tanstack/react-query';
import { PagingResponse } from '../types';
import { PaginateOptions } from '../types/pagination.types';
import {
  SchedulerEventBody,
  SchedulerEventResponse,
  SchedulerGetResponse,
  SchedulerResponse,
  ShowSchedulerResponse,
} from '../types/scheduler.types';
import { ApiServiceErr, axiosApi, DeleteBody, MutOptions } from './apiService';

export type GetSchedulerResponse = SchedulerGetResponse;
export interface SchedulerBody {
  name: string;
  description: string;
  defaultPresentation: string;
  timeZone: string;
  offSet: number;
}

export interface ActiveScheduler {
  id: '';
  name: '';
}

export const useDeleteSchedulers = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/scheduler/delete/${data.id}`);
    return response.data;
  }, opt);

export const useGetPaginateSchedulers = ({
  limit,
  page,
  sortby,
  order,
  search = '',
}: PaginateOptions) =>
  useQuery<PagingResponse<GetSchedulerResponse>, ApiServiceErr>(
    [
      `/scheduler/all?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}`,
      limit,
      page,
      sortby,
      order,
      search,
    ],
    async () => {
      const response = await axiosApi.get(
        `/scheduler/all?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}`,
      );
      return response.data;
    },
  );

export const useSchedulerEvents = (opt?: MutOptions<SchedulerEventResponse>) =>
  useMutation<SchedulerEventResponse, ApiServiceErr, SchedulerEventBody>(
    async (data) => {
      const response = await axiosApi.post('/event/create', data);
      return response.data;
    },
    opt,
  );

export const useGetScheduler = (deviceId?: string) =>
  useQuery<SchedulerResponse, ApiServiceErr>(
    ['/devices/scheduler', deviceId],
    async () => {
      if (deviceId) {
        const response = await axiosApi.get(`/devices/scheduler/${deviceId}`);
        return response.data;
      }
    },
  );

export const useGetShowPresentationFormPreview = (deviceId?: string) =>
  useQuery<ShowSchedulerResponse, ApiServiceErr>(
    ['/devices/show', deviceId],
    async () => {
      if (deviceId) {
        const response = await axiosApi.get(`/devices/show/${deviceId}`);
        return response.data;
      }
    },
  );

export const useGetSchedulerList = (search?: string) =>
  useQuery<[{ id: string; name: string }], ApiServiceErr>(
    ['/scheduler'],
    async () => {
      const response = await axiosApi.get(`/scheduler?search=${search}`);
      return response.data;
    },
  );

export const useCreateSchedulers = (opt?: MutOptions<SchedulerResponse>) =>
  useMutation<SchedulerResponse, ApiServiceErr, SchedulerBody>(async (data) => {
    const response = await axiosApi.post('/scheduler/create', data);
    return response.data;
  }, opt);

export type UpdateSchedulerBody = Pick<
  SchedulerResponse,
  'id' | 'name' | 'description' | 'defaultPresentation'
>;

export const useUpdateSchedulers = (opt?: MutOptions<SchedulerResponse>) =>
  useMutation<SchedulerResponse, ApiServiceErr, UpdateSchedulerBody>(
    async (data) => {
      const response = await axiosApi.put(`/scheduler/update`, {
        id: data.id,
        name: data.name,
        description: data.description,
        defaultPresentation: data.defaultPresentation,
      });
      return response.data;
    },
    opt,
  );

export const useGetCacheInterval = (deviceId?: string) =>
  useQuery<number, ApiServiceErr>(
    ['/devices/getCacheInterval', deviceId],
    async () => {
      if (deviceId) {
        const response = await axiosApi.get(
          `/devices/getCacheInterval/${deviceId}`,
        );
        return response.data;
      }
    },
  );

import styled from 'styled-components';

const ButtonComponent = styled.button`
  align-items: center;
  display: flex;
  background: none;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

export default ButtonComponent;

import { getById, getDB } from '../../indexedDb';
import { IFRAME_WIDGETS } from '../canvas-editor-v2/config';
import { getDateAndTimeSource } from '../canvas-editor-v2/lib/FabricJs/utils/handleWorldClockAndDate';
import {
  getCurrentPlayingVideoDetailsHeaderTxtElement,
  getCurrentPlayingVideoDetailsTxtElement,
  getVideoPlayingTxtElement,
  getVideoScreenshotRestrictedTxtElement,
  getWrapperElement,
} from './screenshotLayer';

import { ShapeType } from '../canvas-editor-v2/types';

const { WORLD_CLOCK, DATE, IFRAME } = IFRAME_WIDGETS;

const handleRatio = (element: any, object: any, displayRatio: number) => {
  element.style.position = 'absolute';
  element.style.display = 'block';
  element.width = object.width;
  element.height = object.height;
  element.style.left = `${object.left * displayRatio}px`;
  element.style.top = `${object.top * displayRatio}px`;
  element.style.width = `${object.width * object.scaleX * displayRatio}px`;
  element.style.height = `${object.height * object.scaleY * displayRatio}px`;
  element.style.transformOrigin = `${object.originX} ${object.originY}`;
  element.style.transform = `rotate(${object.angle}deg)`;
};

const getAndSetAnimations = (dataObj: any) => {
  if (
    !dataObj ||
    !dataObj?.animation ||
    !dataObj?.animationKeyframes ||
    !dataObj?.animationId
  ) {
    return null;
  }
  let animationStr = dataObj?.animation.split('animation: ')[1];
  if (animationStr.endsWith(';')) {
    animationStr = animationStr.slice(0, -1);
  }
  const uniqueId = dataObj?.animationId;

  const existingStyleSheet = document.getElementById(uniqueId);
  if (existingStyleSheet) {
    existingStyleSheet.remove();
  }

  const styleSheet = document.createElement('style');
  // Create a unique identifier
  styleSheet.id = uniqueId;
  styleSheet.innerHTML = dataObj?.animationKeyframes;
  document.head.appendChild(styleSheet);

  return animationStr;
};

const onAddImage = (
  obj: any,
  mainDiv: Element,
  displayRatio: number,
  assetsList: any,
) => {
  const existsDomElement = document.getElementById(obj.id) as HTMLImageElement;
  if (existsDomElement) {
    existsDomElement.id = obj.id;
    handleRatio(existsDomElement, obj, displayRatio);
    mainDiv.appendChild(existsDomElement);
  } else if (obj.data.assetId && assetsList[obj.data.assetId]) {
    const img = assetsList[obj.data.assetId] as HTMLImageElement;
    img.setAttribute('id', obj.id);
    img.src = obj?.src;
    const animation = getAndSetAnimations(obj?.data);
    if (animation) {
      img.style.animation = animation;
    }
    handleRatio(img, obj, displayRatio);
    mainDiv.appendChild(img.cloneNode());
  }
};

const handleRatioVideo = (element: any, object: any, displayRatio: number) => {
  element.style.position = 'absolute';
  element.style.display = 'block';
  element.width = object.width;
  element.height = object.height;
  element.style.top = '0px';
  element.style.width = `${object.width * object.scaleX * displayRatio}px`;
  element.style.height = `${object.height * object.scaleY * displayRatio}px`;
  element.style.transformOrigin = `${object.originX} ${object.originY}`;
};

const getDataUrl = async (assetId: string, remoteUrl: string) => {
  try {
    const indexedDb = getDB();
    const assetFromDevice = await getById(indexedDb, assetId, 'assets');
    const assetBlob = assetFromDevice?.blob; // Get the Blob if it exists
    if (!assetBlob) {
      return remoteUrl;
    }
    return URL.createObjectURL(assetBlob);
  } catch (error) {
    return remoteUrl;
  }
};

const setVideoDetails: any = (
  element: HTMLVideoElement,
  object: any,
  displayRatio: number,
  assetId: string,
  deviceBrand?: string,
) => {
  const { innerWidth } = window;
  const videoRatio =
    (object.width * object.scaleX * displayRatio) / (innerWidth * 2);
  const wrapperDiv = getWrapperElement(
    object,
    displayRatio,
    assetId,
    deviceBrand,
  );
  const videoPlayingTxt = getVideoPlayingTxtElement(videoRatio);
  const videoScreenshotRestrictedTxt =
    getVideoScreenshotRestrictedTxtElement(videoRatio);
  const currentPlayingVideoNameHeaderTxt =
    getCurrentPlayingVideoDetailsHeaderTxtElement(
      'vid-detail-name-header-p',
      videoRatio,
    );
  currentPlayingVideoNameHeaderTxt.innerHTML = 'This is the current Video';
  const currentPlayingVideoNameTxt = getCurrentPlayingVideoDetailsTxtElement(
    'vid-detail-name-p',
    videoRatio,
  );
  const currentPlayingVideoTimeHeaderTxt =
    getCurrentPlayingVideoDetailsHeaderTxtElement(
      'vid-detail-current-time-header-p',
      videoRatio,
    );
  currentPlayingVideoTimeHeaderTxt.innerHTML =
    'This is the currently playing time frame';
  const currentPlayingVideoTimeTxt = getCurrentPlayingVideoDetailsTxtElement(
    'vid-detail-current-time-p',
    videoRatio,
  );
  wrapperDiv.appendChild(videoPlayingTxt);
  wrapperDiv.appendChild(videoScreenshotRestrictedTxt);
  wrapperDiv.appendChild(currentPlayingVideoNameHeaderTxt);
  wrapperDiv.appendChild(currentPlayingVideoNameTxt);
  wrapperDiv.appendChild(currentPlayingVideoTimeHeaderTxt);
  wrapperDiv.appendChild(currentPlayingVideoTimeTxt);
  wrapperDiv.appendChild(element);
  return wrapperDiv;
};

const onAddVideo = async (
  object: any,
  mainDiv: Element,
  displayRatio: number,
  assetsList: any,
  deviceBrand?: string,
  stage?: string,
) => {
  const isOneAsset = Object.keys(assetsList).length === 1;
  const existsDomElement = document.getElementById(
    object.id,
  ) as HTMLVideoElement;
  if (existsDomElement) {
    handleRatioVideo(existsDomElement, object, displayRatio);
    existsDomElement.id = object.id;
    existsDomElement.autoplay = true;
    existsDomElement.controls = false;
    existsDomElement.loop = isOneAsset;
    existsDomElement.preload = 'auto';
    existsDomElement.muted = stage === 'leave';
    existsDomElement.play();
    mainDiv.appendChild(existsDomElement);
  } else if (object.data.assetId) {
    const videoEl = assetsList[object.data.assetId] as HTMLVideoElement;
    const videoUrl = await getDataUrl(object.data.assetId, object.data.url);
    videoEl.id = object.id;
    videoEl.src = videoUrl;
    videoEl.autoplay = true;
    videoEl.controls = false;
    videoEl.preload = 'auto';
    videoEl.poster = object?.data?.thumbnailUrl || '';
    videoEl.muted = stage === 'leave';
    videoEl.width = object.width;
    videoEl.height = object.height;
    videoEl.loop = isOneAsset;
    videoEl.style.zIndex = '1';
    handleRatioVideo(videoEl, object, displayRatio);
    const wrapperDiv = setVideoDetails(
      videoEl,
      object,
      displayRatio,
      object.data.assetId,
      deviceBrand,
    );
    mainDiv.appendChild(wrapperDiv);
  }
};

const onAddIframe = (object: any, mainDiv: Element, displayRatio: number) => {
  const existingIframe = document.getElementById(
    object.id,
  ) as HTMLIFrameElement;
  const iframe = existingIframe || document.createElement('iframe');
  mainDiv.appendChild(iframe);
  iframe.id = object.id;
  iframe.className = object?.data?.className;
  iframe.allow = 'autoplay; encrypted-media';
  iframe.src = object?.data?.src;
  handleRatio(iframe, object, displayRatio);
};

const onAddClock = (object: any, mainDiv: Element, displayRatio: number) => {
  const existingIframe = document.getElementById(
    object.id,
  ) as HTMLIFrameElement;
  const iframe = existingIframe || document.createElement('iframe');
  mainDiv.appendChild(iframe);
  iframe.id = object.id;
  iframe.className = object?.data?.className;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const width = object.width * object.scaleX * displayRatio;
  const height = object.height * object.scaleY * displayRatio;
  iframe.style.border = 'none';
  iframe.style.backgroundColor = object.fill;
  iframe.srcdoc = getDateAndTimeSource(
    timezone,
    width,
    height,
    object.data.color,
  );

  handleRatio(iframe, object, displayRatio);
};

const onAddText = (
  object: any,
  mainDiv: Element,
  displayRatio: number,
  kvsData: any,
) => {
  const existsTextElement = document.getElementById(object.id);
  let textElement;
  const animation = getAndSetAnimations(object?.data);

  if (existsTextElement) {
    existsTextElement.style.display = 'block';
    existsTextElement.style.width = `${object.width}px`;
    existsTextElement.style.height = `${object.height}px`;
    existsTextElement.style.whiteSpace = 'pre';
    textElement = existsTextElement;
  } else {
    textElement = document.createElement('span');
  }
  textElement.innerHTML = object.text;
  textElement.id = object.id;
  textElement.style.fontSize = `${
    object.fontSize * object.scaleX * displayRatio
  }px`;

  textElement.style.position = 'absolute';
  textElement.style.display = 'block';
  textElement.style.left = `${object.left * displayRatio}px`;
  textElement.style.top = `${object.top * displayRatio}px`;
  textElement.style.color = object.fill;
  textElement.style.fontFamily = object.fontFamily;
  textElement.style.fontWeight = object.fontWeight;
  textElement.style.fontStyle = object.fontStyle;
  textElement.style.textAlign = object.textAlign;
  textElement.style.margin = '0px';
  textElement.style.transformOrigin = `${object.originX} ${object.originY}`;
  textElement.style.transform = `rotate(${object.angle}deg)`;
  textElement.style.whiteSpace = 'pre';

  if (animation) {
    textElement.style.animation = `${animation}`;
  }

  mainDiv.appendChild(textElement);
  if (object?.data?.type === 'kvs') {
    const kvsId = object?.data?.kvsId;
    if (kvsId && kvsData[kvsId]) {
      textElement.innerHTML = kvsData[kvsId];
    }
  }
};

const onAddCircle = (object: any, mainDiv: Element, displayRatio: number) => {
  const existsDivElement = document.getElementById(object.id);
  let divElement;
  if (existsDivElement) {
    existsDivElement.style.display = 'block';
    divElement = existsDivElement;
  } else {
    divElement = document.createElement('div');
  }
  divElement.id = object.id;
  divElement.style.borderRadius = '50%';
  divElement.style.backgroundColor = object.fill;

  const animation = getAndSetAnimations(object?.data);
  if (animation) {
    divElement.style.animation = animation;
  }

  handleRatio(divElement, object, displayRatio);
  mainDiv.appendChild(divElement);
};

const onAddRectangle = (
  object: any,
  mainDiv: Element,
  displayRatio: number,
) => {
  const existsDivElement = document.getElementById(object.id);
  let divElement;
  if (existsDivElement) {
    existsDivElement.style.display = 'block';
    divElement = existsDivElement;
  } else {
    divElement = document.createElement('div');
  }
  divElement.id = object.id;
  divElement.style.backgroundColor = object.fill;

  const animation = getAndSetAnimations(object?.data);
  if (animation) {
    divElement.style.animation = animation;
  }

  handleRatio(divElement, object, displayRatio);
  mainDiv.appendChild(divElement);
};

const onAddDate = (object: any, mainDiv: Element, displayRatio: number) => {
  const existingIframe = document.getElementById(
    object.id,
  ) as HTMLIFrameElement;
  const iframe = existingIframe || document.createElement('iframe');
  mainDiv.appendChild(iframe);
  iframe.id = object.id;
  iframe.className = object?.data?.className;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const width = object.width * object.scaleX * displayRatio;
  const height = object.height * object.scaleY * displayRatio;
  iframe.style.border = 'none';
  iframe.style.backgroundColor = object.fill;
  iframe.srcdoc = getDateAndTimeSource(
    timezone,
    width,
    height,
    object.data.color,
    false,
  );

  handleRatio(iframe, object, displayRatio);
};

export const initCanvas = async (
  slide: any,
  displayRatio: number,
  kvsData: any,
  assetsList: any,
  deviceBrand?: string,
  stage?: string,
) => {
  const mainDiv = document?.getElementsByClassName(`${slide?.id}_minimap`)?.[0];
  if (!mainDiv) {
    return;
  }
  const objects = slide?.slideData?.projectData?.objects || [];

  // getting the iframe widgets to the end of object array so that the widgets stay on top.
  for (const [i, object] of objects.entries()) {
    if (Object.values(IFRAME_WIDGETS).includes(object?.data?.type))
      objects.push(...objects.splice(i, 1));
  }

  for (const object of objects) {
    switch (object.type) {
      case 'text':
      case 'i-text':
        onAddText(object, mainDiv, displayRatio, kvsData);
        break;
      case 'image':
        if (object?.data?.type === 'video') {
          await onAddVideo(
            object,
            mainDiv,
            displayRatio,
            assetsList,
            deviceBrand,
            stage,
          );
        } else onAddImage(object, mainDiv, displayRatio, assetsList);
        break;
      case ShapeType.Circle:
        onAddCircle(object, mainDiv, displayRatio);
        break;
      case ShapeType.Rectangle:
        const { type } = object?.data || {};
        if (type === IFRAME) {
          onAddIframe(object, mainDiv, displayRatio);
        } else if (type === WORLD_CLOCK) {
          onAddClock(object, mainDiv, displayRatio);
        } else if (type === DATE) {
          onAddDate(object, mainDiv, displayRatio);
        } else {
          onAddRectangle(object, mainDiv, displayRatio);
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Not implement yet....');
    }

    object.selectable = false;
    if (object?.data?.type === 'video') {
      object.src = '';
    }
  }
};

import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../components/TableTheme';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { UserRole } from '../../types/user.types';
import { useIsUserRole } from '../../util/useIsUserRole';
import { useTable } from '../../util/useTable';
import {
  Button,
  CircularProgress,
  LinearProgress,
  TablePagination,
  Tooltip,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { Edit, Trash } from 'react-feather';
import { useConfirm } from 'material-ui-confirm';
import { notifyMsg } from '../../configs';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { DialogModal } from '../../components';
import { KvsEditForm } from './KvsEditForm';
import { useDeleteKvsGroup, useGetPaginateKvs } from '../../api-http/kvs';
import { AlertBar } from '../../components/AlertBar';
import { useAuth } from '../../context/auth-context';
import { displayDistance } from '../../util/dateTime';
import { NavLink } from 'react-router-dom';
import { KvsResponse } from '../../types/kvs.types';
import { useSnack } from '../../util/useSnack';

interface KvsTableProps {
  searchText: string;
  onRefresh: boolean;
}

interface KvsProps {
  onRefetch: () => void;
}

const KvsTable: ForwardRefRenderFunction<KvsProps, KvsTableProps> = (
  { searchText, onRefresh },
  forwardedRef,
) => {
  const { switchedOrgId } = useAuth();
  const confirm = useConfirm();
  const [openEditor, setOpenEditor] = useState(false);
  const [activeKvsGroup, setActiveKvsGroup] = useState<
    KvsResponse | undefined
  >();
  const { showErrSnack, showSuccessSnack } = useSnack();
  const { sortOnClick, pagingParams, tableProps } = useTable(
    true,
    SortEnum.name,
  );
  const { sortby, order } = pagingParams;
  const {
    data: list,
    isLoading,
    error,
    refetch,
  } = useGetPaginateKvs({ ...pagingParams, search: searchText });

  const { error: deleteError, mutate } = useDeleteKvsGroup({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.KVS_GROUP_REMOVE_SUCCESS);
      refetch();
    },
    onError: (error) =>
      showErrSnack(error || notifyMsg.KVS_GROUP_REMOVE_FAILED),
  });

  useImperativeHandle(forwardedRef, () => ({
    onRefetch() {
      refetch();
    },
  }));

  const isViewOnly = useIsUserRole([UserRole.user, UserRole.layoutEditor]);

  const onDeleteRecord = async (id: string) => {
    try {
      await confirm({
        description: notifyMsg.DELETE_CONFIRMATION,
        confirmationText: 'Yes',
      });
      mutate({ id });
    } catch (e) {}
  };

  const onEdit = (kvsGroup: KvsResponse) => {
    setOpenEditor(true);
    setActiveKvsGroup(kvsGroup);
  };

  useEffect(() => {
    refetch();
  }, [switchedOrgId, onRefresh]);

  return (
    <>
      {isLoading && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg || deleteError?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <StyledTableSortLabel
                  active={sortby === SortEnum.name}
                  direction={sortby === SortEnum.name ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.name)}
                >
                  Group
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right">Total Keys</StyledTableCell>
              <StyledTableCell align="right">Description</StyledTableCell>
              <StyledTableCell align="right">Used In</StyledTableCell>
              <StyledTableCell align="right">
                <StyledTableSortLabel
                  active={sortby === SortEnum.updatedAt}
                  direction={
                    sortby === SortEnum.updatedAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.updatedAt)}
                >
                  Updated At
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right">Updated By</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!list?.data?.length && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {isLoading ? <CircularProgress /> : 'No Records Found '}
                </TableCell>
              </TableRow>
            )}
            {list?.data?.map((kvsGroup) => (
              <TableRow
                key={kvsGroup.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="category">
                  <NavLink
                    style={{
                      textDecoration: 'underline',
                      textUnderlineOffset: '5px',
                    }}
                    to={`/kvs/${kvsGroup._id}`}
                    state={{
                      search: location?.search,
                      groupName: kvsGroup.name,
                    }}
                  >
                    {kvsGroup.name}
                  </NavLink>
                </TableCell>
                <TableCell align="right">{kvsGroup.totalKeys}</TableCell>
                <TableCell align="right">{kvsGroup.description}</TableCell>
                <TableCell align="right">
                  {kvsGroup.slidesCount
                    ? `${kvsGroup.slidesCount} slides`
                    : '-'}
                </TableCell>
                <TableCell align="right">
                  {displayDistance(kvsGroup.updatedAt)}
                </TableCell>
                <TableCell align="right">{kvsGroup.updatedBy || '-'}</TableCell>
                <TableCell align="right">
                  <Button
                    disabled={isViewOnly}
                    onClick={() => onEdit(kvsGroup)}
                  >
                    <Edit size={16} />
                  </Button>
                  <Tooltip
                    title={
                      !isViewOnly && kvsGroup.slidesCount
                        ? 'Used in slides'
                        : ''
                    }
                  >
                    <span>
                      <Button
                        disabled={isViewOnly || kvsGroup.slidesCount}
                        onClick={() => onDeleteRecord(kvsGroup._id)}
                      >
                        <Trash size={16} />
                      </Button>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>
      <DialogModal
        title="Edit KVS Group"
        onClose={() => setOpenEditor(false)}
        open={openEditor}
      >
        <KvsEditForm
          kvsGroup={activeKvsGroup}
          onFinished={() => setOpenEditor(false)}
          refetch={refetch}
        />
      </DialogModal>
    </>
  );
};

export default forwardRef(KvsTable);

import React from 'react';
import MiniLogoImg from '../../assets/vendor/mini-logo.png';
import styled from 'styled-components';

const Image = styled.img`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const MiniLogo = () => {
  return (
    <Image alt="SignageTec logo" width={32} height={32} src={MiniLogoImg} />
  );
};

export default MiniLogo;

import React, { ChangeEvent, FC, useEffect } from 'react';

import { useFormik, getIn } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { Button, CircularProgress, Stack, TextField } from '@mui/material';
import { formMsg, notifyMsg } from '../../configs';
import {
  KeyIdValue,
  useGetKeyValueListOfaGroup,
  useUpdateKvs,
} from '../../api-http/kvs';
import { AlertBar } from '../../components/AlertBar';
import { useSnack } from '../../util/useSnack';
import { formatInputKvsKey } from '../../util/validations';
import { KvsDataResponse } from '../../types/kvs.types';

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

interface KvsFormProps {
  onFinished: () => void;
  kvsGroup: string | undefined;
  refetch: () => void;
}

const validationSchema = yup.object({
  formKeyValues: yup.array().of(
    yup.object().shape({
      key: yup
        .string()
        .required(formMsg.KEY_REQUIRED)
        .matches(/^[0-9A-Z_]+$/, formMsg.INVALID_KEY),
      value: yup.string().required(formMsg.VALUE_REQUIRED),
    }),
  ),
});

export const EditAllKeyValuesForm: FC<KvsFormProps> = ({
  onFinished,
  kvsGroup,
  refetch,
}) => {
  const { showErrSnack, showSuccessSnack } = useSnack();
  const dataBody: Array<KeyIdValue> = [];

  const {
    data: list,
    isLoading: isLoadingData,
    error: dataFetchError,
  } = useGetKeyValueListOfaGroup({ kvsGroupId: kvsGroup });

  const { isLoading, error, data, mutate } = useUpdateKvs({
    onSuccess: () => {
      showSuccessSnack(notifyMsg.KVS_KEY_VALUE_UPDATE_SUCCESS);
      refetch();
    },
    onError: (error) => {
      showErrSnack(error || notifyMsg.KVS_KEY_VALUE_UPDATE_FAILED);
    },
  });

  const formik = useFormik({
    initialValues: {
      formKeyValues: (list?.data as Array<KvsDataResponse>) || [
        {
          key: '',
          value: '',
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { formKeyValues } = values;
      const initialKvsData = list?.data;
      const dataToSend = formKeyValues.filter(
        ({ value: formValue }) =>
          !initialKvsData?.some(
            ({ value: initialKeyValue }) => formValue === initialKeyValue,
          ),
      );
      dataToSend.forEach((formKeyValue) => {
        dataBody.push({
          kvsId: formKeyValue._id,
          value: formKeyValue.value,
        });
      });
      mutate({
        _id: kvsGroup || '',
        data: dataBody,
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (data) {
      onFinished();
    }
  }, [data]);

  return (
    <Container>
      <AlertBar
        style={{ marginBottom: '10px' }}
        severity="error"
        msg={error?.msg || dataFetchError?.msg}
      />
      {isLoadingData ? (
        <Stack spacing={2} alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            {formik.values.formKeyValues.map((keyValue, index) => {
              const keyValueErrors =
                (formik.errors.formKeyValues?.length &&
                  formik.errors.formKeyValues[index]) ||
                {};

              const keyValueTouched =
                (formik.touched.formKeyValues?.length &&
                  formik.touched.formKeyValues[index]) ||
                {};

              return (
                <Stack direction="row" spacing={2} key={index}>
                  <TextField
                    fullWidth
                    id={`formKeyValues.${index}.key`}
                    name={`formKeyValues.${index}.key`}
                    label="Key"
                    value={formik.values.formKeyValues[index].key}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      formatInputKvsKey(e, `formKeyValues.${index}.key`, formik)
                    }
                    size="small"
                    error={
                      keyValueTouched.key &&
                      Boolean(getIn(keyValueErrors, 'key'))
                    }
                    helperText={
                      keyValueTouched.key && getIn(keyValueErrors, 'key')
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    id={`formKeyValues.${index}.value`}
                    name={`formKeyValues.${index}.value`}
                    value={formik.values.formKeyValues[index].value}
                    onChange={formik.handleChange}
                    label="Value"
                    size="small"
                    error={
                      keyValueTouched.value &&
                      Boolean(getIn(keyValueErrors, 'value'))
                    }
                    helperText={
                      keyValueTouched.value && getIn(keyValueErrors, 'value')
                    }
                  />
                </Stack>
              );
            })}

            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={!formik.dirty || isLoading}
            >
              Save
            </Button>
          </Stack>
        </form>
      )}
    </Container>
  );
};

import styled from 'styled-components';

const SvgButton = styled.button`
  align-items: center;
  display: flex;
  background: none;
  cursor: pointer;
  &:hover {
    background: lightgray;
  }
`;

export { SvgButton };

import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { getDimensions, handleRatio, dragElement } from './common';
import { APP_ROUTE } from '../../../../../configs';

const handleWeatherWidget = (
  canvas: fabric.Canvas | null,
  obj?: fabric.Object,
) => {
  const widgetUrl = `${APP_ROUTE}/weather-widget`;
  const canvasEditorContainer = document.getElementById(
    'canvas-editor-container',
  );
  const canvasContainer = canvasEditorContainer?.getElementsByClassName(
    'canvas-container',
  ) as HTMLCollectionOf<HTMLDivElement>;
  if (canvasContainer?.[0]) {
    canvasContainer[0].style.overflow = 'hidden';
  }
  if (obj?.data?.className) {
    const existsElements = document.getElementsByClassName(
      obj?.data?.className,
    );
    const elementsArray = Array.prototype.slice.call(existsElements);

    elementsArray.forEach((element) => {
      element.remove();
    });
  }
  const iframe = document.createElement('iframe');
  const iframeDiv = document.createElement('div');
  canvasContainer?.[0].appendChild(iframe);
  const iframeDimensions = getDimensions(canvas, obj);
  const { realWidth, realHeight, realLeft, realTop } = iframeDimensions;

  const className = obj?.data?.className || uuidv4();

  iframeDiv.className = className;
  iframeDiv.id = 'overlay';
  iframeDiv.style.position = 'absolute';
  iframeDiv.style.backgroundColor = 'transparent';
  iframeDiv.style.zIndex = '2';
  handleRatio(iframeDimensions, iframeDiv);
  canvasContainer?.[0].appendChild(iframeDiv);

  iframe.id = 'canvas-iframe';
  iframe.className = className;
  iframe.allow = 'autoplay; encrypted-media';
  iframe.src = widgetUrl;
  iframeDiv.draggable = true;
  handleRatio(iframeDimensions, iframe);
  dragElement(iframeDiv, iframe, canvas);

  const iframeRect =
    obj ||
    new fabric.Rect({
      left: realLeft,
      top: realTop,
      width: realWidth,
      height: realHeight,
      fill: 'rgba(255, 255, 255, 0.5)',
      stroke: 'black',
      strokeWidth: 1,
      data: {
        type: 'iframe',
        src: widgetUrl,
        className,
      },
    });
  iframeRect.lockRotation = true;

  function updateIframePosition(e: any) {
    const object = e.target;
    const ratio = canvas?.getZoom() || 1;

    if (object === iframeRect) {
      const scaledWidth = `${object.width * object.scaleX * ratio}px`;
      const scaledHeight = `${object.height * object.scaleY * ratio}px`;
      const objectLeft = `${object.left * ratio}px`;
      const objectTop = `${object.top * ratio}px`;

      iframeDiv.style.left = objectLeft;
      iframeDiv.style.top = objectTop;
      iframeDiv.style.width = scaledWidth;
      iframeDiv.style.height = scaledHeight;

      iframe.style.left = objectLeft;
      iframe.style.top = objectTop;
      iframe.style.width = scaledWidth;
      iframe.style.height = scaledHeight;
    }
  }

  canvas?.on('object:scaling', updateIframePosition);

  if (!obj) canvas?.add(iframeRect);
  canvas?.renderAll();
};

export default handleWeatherWidget;

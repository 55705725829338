import React, { FC, useEffect, useState } from 'react';
// import Editor from '@monaco-editor/react';

import Span from './components/Span';
import Button from './components/Button';
import { useFabricEditor } from '../../lib/FabricJs';
import { useHandleHistory } from '../../lib/FabricJs/hooks/useHandleHistory';
import { TextField } from '@mui/material';

interface StyleChanges {
  [key: string]: {
    [prop: string]: string;
  };
}

let activeObjects: fabric.Object[] = [];

const CSSWidget: FC = () => {
  const { canvas } = useFabricEditor();
  const { handleHistory } = useHandleHistory();

  const [code, setCode] = useState('');
  const [originalCodeB4Submit, setOriginalCodeB4Submit] = useState('');
  const [newCode, setNewCode] = useState('');
  const [keyframes, setKeyframes] = useState('');
  const [animation, setAnimation] = useState('');

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    activeObjects = canvas?.getActiveObjects() || [];
    const formatedCode = JSToFormatedString(activeObjects);
    const dataObj = getDataFromActiveObj(activeObjects);
    if (code !== formatedCode) {
      setCode(formatedCode);
      setNewCode(formatedCode);
      setOriginalCodeB4Submit('');
      setAnimation(dataObj?.animation);
      setKeyframes(dataObj?.animationKeyframes);
    }
  }, [canvas?.getActiveObjects()]);

  const getDataFromActiveObj = (activeObjs: fabric.Object[]) => {
    if (!activeObjs.length || !activeObjs[0]) return null;
    const JS = activeObjs[0]?.toObject(['data']);
    if (!JS && !JS?.data) return null;

    return JS?.data;
  };

  const JSToFormatedString = (activeObjs: fabric.Object[]) => {
    if (!activeObjs.length || !activeObjs[0]) return ''; // Return or handle the case where JS is undefined or null

    const first5LettersOfId = (activeObjects[0] as any)?.id?.substring(0, 5);
    if (!first5LettersOfId) return ''; // Return or handle the case where JS is undefined or null

    const JS = activeObjs[0]?.toObject(['data']);
    if (!JS) return ''; // Return or handle the case where JS is undefined or null

    const objectEntries = Object.entries(JS)
      .map(([key, value]) => `\t\t${key}: ${value},`)
      .join('\n');

    if (!first5LettersOfId || !objectEntries) return '';
    return `const styles = {\n\t'${first5LettersOfId}': {\n${objectEntries}\n\t},\n};`;
  };

  const formatedStringToJS = (
    formattedString: string,
  ): fabric.Object | null => {
    // Extract the object properties part
    const propertiesMatch = formattedString.match(/\{\n([\s\S]*?)\n\t\}/);
    if (!propertiesMatch) return null; // Return null if no properties are found
    // eslint-disable-next-line prefer-destructuring
    const propertiesString = propertiesMatch[1];

    // Convert the properties string back into an object, removing the first key-value pair
    const lines = propertiesString.split('\n');
    lines.shift(); // Remove the first line
    const modifiedPropertiesString = lines.join('\n');

    // Convert the properties string back into an object
    const properties = modifiedPropertiesString
      .split('\n')
      .reduce((acc, line) => {
        const trimmedLine = line.trim();

        const [key, value] = trimmedLine.endsWith(',')
          ? trimmedLine.slice(0, -1).split(': ')
          : trimmedLine.split(': ');
        // Check if the key is 'backgroundColor' or 'textBackgroundColor' and if its value is falsy, then set it to an empty string
        // Check if the key is 'styles' or 'filters' and if its value is falsy, then set it to an empty array
        // Check if the key is 'text' and if its value is not string, then set it to a string value
        // Additionally, convert "true", "false", and "null" strings to their respective boolean and null values
        acc[key] =
          // eslint-disable-next-line no-nested-ternary
          key === 'backgroundColor' && !value
            ? ''
            : // eslint-disable-next-line no-nested-ternary
            key === 'textBackgroundColor' && !value
            ? ''
            : // eslint-disable-next-line no-nested-ternary
            key === 'styles' && !value
            ? []
            : // eslint-disable-next-line no-nested-ternary
            key === 'filters' && !value
            ? []
            : // eslint-disable-next-line no-nested-ternary
            key === 'text'
            ? value.toString() // Ensure the value is always treated as a string
            : // eslint-disable-next-line no-nested-ternary
            value === 'true'
            ? true
            : // eslint-disable-next-line no-nested-ternary
            value === 'false'
            ? false
            : // eslint-disable-next-line no-nested-ternary
            value === 'null'
            ? null
            : isNaN(Number(value))
            ? value
            : Number(value);
        return acc;
      }, {} as { [key: string]: any });
    // Construct the fabric.Object (assuming a basic object for demonstration; adjust as needed)
    return new fabric.Object({
      ...properties,
    });
  };

  const getChangedKeyValue = (
    original: string,
    updated: string,
  ): StyleChanges => {
    const originalObj = formatedStringToJS(original);
    const updatedObj = formatedStringToJS(updated);

    const changes: StyleChanges = {};
    if (updatedObj && originalObj) {
      Object.keys(updatedObj as { [key: string]: any }).forEach((key) => {
        if (
          JSON.stringify((updatedObj as { [key: string]: any })[key]) !==
          JSON.stringify((originalObj as { [key: string]: any })[key])
        ) {
          changes[key] = (updatedObj as { [key: string]: any })[key];
        }
      });
    }

    setOriginalCodeB4Submit(updated);

    return changes;
  };

  const onSubmitCode = () => {
    const changes = getChangedKeyValue(originalCodeB4Submit || code, newCode);
    const activeObject = canvas?.getActiveObject();
    if (activeObject && activeObjects.length) {
      activeObjects.forEach((object) => {
        object.set(changes);
        let currentAnimationData = object.data;
        const styleSheetId = `animation-style-${Date.now()}`;

        if (currentAnimationData) {
          currentAnimationData.animation = animation;
          currentAnimationData.animationKeyframes = keyframes;

          if (!currentAnimationData?.animationId) {
            currentAnimationData.animationId = styleSheetId;
          }
        } else {
          currentAnimationData = {
            animationId: styleSheetId,
            animationKeyframes: keyframes,
            animation: animation,
          };
        }
        object.set('data', currentAnimationData);
      });
      canvas?.renderAll();
      handleHistory();
    }
  };

  if (!code) return <Span>Select a element</Span>;

  return (
    <div onKeyDown={handleKeyDown} tabIndex={0}>
      {/* Commenting this out, because we don't need this for now */}
      {/* <Span>CSS Editor</Span>
      <Editor
        defaultLanguage="css"
        value={animation}
        height="70vh"
        options={{
          readOnly: false,
          automaticLayout: true,
          fontFamily:
            "'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace",
          fontSize: 14,
          renderWhitespace: 'none', // Adjust or remove this line as needed
          minimap: { enabled: true }, // Disable minimap for simplicity
          fontLigatures: true,
        }}
        onChange={(value) => setNewCode(value || '')}
      /> */}

      <Span>Add animation</Span>

      <TextField
        variant="standard"
        value={animation}
        sx={{ width: '100%', backgroundColor: 'white' }}
        onChange={(e) => {
          setAnimation(e.target.value);
        }}
        onKeyDown={(e) => {
          // Prevent the event from propagating to the canvas
          e.stopPropagation();
        }}
      />

      <Span>Add animation Keyframes</Span>

      <textarea
        value={keyframes}
        onChange={(e) => {
          setKeyframes(e.target.value);
        }}
        style={{ width: '100%' }}
        rows={10}
        onKeyDown={(e) => {
          // Prevent the event from propagating to the canvas
          e.stopPropagation();
        }}
      />

      <Button style={{ marginTop: 10 }} onClick={onSubmitCode}>
        Submit
      </Button>
    </div>
  );
};

export default CSSWidget;

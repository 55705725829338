import { FC, useEffect, useState } from 'react';
import { CircularProgress, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { useSwitchTransition } from 'transition-hook';
import { Offline, Online } from 'react-detect-offline';

import { metaTitle } from '../../util/tabTitle';
import PresentationSlide from './PresentationSlide';
import { createDatabase, getById } from '../../indexedDb';
import { DEFAULT_TIME_OUT, DEFAULT_TRANS_TIME } from './constants';
import { GetPresentationResponse } from '../../api-http/presentations';
import { GetDisplayPresentationResponse } from '../../api-http/displays';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #252525;
  overflow: hidden;
`;

const StyledOverlay = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: white;
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 50px;
`;

const RedDotWithOverlay = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
`;

let assetsList = null as any;

interface PresentationPreviewProps {
  presentation: GetDisplayPresentationResponse | GetPresentationResponse;
  margin?: number;
  isSyncing?: boolean;
}

function getVideo(src: string) {
  const video = document.createElement('video');
  video.setAttribute('src', src);
  return video;
}

const PresentationPreview: FC<PresentationPreviewProps> = ({
  presentation,
  margin,
  isSyncing,
}) => {
  metaTitle('Preview');

  const [slideIndex, setSlideIndex] = useState(0);
  const [kvsData, setKvsData] = useState<any>({});

  let intervalId: ReturnType<typeof setTimeout>;
  let animateIntervalId: ReturnType<typeof setTimeout>;

  const startInterval = (timeout: number) => {
    intervalId = setTimeout(() => {
      const next =
        slideIndex + 1 >= presentation?.slides?.length ? 0 : slideIndex + 1;
      setSlideIndex(next);
    }, timeout + 2000);
  };

  const clearTimers = () => {
    clearTimeout(intervalId);
    clearTimeout(animateIntervalId);
  };

  const startPresentation = () => {
    clearTimers();

    const _currentSlide = presentation?.slides?.[slideIndex];

    if (_currentSlide) {
      startInterval(_currentSlide.duration || DEFAULT_TIME_OUT);
    }
  };

  const init = async () => {
    if (!assetsList) assetsList = {};
    const indexedDb = await createDatabase();
    for (const asset of presentation?.assets || []) {
      if (asset?.assetSrc && asset?.assetId) {
        const { assetSrc, assetId } = asset;
        if (
          ['jpg', 'png', 'jpeg', 'gif'].includes(
            assetSrc.split('.').pop() || '',
          )
        ) {
          const img = new Image();
          img.src = assetSrc || '';
          assetsList[assetId] = img;
        } else if (['mp4', 'webm'].includes(assetSrc.split('.').pop() || '')) {
          const assetFromDevice = await getById(
            indexedDb,
            asset.assetId,
            'assets',
          );
          try {
            const assetBlob = assetFromDevice?.blob; // Get the Blob if it exists
            if (assetBlob) {
              const objectUrl = URL.createObjectURL(assetBlob);
              assetsList[asset.assetId] = getVideo(objectUrl);
            } else {
              // Handle the case where assetBlob is undefined (e.g., it doesn't exist)
              assetsList[asset.assetId] = getVideo(asset.assetSrc);
            }
          } catch (error) {
            assetsList[asset.assetId] = getVideo(asset.assetSrc);
          }
        }
      }
    }
    setSlideIndex(0);
    setKvsData(presentation?.kvs || {});
    startPresentation();
  };

  useEffect(() => {
    if (presentation) {
      init();
    }
  }, [presentation]);

  useEffect(() => {
    startPresentation();
  }, [slideIndex]);

  useEffect(() => {
    return () => {
      clearTimers();
    };
  }, []);

  const currentSlide = presentation?.slides[slideIndex];
  const transition = useSwitchTransition(
    currentSlide,
    DEFAULT_TRANS_TIME,
    'default',
  );
  const slide = currentSlide?.slideData as any;
  if (!slide || !assetsList) {
    return <LinearProgress />;
  }

  return (
    <Container>
      <Offline>
        <StyledOverlay>
          <RedDotWithOverlay />
        </StyledOverlay>
      </Offline>
      <Online>
        {isSyncing && (
          <StyledOverlay>
            <CircularProgress color="inherit" size={20} />
          </StyledOverlay>
        )}
      </Online>
      {transition((state, stage) => {
        return (
          <PresentationSlide
            currentSlide={state}
            stage={stage}
            kvsData={kvsData}
            assetsList={assetsList}
            margin={margin}
          />
        );
      })}
    </Container>
  );
};

export default PresentationPreview;

import { fabric } from 'fabric';
import {
  FILL,
  STROKE,
  TEXT,
} from '../../../components/Widgets/constants/shapes';
import { useEffect, useState } from 'react';
import { useEditorData } from '../providers/EditorProvider';
import { handleVideo, VideoModeEnum } from '../utils/handleVideo';

interface AddVideoOptions {
  url: string;
  width: number;
  height: number;
  fileType: string;
  assetId: string;
  thumbnailUrl?: string;
}

export interface FabricJSEditor {
  canvas: fabric.Canvas;
  deleteAll: () => void;
  deleteSelected: () => void;
  fillColor: string;
  strokeColor: string;
  setFillColor: (color: string) => void;
  setStrokeColor: (color: string) => void;
  zoomIn: () => void;
  zoomOut: () => void;
  addVideo: (options: AddVideoOptions) => void;
}

/**
 * Creates editor
 */
const buildEditor = (
  canvas: fabric.Canvas,
  fillColor: string,
  strokeColor: string,
  _setFillColor: (color: string) => void,
  _setStrokeColor: (color: string) => void,
  scaleStep: number,
): FabricJSEditor => {
  return {
    canvas,
    deleteAll: () => {
      canvas.getObjects().forEach((object) => canvas.remove(object));
      canvas.discardActiveObject();
      canvas.renderAll();
    },
    deleteSelected: () => {
      canvas.getActiveObjects().forEach((object) => canvas.remove(object));
      canvas.discardActiveObject();
      canvas.renderAll();
    },
    fillColor,
    strokeColor,
    setFillColor: (fill: string) => {
      _setFillColor(fill);
      canvas.getActiveObjects().forEach((object) => object.set({ fill }));
      canvas.renderAll();
    },
    setStrokeColor: (stroke: string) => {
      _setStrokeColor(stroke);
      canvas.getActiveObjects().forEach((object) => {
        if (object.type === TEXT.type) {
          // use stroke in text fill
          object.set({ fill: stroke });
          return;
        }
        object.set({ stroke });
      });
      canvas.renderAll();
    },
    zoomIn: () => {
      const zoom = canvas.getZoom();
      canvas.setZoom(zoom / scaleStep);
    },
    zoomOut: () => {
      const zoom = canvas.getZoom();
      canvas.setZoom(zoom * scaleStep);
    },
    addVideo: async ({ url, fileType, assetId, thumbnailUrl }) => {
      await handleVideo({
        canvas,
        url,
        fileType,
        mode: VideoModeEnum.Thumbnail,
        assetId,
        thumbnailUrl,
      });
    },
  };
};

interface FabricJSEditorState {
  editor?: FabricJSEditor;
}

interface FabricJSEditorHook extends FabricJSEditorState {
  selectedObjects?: fabric.Object[];
  canvas?: fabric.Canvas | null;
  onReady: (canvas: fabric.Canvas) => void;
}

interface FabricJSEditorHookProps {
  defaultFillColor?: string;
  defaultStrokeColor?: string;
  scaleStep?: number;
}

const useFabricEditor = (
  props: FabricJSEditorHookProps = {},
): FabricJSEditorHook => {
  const scaleStep = props.scaleStep || 0.5;
  const { defaultFillColor, defaultStrokeColor } = props;
  const { canvas, setCanvas, setActiveColor } = useEditorData();

  const [fillColor, setFillColor] = useState<string>(defaultFillColor || FILL);
  const [strokeColor, setStrokeColor] = useState<string>(
    defaultStrokeColor || STROKE,
  );
  const [selectedObjects, setSelectedObject] = useState<fabric.Object[]>([]);

  useEffect(() => {
    // TODO: don't we need to clear the event listeners?
    if (canvas) {
      fabric.util.requestAnimFrame(function render() {
        canvas?.renderAll();
        fabric.util.requestAnimFrame(render);
      });
      canvas.on('selection:cleared', () => {
        setSelectedObject([]);
        const backgroundColor = canvas?.backgroundColor;
        if (backgroundColor) setActiveColor(backgroundColor);
      });
      canvas.on('selection:created', (e: any) => {
        setSelectedObject(e.selected);
        const fill = e?.selected?.[0]?.fill;
        if (fill) setActiveColor(fill);
      });
      canvas.on('selection:updated', (e: any) => {
        setSelectedObject(e.selected);
      });
    }
  }, [canvas]);

  return {
    selectedObjects,
    onReady: (canvasReady: fabric.Canvas): void => {
      // https://github.com/fabricjs/fabric.js/wiki/FAQ#how-can-i-change-the-order-of-objects-on-the-canvas
      canvasReady.preserveObjectStacking = true;
      setCanvas(canvasReady);
    },
    canvas,
    editor: canvas
      ? buildEditor(
          canvas,
          fillColor,
          strokeColor,
          setFillColor,
          setStrokeColor,
          scaleStep,
        )
      : undefined,
  };
};

export { useFabricEditor };

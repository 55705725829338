import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../components/TableTheme';
import { useAuth } from '../../context/auth-context';
import { OrderEnum, SortEnum } from '../../types/pagination.types';
import { useSnack } from '../../util/useSnack';
import { useTable } from '../../util/useTable';
import {
  LinearProgress,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import {
  ActivityLogResponse,
  useGetActivityLogs,
} from '../../api-http/activityLogs';
import { Info } from 'react-feather';
import styled from 'styled-components';
import { DialogModal } from '../../components';
import { ViewLog } from './components/ViewLogInfo';

interface ComponentProps {
  filterProps: {
    startDate?: string;
    endDate?: string;
    logType?: string;
    authoredUser?: string;
  };
}

const ViewActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: blue;
  }
`;

const ActivityLogsTable: React.FC<ComponentProps> = ({
  filterProps: { logType, authoredUser, startDate, endDate },
}) => {
  const [activeLog, setActiveLog] = useState<ActivityLogResponse | null>(null);
  const { switchedOrgId } = useAuth();
  const { showErrSnack } = useSnack();
  const { sortOnClick, pagingParams, tableProps } = useTable();
  const { sortby, order } = pagingParams;
  const [viewLogModal, setViewLogModal] = useState<boolean>(false);

  const {
    data: logList,
    isLoading: loadLogs,
    error: errLogs,
    refetch: refetchLogs,
    isRefetching,
  } = useGetActivityLogs({
    ...pagingParams,
    startDate,
    endDate,
    logType,
    authoredUser,
  });

  useEffect(() => {
    if (errLogs) {
      showErrSnack(errLogs.msg);
    }
  }, [errLogs]);

  useEffect(() => {
    refetchLogs();
  }, [switchedOrgId]);

  const isLinerLoading = loadLogs || isRefetching;

  const handleClick = (activityLog: ActivityLogResponse) => {
    setActiveLog(activityLog);
    setViewLogModal(true);
  };

  return (
    <>
      {isLinerLoading && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell width={500} align="center">
                <StyledTableSortLabel
                  active={sortby === SortEnum.createdAt || true}
                  direction={
                    sortby === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Date/Time
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell width={500} align="center">
                Log Type
              </StyledTableCell>

              <StyledTableCell width={500} align="center">
                Action
              </StyledTableCell>

              <StyledTableCell width={500} align="center">
                Authored By
              </StyledTableCell>
              <StyledTableCell width={500} align="center">
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!logList?.data?.length && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {isLinerLoading ? <CircularProgress /> : 'No Logs Found '}
                </TableCell>
              </TableRow>
            )}
            {logList?.data.map((log) => {
              return (
                <TableRow
                  key={log._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">
                    {new Date(log.createdAt).toLocaleString('en-US', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                  </TableCell>

                  <TableCell align="center">{log.collectionName}</TableCell>
                  <TableCell align="center">{log.action}</TableCell>
                  <TableCell align="center">
                    {log.requestedUser?.name}
                  </TableCell>
                  <TableCell align="center">
                    <ViewActionContainer onClick={() => handleClick(log)}>
                      <Info size={15} />
                      More Info
                    </ViewActionContainer>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={logList?.pagination.total === 0}
          count={logList?.pagination.total || 0}
        />
      </TableContainer>

      <DialogModal
        title="Log Info"
        onClose={() => setViewLogModal(false)}
        open={viewLogModal}
      >
        <ViewLog log={activeLog} />
      </DialogModal>
    </>
  );
};

export default ActivityLogsTable;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import { Wrapper } from '../styles';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { DialogModal } from '../../../components';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { AssignFormForm } from './AssignFormForm';
import { AssignSchedulerForm } from './AssignSchedulerForm';
import { AddSchedulerModal } from '../../scheduler/components/AddSchedulerModal';
import { AddFormModal } from '../../form/AddFormModal';
import { GetDeviceProps, useGetDevice } from '../../../api-http/devices';
import { useParams } from 'react-router-dom';

interface AssignedFormProps {
  deviceData: GetDeviceProps;
  modalStatus: boolean;
  handleModalStatus: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}

interface AssignFormFormProps {
  onFinished: () => void;
  selectedDeviceId?: string;
  refetch: () => void;
}

const AssignedForm: FC<AssignedFormProps> = ({
  deviceData,
  handleModalStatus,
  modalStatus,
  refetch,
}) => {
  const {
    form,
    name: deviceName,
    _id: deviceId,
    deviceInfo,
  } = deviceData || {};
  const { touchEnable } = deviceInfo;
  const [openFormModal, setOpenFormModal] = useState(false);
  const [assignFormModalOpen, setAssignFormModalOpen] = useState(false);

  const handleAssignFormClick = () => {
    setAssignFormModalOpen(true);
  };

  const [openSchedulerModal, setOpenSchedulerModal] = useState(false);
  const [
    confirmAssignSchedulerDialogOpen,
    setConfirmAssignSchedulerDialogOpen,
  ] = useState(false);

  const handleAssignSchedulerClick = () => {
    if (data && data.form) {
      setConfirmAssignSchedulerDialogOpen(true);
    } else {
      handleModalStatus(true);
    }
  };

  const handleConfirmAssignScheduler = () => {
    setConfirmAssignSchedulerDialogOpen(false);
    handleModalStatus(true);
  };

  const handleAssignFormFormFinished: AssignFormFormProps['onFinished'] =
    () => {
      setAssignFormModalOpen(false);
      refetch();
    };

  const params = useParams<{ id: string }>();
  const { data } = useGetDevice(params?.id as string);

  return (
    <Wrapper>
      {form ? (
        <Grid display={'flex'} justifyContent={'space-between'}>
          <Grid
            display={'flex'}
            maxWidth={280}
            flexDirection={'column'}
            marginRight={10}
            item
          >
            <Typography fontWeight={'bold'} variant="h6" gutterBottom>
              Active Form
              <PlayCircleFilledIcon style={{ marginLeft: '10px' }} />
            </Typography>
            <Typography marginTop={'10px'} fontWeight={500} fontSize={12}>
              {form.name}
            </Typography>
          </Grid>
          <Grid display={'flex'} flexDirection={'column'}>
            <Button
              onClick={handleAssignFormClick}
              variant="contained"
              color="primary"
              style={{
                fontSize: '12px',
                marginTop: '10px',
                marginBottom: '10px',
                width: '180px',
              }}
            >
              Change Form
            </Button>
            <Button
              onClick={handleAssignSchedulerClick}
              variant="contained"
              color="primary"
              style={{
                fontSize: '12px',
                marginTop: '10px',
                marginBottom: '10px',
                width: '180px',
              }}
            >
              Assign Scheduler
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Stack>
              <Typography variant="body1">
                No forms assigned to this device
              </Typography>

              <Button
                fullWidth={false}
                variant="contained"
                onClick={handleAssignFormClick}
                color="primary"
                style={{
                  fontSize: '12px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  width: '180px',
                }}
                disabled={!touchEnable}
              >
                Assign Form
              </Button>

              <Link
                component="button"
                variant="body2"
                onClick={() => setOpenFormModal(true)}
                color="primary"
                style={{
                  fontSize: '12px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  width: '180px',
                }}
              >
                Create Form
              </Link>
            </Stack>
          </Grid>
        </Grid>
      )}

      <DialogModal
        title={`Assign a form for ${deviceName}`}
        onClose={() => handleModalStatus(false)}
        open={modalStatus}
      >
        <AssignFormForm
          selectedDeviceId={deviceId}
          onFinished={() => {
            refetch();
            handleModalStatus(false);
          }}
        />
      </DialogModal>

      <AddFormModal
        open={openFormModal}
        onClose={() => setOpenFormModal(false)}
      />

      <DialogModal
        title={`Assign a schedule for ${deviceName}`}
        onClose={() => handleModalStatus(false)}
        open={modalStatus}
      >
        <AssignSchedulerForm
          selectedDeviceId={deviceId}
          onFinished={() => {
            refetch();
            handleModalStatus(false);
          }}
        />
      </DialogModal>

      <DialogModal
        title={`Assign a form for ${deviceName}`}
        onClose={() => setAssignFormModalOpen(false)}
        open={assignFormModalOpen}
      >
        <AssignFormForm
          onFinished={handleAssignFormFormFinished}
          selectedDeviceId={deviceId}
        />
      </DialogModal>

      <AddSchedulerModal
        open={openSchedulerModal}
        onClose={() => setOpenSchedulerModal(false)}
      />
      <Dialog
        open={confirmAssignSchedulerDialogOpen}
        onClose={() => setConfirmAssignSchedulerDialogOpen(false)}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have assigned form. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmAssignSchedulerDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmAssignScheduler} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};

export default AssignedForm;

import { useEffect } from 'react';
import { fabric } from 'fabric';
import { useEditorData } from '../providers/EditorProvider';
import { IFRAME_WIDGETS } from '../../../config';

enum KeyboardKeys {
  Enter = 'Enter',
  Backspace = 'Backspace',
  Delete = 'Delete',
  C = 'c',
  V = 'v',
  D = 'd',
  X = 'x',
  Y = 'y',
  Z = 'z',
}

export const useHandleCopyPaste = (canvas?: fabric.Canvas | null) => {
  const { copiedObjects, setCopiedObjects } = useEditorData();
  const activeObjects = canvas?.getActiveObjects() || [];

  const handleCut = () => {
    handleCopy();
    activeObjects.forEach((object) => canvas?.remove(object));
  };

  const handleCopy = () => {
    const newObjects = [] as fabric.Object[];
    activeObjects.map((item) =>
      item.clone((object: fabric.Object) => {
        newObjects.push(object);
      }),
    );
    setCopiedObjects(newObjects);
  };

  const handleDuplicate = () => {
    const newObjects = [] as fabric.Object[];
    activeObjects.map((item) => {
      item.clone((clonedObject: any) => {
        clonedObject.set({
          left: clonedObject.left + 10,
          top: clonedObject.top + 10,
        });
        canvas?.add(clonedObject);
        newObjects.push(clonedObject);
      });
    });
    setCopiedObjects(newObjects);
  };

  const handlePaste = () => {
    const newObjects = [] as fabric.Object[];
    let isTextEditing = false;
    const activeObjects = canvas?.getActiveObjects() || [];
    for (const object of activeObjects) {
      // @ts-ignore:
      if (object.type === 'i-text' && object?.isEditing) {
        isTextEditing = true;
        continue;
      }
    }
    if (!isTextEditing) {
      for (const object of copiedObjects) {
        object.clone((clonedObject: any) => {
          clonedObject.set({
            left: clonedObject.left + 10,
            top: clonedObject.top + 10,
          });
          canvas?.add(clonedObject);
          newObjects.push(clonedObject);
        });
      }
      setCopiedObjects(newObjects);
      canvas?.renderAll();
    }
  };

  const handleDelete = () => {
    const activeObjects = canvas?.getActiveObjects() || [];
    activeObjects.forEach((object) => {
      // @ts-ignore:
      if (object.type === 'i-text' && object?.isEditing) {
        return;
      }

      const currentObj = object.toObject(['data']);

      if (Object.values(IFRAME_WIDGETS).includes(object?.data?.type)) {
        const deleteElements = document.getElementsByClassName(
          object?.data?.className,
        );
        const elementsArray = Array.prototype.slice.call(deleteElements);

        elementsArray.forEach((element) => {
          element.remove();
        });
      }

      if (currentObj.data?.type === 'video') {
        currentObj.data.deleteVideo();
      }

      canvas?.remove(object);
    });
    canvas?.renderAll();
  };

  return {
    handleCopy,
    handlePaste,
    handleCut,
    handleDelete,
    handleDuplicate,
    copiedObjects,
  };
};

export const useKeyboardEvents = () => {
  const { canvas, undo, redo } = useEditorData();
  const { handleCopy, handlePaste, handleCut, handleDelete, handleDuplicate } =
    useHandleCopyPaste(canvas);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (
      // @ts-ignore
      event?.target?.id !== 'font-size-picker' &&
      (event.key === KeyboardKeys.Backspace ||
        event.key === KeyboardKeys.Delete)
    ) {
      handleDelete();
    }

    if (
      event.key === KeyboardKeys.Enter &&
      canvas?.getActiveObject()?.type !== 'i-text'
    ) {
      event.preventDefault();
    }
    if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
        case KeyboardKeys.C:
          handleCopy();
          break;
        case KeyboardKeys.X:
          handleCut();
          break;
        case KeyboardKeys.V:
          handlePaste();
          break;
        case KeyboardKeys.D:
          handleDuplicate();
          break;
        case KeyboardKeys.Z:
          if (event.shiftKey) {
            redo();
          } else {
            undo();
          }
          break;
      }

      event.stopPropagation();
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [canvas, handleCopy, handlePaste]);
};

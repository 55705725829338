import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, FormControl, TextField, debounce } from '@mui/material';
import { useFormik } from 'formik';
import { useUpdateDevice } from '../../../api-http/devices';
import { notifyMsg, DEBOUNCE_TIME } from '../../../configs';
import { useSnack } from '../../../util/useSnack';
import { useGetFormList } from '../../../api-http/forms';
import { StyleAutocomplete } from '../../../components/AutoComplete';

interface FormOption {
  _id: string;
  name: string;
}

interface AssignFormFormProps {
  onFinished: () => void;
  selectedDeviceId?: string;
}

const Container = styled.div`
  display: flex;
  padding: 18px 5px 15px;
  flex-direction: column;
`;

export const AssignFormForm: FC<AssignFormFormProps> = ({
  onFinished,
  selectedDeviceId,
}) => {
  const { showSuccessSnack, showErrSnack } = useSnack();

  const [searchText, setSearchText] = useState('');

  const [selectedForm, setSelectedForm] = useState();

  const { data: formList = [] } = useGetFormList(searchText);

  const { mutate, error, data, isLoading, isSuccess, isError } =
    useUpdateDevice();

  useEffect(() => {
    if (data && isSuccess) {
      showSuccessSnack(notifyMsg.ASSIGNED_FORM);
      onFinished();
    } else if (isError) {
      showErrSnack(error.msg);
      onFinished();
    }
  }, [
    data,
    isSuccess,
    isError,
    showErrSnack,
    showSuccessSnack,
    error,
    onFinished,
  ]);

  const formik = useFormik({
    initialValues: {
      id: selectedDeviceId,
      form: '',
    },
    onSubmit: () => {
      mutate({
        id: selectedDeviceId || '',
        form: selectedForm,
      });
    },
  });

  const {
    values: {},
    handleSubmit,
  } = formik;
  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <FormControl
          sx={{ width: '100%', minWidth: 200 }}
          style={{ marginTop: '8px', marginBottom: '5px' }}
        >
          <StyleAutocomplete
            options={formList || []}
            getOptionLabel={(option: FormOption) => option.name}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={debounce(
                  (event) => setSearchText(event.target.value),
                  DEBOUNCE_TIME,
                )}
                label="Search Forms"
              />
            )}
            onChange={(_event, option) => setSelectedForm(option?._id as any)}
          />
        </FormControl>
        <Button
          color="primary"
          disabled={!selectedForm || isLoading}
          variant="contained"
          fullWidth
          type="submit"
          style={{ marginTop: '10px' }}
        >
          Assign
        </Button>
      </form>
    </Container>
  );
};

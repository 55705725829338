import { fabric } from 'fabric';
import { useHandleHistory } from '../../lib/FabricJs/hooks/useHandleHistory';
import { SelectEvent, SelectMode } from '../../types/types';

export const useEventControls = () => {
  const { handleHistory } = useHandleHistory();

  const handleSelected = (canvas: fabric.Canvas) => {
    const activeObjects = canvas?.getActiveObjects();
    let detail;
    if (activeObjects.length === 1) {
      const [activeObject] = activeObjects;
      if (activeObject.type === 'group') {
        detail = SelectMode.GROUP;
      } else {
        detail = SelectMode.ONE;
      }
    } else if (activeObjects.length > 1) {
      detail = SelectMode.MULTI;
    } else {
      detail = '';
    }
    const event = new CustomEvent(SelectEvent.ONE, {
      detail,
    });
    document.dispatchEvent(event);
  };

  const eventControls = (
    canvas: fabric.Canvas,
    setPoints: (points: common.PointsProps | null | null) => void,
  ) => {
    canvas.on('object:added', function (e: fabric.IEvent<MouseEvent>) {
      // @ts-ignore Check object is Automatically added.
      if (e.target && !e.target.auto) {
        canvas.setActiveObject(e.target);
        handleHistory(canvas);
      }
    });
    canvas.on('object:modified', () => handleHistory(canvas));
    canvas.on('object:removed', (e: fabric.IEvent<MouseEvent>) => {
      const activeObjects = canvas.getActiveObjects();
      if (!activeObjects.length) {
        handleHistory(canvas);
        // @ts-ignore;
      } else if (activeObjects[activeObjects.length - 1]?.id === e.target?.id) {
        canvas.discardActiveObject();
        handleHistory(canvas);
      }
    });
    canvas.on('mouse:down', (event) => {
      if (event.button === 1) {
        setPoints(null);
      }
    });
    canvas.on('selection:created', () => {
      handleSelected(canvas);
    });
    canvas.on('selection:updated', () => {
      handleSelected(canvas);
    });
    canvas.on('selection:cleared', () => {
      handleSelected(canvas);
    });
  };
  return { eventControls };
};

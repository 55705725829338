import { FC } from 'react';
import { Tooltip } from '@mui/material';
import { Trash2 } from 'react-feather';
import ButtonComponent from './common/ButtonComponent';
import { useFabricEditor } from '../lib/FabricJs';
import { useHandleCopyPaste } from '../lib/FabricJs/hooks/useKeyboardEvents';

const Delete: FC = () => {
  const { canvas } = useFabricEditor();
  const { handleDelete } = useHandleCopyPaste(canvas);

  return (
    <Tooltip title="Delete selected items.">
      <ButtonComponent>
        <Trash2 onClick={handleDelete} />
      </ButtonComponent>
    </Tooltip>
  );
};

export default Delete;

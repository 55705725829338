import { Dispatch, FC, SetStateAction, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { AlertCircle } from 'react-feather';

import { FormResponse } from '../../../types/form.types';
import { SchedulerResponse } from '../../../types/scheduler.types';

import { Wrapper } from '../styles';
import { useSnack } from '../../../util/useSnack';
import { DialogModal } from '../../../components';
import { AssignFormForm } from './AssignFormForm';
import { AddFormModal } from '../../form/AddFormModal';
import { APP_ROUTE, notifyMsg } from '../../../configs';
import { AssignSchedulerForm } from './AssignSchedulerForm';
import { useSetDeviceCacheInterval } from '../../../api-http/devices';
import { AddSchedulerModal } from '../../scheduler/components/AddSchedulerModal';

interface AssignedSchedulerProps {
  name?: string;
  id?: string;
  info?: boolean;
  handleModalStatus: Dispatch<SetStateAction<boolean>>;
  modalStatus: boolean;
  form?: FormResponse;
  scheduler?: SchedulerResponse;
  cacheInterval?: number;
  deviceId?: string;
  refetch: () => void;
}

interface AssignSchedulerFormProps {
  onFinished: () => void;
  selectedDeviceId?: string;
  refetch: () => void;
}

const AssignedScheduler: FC<AssignedSchedulerProps> = ({
  name,
  id,
  info,
  handleModalStatus,
  modalStatus,
  scheduler,
  cacheInterval,
  deviceId,
  refetch,
}) => {
  const { showSuccessSnack, showErrSnack } = useSnack();

  const [openSchedulerModal, setOpenSchedulerModal] = useState(false);
  const [assignSchedulerModalOpen, setAssignSchedulerModalOpen] =
    useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [confirmAssignFormDialogOpen, setConfirmAssignFormDialogOpen] =
    useState(false);

  const { mutate } = useSetDeviceCacheInterval({
    onSuccess() {
      showSuccessSnack(notifyMsg.PRE_CACHE_UPDATE_SUCCESS);
      refetch();
    },
    onError: (err) => showErrSnack(err || notifyMsg.PRE_CACHE__UPDATE_FAILED),
  });

  const handleAssignFormClick = () => {
    if (scheduler) {
      setConfirmAssignFormDialogOpen(true);
    } else {
      setOpenFormModal(true);
    }
  };

  const handleConfirmAssignForm = () => {
    setConfirmAssignFormDialogOpen(false);
    handleModalStatus(true);
  };

  const handleAssignSchedulerFormFinished: AssignSchedulerFormProps['onFinished'] =
    () => {
      setAssignSchedulerModalOpen(false);
      refetch();
    };

  const onCacheTimeChange = (duration: number) => {
    mutate({ deviceID: deviceId as string, duration });
  };

  return (
    <Wrapper>
      {scheduler ? (
        <Grid display={'flex'}>
          <Grid
            display={'flex'}
            maxWidth={280}
            flexDirection={'column'}
            marginRight={10}
            item
          >
            <Typography fontWeight={'bold'} variant="h6" gutterBottom>
              Now Playing
              <PlayCircleFilledIcon style={{ marginLeft: '10px' }} />
            </Typography>
            <iframe
              className="iframe-class"
              style={{
                border: '1px solid white',
                borderRadius: '5px',
                marginBottom: '20px',
                marginTop: '10px',
              }}
              src={`${APP_ROUTE}/show/${deviceId}`}
            />
            <Typography fontSize={11} color={'GrayText'} display={'flex'}>
              <AlertCircle size={32} style={{ marginRight: '8px' }} />
              Please note that the presentation playing on this page may not be
              in sync with the assigned device.
            </Typography>
          </Grid>
          <Grid display={'flex'} justifyContent={'space-between'} container>
            <Grid width={'500px'} display={'flex'} flexDirection={'column'}>
              <Grid marginTop={5} display={'flex'} flexDirection={'column'}>
                <Typography fontWeight={500} fontSize={16}>
                  Assigned Scheduler
                  <Typography marginTop={'10px'} fontWeight={500} fontSize={12}>
                    {scheduler.name}
                  </Typography>
                </Typography>

                <Grid marginTop={3} display={'flex'}>
                  <Grid display={'flex'} flexDirection={'column'} />
                  <Typography fontWeight={500} fontSize={16}>
                    Description
                    <Typography
                      marginTop={'10px'}
                      fontWeight={500}
                      fontSize={12}
                    >
                      {scheduler.description}
                    </Typography>
                  </Typography>
                  <Grid
                    marginLeft={10}
                    display={'flex'}
                    flexDirection={'column'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
            >
              <Grid item display={'flex'} flexDirection={'column'}>
                <Button
                  onClick={() => setAssignSchedulerModalOpen(true)}
                  variant="contained"
                  color="primary"
                  style={{
                    fontSize: '12px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '180px',
                  }}
                >
                  Change Scheduler
                </Button>
                <Button
                  onClick={handleAssignFormClick}
                  variant="contained"
                  color="primary"
                  style={{
                    fontSize: '12px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '180px',
                  }}
                  disabled={!info}
                >
                  Assign Form
                </Button>
              </Grid>

              <Grid item>
                <InputLabel
                  variant="standard"
                  id="pre-cache-duration-select-label"
                >
                  Pre Cache Duration
                </InputLabel>
                <Select
                  labelId="pre-cache-duration-select-label"
                  sx={{ width: 180, marginTop: 0.5 }}
                  value={cacheInterval}
                  onChange={(e) => onCacheTimeChange(e.target.value as number)}
                >
                  <MenuItem value={5}>5 mins</MenuItem>
                  <MenuItem value={10}>10 mins</MenuItem>
                  <MenuItem value={15}>15 mins</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Stack>
              <Typography variant="body1">
                No scheduled presentations assigned to this device
              </Typography>
              <Button
                fullWidth={false}
                variant="contained"
                color="primary"
                style={{
                  fontSize: '12px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  width: '180px',
                }}
                onClick={() => setAssignSchedulerModalOpen(true)}
              >
                Assign Schedule
              </Button>
              <Link
                component="button"
                variant="body2"
                onClick={() => setOpenSchedulerModal(true)}
                color="primary"
                style={{
                  fontSize: '12px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  width: '180px',
                }}
              >
                Create Scheduler
              </Link>
            </Stack>
          </Grid>
        </Grid>
      )}

      <DialogModal
        title={`Assign a scheduler for ${name}`}
        onClose={() => setAssignSchedulerModalOpen(false)}
        open={assignSchedulerModalOpen}
      >
        <AssignSchedulerForm
          onFinished={handleAssignSchedulerFormFinished}
          selectedDeviceId={id}
        />
      </DialogModal>
      <AddSchedulerModal
        open={openSchedulerModal}
        onClose={() => setOpenSchedulerModal(false)}
      />

      <DialogModal
        title={`Assign a Form for ${name}`}
        onClose={() => handleModalStatus(false)}
        open={modalStatus}
      >
        <AssignFormForm
          selectedDeviceId={id}
          onFinished={() => handleModalStatus(false)}
        />
      </DialogModal>

      <AddFormModal
        open={openFormModal}
        onClose={() => setOpenFormModal(false)}
      />

      <Dialog
        open={confirmAssignFormDialogOpen}
        onClose={() => setConfirmAssignFormDialogOpen(false)}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have assigned Scheduler. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmAssignFormDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleConfirmAssignForm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};

export default AssignedScheduler;

import { ChangeEvent, FC, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumb';
import RefreshButton from '../../components/RefreshButton';
import { StyleTextfield } from '../../components/TextField';
import { FilterSelectWrapper } from '../../components/canvas-editor-v2/components/common/FilterSelectWrapper';
import { DEBOUNCE_TIME } from '../../configs';
import { UserRole } from '../../types/user.types';
import { metaTitle } from '../../util/tabTitle';
import { useIsUserRole } from '../../util/useIsUserRole';
import { AddFormModal } from './AddFormModal';
import FormsTable from './FormsTable';
import { Button, debounce } from '@mui/material';
import { useHandleSearch } from '../../util/useHandleSearch';
import { useLocationSearch } from '../../util/useLocationSearch';

const FormsPage: FC = () => {
  metaTitle('Forms');

  const searchVal = useLocationSearch();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(searchVal || '');
  const [searchText, setSearchText] = useState(searchVal || '');
  const [refreshTable, setRefreshTable] = useState(false);
  useHandleSearch(searchText);

  const isViewOnly = useIsUserRole([UserRole.user, UserRole.dataEditor]);

  const handleSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSearch(event.target.value);
  };

  return (
    <div>
      <Breadcrumbs
        items={[
          {
            label: 'Forms',
            to: '/forms',
            description:
              'This page serves as a platform for creating and managing feedback forms. The main interface provides an overview of existing forms, allowing users to edit form content, rename form titles, and delete unused forms. Once a form has been utilized, clicking on its name enables users to access a summary of all the responses received for that specific form. This feature allows users to review and analyze the collected feedback effectively.',
          },
        ]}
      />

      <FilterSelectWrapper>
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={isViewOnly}
            onClick={() => setOpen(true)}
          >
            Add new
          </Button>

          <RefreshButton onClick={() => setRefreshTable(!refreshTable)} />
        </div>
        <div>
          <StyleTextfield
            sx={{
              '& > :not(style)': {
                m: 0,
                width: '30ch',
                height: '50px',
                paddingLeft: '10px',
                paddingBottom: '2px',
                fontSize: '14px',
              },
              marginLeft: '15px',
            }}
            label={'Search Forms'}
            variant={'outlined'}
            onChange={(event) => {
              setSearchText(event.target.value);
              debounce(() => handleSearch(event), DEBOUNCE_TIME)();
            }}
            value={searchText}
          />
        </div>
      </FilterSelectWrapper>

      <FormsTable refresh={refreshTable} search={search} />
      <AddFormModal onClose={() => setOpen(false)} open={open} />
    </div>
  );
};

export default FormsPage;

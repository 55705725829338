import { FC, useEffect, useState } from 'react';
import { Box, LinearProgress, Stack } from '@mui/material';

import { AlertBar } from '../../components/AlertBar';
import DraftPublishSwitcher from './components/DraftPublishSwitcher';
import Breadcrumbs from '../../components/Breadcrumb';
import { Mode } from './types';
import usePresentationApi from './hooks/usePresentationApi';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import MetaPreviewSection from './components/MetaPreviewSection';
import ActionButtonSection from './components/ActionButtonSection';
import GuideText from './components/GuideText';
import { PreviewList } from '../presentations/PreviewList';
import { TRANSITIONS } from '../../components/preview/constants';
import { useDraftEdit } from './hooks/useDraftEdit';
import { tranformDetailToSlide } from './utils/tranformDetailToSlide';

const LoadingContainer = styled.div`
  height: 1px;
`;

const MainContainer = styled(Box)`
  width: 100%;
`;

const PresentationEditorPage: FC = () => {
  const params = useParams<{ id: string }>();
  const draftId = params.id || '';
  const { state } = useLocation();

  const [mode, setMode] = useState(Mode.Draft);
  const isPublishedMode = mode === Mode.Published;
  const {
    isLoading,
    draftData,
    errMessage,
    discardDraft,
    publishPresentation,
    refetchDraft,
    updatePresentation,
  } = usePresentationApi(draftId || '');

  const editOptions = useDraftEdit({
    updatePresentation,
    draftData,
  });

  const currenSlides = isPublishedMode
    ? tranformDetailToSlide(draftData?.detailedPresentation)
    : draftData?.slides || []; // either draft or published slides

  useEffect(() => {
    if (draftData) {
      document.title = `Presentation - ${draftData?.name} ${
        isPublishedMode ? '' : '(Draft)'
      }`;
    }
  }, [draftData]);

  const updatedKvs =
    (isPublishedMode ? draftData?.kvs : draftData?.detailedPresentation?.kvs) ||
    {};

  const presentationPageUrl = state?.search
    ? `/presentations${state?.search}`
    : '/presentations';

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: 'Presentations',
            to: presentationPageUrl,
          },
          {
            label: draftData?.name || '-',
            to: `/presentations-editor/${draftId}`,
          },
        ]}
      />
      <LoadingContainer>{isLoading && <LinearProgress />}</LoadingContainer>

      <AlertBar severity="error" msg={errMessage} />

      <DraftPublishSwitcher
        onChange={(v) => setMode(v)}
        onDiscard={() => discardDraft()}
        onRefetch={() => refetchDraft()}
        onPublish={async () => {
          if (draftId) {
            await publishPresentation({ id: draftId });
            refetchDraft();
          }
        }}
        haveUnpublishedChanges={Boolean(draftData?.haveUnpublishedChanges)}
        isPublishedMode={isPublishedMode}
        hasPublishedVersion={Boolean(draftData?.detailedPresentation?._id)}
      />
      <MainContainer>
        {draftData && (
          <MetaPreviewSection
            isPublishedMode={isPublishedMode}
            data={draftData}
            hasSlides={Boolean(currenSlides.length)}
            draftId={draftId}
            publishedId={draftData?.detailedPresentation?._id}
          />
        )}
        <ActionButtonSection
          isPublishedMode={isPublishedMode}
          publishedId={draftData?.detailedPresentation?._id || ''}
          onRefetch={() => refetchDraft()}
          handleAddSlide={editOptions.handleAddSlide}
        />
        <Stack gap={2}>
          {!isLoading && (
            <GuideText
              isPublishedMode={isPublishedMode}
              hasSlides={Boolean(currenSlides.length)}
            />
          )}
          <PreviewList
            {...editOptions}
            slides={currenSlides}
            initialTransitions={TRANSITIONS}
            setSlides={(data) => {
              // TODO: remove console log after fixing this
              // eslint-disable-next-line no-console
              console.log(data);

              // TODO: Fix this smooth the onSlide ordering, get setCachedDraft from usePresentationApi
              // setCachedDraft(data);
            }}
            refetchPresentation={() => refetchDraft()}
            isPublished={isPublishedMode}
            updatedKvs={updatedKvs}
          />
        </Stack>
      </MainContainer>
    </>
  );
};

export default PresentationEditorPage;

import React, { ChangeEvent, FC } from 'react';

import { StyledStack, StyledTextarea, Title } from './common';
import { useEditorData } from '../../EditorProvider';
import { changePageData } from '../../utils/surveyDataUtils';
import { getPageData } from '../../utils/surveyDataUtils';

type TextChangeEvent = ChangeEvent<HTMLTextAreaElement>;

const Page: FC = () => {
  const { history, setHistory, selectedItem: focus } = useEditorData();
  const { present: surveyData } = history;

  const page = getPageData(surveyData, focus?.pageId || '');

  const onChange = (e: TextChangeEvent) => {
    const { id, value } = e.target;

    const params = { surveyData, data: { [id]: value }, focus, setHistory };
    changePageData(params);
  };

  return (
    <StyledStack spacing={2}>
      <Title>Page Settings</Title>
      <StyledTextarea
        aria-label="textarea"
        placeholder={page?.name}
        id="title"
        name="title"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        value={page?.title}
        onChange={(e: TextChangeEvent) => onChange(e)}
      />
      <StyledTextarea
        aria-label="textarea"
        placeholder="Description"
        id="description"
        name="description"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        value={page?.description}
        onChange={(e: TextChangeEvent) => onChange(e)}
      />
    </StyledStack>
  );
};

export default Page;

import { FC } from 'react';
import { Button } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import MetaDataPopover, { MetaDataPopoverProps } from './MetaDataPopover';
import { useNavigate } from 'react-router-dom';

interface MetaPreviewSectionProps extends MetaDataPopoverProps {
  isPublishedMode: boolean;
  hasSlides: boolean;
  draftId: string;
  publishedId?: string;
}

const MetaPreviewSection: FC<MetaPreviewSectionProps> = ({
  isPublishedMode,
  hasSlides,
  draftId,
  publishedId,
  data,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        disabled={!hasSlides}
        onClick={() =>
          isPublishedMode
            ? navigate(`/presentation-preview/${publishedId}`)
            : navigate(`/presentation-preview/${draftId}?isDraft=true`)
        }
        variant="text"
        endIcon={<PlayArrow />}
      >
        Preview {isPublishedMode ? '' : 'Draft'} Presentation
      </Button>
      <MetaDataPopover data={data} />
    </>
  );
};

export default MetaPreviewSection;

import * as React from 'react';
import { FC, useEffect, useState } from 'react';

import { useConfirm } from 'material-ui-confirm';
import { ChevronDown } from 'react-feather';

import { Button, LinearProgress, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useGetPaginateUsers, useUserDelete } from '../../../api-http/users';
import { DialogModal } from '../../../components';
import { AlertBar } from '../../../components/AlertBar';

import { BaseUserWithOrg } from '../../../types/user.types';
import { displayDate } from '../../../util/dateTime';
import { useUserRoleName } from '../../../util/useUserTypes';
import { useSnackbar } from 'notistack';
import { ResetPasswordForm } from './ResetPasswordForm';
import { UserActionMenu } from './UserActionMenu';
import { UserUpdateForm } from './UserUpdateForm';
import { notifyMsg } from '../../../configs';
import { ERROR, SUCCESS } from '../../../util/constant';
import {
  StyledTableCell,
  StyledTableSortLabel,
} from '../../../components/TableTheme';
import { useTable } from '../../../util/useTable';
import { OrderEnum, SortEnum } from '../../../types/pagination.types';

interface UserTableProp {
  selectedOrgId: string;
  search: string;
  refresh: boolean;
}

export const UserTable: FC<UserTableProp> = ({
  selectedOrgId,
  search,
  refresh,
}) => {
  const confirm = useConfirm();

  const { sortOnClick, pagingParams, tableProps } = useTable();
  const { sortby, order } = pagingParams;
  const {
    data: list,
    isLoading,
    error,
    refetch,
    isRefetching,
  } = useGetPaginateUsers({
    ...pagingParams,
    search,
    organization: selectedOrgId,
  });

  const {
    isLoading: isLoadingUserDelete,
    error: errorUserDelete,
    mutate,
    isSuccess,
    isError,
  } = useUserDelete();

  const [activeUser, setActiveUser] = useState<BaseUserWithOrg | undefined>();
  const [renameUserModal, setRenameUserModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userResetPasswordModal, setUserResetPasswordModal] = useState(false);

  useEffect(() => {
    refetch();
    if (isSuccess) {
      enqueueSnackbar(notifyMsg.USER_DELETE_SUCCESS, {
        variant: SUCCESS,
      });
    }
    if (isError) {
      enqueueSnackbar(notifyMsg.DELETE_ATTEMPT_FAILED, {
        variant: ERROR,
      });
    }
  }, [isSuccess, isError, search, refresh]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: BaseUserWithOrg,
  ) => {
    setActiveUser(user);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await confirm({
        description: 'Are you sure you want to delete?',
        confirmationText: 'Yes',
      });

      mutate({ id: activeUser?._id || '' });
    } catch (error) {}
  };

  return (
    <>
      {(isLoading || isLoadingUserDelete || isRefetching) && <LinearProgress />}
      <AlertBar severity="error" msg={error?.msg || errorUserDelete?.msg} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <StyledTableSortLabel
                  active={sortby === SortEnum.name || true}
                  direction={sortby === SortEnum.name ? order : OrderEnum.asc}
                  onClick={() => sortOnClick(SortEnum.name)}
                >
                  User Name
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell align="right">Email</StyledTableCell>
              <StyledTableCell align="right">
                <StyledTableSortLabel
                  active={sortby === SortEnum.updatedAt || true}
                  direction={
                    sortby === SortEnum.updatedAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.updatedAt)}
                >
                  Updated At
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right">
                <StyledTableSortLabel
                  active={sortby === SortEnum.createdAt || true}
                  direction={
                    sortby === SortEnum.createdAt ? order : OrderEnum.asc
                  }
                  onClick={() => sortOnClick(SortEnum.createdAt)}
                >
                  Created At
                </StyledTableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.data.map((user) => {
              return (
                <TableRow
                  key={user._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {useUserRoleName(user.role)}
                  </TableCell>
                  <TableCell align="right">{user.email}</TableCell>
                  <TableCell align="right">
                    {displayDate(user.updatedAt)}
                  </TableCell>
                  <TableCell align="right">
                    {displayDate(user.createdAt)}
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={(event) => handleClick(event, user)}>
                      <ChevronDown size={16} />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          {...tableProps}
          component="div"
          hidden={list?.pagination.total === 0}
          count={list?.pagination.total || 0}
        />
      </TableContainer>

      <UserActionMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClickRename={() => {
          setRenameUserModal(true);
          handleClose();
        }}
        onClickDelete={() => {
          handleDelete();
          handleClose();
        }}
        onClickResetPassword={() => {
          setUserResetPasswordModal(true);
          handleClose();
        }}
      />

      <DialogModal
        onClose={() => setRenameUserModal(false)}
        open={renameUserModal}
        title={'Rename User'}
      >
        <UserUpdateForm
          profileData={activeUser && activeUser}
          onFinished={() => {
            setRenameUserModal(false);
            refetch();
          }}
        />
      </DialogModal>
      <DialogModal
        onClose={() => setUserResetPasswordModal(false)}
        open={userResetPasswordModal}
        title={'Reset password'}
      >
        <ResetPasswordForm
          onFinished={() => setUserResetPasswordModal(false)}
          userData={activeUser}
        />
      </DialogModal>
    </>
  );
};

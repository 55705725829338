import React, { FC, PropsWithChildren } from 'react';
import { create } from 'jss';
import { ThemeProvider } from 'styled-components/macro';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { ThemeProvider as MuiThemeProvider } from '@mui/styles';
import jssPreset from '@mui/styles/jssPreset';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import StylesProvider from '@mui/styles/StylesProvider';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import createTheme from './theme/index';
import NavigationRoutes from './NavigationRoutes';
import { AuthProvider } from './context/auth-context';
import { ConfirmProvider } from 'material-ui-confirm';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      onError: (error: any) => {
        if (error?.status === 401) {
          window.location.href = '/sign-in';
        }
      },
    },
  },
});
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point') || undefined,
});

export const Providers: FC<PropsWithChildren> = ({ children }) => (
  <div className="jss-insertion-point">
    <ConfirmProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={createTheme()}>
                  <ThemeProvider theme={createTheme()}>
                    {children}
                  </ThemeProvider>
                </MuiThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </StylesProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ConfirmProvider>
  </div>
);

const App: FC = () => {
  return (
    <Providers>
      <NavigationRoutes />
    </Providers>
  );
};

export default App;

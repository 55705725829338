import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import * as yup from 'yup';
import styled from 'styled-components';

import { Nullable } from '../../../../types';
import { AlertBar } from '../../../../components/AlertBar';
import { authMsg, formMsg } from '../../../../configs';
import { ApiServiceErr } from '../../../../api-http/apiService';
import { BaseOrganization } from '../../../../types/organization.types';

interface SubmitData extends Pick<BaseOrganization, 'name' | 'email'> {
  id?: BaseOrganization['_id'];
}

interface OrganizationFormProps {
  onSubmit: (data: SubmitData) => void;
  isLoading: boolean;
  organizationData?: Nullable<BaseOrganization>;
  error?: Nullable<ApiServiceErr>;
}

const validationSchema = yup.object({
  name: yup.string().required(formMsg.NAME_REQUIRED),
  email: yup
    .string()
    .email(authMsg.EMAIL_INVALID)
    .required(authMsg.EMAIL_REQUIRED),
});

const Container = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const OrganizationForm: FC<OrganizationFormProps> = ({
  organizationData,
  isLoading,
  error,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: {
      ...(organizationData ? { id: organizationData._id || '' } : {}),
      name: organizationData?.name || '',
      email: organizationData?.email || '',
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Container>
      <AlertBar severity="error" mb={4} msg={error?.msg} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          style={{ marginBottom: 10 }}
        />

        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <Box mt={'10px'} />
        <Button
          color="primary"
          variant="contained"
          disabled={!formik.dirty || isLoading}
          fullWidth
          type="submit"
        >
          {organizationData ? 'Update' : 'Create'}
        </Button>
      </form>
    </Container>
  );
};

import { useEffect, useState } from 'react';
import { useFabricEditor, useEditorData } from '../../lib/FabricJs';
import FontFaceObserver from 'fontfaceobserver';
import {
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  Tooltip,
} from '@mui/material';
import { fontFamilies } from '../../config';
import styled from 'styled-components';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import Divider from '@mui/material/Divider';

const FONT_SIZES = [
  20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 140, 160, 180, 200, 220, 240,
  260, 280, 360,
];

enum FontStyle {
  Italic = 'italic',
  Bold = 'bold',
  Underline = 'underline',
}

const Button = styled.button<{ active?: boolean }>`
  margin: 4px;
  cursor: pointer;
  text-align: center;
  width: 36px;
  background-color: ${({ active }) => (active ? '#e2e4e7' : '#fff')};
  &:hover {
    background-color: #eee;
  }
`;

const useFontStyle = () => {
  const { editor, canvas } = useFabricEditor();

  const [font, setFont] = useState('');
  const [italic, setItalic] = useState(true);
  const [bold, setBold] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [isTextSelected, setIsTextSelected] = useState(false);
  const [fontSize, seFontSize] = useState('160');

  useEffect(() => {
    const activeObjectList = canvas?.getActiveObjects() || [];

    const listOfFontsInSelected: string[] = [];

    activeObjectList.forEach((object: any) => {
      if (object.type === 'text' || object.type === 'i-text') {
        if (!listOfFontsInSelected.includes(object.fontFamily)) {
          listOfFontsInSelected.push(object.fontFamily);
        }
        if (listOfFontsInSelected.length === 1) {
          setBold(object.fontWeight === 'bold');
          setItalic(object.fontStyle === 'italic');
          setUnderline(object.underline);
          seFontSize(String(object.fontSize));
        }
      }
    });
    if (listOfFontsInSelected.length === 1) {
      setFont(listOfFontsInSelected[0]);
    } else {
      setFont('');
      setBold(false);
      setItalic(false);
      setUnderline(false);
      seFontSize('');
    }
    setIsTextSelected(listOfFontsInSelected.length > 0);
  }, [editor]);

  return {
    font,
    setFont,
    italic,
    bold,
    fontSize,
    setItalic,
    setBold,
    underline,
    setUnderline,
    isTextSelected,
    seFontSize,
  };
};

let activeObjects: fabric.Object[] = [];

export const FontPicker = () => {
  const { canvas } = useFabricEditor();
  const { setHistory } = useEditorData();

  useEffect(() => {
    activeObjects = canvas?.getActiveObjects() || [];
  }, [canvas?.getActiveObjects()]);

  const {
    font,
    setFont,
    italic,
    bold,
    underline,
    fontSize,
    setItalic,
    setBold,
    setUnderline,
    isTextSelected,
    seFontSize,
  } = useFontStyle();

  const onFontChange = (font: string) => {
    const fontObserver = new FontFaceObserver(font);
    setFont(font);
    fontObserver.load().then(() => {
      activeObjects?.map((object: any) => {
        if (object.type === 'text' || object.type === 'i-text') {
          object.set({ fontFamily: font });
        }
      });
      canvas?.renderAll();
      const newPresent = canvas?._objects.map((object: fabric.Object) => {
        return {
          object,
          data: object.toJSON(['data']),
        };
      });
      setHistory(newPresent);
    });
  };

  const onFontSizeChange = (fontSize: string) => {
    seFontSize(fontSize);
    activeObjects?.map((object: any) => {
      if (object.type === 'text' || object.type === 'i-text') {
        object.set({ fontSize: Number(fontSize) });
      }
    });
    canvas?.renderAll();
  };

  const onChangeFontStyle = (style: FontStyle) => {
    let newStyle: any = {};
    if (style === FontStyle.Bold) {
      const newValue = !bold;
      setBold(newValue);
      newStyle = { fontWeight: newValue ? 'bold' : 'normal' };
    } else if (style === FontStyle.Italic) {
      const isItalic = !italic;
      setItalic(isItalic);
      newStyle = { fontStyle: isItalic ? 'italic' : 'normal' };
    } else if (style === FontStyle.Underline) {
      const newValue = !underline;
      setUnderline(newValue);
      newStyle = { underline: newValue };
    }
    activeObjects?.map((object: any) => {
      if (object.type === 'text' || object.type === 'i-text') {
        object.set(newStyle);
      }
    });
    canvas?.renderAll();
    const newPresent = canvas?._objects.map((object: fabric.Object) => {
      return {
        object,
        data: object.toJSON(['data']),
      };
    });
    setHistory(newPresent);
  };

  if (!isTextSelected) {
    return null;
  }

  return (
    <>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ color: 'black', marginRight: '10px' }}
      />
      <Select
        id="select-font"
        value={font}
        label="Select Font"
        size="small"
        onChange={(e) => onFontChange(e.target.value)}
      >
        {fontFamilies.map((font) => (
          <MenuItem key={font} value={font}>
            {font}
          </MenuItem>
        ))}
      </Select>
      <Autocomplete
        id="font-size-picker"
        options={FONT_SIZES.map((fontSize) => fontSize.toString())}
        renderInput={(params) => (
          <TextField
            {...params}
            value={Number(params.inputProps.value)}
            type="number"
          />
        )}
        size="small"
        sx={{ minWidth: '100px', marginLeft: '10px' }}
        value={fontSize}
        onChange={(e, value) => {
          onFontSizeChange(value);
        }}
        freeSolo
        disableClearable
      />
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ color: 'black', marginLeft: '15px' }}
      />
      <Tooltip title="Bold">
        <Button onClick={() => onChangeFontStyle(FontStyle.Bold)} active={bold}>
          <FormatBoldIcon />
        </Button>
      </Tooltip>
      <Tooltip title="Italic">
        <Button
          onClick={() => onChangeFontStyle(FontStyle.Italic)}
          active={italic}
        >
          <FormatItalicIcon />
        </Button>
      </Tooltip>
      <Tooltip title="Underlined">
        <Button
          onClick={() => onChangeFontStyle(FontStyle.Underline)}
          active={underline}
        >
          <FormatUnderlined />
        </Button>
      </Tooltip>
    </>
  );
};
